<lef-section-title [title]="'Your Subscriptions'"></lef-section-title>
<div *ngIf="autoShip">
    <div class="row mb-4 auto-ship-top" *ngIf="autoShip.messages.length > 0">
        <div class="col auto-ship-top-left">
            <lef-cart-messages [messages]="autoShip.messages"></lef-cart-messages>
        </div>
    </div>

    <div class="auto-ship-tabs">
        <button *ngIf="selectedTabIndex == 0" tabindex="0" class="auto-ship-sch-link btn-link btn-hover-underline"
                aria-haspopup="dialog" (click)="openSchedule()">
            <i class="k-icon k-font-icon k-i-calendar font-2x mr-2"></i> <span class="font-weight-bold">Upcoming
                Shipments</span>
        </button>
        <kendo-tabstrip (keyup.enter)="onTabSelect(null)" #autoShipTabWrap id="autoShipTabWrap"
                        (tabSelect)="onTabSelect($event)" [keepTabContent]="true">
            <kendo-tabstrip-tab *ngFor="let tab of tabs let i=index" [title]="tab.title" [disabled]="tab.disabled"
                                [selected]="i == selectedTabIndex">
                <ng-template kendoTabContent *ngIf="tab.tab=='items'">
                    <div *ngIf="autoShip?.hasItemsAutoShip else noAutoshipMessage">
                        <div class="d-md-flex mb-3">
                            <div>
                                <div *ngIf="!autoShip.isWholesaler" class="mb-3">
                                    <input id="useLERewards" type="checkbox" [(ngModel)]="autoShip.useRewardsOnVip"
                                           (ngModelChange)="updateUseRewardsOnVip($event)" />
                                    <label for="useLERewards" class="d-inline">Use <span
                                              class="zilla text-primary font-weight-bold">
                                            LE Dollars</span> first for payment. <button
                                                class="font-weight-normal btn-link-txt d-inline p-0"
                                                (click)="openLeDollarsDialog(leDollarsInformation)"><span>Learn
                                                more.</span> <i
                                               class="lef-information-solid text-info ml-1"></i></button>
                                    </label>
                                </div>
                                <p>AutoShip orders will be shipped once a month for each delivery address.<br />
                                    You will receive an email reminder of upcoming shipments</p>
                                <p *ngIf="autoShip.isWholesaler">
                                    The price that is currently being displayed is the standard customer price.
                                    When your VIP Auto Ship order is processed your Wholesale price will be honored.
                                </p>
                            </div>
                            <div class="text-right flex-auto">
                                <div class="d-lg-flex justify-content-lg-end as-actions">
                                    <button class="btn btn-info font-weight-bold mb-md-2 mb-lg-0"
                                            [ngClass]="{'btn-info btn-info-active': showActiveItems, 'btn-white-info':!showActiveItems}"
                                            [disabled]="!hasActiveDeliveries"
                                            (click)="toggleActiveItems(true)">{{getItemButtonTitle(true)}}</button>
                                    <button class="btn font-weight-bold"
                                            [ngClass]="{'btn-white-info': showActiveItems, 'btn-info btn-info-active':!showActiveItems}"
                                            [disabled]="!hasInactiveDeliveries"
                                            (click)="toggleActiveItems(false)">{{getItemButtonTitle(false)}}</button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="showActiveItems">
                            <div *ngFor="let delivery of autoShip.activeDeliveries">
                                <lef-auto-ship-delivery [delivery]="delivery" [isActiveView]="true"
                                                        (updateAutoShip)="updateAutoShip($event)">
                                </lef-auto-ship-delivery>
                            </div>
                        </div>
                        <div *ngIf="!showActiveItems">
                            <div *ngFor="let delivery of autoShip.inactiveDeliveries">
                                <lef-auto-ship-delivery [delivery]="delivery" [isActiveView]="false"
                                                        (updateAutoShip)="updateAutoShip($event)">
                                </lef-auto-ship-delivery>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template kendoTabContent *ngIf="tab.tab=='premier'">
                    <div *ngFor="let delivery of autoShip.subscriptionDeliveries">
                        <lef-auto-ship-delivery [delivery]="delivery" [isActiveView]="true"
                                                (updateAutoShip)="updateAutoShip($event)">
                        </lef-auto-ship-delivery>
                    </div>
                </ng-template>
            </kendo-tabstrip-tab>
        </kendo-tabstrip>

    </div>
</div>

<ng-template #noAutoshipMessage>

    <h2>Welcome To AutoShip & Save</h2>
    <p>
        Configuring your AutoShip & Save orders is easy. Just follow the simple steps below. Once you set up your first
        item(s) for automatic replenishment, you’ll be able to easily manage your entire AutoShip & Save account from
        here.
    </p>
    <p>
        <strong>Step 1</strong> — Add item(s) for AutoShip & Save to your shopping cart.
        <br />
        <strong>Step 2</strong> — Choose the Frequency and Next Shipment date for each item.
        <br />
        <strong>Step 3</strong> — Place your order.
        <br />
    </p>
    <p>
        Your AutoShip & Save items will ship within one business day, and they’ll be set up to ship to you automatically
        when you need them again.
    </p>
</ng-template>

<ng-template #leDollarsInformation>
    <p>
        If you’ve earned LE Dollars, you can use them like cash for your Life Extension purchases. Just check the box to
        apply your LE Dollars balance to your recurring AutoShip & Save orders.
    </p>
</ng-template>