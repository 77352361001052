<div class="loginComponent">
  <label id="loginEmail" *ngIf="showWelcomeMessage && (!loginModel || !loginModel.email)"
         class="login-welcome text-primary">
    <strong>{{welcomeMessage}}</strong>
  </label>

  <div class="customer-email">
    <p>
      <span *ngIf="showEmailLabel && loginModel?.email"><strong>{{label}}</strong></span>
      <span *ngIf="loginModel?.email"> {{loginModel.email}}</span>
      <span *ngIf="showResetOption">
        <button (click)="resetForms()" class="btn btn-link ml-3">Change</button>
      </span>
    </p>
  </div>

  <div class="email-form" *ngIf="showEmailForm">
    <form class="k-form w-100" [formGroup]="emailForm" (ngSubmit)="submitEmailForm()">
      <div class="d-md-flex justify-content-md-between">
        <div class="w-100 login-email">
          <input kendoTextBox lefSetFocus type="text" class="form-control" [attr.placeholder]="placeHolder"
                 formControlName="email" aria-label="Email Address" />
          <p *ngIf="isResellerFlow" class="mt-1 mb-0" role="alert">Your account email address must match the owner's
            email
            address</p>
        </div>
        <button type="submit" class="btn btn-action h-100" [disabled]="!submitEnabled">Submit</button>
      </div>
      <div *ngIf="showFormErrorMessage && errorMessage">
        <p class="text-red mt-1" role="alert">{{errorMessage}}</p>
      </div>
      <div *ngIf="showFormErrorMessage && !emailForm.valid && emailForm.controls.email.errors?.notNumber">
        <p class="mt-1" role="alert">If you don't know the email address asociated with your account, please contact
          support at<br>1-800-678-8989.</p>
      </div>
    </form>
  </div>
  <div class="password-form" *ngIf="showPasswordForm">
    <label class="mb-3"><strong>Welcome back!</strong> Please enter your password to sign in and
      complete your purchase.</label>
    <form class="k-form" [formGroup]="passwordForm" (ngSubmit)="submitLoginForm()">
      <div class="sc-login-box d-md-flex justify-content-md-between mb-md-3">
        <div class="pass-container">
          <input kendoTextBox lefSetFocus type="password" class="form-control login-email" placeholder="Password"
                 [type]="showLoginPassword ? 'text' : 'password'" formControlName="password"
                 aria-label="enter password" />
          <button type="button" class="hide-show-btn" (click)="toggleLoginPasswordVisibility()">{{showLoginPassword ?
            'Hide'
            :
            'Show'}}</button>
        </div>
        <button type="submit" class="btn btn-action sc-login-btn"
                [disabled]="!passwordForm.valid || !submitEnabled">Submit</button>
      </div>
      <div *ngIf="showFormErrorMessage" class="mt-2">
        <p class="text-red" role="alert">{{errorMessage}}</p>
      </div>
      <div class="mt-2">
        <button type="button" (click)="showForgotPasswordDialog()" aria-haspopup="dialog" class="btn btn-link mr-3"
                tabindex="0">
          {{forgotPasswordLabel}}</button>
      </div>
    </form>
  </div>
  <div class="new-password-form" *ngIf="showNewPasswordForm">
    <p class="m-0"><strong>Create an Account</strong></p>
    <form class="k-form" [formGroup]="newPasswordForm" (ngSubmit)="submitNewPasswordForm()">
      <div>
        <p class="mb-3">Save your information for future orders and access your order history.</p>

        <div class="pass-container">
          <input kendoTextBox lefSetFocus type="password" class="form-control mb-2" placeholder="Create a Password*"
                 aria-label="Create a Password" formControlName="password"
                 [type]="showRegistrationPassword ? 'text' : 'password'" />
          <button type="button" class="hide-show-btn"
                  (click)="toggleRegistrationPasswordVisibility()">{{showRegistrationPassword ?
            'Hide' :
            'Show'}}</button>
        </div>
        <div *ngIf="newPasswordForm.controls.password.hasError('passwordStrength') && newPasswordForm.controls.password.touched"
             class="mb-2">
          <p class="text-red" role="alert">{{ ValidationMessages.PasswordStrengthMessage }}</p>
        </div>
        <div *ngIf="newPasswordForm.controls.password.hasError('passwordMinLength') && newPasswordForm.controls.password.touched"
             class="mb-2">
          <p class="text-red mb-1" role="alert">{{ ValidationMessages.PasswordMinLengthMessage }}</p>
        </div>

        <div class="pass-container">
          <input kendoTextBox type="password" class="form-control mb-2" placeholder="Retype Password*"
                 aria-label="Retype password" formControlName="passwordConfirmation"
                 [type]="showConfirmPassword ? 'text' : 'password'" />
          <button type="button" class="hide-show-btn" (click)="toggleConfirmPasswordVisibility()">{{showConfirmPassword
            ?
            'Hide' :
            'Show'}}</button>
        </div>

        <div
             *ngIf="newPasswordForm.controls.passwordConfirmation.hasError('passwordMismatch') && newPasswordForm.controls.passwordConfirmation.touched">
          <p class="text-red" role="alert">{{ ValidationMessages.PasswordMismatchMessage }}</p>
        </div>
        <div *ngIf="showFormErrorMessage">
          <p class="text-red" role="alert">{{errorMessage}}</p>
        </div>
        <div class="text-md-right">
          <button type="submit" class="btn btn-action"
                  [disabled]="!newPasswordForm.valid  || !submitEnabled">Submit</button>
        </div>
      </div>
    </form>
  </div>
  <div class="zip-form" *ngIf="showZipVerificationForm">
    <form class="k-form" [formGroup]="zipVerificationForm" (ngSubmit)="submitZipForm()">
      <div *ngIf="!showFormErrorMessage" class="mb-3">
        <label id="loginZipCode" role="alert">For Account Verification please enter your billing zip code.</label>
      </div>
      <div class="login-zip-verify">
        <input kendoTextBox lefSetFocus type="text" class="form-control" placeholder="Zip Code"
               formControlName="zipCode" aria-labelledby="loginZipCode" />
        <button type="submit" class="btn btn-action"
                [disabled]="!zipVerificationForm.valid || !submitEnabled">Submit</button>
      </div>
      <div *ngIf="showFormErrorMessage">
        <p class="text-red" role="alert">{{errorMessage}}</p>
      </div>
    </form>
  </div>
  <div class="country-form" *ngIf="showCountryVerificationForm">
    <form class="k-form" [formGroup]="countryVerificationForm" (ngSubmit)="submitCountryForm()">
      <div *ngIf="!showFormErrorMessage" class="mb-3">
        <p role="alert">For Account Verification please select your billing country.</p>
      </div>
      <div class="d-flex justify-content-between mb-3">
        <select formControlName="country" name="country">
          <option [ngValue]="null">Select*</option>
          <ng-container *ngFor="let opt of countries">
            <option [ngValue]="opt.code">{{ opt.description }}</option>
          </ng-container>
        </select>
        <button type="submit" class="btn btn-action"
                [disabled]="!countryVerificationForm.valid|| !submitEnabled">Submit</button>
      </div>
      <div *ngIf="showFormErrorMessage">
        <p class="text-red" role="alert">{{errorMessage}}</p>
      </div>
    </form>
  </div>
</div>