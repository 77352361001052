import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { HtmlUtilityService } from 'src/app/shared/service/html-utilitity.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'lef-quantity-input',
  templateUrl: './quantity-input.component.html',
  styleUrls: ['./quantity-input.component.scss'],
})
export class QuantityInputComponent implements OnInit, OnChanges {
  @Input() label = '';
  @Input() quantity = 1;
  @Input() canChangeQuantity = true;
  @Input() componentId: string;

  @Output()
  quantityUpdate: EventEmitter<number> = new EventEmitter();

  quantityValue = '1';

  quantityDropDownOptions = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10+'];
  get showQuantityDropDown(): boolean {
    return !this.quantityValue || (this.quantityValue !== '10+' && +this.quantityValue < 10 && +this.quantityValue > 0);
  }

  constructor(private readonly htmlUtilityService: HtmlUtilityService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.itemQuantity) {
      this.initializeQuantity();
    }
  }

  ngOnInit() {
    this.initializeQuantity();
    if (!this.componentId) {
      this.componentId = uuidv4();
    }
  }

  initializeQuantity() {
    if (this.quantity) {
      this.quantityValue = this.quantity.toString();
    }
  }

  updateQuantity(newQuantity) {
    if (!newQuantity || +newQuantity === this.quantity) {
      return;
    }

    if (newQuantity === '0') {
      newQuantity = '1';
    }

    if (this.quantityValue !== newQuantity) {
      this.quantityValue = newQuantity;
    }

    if (newQuantity === '10+') {
      this.quantityValue = '10';
    }

    this.quantity = +this.quantityValue;
    this.htmlUtilityService.setFocusPoint(this.componentId);
    this.quantityUpdate.emit(this.quantity);
  }
}
