import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonMessage } from 'src/app/common/model/common-message.model';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { InvoicePreferences } from 'src/app/preferences/model/invoice-preferences.model';
import { BaseService } from 'src/app/shared/service/base-service';
import { IProp65 } from '../model/prop65.model';
import { IAccountInformation } from './../model/account-information.model';
import { IAccount } from './../model/account.model';
import { IOfficer } from './../model/officer.model';
import { ISignInSettings } from './../model/sign-in-settings.model';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseService<IAccount> {
  protected apiUrl: string;

  constructor(protected override readonly httpClient: HttpClient, private readonly config: CoreConfiguration) {
    super(httpClient);
    this.apiUrl = config.environment.api + config.apiRoutes.account;
  }

  getCustomer(): Observable<IAccount> {
    return this.getSingle(`${this.apiUrl}/Details`);
  }

  getOfficer(): Observable<IOfficer> {
    return this.httpGet(`${this.apiUrl}/GetOfficerInfo`);
  }

  saveOfficer(model: IOfficer): Observable<IOfficer> {
    return this.httpClient.post<IOfficer>(`${this.apiUrl}/SaveOfficer`, model);
  }

  getItemProp65List(): Observable<IProp65[]> {
    return this.httpGet(`${this.apiUrl}/GetItemProp65List`);
  }

  getSignInSettings(): Observable<ISignInSettings> {
    return this.httpGet(`${this.apiUrl}/GetSignInSettings`);
  }

  changeEmail(model: ISignInSettings): Observable<ISignInSettings> {
    return this.httpClient.post<ISignInSettings>(`${this.apiUrl}/ChangeEmail`, model);
  }

  changePassword(model: ISignInSettings): Observable<ISignInSettings> {
    return this.httpClient.post<ISignInSettings>(`${this.apiUrl}/ChangePassword`, model);
  }

  getAccountInformation(): Observable<IAccountInformation> {
    return this.httpGet(`${this.apiUrl}/GetAccountInformation`);
  }

  saveAccountInformation(model: IAccountInformation): Observable<IAccountInformation> {
    return this.httpClient.post<IAccountInformation>(`${this.apiUrl}/SaveAccountInformation`, model);
  }

  updateNcoaRecord(model: IAccountInformation): Observable<IAccountInformation> {
    return this.httpClient.post<IAccountInformation>(`${this.apiUrl}/UpdateNcoaRecord`, model);
  }

  saveAutoRenew(paymentOptionId: string, account: IAccount): Observable<IAccount> {
    return this.post(`${this.apiUrl}/SaveAutoRenew?PaymentOptionId=${paymentOptionId}`, account);
  }

  customerConfirmEmail(model: ISignInSettings): Observable<ISignInSettings> {
    return this.httpClient.post<ISignInSettings>(`${this.apiUrl}/CustomerConfirmEmail`, model);
  }

  validationEmail(id: string) {
    return this.httpClient.post<CommonMessage>(`${this.apiUrl}/EmailActivation?id=${id}`, {});
  }

  getInvoicePreference(): Observable<InvoicePreferences> {
    return this.httpClient.get<InvoicePreferences>(`${this.apiUrl}/GetInvoicePreference`);
  }

  updateInvoicePreferences(invoicePreferences: InvoicePreferences): Observable<InvoicePreferences> {
    return this.httpClient.put<InvoicePreferences>(`${this.apiUrl}/UpdateInvoicePreferences`, invoicePreferences);
  }
}
