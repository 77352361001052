import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ItemConfigurationService } from 'src/app/cart-common/service/item-configuration.service';
import { IPaymentOption } from 'src/app/payment/model';
import { IAutoShipChange } from '../../model/auto-ship-change';
import { IAutoShipDelivery } from '../../model/auto-ship-delivery';
import { IAutoShipItem } from '../../model/auto-ship-item';
import { AutoShipService } from '../../service';
import { AutoShipDialogService } from '../../service/auto-ship-dialog.service';

@Component({
  selector: 'lef-auto-ship-subscription-item',
  templateUrl: './auto-ship-subscription-item.component.html',
  styleUrls: ['./auto-ship-subscription-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AutoShipSubscriptionItemComponent implements OnInit, OnChanges {
  @Input()
  item: IAutoShipItem;

  @Input()
  delivery: IAutoShipDelivery;

  @Input()
  shippingDay: string;

  @Output()
  updateAutoShip: EventEmitter<void> = new EventEmitter();

  get itemUrl(): string {
    return this.itemConfig.getItemUrl(this.item);
  }

  showPaymentMethod: boolean = false;

  constructor(
    private readonly itemConfig: ItemConfigurationService,
    private readonly dialogService: AutoShipDialogService,
    private readonly autoShipService: AutoShipService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) { }

  onPaymentSelected(paymentOption: IPaymentOption) {
    if(this.delivery.isNewSubscription){
      this.item.paymentOptionId = paymentOption.id;
      this.addPremierItem();
      return;
    }

    const paymentChange: IAutoShipChange = {
      paymentOptionId: paymentOption.id,
      autoShipNumber: this.delivery.autoShipNumber,
      autoShipItemId: this.item.autoShipItemId,
    };

    this.autoShipService.changePaymentMethod(paymentChange).subscribe((autoShip) => {
      this.autoShipService.autoShipChange.next(autoShip);
    });
  }

  showSubscriptionInformation() {
    this.dialogService.openSubscriptionInformationDialog();
  }

  reactivate() {
    if(this.delivery.isBilling) {
      this.addPremierItem();
      return;
    }

    this.autoShipService.reactivateItem(this.item.autoShipItemId).subscribe((autoShip) => {
      this.autoShipService.autoShipChange.next(autoShip);
    });
  }

  deactivate() {
    if(this.delivery.isBilling) {
      this.delete();
      return;
    }

    this.autoShipService.deactivateItem(this.item.autoShipItemId).subscribe((autoShip) => {
      this.autoShipService.autoShipChange.next(autoShip);
    });
  }

  private addPremierItem() {
    if(!this.item.paymentOptionId || this.item.paymentOptionId == '00000000-0000-0000-0000-000000000000' ){
      this.showPaymentMethod = true;
      return;
    }

    this.autoShipService.addPremierItem(this.item.paymentOptionId).subscribe((autoShip) => {
      this.autoShipService.autoShipChange.next(autoShip);
    });
  }

  private delete() {
    this.autoShipService.deleteItem(this.item.autoShipItemId).subscribe((autoShip) => {
      this.autoShipService.autoShipChange.next(autoShip);
    });
  }

}
