import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAutoShipChange } from '../../model/auto-ship-change';
import { AutoShipService, AutoShipValuesService } from '../../service';

@Component({
  selector: 'lef-auto-ship-item-reschedule',
  templateUrl: './auto-ship-item-reschedule.component.html',
  styleUrls: ['./auto-ship-item-reschedule.component.scss']
})
export class AutoShipItemRescheduleComponent implements OnInit {

  constructor(
    private readonly autoShipService: AutoShipService,
    private readonly valuesService: AutoShipValuesService
  ) { }

  @Input()
  nextShipDate: Date = new Date();

  @Input()
  autoShipNumber = 0;

  @Input()
  autoShipItemId: string = null;

  @Input()
  autoShipItemName: string = null;

  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter();

  updateAllDeliveryItems = false;

  shippingDates: Array<{ value: string; text: string }>;
  shippingDate: string;

  ngOnInit() {
    this.valuesService.setAdjustedAutoShipStartDates(this.nextShipDate.getDate());
    this.valuesService.useAdjustedDates = true;

    this.shippingDates = this.valuesService.getAutoShipStartDates();
    this.shippingDate = this.shippingDates?.find(x => x?.value === this.nextShipDate.toDateString())?.value;
  }

  submit() {
    const rescheduleChange: IAutoShipChange = {
      rescheduleDate: this.shippingDate,
      autoShipNumber: this.autoShipNumber,
      autoShipItemId: this.updateAllDeliveryItems ? null : this.autoShipItemId,
    };

    this.autoShipService.rescheduleDate(rescheduleChange).subscribe((autoShip) => {
      this.closeDialog.emit();
      this.autoShipService.autoShipChange.next(autoShip);
    });
  }
}
