import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  printElement(elementToPrint: HTMLElement): void {
    const printable = elementToPrint.cloneNode(true);
    this.renderer.addClass(document.body, 'print-section-only');
    const printDiv = this.renderer.createElement('div');
    this.renderer.setAttribute(printDiv, 'id', 'printSection');
    this.renderer.appendChild(printDiv, printable);
    this.renderer.appendChild(document.body, printDiv);
    window.print();
    this.renderer.removeClass(document.body, 'print-section-only');
    this.renderer.removeChild(document.body, printDiv);
  }
}
