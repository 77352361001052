<div class="p-3 bg-light-blue premier-banner">
  <div class="d-lg-flex align-items-center">
    <div class="pr-lg-3 mb-2 mb-lg-0 premier-rw-logo">
      <img src="../../../../assets/images/premier_rewards.svg" alt="Premier Rewards" aria-hidden="true" />
      <h1>
        <span class="roboto text-info font-weight-bold">Premier</span><br />
        <span class="h3 text-primary">Rewards</span>
      </h1>
      <!-- <img src="../../../../assets/images/premier-rewards.png" alt="Premier Rewards" /> -->
    </div>
    <div>
      <div class="os-premier-prog">
        <kendo-progressbar [value]="progress" [label]="{visible: false}"></kendo-progressbar> <span
              class="pl-2">{{adjustedTotal|currency}}</span>
      </div>
      <div *ngIf="!isRenewalCandidate">
        <p *ngIf="!qualifies">
          Add {{amountLeft|currency}} to get <strong class="text-primary">FREE SHIPPING</strong>, plus qualify for
          <strong class="text-primary">PREMIER</strong> for more <strong>Exclusive Perks</strong> and <strong
                  class="text-primary">4% LE Dollars</strong> back for one
          year. Premier costs $49.95, but you'll receive a <strong>$50 instant credit</strong> as soon as you enroll.
        </p>

        <p *ngIf="qualifies">
          You have reached <strong class="text-primary">PREMIER</strong> status for no extra cost. Get <strong>FREE
            SHIPPING, Exclusive Perks,</strong> plus earn <strong>4% LE Dollars</strong> back for one year. Premier
          costs $49.95, but you'll receive a <strong>$50 instant credit</strong> as soon as you enroll.
        </p>
      </div>
      <div *ngIf="isRenewalCandidate">
        <p *ngIf="!qualifies">
          <strong *ngIf="!isVipPremierCustomer">
            {{ isPremierExpired ? 'Your Premier Rewards have expired! ': 'Expiring Soon! '}}
          </strong>
          Spend {{amountLeft|currency}} more to renew your <strong class="text-primary">PREMIER</strong> for free!
          Remember, you get <strong>FREE SHIPPING</strong>for a year, <strong>Exclusive Perks</strong> and earn
          <strong>4% LE Dollars.</strong> Premier costs $49.95, but you'll receive a <strong>$50 instant credit</strong>
          as soon as you enroll.
        </p>
        <p *ngIf="qualifies">
          <strong *ngIf="!isVipPremierCustomer">
            {{ isPremierExpired ? 'Your Premier Rewards have expired! ': 'Expiring Soon! '}}
          </strong>
          You qualify to renew your <strong class="text-primary">PREMIER</strong> at <strong>NO EXTRA COST</strong>.
          You will get <strong>FREE SHIPPING</strong> for a year, <strong>Exclusive Perks</strong> and earn <strong>4%
            LE Dollars.</strong> Premier costs $49.95, but you'll receive a <strong>$50 instant credit</strong> as soon
          as you enroll.
        </p>
      </div>
      <div class="premier-banner-learn">
        <button class="btn btn-white-orange font-weight-bold px-5 mb-2 mb-lg-0" (click)="addPremier()">
          Activate Now
        </button>

        <a (click)="showPremierInfo(premierInfo)" class="ml-lg-3 d-flex align-items-center text-info"><span>Learn
            More</span><i class="lef-information-solid text-info ml-1"></i></a>
      </div>
    </div>
  </div>

</div>

<ng-template #premierInfo>
  <div class="premier-modal">
    <div class="text-center mb-4">
      <img src="../../../../assets/images/premier-rewards.png" width="211" />
    </div>
    <div class="premier-modal-content">
      <div class="premier-modal-list">
        <div><img src="../../../../assets/images/cashback.svg" alt="Free Unlimited Shipping" /></div>
        <p>
          <strong>Get 4% Back on Every Purchase</strong><br />
          Earn LE Dollars you can use on any Life Extension product & lab services!
        </p>
      </div>
      <div class="premier-modal-list">
        <div><img src="../../../../assets/images/unlimited-free-shipping.svg" alt="Free Unlimited Shipping" /></div>
        <p>
          <strong>Unlimited free shipping</strong><br />
          No matter how big or small the order, U.S. shipping is always free
        </p>
      </div>
      <div class="premier-modal-list">
        <div><img src="../../../../assets/images/exclusive-savings.svg" alt="Free Unlimited Shipping" /></div>
        <p>
          <strong>Exclusive Savings</strong><br />
          Get sneak peaks into sales & special pricing
        </p>
      </div>
      <div class="premier-modal-list">
        <div><img src="../../../../assets/images/premium-content.svg" alt="Free Unlimited Shipping" /></div>
        <p>
          <strong>Premium Content</strong><br />
          Exclusive webinars, plus a free subscription to Life Extension Magazine<sup>&reg;</sup>!
        </p>
      </div>
    </div>
    <div class="text-right pt-3">
      <a [href]="faqUrl" class="text-primary" target="_blank">
        <span class="font-weight-medium mr-1">Premier Program FAQs</span>
        <i class="lef-chevron-circle-right text-orange"></i>
      </a>
    </div>
  </div>
</ng-template>