import { Component, OnInit } from '@angular/core';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { LoadingService } from 'src/app/shared/service/loading.service';

@Component({
  selector: 'lef-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
  constructor(public readonly config: CoreConfiguration, private readonly loadingService: LoadingService) {}

  ngOnInit() {
    this.loadingService.hide();
  }
}
