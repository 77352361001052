import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CartCommonModule } from '../cart-common/cart-common.module';
import { SharedModule } from '../shared/shared.module';
import {
  PaymentApplePayComponent,
  PaymentCreditCardComponent,
  PaymentGooglePayComponent,
  PaymentMethodSelectorComponent,
  PaymentNewMethodComponent,
  PaymentOptionSelectorComponent,
  PaymentPayPalComponent,
} from './component';
import { PaypalPayLaterMessagesComponent } from './component/paypal-pay-later-messages/paypal-pay-later-messages.component';
import { PciCallbackComponent } from './component/pci-callback/pci-callback.component';

const components = [
  PaymentOptionSelectorComponent,
  PaymentMethodSelectorComponent,
  PaymentPayPalComponent,
  PaymentCreditCardComponent,
  PaymentNewMethodComponent,
  PciCallbackComponent,
  PaypalPayLaterMessagesComponent,
  PaymentApplePayComponent,
  PaymentGooglePayComponent,
];

const modules = [CommonModule, SharedModule, CartCommonModule];

@NgModule({
  declarations: [components],
  imports: [modules],
  exports: [components],
})
export class PaymentModule {}
