import { Component, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { ICart } from 'src/app/cart-common/model/cart';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { TrackingService } from '../../service/tracking.service';

declare global {
  interface Window {
    POWERREVIEWS: any;
  }
}

@Component({
  selector: 'lef-power-reviews',
  templateUrl: './power-reviews.component.html',
  styleUrls: ['./power-reviews.component.scss'],
})
export class PowerReviewsComponent implements OnInit {
  private containerId = 'pr-srw-container';
  private container: HTMLDivElement;
  private scriptId = 'pwr-ui';
  private script: HTMLScriptElement;

  constructor(
    private readonly trackingService: TrackingService,
    private readonly config: CoreConfiguration,
    private readonly renderer: Renderer2
  ) {
    if (config.environment.powerReviews.enabled && this.trackingService.checkOneTrustActiveGroup(this.config.oneTrustGroups.mandatory)) {
      trackingService.orderPlaced.subscribe({ next: (cart) => this.onOrderPlaced(cart) });
      trackingService.navigationChanged.subscribe({ next: (event) => this.onNavigationChanged(event) });
    }
  }

  ngOnInit(): void {}

  private onNavigationChanged(event: NavigationEnd) {
    const pagesWithPowerReviewsEnabled = this.config.environment.powerReviews.includedOn;
    if (pagesWithPowerReviewsEnabled.indexOf(event.urlAfterRedirects) === -1) {
      this.removePowerReviewsResources();
    }
  }

  onOrderPlaced(cart: ICart) {
    //this.triggerSellerRating();
    this.triggerOrderTracker(cart);
  }

  triggerOrderTracker(cart: ICart) {
    if (this.config.environment.powerReviews.orderTrackerEnabled && !!window.POWERREVIEWS && !!window.POWERREVIEWS.tracker) {
      try {
        const customer = this.trackingService.getCurrentCustomerData();
        const tracker = window.POWERREVIEWS.tracker.createTracker({
          merchantGroupId: '14918',
        });
        const items = cart.items
          .filter((i) => i.quantityBackOrdered < i.quantity)
          .map((i) => ({
            page_id: i.itemNumber,
            quantity: i.quantity - i.quantityBackOrdered,
            unit_price: i.yourPrice,
          }));
        if (items.length > 0) {
          const orderFeed = {
            merchantGroupId: '14918',
            merchantId: '650325',
            locale: 'en_US',
            merchantUserId: customer.customerNumber,
            marketingOptIn: true,
            userEmail: customer.email,
            userFirstName: customer.firstName,
            userLastName: customer.lastName,
            orderId: cart.orderNumber,
            orderItems: items,
          };
          tracker.trackCheckout(orderFeed);
        }
      } catch (e) {}
    }
  }

  triggerSellerRating() {
    if (!!window.POWERREVIEWS) {
      const powerReviewsConfig = this.config.environment.powerReviews;
      window.POWERREVIEWS.config = window.POWERREVIEWS.config || {};
      window.POWERREVIEWS.config.merchant_id = powerReviewsConfig.merchantId;
      window.POWERREVIEWS.config.page_id = powerReviewsConfig.checkoutPageId;
      window.POWERREVIEWS.config.locale = 'en_US';
      window.POWERREVIEWS.config.srwVariant = 'long';
      this.addPowerReviewsResources();
    }
  }

  addPowerReviewsResources() {
    new Promise((resolve, reject) => {
      this.container = document.createElement('div');
      this.container.id = this.containerId;
      this.container.className = 'p-w-r';
      this.renderer.appendChild(document.body, this.container);

      this.script = document.createElement('script');
      this.script.id = this.scriptId;
      this.script.src = '//ui.powerreviews.com/stable/3.0/ui.js';
      this.script.onload = () => resolve(null);
      this.script.onerror = reject;
      this.renderer.appendChild(document.body, this.script);
    }).then(
      () => {
        _ = window.POWERREVIEWS.display.renderSRW;
      },
      (error) => {}
    );
  }

  private removePowerReviewsResources() {
    if (!!this.container) {
      this.renderer.removeChild(document.body, this.container);
      this.container = null;
    }

    if (!!this.script) {
      this.renderer.removeChild(document.body, this.script);
      this.script = null;
    }
  }
}
