import { FloatingCartItem } from './floating-cart-item.model';

export class FloatingCart {
  ItemsTotal: string;
  TotalSavings: string;
  SubTotal: string;
  TotalItemsCount: number;
  HasMoreItems: boolean;
  Items: FloatingCartItem[] = [];
}
