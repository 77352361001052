import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AppValidators } from 'src/app/shared/form/form-validations';
import { LoginModel } from '../../model/login-model.model';
import { PasswordResetModel } from '../../model/password-reset.model';
import { LoginService } from '../../service/login.service';

@Component({
  selector: 'lef-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  @Input()
  recoveryId: string = null;

  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter();

  passwordLabel = 'Password';
  passwordConfirmationLabel = 'Confirm Password';

  passwordUpdatedMessage = 'Your Password was updated. Continue to Sign In.';
  passwordRecoveryExpiredMessage = 'The recovery link is invalid or has expired.';

  requestModel = new LoginModel();
  resetPasswordModel: PasswordResetModel;
  resetPasswordForm: UntypedFormGroup;
  resetPasswordFormFields: FormlyFieldConfig[];

  showForm = false;
  showMessage = false;
  message = '';

  constructor(private readonly loginService: LoginService) { }

  ngOnInit() {
    this.initForm();
    if (this.recoveryId) {
      this.validateRecoveryId();
    } else {
      this.displayMessage(this.passwordRecoveryExpiredMessage);
    }
  }

  initForm() {
    const passwordControl = new UntypedFormControl('password');
    const confirmationControl = new UntypedFormControl('passwordConfirmation');

    this.resetPasswordModel = new PasswordResetModel();
    this.resetPasswordForm = new UntypedFormGroup({});
    this.resetPasswordFormFields = [
      {
        key: 'password',
        type: 'focusInput',
        templateOptions: {
          label: this.passwordLabel,
          placeholder: '',
          type: 'password',
        },
        formControl: passwordControl,
        validators: {
          validation: [
            Validators.required,
            AppValidators.passwordMinLength,
            AppValidators.passwordStrength,
            AppValidators.passwordMismatchTrigger('passwordConfirmation')],
        },
      },
      {
        key: 'passwordConfirmation',
        type: 'kTextBox',
        templateOptions: {
          label: this.passwordConfirmationLabel,
          placeholder: '',
          type: 'password',
        },
        formControl: confirmationControl,
        validators: {
          validation: [Validators.required, AppValidators.passwordMismatch('password')],
        },
      },
    ];
  }

  validateRecoveryId() {
    this.requestModel.recoveryId = this.recoveryId;
    this.loginService.validateRecoveryId(this.requestModel).subscribe(
      (response: LoginModel) => {
        if (response.success) {
          this.displayForm();
        } else {
          this.displayMessage(this.passwordRecoveryExpiredMessage);
        }
      },
      (error) => {
        this.displayMessage(this.passwordRecoveryExpiredMessage);
      }
    );
  }

  resetPassword() {
    if (this.resetPasswordForm.valid) {
      this.requestModel.password = this.resetPasswordModel.password;
      this.loginService.resetPassword(this.requestModel).subscribe(
        (response: LoginModel) => {
          if (response.success) {
            this.displayMessage(this.passwordUpdatedMessage);
          } else {
            this.displayMessage(this.passwordRecoveryExpiredMessage);
          }
        },
        (error) => {
          this.displayMessage(this.passwordRecoveryExpiredMessage);
        }
      );
    }
  }

  close() {
    this.closeDialog.emit();
    this.initForm();
  }

  displayForm() {
    this.showMessage = false;
    this.showForm = true;
  }

  displayMessage(message: string) {
    this.message = message;

    this.showForm = false;
    this.showMessage = true;
  }
}
