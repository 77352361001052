<div class="d-flex justify-content-around">
    <div #printable class="label-wrapper">
        <div class="pb-3">
            <p>
                We’re eager to process your return and/or exchange. Please send back the item(s) listed below within 30
                days.
                Upon receipt, we will fulfill your refund and/or exchange(s).Please print this email and follow these
                instructions to send us your item(s).
            </p>
            <p>
                Thank you for shopping with Life Extension.
            </p>
            <p><strong class="text-primary">Return Item(s)</strong></p>
            <div class="order-details-wh">
                <ul class="order-details-wh-hd">
                    <li><strong>Item #</strong></li>
                    <li><strong>Name</strong></li>
                    <li><strong>Quantity</strong></li>
                </ul>
                <ul class="order-details-wh-content" *ngFor="let item of items">
                    <li>{{item.itemNumber}}</li>
                    <li>{{item.itemName}}</li>
                    <li>{{item.quantity}}</li>
                </ul>
            </div>
            <div class="order-details-instr">
                <p><strong class="text-primary">Instructions</strong></p>
                <div class="row">
                    <div class="col-md-9">
                        <ol>
                            <li>Print your shipping label</li>
                            <li>Pack the items listed above (include print-out of the packaging slip)</li>
                            <li>Affix the shipping label to your package</li>
                            <li>Take package to local drop-off location or arrange to have it picked up</li>
                        </ol>
                    </div>
                    <div class="col-md-3">
                        <img src="https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl={{orderNumber}}" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>