export interface IAccountVerification {
  email: string;
  verificationCode: string
  customerNumber: number;
  isValid: boolean;
  errorMessage: string;
}

export class AccountVerificationModel implements IAccountVerification {
  email: string;
  verificationCode: string
  customerNumber: number;
  isValid: boolean;
  errorMessage: string;

  constructor() {
    this.email = '';
    this.isValid = false;
    this.verificationCode = '';
    this.customerNumber = 0;
    this.errorMessage = '';
  }
}
