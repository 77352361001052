import { Component, OnInit } from '@angular/core';
import { TrackingService } from '../../service/tracking.service';

@Component({
  selector: 'lef-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss'],
})
export class TrackingComponent implements OnInit {
  isTrackingLoaded: boolean = false;

  constructor(private readonly trackingService: TrackingService) {}

  ngOnInit(): void {
    this.trackingService.loadTracking.subscribe({ next: () => this.onLoadTracking() });
  }

  onLoadTracking(): void {
    this.isTrackingLoaded = true;
  }
}
