import { Component, OnInit, Renderer2 } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthorizationService } from 'src/app/authorization';
import { ICart } from 'src/app/cart-common/model';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { TrackingService } from '../../service/tracking.service';

@Component({
  selector: 'lef-cj',
  templateUrl: './cj.component.html',
  styleUrls: ['./cj.component.scss'],
})
export class CJComponent implements OnInit {
  private cjId: string;
  constructor(
    private trackingService: TrackingService,
    private readonly config: CoreConfiguration,
    private cookieService: CookieService,
    private readonly renderer: Renderer2,
    private readonly authService: AuthorizationService
  ) {
    if (
      config.environment.commissionJunction.enableCJTracking &&
      this.trackingService.checkOneTrustActiveGroup(this.config.oneTrustGroups.mandatory)
    ) {
      trackingService.orderPlaced.subscribe({ next: (cart) => this.onOrderPlaced(cart) });
      this.cjId = this.getCookieCJIdValue();
    }
  }

  ngOnInit(): void {
    //Just checking if new id is generated
  }

  onOrderPlaced(cart: ICart) {
    this.cjId = this.getCookieCJIdValue();
    if (!this.authService.isWholesaler() && !this.authService.isEmployee()) {
      const customer = this.trackingService.getCurrentCustomerData();
      const data = cart.items
        .map((c, i) => `ITEM${i + 1}=${c.itemNumber}&AMT${i + 1}=${c.yourPriceUnRounded.toFixed(4)}&QTY${i + 1}=${c.quantity}`)
        .join('&');
      const cjCoupon = this.getCoupon(cart);
      const shipping = cart.shipping.selectedAddress;
      const country = shipping.countryCode;
      const mapObj = {
        _order_: cart.orderNumber,
        _idValue_: this.cjId,
        _items_: data,
        _LOYALTY_STATUS_: customer.customerType === 'P' ? 'Yes' : 'No',
        _CUST_COUNTRY_: country,
        _LOCATION_: country === 'US' || country === 'CA' ? shipping.state : '',
        _CUST_STATUS_: customer.customerRecurrence === 'New' ? 'New' : 'Return',
      };
      const beaconUrl = this.config.environment.commissionJunction.affiliateImageUrl;
      let beaconSource = beaconUrl.replace(
        /_order_|_idValue_|_items_|_LOYALTY_STATUS_|_CUST_COUNTRY_|_LOCATION_|_CUST_STATUS_/gi,
        (matched) => {
          return mapObj[matched];
        }
      );

      if (cart.marketingDiscountItem) {
        beaconSource = `${beaconSource}&DISCOUNT=${Math.abs(cart.marketingDiscountItem.yourPrice)}&COUPON=${cjCoupon}`;
      } else {
        beaconSource = `${beaconSource}&DISCOUNT=0&COUPON=${cjCoupon}`;
      }
      const iframe = document.createElement('iframe');
      iframe.id = 'ConversionBeacon';
      iframe.height = '1';
      iframe.width = '1';
      iframe.src = beaconSource;
      iframe.name = 'Conversiontag';
      this.renderer.appendChild(document.body, iframe);
    }
  }
  getCoupon(cart: ICart) {
    if (cart.exclusiveMarketingCode !== '') {
      return cart.exclusiveMarketingCode;
    }
    const intRegex = new RegExp(/^\s*INT\s*/);
    const item = cart.items.find((i) => !intRegex.test(i.marketingCode));
    return item ? item.marketingCode.trim() : '';
  }

  private getCookieCJIdValue(): string {
    if (!this.cookieService.check(this.config.environment.commissionJunction.cookieName)) {
      var storageCookieId = localStorage.getItem(this.config.environment.commissionJunction.cookieName);
      return storageCookieId ? storageCookieId : null;
    }

    const cookieString = this.cookieService.get(this.config.environment.commissionJunction.cookieName);
    const cookie = decodeURIComponent(cookieString);

    const cookieValues = cookie.split('&');
    if (cookieValues.length === 1) {
      if (cookieValues[0].indexOf('=') === -1) {
        return cookieValues[0];
      }
    }
    const cookieObject = {};
    for (const cookieValue of cookieValues) {
      const kvp = cookieValue.split('=');
      if (!kvp[0]) {
        continue;
      }

      cookieObject[kvp[0]] = kvp.length > 1 ? kvp[1] : '';
    }

    const cookieId = cookieObject[this.config.environment.commissionJunction.cookieID];
    localStorage.setItem(this.config.environment.commissionJunction.cookieName, cookieId);
    return cookieId;
  }
}
