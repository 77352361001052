<div *ngIf="!autoPrint" class="d-print-none">
  <div class="pb-lg-3 pt-3">
    <lef-section-title [title]="'Prepaid Shipping Label'"></lef-section-title>
  </div>

  <div class="d-flex justify-content-between">
    <button *ngIf="!autoPrint" class="btn btn-link text-primary font-weight-bold" routerLink="/account/history">
      <i class="lef-chevron-circle-left text-orange"></i> My Orders
    </button>

    <div *ngIf="!autoPrint && hasLabel" class="d-print-none">
      <button class="btn btn-link">
        <div class="d-flex align-items-center" (click)="simplePrint()">
          Print Label <i class="lef-print font-lg ml-2"></i>
        </div>
      </button>
    </div>
  </div>
</div>

<div #printable class="label-wrapper">
  <div *ngIf="hasLabel" [ngClass]="{'ups-wrapper':shippingLabel.carrier.toLowerCase() === 'ups'}">
    <img *ngIf="shippingLabel.carrier.toLowerCase() === 'ups'" class="ups-label"
         [attr.src]="'data:image/gif;base64,' + shippingLabel.imageData" />
    <img *ngIf="shippingLabel.carrier.toLowerCase() === 'usps'" class="usps-label"
         [attr.src]="'data:image/gif;base64,' + shippingLabel.imageData" />
  </div>
  <div *ngIf="!hasLabel">
    A return label could not be found. Please contact customer service at 1-800-678-8989.
  </div>
</div>