import { Component, Input, OnInit } from '@angular/core';
import { CoreConfiguration } from 'src/app/core/core-configuration';

@Component({
  selector: 'lef-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  @Input() align: 'left' | 'center' | 'right' = 'left';
  constructor(public readonly config: CoreConfiguration) {}

  ngOnInit(): void {}

  public sendFeedBack() {
    const feedBackUrl = `${this.config.environment.feedbackUrl}`;
    const windowSettings =
      'width=1010,height=630,toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=yes,resizable=yes,left=50,top=50';
    window.open(feedBackUrl, 'surveywindow', windowSettings);
  }
}
