import { CoreConfiguration } from 'src/app/core/core-configuration';
import { IPaymentGateway } from './payment-gateway';
import {
  BraintreeGooglePayPaymentProcessor,
  EmptyGooglePayPaymentProcessor,
  IGooglePayPaymentProcessor,
} from './payment-google-pay-processor';

export class GooglePayPaymentProcessorFactory {
  constructor(private readonly config: CoreConfiguration){ }

  GetProcessor(gateway: IPaymentGateway): IGooglePayPaymentProcessor {
    switch (gateway.name) {
      case 'Braintree':
        return new BraintreeGooglePayPaymentProcessor(gateway, this.config);

      default:
        return new EmptyGooglePayPaymentProcessor();
    }
  }
}
