import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { AccountVerificationModel } from '../../model/verification-code.model';
import { LoginService } from '../../service/login.service';

@Component({
  selector: 'lef-verify-account-code',
  templateUrl: './verify-account-code.component.html',
  styleUrls: ['./verify-account-code.component.scss']
})
export class VerifyAccountCodeComponent implements OnInit {
  @Output() closeDialog: EventEmitter<void> = new EventEmitter();
  @Output() accountVerified: EventEmitter<AccountVerificationModel> = new EventEmitter();

  @Input() verificationModel = new AccountVerificationModel();

  @ViewChildren('inputField') private inputFields: QueryList<ElementRef>;

  form: FormGroup;
  requestVerificationCodeEnabled: boolean = true;
  showCodeError: boolean = false;
  submitEnabled: boolean = true;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly loginService: LoginService,
    private readonly renderer: Renderer2,
    private readonly loadingService: LoadingService) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    if (this.form === undefined) {
      this.form = this.formBuilder.group({
        digit1: ['', Validators.required],
        digit2: ['', Validators.required],
        digit3: ['', Validators.required],
        digit4: ['', Validators.required],
        digit5: ['', Validators.required],
        digit6: ['', Validators.required]
      });
    }
  }

  onKeyPress(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    const regex = /^[0-9]+$/;

    if (event.key.length > input.maxLength || !regex.test(event.key)) {
      event.preventDefault();
    }
  }

  moveFocus(event: Event, index: number) {
    const input = event.target as HTMLInputElement;
    if (input.value.length === input.maxLength) {
      const nextInput = this.inputFields.toArray()[index + 1];
      if (nextInput) {
        this.renderer.selectRootElement(nextInput.nativeElement).focus();
      }
    }
  }

  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData || window['clipboardData'];
    if (!clipboardData) {
      return;
    }

    const pastedCode = clipboardData.getData('text')?.trim();
    if (isNaN(pastedCode)) {
      return;
    }

    if (pastedCode.length === this.inputFields.toArray().length) {
      for (let i = 0; i < this.inputFields.toArray().length; i++) {
        this.form.controls[`digit${i + 1}`].setValue(pastedCode[i]);
      }

      this.showCodeError = false;
    }
    event.preventDefault();
  }

  verify(): void {
    this.loadingService.show();
    this.submitEnabled = false;
    this.verificationModel.verificationCode = `${this.form.value.digit1}${this.form.value.digit2}${this.form.value.digit3}${this.form.value.digit4}${this.form.value.digit5}${this.form.value.digit6}`;

    if (this.form.valid) {
      this.loginService.verifyAccount(this.verificationModel).subscribe({
        next: (response: AccountVerificationModel) => {
          if (!response || response.errorMessage) {
            this.showCodeError = true;
            return;
          }
          this.accountVerified.emit(response);
          this.close();
          this.loadingService.hide();
        },
        error: () => {
          this.submitEnabled = true;
          this.loadingService.hide();
        },
        complete: () => {
          this.submitEnabled = true;
          this.loadingService.hide();
        }
      });
    }
  }

  requestVerificationCode(): void {
    this.loadingService.show();
    this.requestVerificationCodeEnabled = false;
    this.loginService.requestVerificationCode().subscribe({
      complete: () => {
        this.loadingService.hide();
      }
    });
  }

  close(): void {
    this.closeDialog.emit();
    this.initForm();
  }
}