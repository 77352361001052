import { BraintreeApplePayPaymentProcessor, EmptyApplePayPaymentProcessor, IApplePayPaymentProcessor } from './payment-apple-pay-processor';
import { IPaymentGateway } from './payment-gateway';

export class ApplePayPaymentProcessorFactory {
  GetProcessor(gateway: IPaymentGateway): IApplePayPaymentProcessor {
    switch (gateway.name) {
      case 'Braintree':
        return new BraintreeApplePayPaymentProcessor(gateway);

      default:
        return new EmptyApplePayPaymentProcessor();
    }
  }
}
