import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CoreConfiguration } from 'src/app/core/core-configuration';

@Injectable({
  providedIn: 'root',
})
export class ResellerAppGuardGuard  {
  constructor(private readonly config: CoreConfiguration) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.config.environment.enableWholesaleResellerApp;
  }
}
