import { PaymentMethod } from './payment-method';
import { PaymentType } from './payment-type';

export class WalletPayment {
  id: string;
  amount: number;
  nonce: string;
  email: string;
  firstName: string;
  lastName: string;
  name: string;
  payerId: string;
  deviceData: string;
  postalCode: string;
  savePayment: boolean;
  isCheckOut?: boolean;
  instantSave?: boolean;
  usingCheckoutFlow?: boolean;
  paymentType?: PaymentType;
  paymentMethod?: PaymentMethod;
}
