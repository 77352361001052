import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/account/service/account.service';
import { CommonMessage, ICommonMessage } from 'src/app/common/model/common-message.model';
import { LoadingService } from 'src/app/shared/service/loading.service';

@Component({
  selector: 'lef-email-activation',
  templateUrl: './email-activation.component.html',
  styleUrls: ['./email-activation.component.scss'],
})
export class EmailActivationComponent implements OnInit {
  model: ICommonMessage = new CommonMessage();

  constructor(
    private readonly accountService: AccountService,
    private readonly loadingService: LoadingService,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    let params: any = this.route.snapshot.queryParams;
    if (!params) {
      params = this.route.snapshot.params;
    }
    if (params) {
      const id = params.Id || params.id;
      if (id) {
        this.emailValidation(encodeURIComponent(id));
      }
    }
  }

  emailValidation(id: any) {
    this.loadingService.show('Loading...');
    this.accountService.validationEmail(id).subscribe({
      next: (result: CommonMessage) => {
        this.model = result;
        this.loadingService.hide();
      },
      error: (error) => {
        this.loadingService.hide();
      },
      complete: () => {
        this.loadingService.hide();
      },
    });
  }
}
