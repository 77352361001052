import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CartCommonModule } from '../cart-common/cart-common.module';
import { CommonsModule } from '../common/common.module';
import { SharedModule } from '../shared/shared.module';
import { MinorConsentRequisitionComponent } from './component/minor-consent-requisition/minor-consent-requisition.component';
import { MinorConsentComponent } from './component/minor-consent/minor-consent.component';

const modules = [CommonModule, SharedModule, RouterModule, CommonsModule, CartCommonModule];

const components = [MinorConsentComponent, MinorConsentRequisitionComponent];

@NgModule({
  declarations: [components],
  imports: [modules],
})
export class MinorConsentModule {}
