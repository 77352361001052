import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { RegionVerification } from '../../model/region-verification';

@Component({
  selector: 'lef-distributor-country',
  templateUrl: './distributor-country.component.html',
  styleUrls: ['./distributor-country.component.css']
})
export class DistributorCountryComponent implements OnInit {

  @Input()
  verification: RegionVerification;

  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter();

  distributor: string;


  constructor(private readonly config: CoreConfiguration) { }

  ngOnInit() {
  }

  close() {
    this.closeDialog.emit();
  }

  goToDistributorSite() {
    window.location.href = this.verification.distributorSiteUrl;
    this.closeDialog.emit();
  }

}
