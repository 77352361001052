import { AfterViewInit, ChangeDetectionStrategy, Component, Renderer2, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { DateInputComponent } from '@progress/kendo-angular-dateinputs';

@Component({
  selector: 'lef-date-input',
  template: `
    <kendo-dateinput
      #dateInput
      [formControl]="formControl"
      [formlyAttributes]="field"
      [format]="'MM/dd/yyyy'"
      placeholder="MM/DD/YYYY"
      [min]="to.minDate"
      [max]="to.maxDate"
      (valueChange)="to.change && to.change(field, $event)"
    ></kendo-dateinput>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDateInputComponent extends FieldType<FieldTypeConfig> implements AfterViewInit {
  @ViewChild('dateInput', { read: DateInputComponent }) dateInput: DateInputComponent;

  constructor(private readonly renderer: Renderer2) {
    super();
  }

  ngAfterViewInit(): void {
    if (this.dateInput) {
      if (!this.props.label) {
        this.renderer.setAttribute(this.dateInput.inputElement.nativeElement, 'aria-label', this.props.placeholder);
      }
    }
  }
}
