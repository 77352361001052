<div>
  <form class="k-form" [formGroup]="form" (ngSubmit)="validateAddress()">
    <formly-form [model]="model" [fields]="formFields" [form]="form"></formly-form>
    <div class="messages">
      <div *ngFor="let message of model.messages">
        <p class="text-red">{{message.message}}</p>
      </div>
    </div>
    <div class="text-right">
      <button type="submit" class="btn btn btn-warning" *ngIf="!model.hasErrorInAddress">Submit</button>
      <button type="button" class="btn btn btn-warning mr-3" (click)="validateAddress()"
              *ngIf="model.hasErrorInAddress">Verify Again</button>
      <button type="button" class="btn btn btn-warning" (click)="saveAsEnter()" *ngIf="model.hasErrorInAddress">Continue
        as Entered</button>
    </div>
  </form>
</div>
