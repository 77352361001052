import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lef-auto-ship-deactivation',
  templateUrl: './auto-ship-deactivation.component.html',
  styleUrls: ['./auto-ship-deactivation.component.scss']
})
export class AutoShipDeactivationComponent {

  @Input()
  itemName: string;

  @Input()
  shippingDay: string;

  @Output()
  deactivate: EventEmitter<any> = new EventEmitter();

  @Output()
  reschedule: EventEmitter<any> = new EventEmitter();

}
