<div class="collapse-box co-collapse-box" [ngClass]="boxClass">
  <div class="collapse-box-header" [ngClass]="{headerClass: true, 'has-pointer' : enabled}" (click)="toggleExpanded()">
    <div class="collapse-box-header-title" [ngClass]="titleClass">
      <ng-content select="[title]"></ng-content>
    </div>
    <div *ngIf="showIcon" class="collapse-box-header-icon">
      <i *ngIf="expanded" [ngClass]="expandedIcon"></i>
      <i *ngIf="!expanded" [ngClass]="collapsedIcon"></i>
    </div>
  </div>
  <div @openClose *ngIf="expanded" class="collapse-box-body" [ngClass]="bodyClass">
    <ng-content select="[body]"></ng-content>
    <ng-content></ng-content>
  </div>
</div>