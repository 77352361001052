import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeaderService } from 'src/app/common/service/header.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { MinorConsent } from '../../model/minor-consent.model';
import { Requisition } from '../../model/requisition.model';
import { MinorConsentService } from '../../service/minor-consent.service';

@Component({
  selector: 'lef-minor-consent',
  templateUrl: './minor-consent.component.html',
  styleUrls: ['./minor-consent.component.scss'],
})
export class MinorConsentComponent implements OnInit, OnDestroy {
  orderNumber: number;
  consent: MinorConsent = new MinorConsent();
  isValid: boolean;

  get hasRequisitions(): boolean {
    return this.consent.requisitions.length > 0;
  }

  constructor(
    private readonly route: ActivatedRoute,
    private readonly minorConsentService: MinorConsentService,
    private readonly loadingService: LoadingService,
    private readonly headerService: HeaderService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.headerService.showFullHeader = false;
    });
    this.orderNumber = this.route.snapshot.params.orderNumber;
    this.loadingService.show(['Please Wait...', 'Loading consent information']);
    this.minorConsentService.get(this.orderNumber).subscribe({
      next: (consent: MinorConsent) => {
        this.consent = consent;
      },
      error: (error) => {},
      complete: () => {
        this.loadingService.hide();
      },
    });
  }

  ngOnDestroy(): void {
    this.headerService.showFullHeader = true;
  }

  onConsentByChanged(requisition: Requisition) {
    for (let index = 0; index < this.consent.requisitions.length; index++) {
      const element = this.consent.requisitions[index];
      if (element.requisitionId === requisition.requisitionId) {
        this.consent.requisitions[index] = requisition;
      }
    }
    const r = this.consent.requisitions.find((req) => req.consentBy === '');
    this.isValid = !!!r;
  }

  submitConsent() {
    this.loadingService.show(['Please Wait...', 'Sending information']);
    this.minorConsentService.set(this.consent).subscribe({
      next: (consent: MinorConsent) => {
        this.consent = consent;
      },
      error: (error) => {},
      complete: () => {
        this.loadingService.hide();
      },
    });
  }
}
