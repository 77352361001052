import { Injectable } from '@angular/core';
import * as braintree from 'braintree-web';
import { Subject } from 'rxjs';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { PaymentGateway } from '../model/payment-gateway';
import { PaymentOptionService } from './payment-option.service';
declare global {
  interface Window {
    google: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class GooglePayService {
  public readonly googlePayAvailabilityChange: Subject<boolean> = new Subject();
  public readonly onButtonsReady: Subject<boolean> = new Subject<boolean>();
  public IsStatusOnline = false;
  private auth: string;
  private signature: string;
  private requiredIntent = 'authorize';
  googlePayInstance: any;
  googlePayClient: any;
  deviceData = '';
  supportedBrowser = /edg\/|chrome\/|safari\/|firefox\/|opr\/|ucbrowser\//i.test(window.navigator.userAgent);

  private isGooglePayServiceEnabled: boolean;
  get isGooglePaySessionActive(): boolean {
    return this.isGooglePayServiceEnabled;
  }

  constructor(private readonly paymentOptionService: PaymentOptionService, private readonly config: CoreConfiguration) {
    this.isGooglePayServiceEnabled = !!window.google && !!window.google.payments;
  }

  refreshGooglePayInstanceForAuthorization(gateway: PaymentGateway) {
    this.refreshGooglePayInstance('authorize', gateway);
  }

  refreshGooglePayInstanceForCapture(gateway: PaymentGateway) {
    this.refreshGooglePayInstance('capture', gateway);
  }

  private refreshGooglePayInstance(intent: string, gateway: PaymentGateway) {
    if (!this.supportedBrowser) {
      return;
    }
    if (!!gateway) {
      this.checkIfRequireRefresh(gateway, intent);
    } else {
      this.paymentOptionService.paymentOptions(false, false).subscribe({
        next: (result) => {
          this.checkIfRequireRefresh(result.gateway, intent);
        },
        error: (error) => {},
      });
    }
  }

  private setOnlineStatus(status: boolean) {
    this.IsStatusOnline = status;
    this.googlePayAvailabilityChange.next(status);
  }

  private checkIfRequireRefresh(gateway: PaymentGateway, intent: string) {
    this.isGooglePayServiceEnabled = gateway.isGooglePayEnabled;
    const refreshRequired = this.isGooglePayServiceEnabled && (gateway.signature !== this.signature || intent !== this.requiredIntent);
    if (refreshRequired) {
      this.requiredIntent = intent;
      this.auth = gateway.auth;
      this.signature = gateway.signature;
      this.setOnlineStatus(false);
      this.initGooglePayInstance();
    }
  }

  initGooglePayInstance() {
    this.googlePayClient = new window.google.payments.api.PaymentsClient({
      environment: this.config?.googlepayConfiguration?.environment,
      paymentDataCallbacks: {
          onPaymentAuthorized: () => Promise.resolve({transactionState: 'SUCCESS'})
        }
    });

    braintree.client
      .create({
        authorization: this.auth,
      })
      .then((clientInstance) => {
        braintree.dataCollector
          .create({
            client: clientInstance,
          })
          .then((dataCollectorInstance) => {
            this.deviceData = dataCollectorInstance.deviceData;
          })
          .catch((err) => {
            console.log(err)
          });

        return braintree.googlePayment.create({
          client: clientInstance,
          googlePayVersion: 2,
          googleMerchantId: this.config?.googlepayConfiguration?.merchantId,
        });
      })
      .then((googlePaymentInstance) => {
        this.googlePayInstance = googlePaymentInstance || {};
        this.setOnlineStatus(true);
      })
      .catch((err) => {
        console.error('GooglePayService', err);
        this.setOnlineStatus(false);
      });
  }
}
