import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { IListItem } from 'src/app/shared/model/list-item';
import { CommonService } from 'src/app/shared/service/common.service';
import { HtmlUtilityService } from 'src/app/shared/service/html-utilitity.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { ApplicationSectionStep } from '../../model/application-section-step.enum';
import { IApplicationSection } from '../../model/application-section.model';
import { CompanyRegistration } from '../../model/company-registration.model';
import { ResellerApplicationService } from '../../service/reseller-application.service';

@Component({
  selector: 'lef-reseller-company-registration',
  templateUrl: './reseller-company-registration.component.html',
  styleUrls: ['./reseller-company-registration.component.scss'],
})
export class ResellerCompanyRegistrationComponent implements OnInit, OnChanges, OnDestroy, IApplicationSection {
  stepNumber = '2.';
  stepTitle = 'Company Registration';
  submitAction = 'continue';
  isDataAlreadyInitialized = false;
  model: CompanyRegistration = new CompanyRegistration();
  applicationForm: UntypedFormGroup = new UntypedFormGroup({});
  conditionalValidatorsMap = new Map<string, ValidatorFn[]>();
  companyInformationFieldsRequired = ['name', 'address', 'city', 'state', 'postalCode'];
  corporateOfficeInformationFieldsRequired = ['name', 'address', 'city', 'state', 'postalCode', 'emailAddress', 'phone'];
  ownerContactInformationFieldsRequired = ['name', 'address', 'city', 'state', 'postalCode', 'emailAddress', 'phone'];
  buyerContactInformationFieldsRequired = ['name', 'emailAddress', 'phone'];
  countries: IListItem[] = [];
  usCountryState: IListItem[] = [];
  statesCompany: IListItem[] = [];
  statesCorporate: IListItem[] = [];
  statesOwner: IListItem[] = [];
  statesBuyerContact: IListItem[] = [];

  @Input()
  applicationId: string;
  @Input()
  isActive = false;
  @Input()
  isEditable = false;
  @Input()
  hasContent = false;
  @Input()
  isContentValid = true;

  @Output()
  applicationProcessContinue: EventEmitter<IApplicationSection> = new EventEmitter<IApplicationSection>();

  @Output()
  activateSection: EventEmitter<ApplicationSectionStep> = new EventEmitter<ApplicationSectionStep>();

  customerRequestSaveSectionSubscription: Subscription;

  constructor(
    private readonly commonService: CommonService,
    private readonly loadingService: LoadingService,
    private readonly resellerApplicationService: ResellerApplicationService,
    private readonly DOMElement: ElementRef,
    private readonly htmlUtilityService: HtmlUtilityService
  ) {}

  isValid(): boolean {
    return this.applicationForm.valid;
  }

  isDirty(): boolean {
    return this.applicationForm.dirty;
  }

  activateCompanySection() {
    this.activateSection.emit(ApplicationSectionStep.CompanyRegistration);
  }

  saveSection(): void {
    this.loadingService.show('Saving company information');
    this.model = new CompanyRegistration(this.applicationForm.value);
    this.model.isValid = this.applicationForm.valid;
    this.resellerApplicationService.saveCurrentCompanyRegistration(this.model).subscribe(
      (registration) => {
        this.isContentValid = this.model.isValid;
        if (this.submitAction === 'continue') {
          this.applicationProcessContinue.emit(this as IApplicationSection);
        } else {
          this.model = registration;
          this.initForm();
        }
        this.loadingService.hide();
      },
      (error) => {
        this.loadingService.hide();
      }
    );
  }

  save() {
    this.submitAction = 'save';
    this.saveSection();
  }

  onSubmit() {
    if (this.applicationForm.valid) {
      this.submitAction = 'continue';
      this.saveSection();
      return;
    }
    this.htmlUtilityService.focusFirstInvalidElement(this.DOMElement);
  }

  getCurrentStep(): ApplicationSectionStep {
    return ApplicationSectionStep.CompanyRegistration;
  }

  ngOnInit(): void {
    this.customerRequestSaveSectionSubscription = this.resellerApplicationService.customerRequestSaveSection.subscribe(() => {
      if (this.isActive) {
        this.save();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.customerRequestSaveSectionSubscription) {
      this.customerRequestSaveSectionSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isActive) {
      if (changes.isActive.currentValue) {
        this.initializeData();
      }
    }
  }

  initializeData() {
    this.loadingService.show();
    forkJoin([
      this.commonService.getCountryList(),
      this.commonService.getStateList(''),
      this.resellerApplicationService.getCurrentCompanyRegistration(this.applicationId),
    ])
      .pipe(
        map(([countries, states, companyRegistration]) => {
          return { countries, states, companyRegistration };
        })
      )
      .subscribe(
        (data) => {
          this.countries = data.countries;
          this.usCountryState = data.states;
          this.statesCompany = data.states;
          this.statesCorporate = data.states;
          this.statesBuyerContact = data.states;
          this.statesOwner = data.states;
          this.model = data.companyRegistration;
          this.initForm();
          this.loadingService.hide();
        },
        () => {
          this.loadingService.hide();
        }
      );
  }

  checkValidators(section: string, checkboxField: string, fields: string[]) {
    const container = this.applicationForm.get(section);
    this.applicationForm.get(checkboxField).valueChanges.subscribe((same) => {
      fields.forEach((field) => {
        const formControl = container.get(field);
        const fieldValidators = this.conditionalValidatorsMap.has(`${section}_${field}`)
          ? this.conditionalValidatorsMap.get(`${section}_${field}`)
          : [Validators.required];
        if (formControl) {
          formControl.setValidators(!same ? fieldValidators : []);
          formControl.updateValueAndValidity();
        }
      });
    });
  }

  initForm(): void {
    this.conditionalValidatorsMap.set('corporateOfficeInformation_emailAddress', [Validators.required, Validators.email]);
    this.conditionalValidatorsMap.set('buyerContactInformation_emailAddress', [Validators.required, Validators.email]);
    this.conditionalValidatorsMap.set('ownerContactInformation_emailAddress', [Validators.required, Validators.email]);
    const corporateOfficeInitialValidator = this.model.corporateContactSameAsContact ? [] : [Validators.required];
    const ownerContactInformationValidator = this.model.ownerInformationSameAsPersonal ? [] : [Validators.required];
    this.applicationForm = new UntypedFormGroup({
      action: new UntypedFormControl(''),
      id: new UntypedFormControl(this.model.id),
      applicationId: new UntypedFormControl(this.model.applicationId),
      hasOfficesOutsideUS: new UntypedFormControl(this.model.hasOfficesOutsideUS || 'N', [Validators.required]),
      exportOutsideUS: new UntypedFormControl(this.model.exportOutsideUS || 'N', [Validators.required]),
      companyInformationSameAsPersonal: new UntypedFormControl(this.model.companyInformationSameAsPersonal),
      companyInformation: new UntypedFormGroup({
        name: new UntypedFormControl(this.model.companyInformation.name, this.model.companyInformationSameAsPersonal ? [] : [Validators.required]),
        address: new UntypedFormControl(
          this.model.companyInformation.address,
          this.model.companyInformationSameAsPersonal ? [] : [Validators.required]
        ),
        city: new UntypedFormControl(this.model.companyInformation.city, this.model.companyInformationSameAsPersonal ? [] : [Validators.required]),
        state: new UntypedFormControl(
          this.model.companyInformation.state,
          this.model.companyInformationSameAsPersonal ? [] : [Validators.required]
        ),
        country: new UntypedFormControl(this.model.companyInformation.country),
        postalCode: new UntypedFormControl(
          this.model.companyInformation.postalCode,
          this.model.companyInformationSameAsPersonal ? [] : [Validators.required]
        ),
      }),
      contactPerson: new UntypedFormControl(this.model.contactPerson, [Validators.required]),
      title: new UntypedFormControl(this.model.title),
      emailAddress: new UntypedFormControl(this.model.emailAddress, [Validators.required, Validators.email]),
      website: new UntypedFormControl(this.model.website, []),
      phone: new UntypedFormControl(this.model.phone, [Validators.required]),
      stateOnIncorporation: new UntypedFormControl(this.model.stateOnIncorporation, [Validators.required]),
      corporateContactSameAsContact: new UntypedFormControl(this.model.corporateContactSameAsContact),
      corporateOfficeInformation: new UntypedFormGroup({
        name: new UntypedFormControl(this.model.corporateOfficeInformation.name, corporateOfficeInitialValidator),
        address: new UntypedFormControl(this.model.corporateOfficeInformation.address, corporateOfficeInitialValidator),
        city: new UntypedFormControl(this.model.corporateOfficeInformation.city, corporateOfficeInitialValidator),
        state: new UntypedFormControl(this.model.corporateOfficeInformation.state, corporateOfficeInitialValidator),
        country: new UntypedFormControl(this.model.corporateOfficeInformation.country),
        postalCode: new UntypedFormControl(this.model.corporateOfficeInformation.postalCode, corporateOfficeInitialValidator),
        emailAddress: new UntypedFormControl(
          this.model.corporateOfficeInformation.emailAddress,
          this.model.corporateContactSameAsContact ? [] : this.conditionalValidatorsMap.get('corporateOfficeInformation_emailAddress')
        ),
        phone: new UntypedFormControl(this.model.corporateOfficeInformation.phone, corporateOfficeInitialValidator),
      }),
      ownerInformationSameAsPersonal: new UntypedFormControl(this.model.ownerInformationSameAsPersonal),
      ownerContactInformation: new UntypedFormGroup({
        name: new UntypedFormControl(this.model.ownerContactInformation.name, ownerContactInformationValidator),
        address: new UntypedFormControl(this.model.ownerContactInformation.address, ownerContactInformationValidator),
        city: new UntypedFormControl(this.model.ownerContactInformation.city, ownerContactInformationValidator),
        state: new UntypedFormControl(this.model.ownerContactInformation.state, ownerContactInformationValidator),
        country: new UntypedFormControl(this.model.ownerContactInformation.country),
        postalCode: new UntypedFormControl(this.model.ownerContactInformation.postalCode, ownerContactInformationValidator),
        emailAddress: new UntypedFormControl(
          this.model.ownerContactInformation.emailAddress,
          this.model.ownerInformationSameAsPersonal ? [] : this.conditionalValidatorsMap.get('ownerContactInformation_emailAddress')
        ),
        phone: new UntypedFormControl(this.model.ownerContactInformation.phone, ownerContactInformationValidator),
      }),
      buyerContactSameAsOwner: new UntypedFormControl(this.model.buyerContactSameAsOwner),
      buyerContactInformation: new UntypedFormGroup({
        name: new UntypedFormControl(this.model.buyerContactInformation.name, this.model.buyerContactSameAsOwner ? [] : [Validators.required]),
        emailAddress: new UntypedFormControl(
          this.model.buyerContactInformation.emailAddress,
          this.model.buyerContactSameAsOwner ? [] : this.conditionalValidatorsMap.get('buyerContactInformation_emailAddress')
        ),
        phone: new UntypedFormControl(this.model.buyerContactInformation.phone, this.model.buyerContactSameAsOwner ? [] : [Validators.required]),
      }),
    });
    this.checkValidators('companyInformation', 'companyInformationSameAsPersonal', this.companyInformationFieldsRequired);
    this.checkValidators('corporateOfficeInformation', 'corporateContactSameAsContact', this.corporateOfficeInformationFieldsRequired);
    this.checkValidators('buyerContactInformation', 'buyerContactSameAsOwner', this.buyerContactInformationFieldsRequired);
    this.checkValidators('ownerContactInformation', 'ownerInformationSameAsPersonal', this.ownerContactInformationFieldsRequired);
    if (!this.isEditable) {
      this.applicationForm.disable();
    }
    this.isDataAlreadyInitialized = true;
  }

  companyInformationSameAsPersonalChange() {
    if (!this.applicationForm.get('companyInformationSameAsPersonal').value) {
      const phone = this.applicationForm.get('phone').value === null ? '' : this.applicationForm.get('phone').value;
      if ((phone as string).trim() === '' && this.model.currentCustomerInfo?.phoneNumber?.trim() !== '') {
        this.applicationForm.get('phone').setValue(this.model.currentCustomerInfo.phoneNumber);
      }
      const emailAddress = this.applicationForm.get('emailAddress').value === null ? '' : this.applicationForm.get('emailAddress').value;
      if ((emailAddress as string).trim() === '' && this.model.currentCustomerInfo?.email?.trim() !== '') {
        this.applicationForm.get('emailAddress').setValue(this.model.currentCustomerInfo.email);
      }
    }
  }

  countrySelectedChange(group: string, target: string) {
    const companyInformationControl = this.applicationForm.get(group);
    const newCountry = companyInformationControl.get('country').value;
    this.loadingService.show();
    this.commonService.getStateList(newCountry).subscribe(
      (states) => {
        this.updateStates(states, target);
        this.loadingService.hide();
      },
      () => {
        this.updateStates([], target);
        this.loadingService.hide();
      }
    );
  }

  updateStates(states: IListItem[], target: string) {
    if (target === 'company') {
      this.statesCompany = states;
    } else if (target === 'corporate') {
      this.statesCorporate = states;
    } else if (target === 'buyer') {
      this.statesBuyerContact = states;
    } else if (target === 'owner') {
      this.statesOwner = states;
    }
  }
}
