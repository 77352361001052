<div class="shipping-address-selection" [ngClass]="componentClass">
  <div *ngIf="searchEnable" class="selection-header mb-2 p-2">
    <div class="cart-box">
      <p>{{ searchMessage }}</p>
    </div>
    <div class="d-flex justify-content-between">
      <input type="text" class="form-control mr-3" [(ngModel)]="searchText"
             placeholder="Ship To Number / Last Name / Nickname" />
      <button type="button" class="btn btn-link" [disabled]="!this.searchText || this.searchText.length < 2"
              (click)="search()">Search</button>
    </div>
  </div>

  <div class="position-relative">
    <div class="d-flex justify-content-around align-items-center absolute-overlay" *ngIf="searching">
      <span class="k-i-loading k-icon k-font-icon font-weight-medium text-info" style="font-size: 34px;"></span>
    </div>
    <div *ngIf="shippingAddresses" class="selection-body">
      <div *ngFor="let shippingAddress of pagedShippingAddresses"
           class="selection-record d-flex justify-content-between p-2">
        <label class="d-flex justify-content-between clickable" (click)="onAddressSelected()">
          <input kendoRadioButton class="k-radio form-check-input k-radio" kendoRadioButton type="radio"
                 [value]="shippingAddress" [(ngModel)]="selectedAddress" />
          <div class="form-check-label">
            <div>{{shippingAddress.alias}}</div>
            <div>{{shippingAddress.address}} {{shippingAddress.city}}, {{shippingAddress.state}}
              {{shippingAddress.postalCode}}</div>
          </div>
        </label>
        <button class="btn btn-link font-weight-medium" (click)="onEditAddress(shippingAddress)">
          {{ shippingAddress.isEditableOnWeb? "Edit": "View"}}
        </button>
      </div>
    </div>
  </div>
  <div class="selection-footer">
    <div class="selection-footer-controls">
      <div>
        <button class="btn btn-action" (click)="onSelectionVerified()">Use This Address</button>
      </div>
      <div>
        <button class="btn btn-link font-weight-medium" (click)="onAddAddress()">Add New Address</button>
      </div>
    </div>
    <div *ngIf="this.shippingAddresses.length > paginationPageSize" class="text-center">
      <kendo-datapager [pageSize]="paginationPageSize" [skip]="paginationSkip" [total]="paginationTotalPages"
                       (pageChange)="onPageChange($event)">
      </kendo-datapager>
    </div>
  </div>
</div>