import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'lef-icon-label',
  template: `
    <div [ngClass]="iconLabelOptions.labelClass">
      <i
        #icon
        *ngIf="iconLabelOptions.iconPosition === 'left'"
        [ngClass]="iconLabelOptions.iconClass"
        (click)="onClick($event)"
        tabindex="0"
      ></i>
      {{ to.label }}
      <i
        #icon
        *ngIf="iconLabelOptions.iconPosition === 'right'"
        [ngClass]="iconLabelOptions.iconClass"
        (click)="onClick($event)"
        tabindex="0"
      ></i>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconLabelComponent extends FieldType<FieldTypeConfig> implements OnInit, AfterViewInit {
  @ViewChild('icon') icon: ElementRef;

  iconLabelOptions = {
    labelClass: '',
    iconClass: '',
    iconPosition: 'right',
    ariaHasPopup: '',
    role: '',
  };

  constructor(private readonly renderer: Renderer2) {
    super();
  }

  ngOnInit(): void {
    if (this.props.options) {
      const options: any[] = this.props.options as any[];
      if (options && options.length > 0) {
        if (options[0].labelClass) {
          this.iconLabelOptions.labelClass = options[0].labelClass;
        }
        if (options[0].iconClass) {
          this.iconLabelOptions.iconClass = options[0].iconClass;
        }

        if (options[0].iconPosition && options[0].iconPosition === 'left') {
          this.iconLabelOptions.iconPosition = 'left';
        }
        if (options[0].ariaHasPopup) {
          this.iconLabelOptions.ariaHasPopup = options[0].ariaHasPopup;
        }
        if (options[0].role) {
          this.iconLabelOptions.role = options[0].role;
        }
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.iconLabelOptions.ariaHasPopup && this.icon) {
      this.renderer.setAttribute(this.icon.nativeElement, 'aria-haspopup', this.iconLabelOptions.ariaHasPopup);
    }
    if (this.iconLabelOptions.role && this.icon) {
      this.renderer.setAttribute(this.icon.nativeElement, 'role', this.iconLabelOptions.role);
    }
  }

  onClick($event) {
    if (this.props.onClick) {
      this.props.onClick($event);
    }
  }
}
