import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { forkJoin } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { CommonDialogService } from 'src/app/common/service/common-dialog.service';
import { FormlyHelper } from 'src/app/shared/form/formly-helper';
import { IListItem } from 'src/app/shared/model/list-item';
import { CartDialogService } from 'src/app/shared/service/cart-dialog.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { IRegistration } from '../../model';
import { RegistrationService } from '../../service/registration.service';

@Component({
  selector: 'lef-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  @Input()
  model: IRegistration;

  @Output()
  registrationComplete: EventEmitter<boolean> = new EventEmitter();

  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter();

  password: string;
  salutationList: IListItem[] = [];
  countries: IListItem[] = [];
  states: IListItem[] = [];
  aboutUsOptions: IListItem[] = [];

  form: UntypedFormGroup = new UntypedFormGroup({});
  formFields: FormlyFieldConfig[] = [];
  hideEmailField = true;

  constructor(
    private readonly registrationService: RegistrationService,
    private readonly commonService: CommonService,
    private readonly cartDialogService: CartDialogService,
    private readonly commonDialogService: CommonDialogService,
    private readonly loadingService: LoadingService
  ) { }

  ngOnInit() {
    this.init();
  }

  validateAddress() {
    if ((!this.form.valid || !this.model) && this.model.isEditableOnWeb) {
      return;
    }

    this.loadingService.show('');

    this.registrationService.validateAddress(this.model).subscribe((response) => {
      this.model = response;

      if (this.model.isServiceByDistributor) {
        this.showDistributorDialog(response);
        this.loadingService.hide();
        return;
      }

      if (this.model.showAddressValidation) {
        this.showAddressValidationDialog();
        this.loadingService.hide();
        return;
      }
      if (this.model.hasErrorInAddress) {
        // Show Error Message
        this.loadingService.hide();
        return;
      }

      this.submit();
    });
  }

  saveAsEnter() {
    this.loadingService.show('');
    this.model.addressVerified = true;
    this.model.addressValidation.addressSelected = '';
    this.submit();
  }

  private init() {
    if (!this.model) {
      this.model = {};
    }

    this.password = this.model?.password ?? null;

    this.loadingService.show();
    forkJoin([
      this.commonService.getSalutationList(),
      this.commonService.getCountryList(),
      this.commonService.getStateList(''),
      this.commonService.getAboutUsList(),
      this.registrationService.getRegistrationInfo(this.model),
    ])
      .pipe(
        map(([salutationList, countries, states, aboutUs, registrationModel]) => {
          return { salutationList, countries, states, aboutUs, registrationModel };
        })
      )
      .subscribe((data) => {
        this.salutationList = data.salutationList;
        this.countries = data.countries;
        this.states = data.states;
        this.aboutUsOptions = data.aboutUs;
        this.model = data.registrationModel;

        this.setDefaultValues();
        this.initForm();
        this.loadingService.hide();
      });
  }

  private initForm() {
    if (!this.model.email) {
      this.hideEmailField = false;
    }
    this.form = new UntypedFormGroup({});
    this.formFields = [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            type: 'kTextBox',
            key: 'email',
            templateOptions: {
              label: 'Email',
              type: 'email',
              required: true,
              setFocus: true,
              disabled: !this.model.isEditableOnWeb
            },
            validators: {
              validation: [Validators.required, Validators.email],
            },
            hideExpression: (model: IRegistration) => this.hideEmailField,
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            type: 'checkbox',
            key: 'isCompany',
            templateOptions: {
              label: 'Company',
              disabled: !this.model.isEditableOnWeb
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-6',
            type: 'kDropdown',
            key: 'salutation',
            templateOptions: {
              placeholder: 'Salutation',
              options: this.salutationList,
              valueProp: 'value',
              labelProp: 'description',
              disabled: !this.model.isEditableOnWeb,
              autocomplete: 'honorific-prefix'
            },
            hideExpression: (model: IRegistration) => model.isCompany,
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-6',
            type: 'kTextBox',
            key: 'firstName',
            templateOptions: {
              placeholder: 'First Name*',
              disabled: !this.model.isEditableOnWeb,
              autocomplete: 'given-name'
            },
            validators: {
              validation: [Validators.required],
            },
            hideExpression: (model: IRegistration) => model.isCompany,
          },
          {
            className: 'col-6',
            type: 'kTextBox',
            key: 'lastName',
            templateOptions: {
              placeholder: 'Last Name*',
              disabled: !this.model.isEditableOnWeb,
              autocomplete: 'family-name'
            },
            validators: {
              validation: [Validators.required],
            },
            hideExpression: (model: IRegistration) => model.isCompany,
          },
          {
            className: 'col-12',
            type: 'kTextBox',
            key: 'mailingName',
            templateOptions: {
              placeholder: 'Company Name*',
              disabled: !this.model.isEditableOnWeb
            },
            validators: {
              validation: [Validators.required],
            },
            hideExpression: (model: IRegistration) => !model.isCompany,
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            type: 'checkbox',
            key: 'isPoBox',
            templateOptions: {
              label: 'P.O. Box',
              disabled: !this.model.isEditableOnWeb
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            type: 'kTextBox',
            key: 'addressLn1',
            templateOptions: {
              placeholder: 'Street Address 1*',
              disabled: !this.model.isEditableOnWeb,
              autocomplete: 'address-line1'
            },
            validators: {
              validation: [Validators.required],
            },
          },
          {
            className: 'col-12',
            type: 'kTextBox',
            key: 'addressLn2',
            templateOptions: {
              placeholder: 'Street Address 2*',
              disabled: !this.model.isEditableOnWeb,
              autocomplete: 'address-line2'
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-6',
            type: 'kTextBox',
            key: 'city',
            templateOptions: {
              placeholder: 'City*',
              disabled: !this.model.isEditableOnWeb,
              autocomplete: 'city'
            },
            validators: {
              validation: [Validators.required],
            },
          },
          {
            className: 'col-6',
            type: 'kTextBox',
            key: 'postalCode',
            templateOptions: {
              placeholder: 'ZIP Code',
              required: true,
              disabled: !this.model.isEditableOnWeb,
              autocomplete: 'postal-code'
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-6',
            type: 'kDropdown',
            key: 'country',
            templateOptions: {
              placeholder: 'Country',
              options: this.countries,
              valueProp: 'code',
              labelProp: 'description',
              disabled: !this.model.isEditableOnWeb,
              autocomplete: 'country'
            },
            hooks: {
              onInit: (field) => {
                return field.formControl.valueChanges.pipe(debounceTime(400)).subscribe((country) => this.onCountryChange(country));
              },
            },
          },
          {
            className: 'col-6',
            type: 'kDropdown',
            key: 'state',
            templateOptions: {
              placeholder: 'State',
              options: this.states,
              valueProp: 'value',
              labelProp: 'description',
              disabled: !this.model.isEditableOnWeb,
              autocomplete: 'state'
            },
            hideExpression: (model: IRegistration) => model.country !== '' && model.country !== 'CA',
            validators: {
              validation: [Validators.required],
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-6',
            type: 'kTextBox',
            key: 'phoneNumber',
            templateOptions: {
              placeholder: 'Phone',
              disabled: !this.model.isEditableOnWeb,
              autocomplete: 'tel'
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            type: 'kDropdown',
            key: 'aboutUs',
            templateOptions: {
              placeholder: 'How did you hear about us?',
              options: this.aboutUsOptions,
              valueProp: 'value',
              labelProp: 'description',
              disabled: !this.model.isEditableOnWeb
            },
            hideExpression: (model: IRegistration) => !model.showAboutUsDropDown,
            validators: {
              validation: [Validators.required],
            },
          },
        ],
      },
    ];
  }

  private submit() {
    if ((!this.form.valid || !this.model) && this.model.isEditableOnWeb) {
      this.loadingService.hide();
      return;
    }

    this.prepareRequestModel();

    this.registrationService.save(this.model).subscribe(
      (response) => {
        this.model = response;
        this.loadingService.hide();

        if (this.model.messages.length) {
          // If Email is in Use, lets allow the customer to type a different one
          if (this.model.messages.some(m => m.message && m.message.toLowerCase() === 'user name already in use.')) {
            this.hideEmailField = false;
          }

          // Just Show Error Messages
          return;
        }

        if (this.model.customerEmailInvalid && !this.model.customerConfirmEmail) {
          this.showEmailVerificationDialog();
          return;
        }

        // The Registration is Complete, Lets inform parent
        this.registrationComplete.emit(true);
      },
      () => {
        this.loadingService.hide();
      }
    );
  }

  private prepareRequestModel() {
    this.model.password = this.password;
    this.model.customerEmailInvalid = false;
  }

  private showEmailVerificationDialog() {
    this.cartDialogService.showEmailVerificationDialog(this.model.email, (confirm) => {
      if (confirm) {
        this.model.customerConfirmEmail = true;
        this.loadingService.show('');
        this.submit();
      } else {
        this.hideEmailField = false;
      }
    });
  }

  private showAddressValidationDialog() {
    this.cartDialogService.showAddressValidationDialog(this.model.addressValidation, (confirm) => {
      if (confirm) {
        this.model.addressVerified = true;
        this.loadingService.show('');
        this.submit();
      }
    });
  }

  private showDistributorDialog(registrationInfo: IRegistration) {
    this.commonDialogService.openDistributorCountryVerificationDialog({
      showDialog: registrationInfo.isServiceByDistributor,
      message: registrationInfo.distributorRegionMessage,
      distributor: registrationInfo.distributorName,
      distributorSiteUrl: registrationInfo.distributorSiteUrl,
      region: registrationInfo.distributorRegion,
    });
  }

  private onCountryChange(country) {
    if (this.form.get('state')) {
      this.form.get('state').setValue('');
    } else {
      this.model.state = null;
    }

    if (country === '' || country === 'CA') {
      FormlyHelper.getField('postalCode', this.formFields).templateOptions.required = true;
      this.commonService.getStateList(country).subscribe((states) => {
        this.states = states;
        FormlyHelper.getField('state', this.formFields).templateOptions.options = this.states;
      });
    } else {
      FormlyHelper.getField('postalCode', this.formFields).templateOptions.required = false;
    }
  }

  private setDefaultValues() {
    if (!this.model.email) {
      this.model.email = '';
    }

    if (!this.model.country) {
      this.model.country = '';
    }

    if (this.model.state === '') {
      this.model.state = null;
    }

    if (this.model.aboutUs === '') {
      this.model.aboutUs = null;
    }

    if (this.model.salutation === '') {
      this.model.salutation = null;
    }
  }
}
