import { FormlyExtension } from '@ngx-formly/core';

export const CheckboxExtension: FormlyExtension = {
  prePopulate(field): void {
    if(!field || field.type !== "checkbox" || !field.templateOptions) {
      return;
    }
    
    field.expressions = { 
      ...(field.expressions ?? {}),
      'templateOptions.attributes': (field) => {
        return {
          ...(field?.templateOptions?.attributes ?? {}),
          'aria-checked': field?.formControl?.value? 'true':'false'   
        };
      }       
    };
  },
  
  onPopulate(field): void {
  },
  
  postPopulate(field): void {
  },

};