<div class="forgot-password-form" *ngIf="!forgotDialogSubmitted">
  <p><strong>Forgot your password?</strong></p>
  <p class="mb-2">
    Please enter your e-mail address below to begin the password retrieval process.
    Remember that passwords are case-sensitive.
  </p>
  <form class="k-form" [formGroup]="forgotPasswordForm" (ngSubmit)="requestResetPassword()">
    <div class="d-md-flex forgot-pw-form">
      <div class="inline-form-field mb-2 mb-md-0 mr-md-3">
        <formly-form [form]="forgotPasswordForm" [fields]="forgotPasswordFormFields" [model]="forgotPasswordModel">
        </formly-form>
      </div>
      <button type="submit" class="btn btn-warning inline-form-btn" [disabled]="!forgotPasswordForm.valid">
        Submit
      </button>
    </div>
  </form>
  <p>If you continue to encounter problems, please contact our customer service team.</p>
</div>
<div class="forgot-password-confirmation" *ngIf="forgotDialogSubmitted">
  <p>
    If this is a registered email address with Life Extension, an email to reset your password has been sent to
    the address provided.
  </p>
  <div class="float-right">
    <button type="submit" class="btn btn-warning" (click)="close()">
      Close
    </button>
  </div>
</div>