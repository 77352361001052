<div class="order-history">
  <lef-section-title [title]="'Order Details'" titleClass="mb-4"></lef-section-title>
  <button class="btn btn-link text-primary mb-md-3 d-print-none" (click)="goBack()"><i
       class="lef-chevron-circle-left text-orange"></i>
    My Orders</button>
  <div *ngIf="orderNotFound">
    <p>Order not found!</p>
  </div>
  <div class="order-details-wrap">
    <div *ngIf="!orderNotFound" class="order-details">
      <div class="row">
        <div class="col-md-4 col-lg-5 order-details-content border-md-right">
          <ul>
            <li><span><strong class="font-md text-primary">{{order.isReturn ? 'Return Order': 'Order'}}
                  #:</strong></span>
              {{order.orderNumber}}</li>
            <li><span><strong class="font-md text-primary">{{order.isReturn ? 'Return': 'Order'}}
                  Placed:</strong></span>
              {{order.orderDate}}</li>
            <li><span><strong class="font-md text-primary">Type:</strong></span> {{order.orderType}}</li>
            <li *ngIf="order.dollarsEarned">
              <span><strong class="font-md text-primary">LE Dollars
                  {{order.dollarsStatus}}:</strong></span>
              {{order.dollarsEarned}}
            </li>
          </ul>
        </div>
        <div class="col-md-4 col-lg-4 order-details-content border-md-right">
          <p *ngIf="order.prepaidLabel" class="">
            <strong class="font-md text-primary">Return Shipment Method:</strong><br />
            Prepaid Label: <b>{{order.prepaidLabel}}</b>
          </p>
          <div *ngIf="order.shippingMethods.length > 0" class="shipping-methods mb-3">
            <p class="mb-1"><strong class="font-md text-primary">Shipment Method:</strong></p>
            <p *ngFor="let shipment of order.shippingMethods" class="shipping-method mb-1">
              {{shipment.shippingMethod}} ({{shipment.description}}) <b>{{shipment.shippingCharge}}</b>
            </p>
          </div>
          <div *ngIf="order.payments.length > 0" class="">
            <p class="mb-1"><strong class="font-md text-primary">Payment Method:</strong></p>
            <p *ngFor="let payment of order.payments" class="mb-1">
              <ng-container *ngIf="payment.type==='Credit Card'">
                <span [innerHTML]="payment.description"></span>
                <span *ngIf="payment.expiration"> Exp: <strong>{{payment.expiration}}</strong> </span>
                <span *ngIf="payment.operation"><br />{{payment.operation}}: <strong>{{payment.amount}}</strong>
                </span>
              </ng-container>
              <ng-container *ngIf="payment.type!=='Credit Card'">
                <span>{{payment.description}}: <strong>{{payment.amount}}</strong></span>
              </ng-container>
            </p>
          </div>
        </div>
        <div class="col-md-4 col-lg-3 order-details-content">
          <p *ngIf="order.isReturn && order.returnPayments.length > 0" class="mb-1">
            <strong class="font-md text-primary">Refund Method:</strong>
          </p>
          <p *ngFor="let payment of order.returnPayments" class="d-flex justify-content-between">
            To <span [innerHTML]="payment.description"></span> <strong>{{payment.amount}}</strong>
          </p>
          <p *ngIf="order.subTotal" class="d-flex justify-content-between"><span>Items Total</span>
            <span>{{order.subTotal}}</span>
          </p>
          <p *ngIf="order.totalTaxes" class="d-flex justify-content-between"><span>Tax</span>
            <span>{{order.totalTaxes}}</span>
          </p>
          <p *ngIf="order.shippingCost" class="d-flex justify-content-between"><span>Shipping Cost</span>
            <span>{{order.shippingCost}}</span>
          </p>
          <p *ngIf="order.total" class="d-flex justify-content-between font-md border-btm-none"><strong>Order
              Total</strong>
            <strong>{{order.total}}</strong>
          </p>
          <p *ngIf="order.refundTotal" class="d-flex justify-content-between font-md border-btm-none">
            <span>Refund Total:</span>
            <span>{{order.refundTotal}}</span>
          </p>
          <div class="text-right mt-3 d-print-none">
            <button class="btn btn-warning mb-3" aria-haspopup="dialog" (click)="addAllItemsToCart()">Add All Items to
              Cart</button>
            <a *ngIf="order.isReturnAllow" routerLink="/returns/{{orderNumber}}" class="btn btn-link">Return or
              Replace an Item</a>
            <div *ngIf="order.returnNotAvailable">
              Return Not Available <button class="btn btn-link" (click)="showReturnsAlert()"> Why?
              </button></div>
            <div *ngIf="order.showCustomMessage" class="d-flex justify-content-around">
              <div class="text-center">
                <p>{{order.customMessage}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngFor="let shipment of order.shipments; let i = index">
      <lef-collapse-box [expanded]="true" [enabled]="false" [showIcon]="false">
        <div title class="d-flex flex-column flex-md-row flex-md-row justify-content-between">
          <div class="d-flex"> Shipment {{i+1}} of {{order.shipments.length}}</div>
          <div class="d-flex"><span *ngIf="shipment.shipmentDate"> Shipment Date: {{shipment.shipmentDate}}</span></div>
        </div>
        <div body class="order-details-ship">
          <lef-order-shipment [shippedTo]="shipment.shippedTo" [items]="shipment.items"
                              [trackingNumbers]="shipment.trackingNumbers">
          </lef-order-shipment>
        </div>
      </lef-collapse-box>
    </div>

    <lef-collapse-box *ngIf="order.returnItems.length > 0" [expanded]="true">
      <div title class="d-flex justify-content-between">
        Return Details
      </div>
      <div body class="order-details-ship order-ship-wrapper">
        <ng-container *ngFor="let item of order.returnItems">
          <lef-order-item [item]="item" [embeddedView]="true">
          </lef-order-item>
        </ng-container>
      </div>
    </lef-collapse-box>

    <lef-collapse-box *ngIf="order.returnItems.length > 0" [expanded]="true">
      <div title class="d-flex justify-content-between items-warehouse-print">
        Item(s) Expected at Warehouse
      </div>
      <div body>
        <ng-container *ngFor="let expected of order.warehouseExpected">
          <div class="order-details-wh-stat">
            <p><strong class="text-primary">Status:</strong> {{expected.status}}</p>
            <p><strong class="text-primary">Expected Return Date:</strong> {{expected.expectedDate}}</p>
            <p>We must receive your item(s) by this date to avoid incurring a charge. <i
                 (click)="showDeadlineReturnsDialog()" class="lef-information-solid text-info"></i>
            </p>
          </div>
          <div class="p-3">
            <div class="order-details-wh">
              <ul class="order-details-wh-hd">
                <li><strong>Item #</strong></li>
                <li><strong>Name</strong></li>
                <li><strong>Quantity</strong></li>
              </ul>
              <ul class="order-details-wh-content" *ngFor="let item of expected.items">
                <li>{{item.itemNumber}}</li>
                <li>{{item.itemName}}</li>
                <li>{{item.quantity}}</li>
              </ul>
            </div>
            <div *ngIf="order.trackingNumber" class="order-details-instr">
              <p><strong class="text-primary">Instructions</strong></p>
              <div class="row">
                <div class="col-md-9">
                  <ol>
                    <li>Print your <button class="btn btn-link" type="button"
                              (click)="openShippingLabelDialog(dialogActions)">shipping label<i
                           class="lef-print font-lg ml-2"></i></button> and print the
                      <button class="btn btn-link" type="button"
                              (click)="printPackagingSlip(expected.items, printSlipActions)">packaging
                        slip<i class="lef-print font-lg ml-2"></i></button>
                    </li>
                    <li>Pack the items listed above (include print-out of the packaging slip)</li>
                    <li>Affix the shipping label to your package</li>
                    <li>Take package to local drop-off location or arrange to have it picked up</li>
                  </ol>
                  <ng-template #dialogActions>
                    <button kendoButton class="k-info font-lg" (click)="printLabel()">
                      Reprint Label <i class="lef-print ml-2"></i>
                    </button>
                  </ng-template>
                  <ng-template #printSlipActions>
                    <button kendoButton class="k-info font-lg" (click)="printSlip()">
                      Reprint Packaging Slip <i class="lef-print ml-2"></i>
                    </button>
                  </ng-template>
                </div>
                <div class="col-md-3 text-center">
                  <img src="https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl={{order.orderNumber}}" />
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </lef-collapse-box>
  </div>
</div>