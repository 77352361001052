import { Component, OnInit } from '@angular/core';
import { ICart } from 'src/app/cart-common/model';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { TrackingService } from '../../service/tracking.service';

declare global {
  interface Window {
    pintrk: any;
  }
}

@Component({
  selector: 'lef-pinterest',
  templateUrl: './pinterest.component.html',
  styleUrls: ['./pinterest.component.scss']
})
export class PinterestComponent implements OnInit {

  constructor(
    private readonly trackingService: TrackingService,
    private readonly config: CoreConfiguration
  ) { }

  ngOnInit(): void {
    if (this.config.environment.enablePinterestTracking && this.trackingService.checkOneTrustActiveGroup(this.config.oneTrustGroups.socialMedia)) {
      this.trackingService.orderPlaced.subscribe({ next: (cart) => this.onOrderPlaced(cart) });
    }
  }

  onOrderPlaced(cart: ICart) {
    if (!window.pintrk) {
      return;
    }

    const items: any[] = [];
    cart.items
      .filter((i) => i.lineTye !== 'F')
      .forEach((i) => {
        items.push({
          product_name: i.friendlyDescription,
          product_id: i.itemNumber,
          product_price: i.yourPrice,
          product_quantity: i.quantity,
          product_category: i.primaryCategory,
          product_brand: i.brand,
        });
      });

    window.pintrk('track', 'checkout', {
      value: cart.totalOrder,
      order_quantity: cart.quantity,
      currency: 'USD',
      line_items: items
    });
  }
}
