import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthorizationService } from 'src/app/authorization';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { CartCookieService } from 'src/app/shared/service/cart-cookie-service.service';
import { CustomerData } from 'src/app/tracking/model/customer-data.model';
import { TrackingService } from 'src/app/tracking/service/tracking.service';

@Component({
  selector: 'lef-genesys-chat',
  templateUrl: './genesys-chat.component.html',
  styleUrls: ['./genesys-chat.component.scss'],
})
export class GenesysChatComponent implements OnInit, AfterViewInit {
  form: FormGroup;
  queue: string;
  customerData: CustomerData;
  startTime: number;
  endTime: number;
  startTimeLabel: string;
  endTimeLabel: string;
  startDayLabel: string;
  endDayLabel: string;
  currenLocaleDate: Date;
  locale: string;
  localeTimezoneId: string;
  workingDays: string[] = [];
  deploymentIdentifier: string;
  genesysChatStorageKey: string;
  showInitialChat: boolean;
  showInitialButton: boolean;
  showSpinner: boolean;
  isUserLoggedIn: boolean;
  wellnessOption: string;
  genesysStorageValueTrue: string;
  genesysStorageValueFalse: string;
  wellnessIdentifier: string;
  serviceIdentifier: string;
  messageId: string;
  initializationDateTime: Date;
  genesysActive: boolean;

  @ViewChild('chatTalkTo') chatTalkTo: ElementRef;
  @ViewChild('chatTalkToLabel') chatTalkToLabel: ElementRef;
  @ViewChild('chatTalkToOption') chatTalkToOption: ElementRef;
  @ViewChild('survey') survey: ElementRef;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly trackingService: TrackingService,
    private readonly config: CoreConfiguration,
    private readonly authService: AuthorizationService,
    private readonly cartCookieService: CartCookieService
  ) {
    try {
      this.initForm();
      this.setInitialValues();
      this.subscribeToEvents();
      this.setHourInformation();
    } catch (e) {}
  }

  ngOnInit(): void {
    try {
      this.checkEnableDayTime();
    } catch (e) {}
  }

  ngAfterViewInit() {
    this.setInitialStyle();
  }

  private setInitialValues(): void {
    this.locale = 'en-US';
    this.localeTimezoneId = 'US/Eastern';
    this.workingDays = this.config.genesysConfiguration.workingDays;
    this.genesysChatStorageKey = '_actmu';
    this.isUserLoggedIn = this.authService.isLoggedIn() || this.authService.isSoftLoggedIn();
    this.wellnessOption = 'wellness';
    this.genesysStorageValueTrue = '{"value":"true","ttl":null}';
    this.genesysStorageValueFalse = '{"value":"false","ttl":null}';
    this.wellnessIdentifier = this.config.genesysConfiguration.wellnessId;
    this.serviceIdentifier = this.config.genesysConfiguration.serviceId;
    this.genesysActive = this.cartCookieService.getCookieFromName(this.config.cookies.genesysActive) == 'true';
  }

  private setInitialStyle(): void {
    this.chatTalkToOption.nativeElement.style.display = 'none';
    this.chatTalkToLabel.nativeElement.style.display = 'none';
    this.survey.nativeElement.style.display = 'none';
  }

  private subscribeToEvents(): void {
    this.trackingService.logIn.subscribe({ next: () => this.initForm() });
    this.trackingService.logOut.subscribe({ next: () => this.initForm() });
  }

  private checkEnableDayTime(): void {
    this.currenLocaleDate = new Date(new Date().toLocaleString(this.locale, { timeZone: this.localeTimezoneId }));
    if (
      this.genesysActive ||
      (this.config.genesysConfiguration.enable &&
        this.workingDays.some((d) => d === this.currenLocaleDate.toLocaleDateString(this.locale, { weekday: 'long' })) &&
        this.currenLocaleDate.getHours() >= this.startTime &&
        this.currenLocaleDate.getHours() < this.endTime)
    ) {
      this.checkGenesys();
    }
  }

  private setHourInformation(): void {
    this.startTime = this.config.genesysConfiguration.startTime;
    this.endTime = this.config.genesysConfiguration.endTime;
    this.startTimeLabel = this.startTime > 12 ? `${this.startTime - 12}PM` : `${this.startTime}AM`;
    this.endTimeLabel = this.endTime > 12 ? `${this.endTime - 12}PM` : `${this.endTime}AM`;

    this.startDayLabel = this.workingDays[0].substring(0, 3);
    this.endDayLabel = this.workingDays[this.workingDays.length - 1].substring(0, 3);
  }

  private setChatIntoCookie(): void {
    setTimeout(() => {
      const chatLocalStorage = window.localStorage.getItem(this.genesysChatStorageKey);
      if (chatLocalStorage != null && chatLocalStorage?.toString()?.length > 0 && chatLocalStorage != undefined) {
        this.cartCookieService.setContentSiteCookie(this.config.cookies.genesysChat, chatLocalStorage, 0, 1);
      }
    }, 3000);
  }

  private initForm(): void {
    if (this.form === undefined) {
      this.form = this.formBuilder.group({
        name: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        message: ['', [Validators.required]],
      });
    }

    this.isUserLoggedIn = this.authService.isLoggedIn() || this.authService.isSoftLoggedIn();
    if (this.isUserLoggedIn) {
      this.customerData = this.trackingService.getCurrentCustomerData();
      this.form?.get('name')?.setValue(this.authService.userFullName());
      this.form?.get('email')?.setValue(this.customerData?.email);
    }
  }

  private checkGenesys(): void {
    this.queue = this.cartCookieService.getCookieFromName(this.config.cookies.genesysQueue);
    if (this.queue) {
      this.deploymentIdentifier = this.queue === this.wellnessOption ? this.wellnessIdentifier : this.serviceIdentifier;

      const genesysChatValue = this.cartCookieService.getCookieFromName(this.config.cookies.genesysChat);
      if (genesysChatValue != null && genesysChatValue != undefined && genesysChatValue?.toString().length > 0) {
        window.localStorage.setItem(this.genesysChatStorageKey, genesysChatValue);
        window.localStorage.setItem(`_${this.deploymentIdentifier}:gcmcsessionActive`, this.genesysStorageValueTrue);
      }

      const genesysVisibilityValue = this.cartCookieService.getCookieFromName(this.config.cookies.genesysVisibility);
      if (genesysVisibilityValue != null && genesysVisibilityValue != undefined && genesysVisibilityValue?.toString().length > 0) {
        window.localStorage.setItem(`_${this.deploymentIdentifier}:gcmcopn`, genesysVisibilityValue);
      }

      this.addGenesysScript(true, this.queue);
      this.setInitializationDateTime();
      this.subscribeToGenesysEvents(false);
    } else {
      this.showInitialButton = true;
    }
  }

  private addGenesysScript(isActive: boolean, queue: string): void {
    this.deploymentIdentifier = queue === this.wellnessOption ? this.wellnessIdentifier : this.serviceIdentifier;

    const e = 'Genesys';
    const n = this.config.genesysConfiguration.urlSendChat;
    const es = {
      environment: 'use1',
      deploymentId: this.deploymentIdentifier,
    };
    let ys;

    window['_genesysJs'] = e;
    window[e] =
      window[e] ||
      function () {
        (window[e].q = window[e].q || []).push(arguments);
      };
    window[e].t = new Date();
    window[e].c = es;
    ys = document.createElement('script');
    ys.async = true;
    ys.charset = 'utf-8';
    document.head.appendChild(ys);
    if (!isActive) {
      ys.onload = () => this.onScriptLoaded(queue);
    }
    ys.src = n;

    this.applyGenesysStyle(1);
  }

  private onScriptLoaded(queue: string): void {
    this.showSpinner = true;
    window.Genesys('subscribe', 'Launcher.ready', () => this.onMessengerReady(queue));
  }

  private onMessengerReady(queue: string): void {
    window.Genesys('command', 'Database.set', {
      messaging: {
        customAttributes: {
          CustomerName: this.form?.get('name')?.value,
          CustomerNumber: this.customerData?.customerNumber,
          EmailAddress: this.form?.get('email')?.value,
          Region: this.customerData?.customerRegion,
          IpAddress: this.customerData?.customerIP,
          UserAgent: window.navigator.userAgent,
          DeviceType: this.getDeviceType(),
          CurrentPage: window.location.href,
        },
      },
    });

    this.setInitializationDateTime();
    this.subscribeToGenesysEvents(true);

    this.showInitialChat = false;
    this.showInitialButton = false;
    this.showSpinner = false;

    window.Genesys('subscribe', 'Messenger.ready', () => {
      window.Genesys('command', 'Messenger.open');
    });

    this.cartCookieService.setContentSiteCookie(this.config.cookies.genesysQueue, queue, 0, 1);
  }

  private setInitializationDateTime(): void {
    this.initializationDateTime = new Date();
  }

  private subscribeToGenesysEvents(sendInitialMessage: boolean): void {
    window.Genesys('subscribe', 'Messenger.opened', () => {
      this.cartCookieService.setContentSiteCookie(this.config.cookies.genesysVisibility, this.genesysStorageValueTrue, 0, 1);
      if (sendInitialMessage) {
        window.Genesys('subscribe', 'MessagingService.ready', () => {
          window.Genesys('command', 'MessagingService.sendMessage', { message: this.form?.get('message')?.value });
        });

        sendInitialMessage = false;
      }
    });

    window.Genesys('subscribe', 'Messenger.closed', () => {
      this.cartCookieService.setContentSiteCookie(this.config.cookies.genesysVisibility, this.genesysStorageValueFalse, 0, 1);
    });

    window.Genesys('subscribe', 'MessagingService.conversationDisconnected', (eventData: any) => {
      let isBot = eventData?.data?.message?.originatingEntity === 'Bot';
      let eventTime = eventData?.data?.message?.channel?.time;
      let eventDateTime = new Date(eventTime);

      if (eventDateTime > this.initializationDateTime && !isBot) {
        this.messageId = eventData?.data?.message?.id;
        this.survey.nativeElement.style.display = 'block';
      }
    });
  }

  private getDeviceType(): string {
    if (this.isMobileDevice()) return 'Mobile';
    if (this.isTabletDevice()) return 'Tablet';
    return 'Desktop';
  }

  private isMobileDevice(): boolean {
    const regexs = [/(Android)(.+)(Mobile)/i, /BlackBerry/i, /iPhone|iPod/i, /Opera Mini/i, /IEMobile/i];
    return regexs.some((b) => navigator.userAgent.match(b));
  }

  private isTabletDevice(): boolean {
    const regex = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/;
    return regex.test(navigator.userAgent.toLowerCase());
  }

  private applyGenesysStyle(attempt) {
    if (attempt == 20) {
      return;
    }

    attempt = !attempt ? 1 : attempt + 1;

    var genIframe = document.getElementById('genesys-mxg-frame');
    if (!genIframe) {
      setTimeout(() => {
        this.applyGenesysStyle(attempt);
      }, 500);
      return;
    }

    genIframe.style.maxHeight = '600px';
    genIframe.style.maxWidth = '367px';
    genIframe.style.right = '5px';
  }

  chatTalkToChange(): void {
    if (this.chatTalkTo.nativeElement.selectedIndex >= 1) {
      this.chatTalkToLabel.nativeElement.style.display = 'none';
    } else {
      this.chatTalkToLabel.nativeElement.style.display = 'block';
    }
  }

  toggleGenesysChat(): void {
    this.showInitialChat = !this.showInitialChat;
    this.initForm();
  }

  sendChat(event: Event): void {
    event.preventDefault();
    if (this.form.valid && this.chatTalkTo.nativeElement.selectedIndex != 0) {
      this.customerData = this.trackingService.getCurrentCustomerData();
      this.addGenesysScript(false, this.chatTalkTo.nativeElement.value);
      this.setChatIntoCookie();
    } else {
      Object.keys(this.form.controls).forEach((key) => {
        this.form.get(key).markAsDirty();
      });

      if (this.chatTalkTo.nativeElement.selectedIndex == 0) {
        this.chatTalkToLabel.nativeElement.style.display = 'block';
      }
    }
  }

  onCloseSurvey(): void {
    this.survey.nativeElement.style.display = 'none';
  }
}
