import { Subject } from 'rxjs';
import { IPaymentGateway, PaymentType, WalletPayment } from '.';
import { PaymentMethod } from './payment-method';
declare global {
  interface Window {
    ApplePaySession: any;
  }
}

export interface IApplePayPaymentProcessor {
  gateway: IPaymentGateway;
  onPaymentDataReceived: Subject<WalletPayment>;
  isProcessingPayment: Subject<boolean>;
  onError: Subject<string>;
  onCheckOutPaymentSelected: Subject<WalletPayment>;
  refresh(applePayInstance: any, usingCheckoutFlow: boolean, amount: number, applePayButton: HTMLDivElement): void;
}

export class EmptyApplePayPaymentProcessor implements IApplePayPaymentProcessor {
  gateway: IPaymentGateway;
  amount: number;
  onPaymentDataReceived: Subject<WalletPayment> = new Subject<WalletPayment>();
  onCheckOutPaymentSelected: Subject<WalletPayment> = new Subject<WalletPayment>();
  isProcessingPayment: Subject<boolean> = new Subject<boolean>();
  onError: Subject<string> = new Subject<string>();
  refresh(applePayInstance: any, usingCheckoutFlow: boolean, amount: number, applePayButton: HTMLDivElement): void {}
}

export class BraintreeApplePayPaymentProcessor implements IApplePayPaymentProcessor {
  applePayInstance: any;
  onPaymentDataReceived: Subject<WalletPayment> = new Subject<WalletPayment>();
  onCheckOutPaymentSelected: Subject<WalletPayment> = new Subject<WalletPayment>();
  isProcessingPayment: Subject<boolean> = new Subject<boolean>();
  onError: Subject<string> = new Subject<string>();
  usingCheckoutFlow: boolean;
  amount: number;
  gateway: IPaymentGateway;
  applePayButton: HTMLDivElement;

  constructor(gateway: IPaymentGateway) {
    this.gateway = gateway;
  }

  refresh(applePayInstance: any, usingCheckoutFlow: boolean, amount: number, applePayButton: HTMLDivElement): void {
    this.applePayInstance = applePayInstance;
    this.usingCheckoutFlow = usingCheckoutFlow;
    this.amount = amount;
    this.applePayButton = applePayButton;
    this.initApplePayButton();
  }

  initApplePayButton() {
    const applePayInstance = this.applePayInstance;
    const onCheckOutPaymentSelected = this.onCheckOutPaymentSelected;

    this.applePayButton.onclick = () => {
      const amount = +this.amount.toFixed(2);
      const paymentRequest = this.applePayInstance.createPaymentRequest({
        total: {
          label: 'Life Extension',
          amount: amount,
        },
        requiredBillingContactFields: ['postalAddress'],
      });

      var session = new window.ApplePaySession(3, paymentRequest);
      session.begin();

      session.onvalidatemerchant = function (event) {
        applePayInstance.performValidation(
          {
            validationURL: event.validationURL,
            displayName: 'My Store',
          },
          function (err, merchantSession) {
            if (err) {
              this.onError.next('Apple Pay failed to load.');
              return;
            }
            session.completeMerchantValidation(merchantSession);
          }
        );
      };

      session.onpaymentauthorized = function (event) {
        applePayInstance.tokenize(
          {
            token: event.payment.token,
          },
          function (tokenizeErr, payload) {
            if (tokenizeErr) {
              session.completePayment(window.ApplePaySession.STATUS_FAILURE);
              return;
            }
            const payment = new WalletPayment();
            payment.usingCheckoutFlow = true;
            payment.email = payload.details.paymentInstrumentName;
            payment.firstName = event.payment.billingContact.givenName;
            payment.lastName = event.payment.billingContact.familyName;
            payment.postalCode = event.payment.billingContact?.postalCode;
            payment.nonce = payload.nonce;
            payment.paymentType = PaymentType.ApplePay;
            payment.paymentMethod = PaymentMethod.ApplePay;
            onCheckOutPaymentSelected.next(payment);

            // After you have transacted with the payload.nonce,
            // call 'completePayment' to dismiss the Apple Pay sheet.
            session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
          }
        );
      };
    };
  }
}
