import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { ICarouselItem } from '../../model/carousel-item';
import { ItemConfigurationService } from '../../service/item-configuration.service';

@Component({
  selector: 'lef-item-carousel-item',
  templateUrl: './item-carousel-item.component.html',
  styleUrls: ['./item-carousel-item.component.scss'],
})
export class ItemCarouselItemComponent implements OnInit {
  @Input() carouselItem: ICarouselItem;
  @Input() buttonText;

  @Output() addToCartClicked = new EventEmitter<string>();

  get isOnSale(): boolean {
    return (
      this.carouselItem &&
      (this.carouselItem.isWholeSale ? this.carouselItem.pricing.wholesaleSavingsAmount > 0 : this.carouselItem.pricing.savingsAmount > 0)
    );
  }

  get itemUrl(): string {
    return this.itemConfig.getItemUrl(this.carouselItem);
  }

  get itemImageUrl(): string {
    return this.carouselItem && this.carouselItem.itemNumber
      ? this.baseConfig.environment.itemUrlTemplates.itemImageUrl.replace('{itemNumber}', this.carouselItem.itemNumber)
      : '';
  }

  get defaultImage(): string {
    return this.baseConfig.images.placeHolder.small;
  }


  constructor(private readonly baseConfig: CoreConfiguration, private readonly itemConfig: ItemConfigurationService) {}

  ngOnInit(): void {}

  generateAriaLabel(carouselItem: any): string {
    return `${carouselItem.averageOverallRating} stars, ${carouselItem.fullReviews} reviews`;
  }
  
}
