import { Injectable, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';
import { PagerModule } from '@progress/kendo-angular-pager';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CartModule } from './cart/cart.module';
import { CheckoutSuccessPageGuardGuard } from './checkout/guard/checkout-success-page-guard.guard';
import { CommonsModule } from './common/common.module';
import { CoreModule } from './core/core.module';
import { MinorConsentModule } from './minor-consent/minor-consent.module';
import { ResellerModule } from './reseller/reseller.module';
import { SecurityModule } from './security/security.module';
import { SharedModule } from './shared/shared.module';
import { TrackingModule } from './tracking/tracking.module';

@Injectable()
class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  override parse(url: string): UrlTree {
    // No QueryString
    const queryStringIndex = url.indexOf('?');
    if (queryStringIndex === -1) {
      return super.parse(url.toLowerCase());
    }

    // All is QueryString
    if (queryStringIndex === 0 || (queryStringIndex === 1 && url[0] === '/')) {
      return super.parse(url);
    }

    // Lowercase Route and Original QueryString
    const urlParts = url.split('?');
    const newUrl = `${urlParts[0].toLowerCase()}?${urlParts[1]}`;
    return super.parse(newUrl);
  }
}

const modules = [
  BrowserModule,
  BrowserAnimationsModule,
  AppRoutingModule,
  CoreModule.forRoot(),
  SharedModule,
  CommonsModule,
  CartModule,
  TrackingModule,
  SecurityModule,
  MinorConsentModule,
  ResellerModule,
  NgxWebstorageModule.forRoot({ prefix: 'lef-cart-storage' }),
];

@NgModule({
  declarations: [AppComponent],
  imports: [modules, PagerModule, BrowserAnimationsModule],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer,
    },
    Title,
    CheckoutSuccessPageGuardGuard,
  ],
})
export class AppModule {}
