<div>
  <p><strong>Items are shipped once a month per shipping address.</strong></p>
  <p>Ship All Items in this AutoShip &amp; Save on:</p>
  <div class="auto-ship-date-qty mb-3">
    <!-- <kendo-dropdownlist [data]="shippingDaysOptions" [textField]="'description'" [valueField]="'value'"
                        [valuePrimitive]="true" name="shippingDay" [(ngModel)]="shippingDay">
    </kendo-dropdownlist> -->
    <select [(ngModel)]="shippingDay" name="shippingDay">
      <ng-container *ngFor="let opt of shippingDaysOptions">
        <option [ngValue]="opt.value">{{ opt.description }}</option>
      </ng-container>
    </select>
    of the Month
  </div>
  <div>
    <p>
      This will be the date that your order will process. Order ships within one business day.
      If the date selected falls on a weekend or a holiday, the order will ship the next business day.
      See upcoming shipping dates on My Subscription screen.
    </p>
  </div>
  <div class="text-right">
    <button type="button" (click)="submit()" class="btn btn-action" type="submit">Submit</button>
  </div>
</div>