export interface AuthorizationRoutes {
  login: string;
  unauthorized: string;
}

export class AuthorizationRoutesModel implements AuthorizationRoutes {
  public login: string;
  public unauthorized: string;

  constructor(loginRoute: string, unauthorizedRoute: string) {
    this.login = loginRoute;
    this.unauthorized = unauthorizedRoute;
  }
}
