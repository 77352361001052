import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { BaseService } from 'src/app/shared/service/base-service';
import { IReturnOrder, IShippingLabel } from '../model';

@Injectable({
  providedIn: 'root',
})
export class ReturnsService extends BaseService<IReturnOrder> {
  protected apiUrl: string;

  constructor(protected override readonly httpClient: HttpClient, private readonly config: CoreConfiguration) {
    super(httpClient);

    this.apiUrl = config.environment.api + config.apiRoutes.returns;
  }

  getReturnOrder(orderNumber: number): Observable<IReturnOrder> {
    return this.getSingle(`${this.apiUrl}/ReturnOrder/${orderNumber}`);
  }

  selectReturnItems(returnOrder: IReturnOrder): Observable<IReturnOrder> {
    return this.update(`${this.apiUrl}/SelectReturnItems`, returnOrder);
  }

  setReturnOptions(returnOrder: IReturnOrder): Observable<IReturnOrder> {
    return this.update(`${this.apiUrl}/SetReturnOptions`, returnOrder);
  }

  completeReturn(returnOrder: IReturnOrder): Observable<IReturnOrder> {
    return this.post(`${this.apiUrl}/CompleteReturn`, returnOrder);
  }

  setPreviousStep(returnOrder: IReturnOrder): Observable<IReturnOrder> {
    return this.update(`${this.apiUrl}/SetPreviousStep`, returnOrder);
  }

  setShippingDestination(shipTo: number): Observable<IReturnOrder> {
    return this.update(`${this.apiUrl}/SetShippingDestination?shipTo=${shipTo}`, null);
  }

  setShippingMethod(method: string): Observable<IReturnOrder> {
    return this.update(`${this.apiUrl}/SetShippingMethod?shippingMethod=${method}`, null);
  }

  getPrepaidShippingLabel(trackingNumber: string, orderNumber: string): Observable<IShippingLabel> {
    return this.httpGet<IShippingLabel>(
      `${this.apiUrl}/GetPrepaidShippingLabel?trackingNumber=${trackingNumber}&orderNumber=${orderNumber}`
    );
  }
}
