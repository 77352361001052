import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { environment as env } from '../../environments/environment';
import { AuthorizationModule, AuthorizationRootConfigModel, CredentialsInterceptor } from '../authorization';
import { ErrorMonitoringHandler, ErrorRootConfigModel, ErrorsHandler, ErrorsModule } from '../errors';

export const currentEnvironment = env;

const authorizationConfig: AuthorizationRootConfigModel = {
  apiServer: currentEnvironment.api,
};

const errorConfig: ErrorRootConfigModel = {
  apiServer: currentEnvironment.logging,
  enableApiLogging: currentEnvironment.useApiLogging,
};

@NgModule({
  declarations: [],
  imports: [CommonModule, AuthorizationModule.forRoot(authorizationConfig), ErrorsModule.forRoot(errorConfig)],
})
export class CoreModule {
  private static readonly handlerClass = currentEnvironment.useInsights ? ErrorMonitoringHandler : ErrorsHandler;

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: HTTP_INTERCEPTORS, useClass: CredentialsInterceptor, multi: true },
        { provide: ErrorHandler, useClass: CoreModule.handlerClass },
      ],
    };
  }
}
