import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CartCommonModule } from '../cart-common/cart-common.module';
import { CommonsModule } from '../common/common.module';
import { LoginModule } from '../login/login.module';
import { SharedModule } from '../shared/shared.module';
import { ResellerApplicationCompleteComponent } from './component/reseller-application-complete/reseller-application-complete.component';
import { ResellerApplicationComponent } from './component/reseller-application/reseller-application.component';
import { ResellerBusinessInformationComponent } from './component/reseller-business-information/reseller-business-information.component';
import { ResellerCompanyRegistrationComponent } from './component/reseller-company-registration/reseller-company-registration.component';
import { ResellerCustomerComponent } from './component/reseller-customer/reseller-customer.component';
import { ResellerRoutingModule } from './reseller-routing.module';
import { ResellerAcknowledgmentComponent } from './component/reseller-acknowledgment/reseller-acknowledgment.component';

const modules = [
  ResellerRoutingModule,
  CommonModule,
  CommonsModule,
  LoginModule,
  SharedModule,
  CartCommonModule
];

@NgModule({
  declarations: [
    ResellerApplicationComponent,
    ResellerCustomerComponent,
    ResellerCompanyRegistrationComponent,
    ResellerBusinessInformationComponent,
    ResellerApplicationCompleteComponent,
    ResellerAcknowledgmentComponent,
  ],
  imports: [modules],
})
export class ResellerModule { }
