import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'lef-cart-item-price',
  templateUrl: './cart-item-price.component.html',
  styleUrls: ['./cart-item-price.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CartItemPriceComponent {
  @Input()
  totalBaseStandardPrice = 0;
  @Input()
  extendedPrice = 0;
  @Input()
  percentSavings = 0;
  @Input()
  isSuperSale = false;
  @Input()
  isExcludedFromPromotion = false;
  @Input()
  excludedFromPromotionMessage = '';

  get priceLabel(): string {
    if (this.extendedPrice < this.totalBaseStandardPrice && this.isSuperSale) {
      return 'Super Sale: ';
    }
    return '';
  }

  get priceClass(): string {
    return this.percentSavings > 0 ? 'text-red' : 'text-primary';
  }
}
