<table class="w-100">
    <tr>
        <td class="text-right pr-2">Requisition Number:</td>
        <td><strong>{{requisition.requisitionId}}</strong></td>
        <td class="ml-2">
            <label>Parent / Legal Guardian Full Name*</label>
        </td>
    </tr>
    <tr>
        <td class="text-right pr-2">Subject Name:</td>
        <td>{{requisition.subjectName}}</td>
        <td>
            <form class="k-form" [formGroup]="consentForm">
                <div class="d-flex justify-content-between">
                    <kendo-textbox type="text" class="form-control consent-field mr-3"
                                   placeholder="Parent / Legal Guardian Full Name*" formControlName="guardian"
                                   (valueChange)="onConsentByChange($event)">
                    </kendo-textbox>
                </div>
            </form>
        </td>
    </tr>
</table>



<h3>Blood Tests:</h3>
<ul class="standard-list">
    <li *ngFor="let test of requisition.bloodTests">
        {{test.itemNumber}} - {{test.itemDescription}}
    </li>
</ul>