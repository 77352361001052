<div>
  <p>Items are shipped once a month per shipping address.</p>
  <div class="border-bottom-dashed d-flex align-items-center">
    <p class="pr-2"><strong>Schedule Next Shipment For:</strong></p>
    <!-- <kendo-dropdownlist [data]="shippingDates" [textField]="'text'" [valueField]="'value'" [valuePrimitive]="true"
                        name="shippingDate" [(ngModel)]="shippingDate">
    </kendo-dropdownlist> -->
    <select [(ngModel)]="shippingDate" name="shippingDate">
      <ng-container *ngFor="let opt of shippingDates">
        <option [ngValue]="opt.value">{{ opt.text }}</option>
      </ng-container>
    </select>
  </div>
  <div class="pl-3">
    <div>
      <label>
        <input kendoradiobutton class="k-radio form-check-input" type="radio" [value]="false"
               [(ngModel)]="updateAllDeliveryItems" />
        Apply to this Item only
      </label>
    </div>
    <div>
      <label>
        <input kendoradiobutton class="k-radio form-check-input" type="radio" [value]="true"
               [(ngModel)]="updateAllDeliveryItems" />
        Apply to all items in this AutoShip &amp; Save
      </label>
    </div>
    <div class="text-right">
      <button class="btn btn-action" (click)="submit()">Submit</button>
    </div>
  </div>
</div>
