import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { SpinnerComponent } from '../component/spinner/spinner.component';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private overlayRef;
  private spinnerRef: ComponentRef<SpinnerComponent> = null;
  private textSubscription: Subscription;
  private textIndex = 0;
  private defaultLoadingText = 'Loading ...';

  constructor(private readonly overlay: Overlay) {
    this.initializeOverlay();
  }

  public show(loadingText: string | string[] = this.defaultLoadingText, loopMessages: boolean = false) {
    if (!this.spinnerRef) {
      this.spinnerRef = this.overlayRef.attach(new ComponentPortal(SpinnerComponent));
    }

    this.stopTextChange();

    if (Array.isArray(loadingText)) {
      if (loadingText.length > 0) {
        this.updateText(loadingText[0]);
        if (loadingText.length > 1) {
          this.startTextChange(loadingText, loopMessages);
        }
      } else {
        this.updateText(this.defaultLoadingText);
      }
    } else {
      this.updateText(loadingText);
    }
  }

  public hide() {
    if (this.spinnerRef) {
      this.overlayRef.detach();
      this.spinnerRef = null;
      this.stopTextChange();
    }
  }

  public updateText(loadingText = '') {
    if (this.spinnerRef) {
      this.spinnerRef.instance.text = loadingText;
    }
  }

  private initializeOverlay() {
    this.overlayRef = this.overlay.create();
  }

  private startTextChange(loadingText: string[], loopMessages: boolean) {
    const changeTime = interval(4000);
    this.textSubscription = changeTime.subscribe((val) => {
      this.textIndex++;
      if (this.textIndex === loadingText.length) {
        if (loopMessages) {
          this.textIndex = 0;
        } else {
          this.textSubscription.unsubscribe();
          return;
        }
      }
      this.updateText(loadingText[this.textIndex]);
    });
  }

  private stopTextChange() {
    if (this.textSubscription) {
      this.textSubscription.unsubscribe();
    }
  }
}
