import { Injectable, Type } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { ApplicationInsights, IEventTelemetry, IExceptionTelemetry, IPageViewTelemetry } from '@microsoft/applicationinsights-web';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ValueTypeHelper } from 'src/app/shared/helpers/value-type.helper';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppInsightsMonitoringService {
  private routerEventsSubscription: Subscription;
  private appInsights: ApplicationInsights;

  constructor(private router: Router) {
    this.initialize();
  }

  public initialize() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
      },
    });
    this.appInsights.loadAppInsights();
  }

  public startRouteMonitoring() {
    // Prevent Multiple Subscribes
    if (!this.routerEventsSubscription) {
      this.routerEventsSubscription = this.router.events.pipe(filter((event) => event instanceof ResolveEnd)).subscribe({
        next: (event: ResolveEnd | any) => {
          const activatedComponent = this.getActivatedComponent(event.state.root);
          const activatedComponentName = ValueTypeHelper.isType(activatedComponent) ? activatedComponent.name : activatedComponent;

          if (activatedComponentName) {
            this.trackPageView(`${activatedComponentName} ${this.getRoutePath(event.state.root)}`, event.urlAfterRedirects);
          }
        },
      });
    }
  }

  public stopRouteMonitoring() {
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }

  public setAuthenticatedUser(userId: string): void {
    this.appInsights.setAuthenticatedUserContext(userId);
  }

  public clearAuthenticatedUser(): void {
    this.appInsights.clearAuthenticatedUserContext();
  }

  public trackPageView(
    name?: string,
    uri?: string,
    properties?: { [key: string]: string },
    measurements?: { [key: string]: number }
  ): void {
    const telemetry: IPageViewTelemetry = { name, uri, properties, measurements };
    this.appInsights.trackPageView(telemetry);
  }

  public trackEvent(name: string, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
    const telemetry: IEventTelemetry = { name, properties, measurements };
    this.appInsights.trackEvent(telemetry);
  }

  public trackException(error: Error, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
    const telemetry: IExceptionTelemetry = { error, properties, measurements };
    this.appInsights.trackException(telemetry);
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): string | Type<any> {
    return snapshot.firstChild ? this.getActivatedComponent(snapshot.firstChild) : snapshot.component;
  }

  private getRoutePath(snapshot: ActivatedRouteSnapshot): string {
    const path = snapshot.routeConfig ? snapshot.routeConfig.path : '';
    return snapshot.firstChild ? path + this.getRoutePath(snapshot.firstChild) : path;
  }
}
