<div class="verify-account">
       <div>
              <p class="text-center font-md px-3">
                     A message with a verification code has been sent to the email you entered. Enter the code to
                     continue.
              </p>
              <form class="d-flex flex-column justify-content-center" [formGroup]="form" (ngSubmit)="verify()"
                    (keydown.enter)="verify()">
                     <div class="verify-account-fields" [ngClass]="{'code-error': showCodeError}">
                            <input type="text" (keypress)="onKeyPress($event)" class="form-control mx-1" minlength="1"
                                   maxlength="1" (input)="moveFocus($event, 0)" formControlName="digit1"
                                   (paste)="onPaste($event)" #inputField />
                            <input type="text" (keypress)="onKeyPress($event)" class="form-control mx-1" minlength="1"
                                   maxlength="1" (input)="moveFocus($event, 1)" formControlName="digit2" #inputField />
                            <input type="text" (keypress)="onKeyPress($event)" class="form-control mx-1" minlength="1"
                                   maxlength="1" (input)="moveFocus($event, 2)" formControlName="digit3" #inputField />
                            <input type="text" (keypress)="onKeyPress($event)" class="form-control mx-1" minlength="1"
                                   maxlength="1" (input)="moveFocus($event, 3)" formControlName="digit4" #inputField />
                            <input type="text" (keypress)="onKeyPress($event)" class="form-control mx-1" minlength="1"
                                   maxlength="1" (input)="moveFocus($event, 4)" formControlName="digit5" #inputField />
                            <input type="text" (keypress)="onKeyPress($event)" class="form-control mx-1" minlength="1"
                                   maxlength="1" (input)="moveFocus($event, 5)" formControlName="digit6" #inputField />
                     </div>
                     <div class="col-12 d-flex flex-column align-items-center flex-nowrap">
                            <button type="submit" class="btn btn-warning col-4 mb-2"
                                    [disabled]="!form.valid || !submitEnabled">
                                   Submit
                            </button>
                            <button type="button" class="btn btn-link" (click)="requestVerificationCode()"
                                    [disabled]="!requestVerificationCodeEnabled">Didn´t get a verification
                                   code?</button>
                            <div *ngIf="!requestVerificationCodeEnabled" class="font-md">Your verification code has been
                                   sent</div>
                     </div>
              </form>
       </div>
</div>