import { CreditCard, PaymentType } from '.';

export interface IPaymentOption {
  id: string;
  type: PaymentType;
  subType: string;
  subTypeName: string;
  description: string;
  shortDescription: string;
  usedInAutoShip: boolean;
  c: string;
  lastFour: string;
  expiration: string;
  image: string;
  sortOrder: number;
  isExpired: boolean;
  isAllowSetAsDefault: boolean;
  setAsDefault: boolean;
  isAllowSaveOnFile: boolean;
  saveOnFile: boolean;
  isOnFile: boolean;
  hidePaymentMethodActions: boolean;
  payAtCheckout: boolean;
  verifyAtCheckout: boolean;
  creditCardVerification: CreditCard;
}

export class PaymentOption implements IPaymentOption {
  c: string;
  id: string;
  type: PaymentType;
  subType: string;
  subTypeName: string;
  description: string;
  shortDescription: string;
  usedInAutoShip: boolean;
  lastFour: string;
  expiration: string;
  image: string;
  sortOrder: number;
  isExpired: boolean;
  isAllowSetAsDefault: boolean;
  setAsDefault: boolean;
  isAllowSaveOnFile: boolean;
  saveOnFile: boolean;
  isOnFile: boolean;
  hidePaymentMethodActions: boolean;
  payAtCheckout: boolean;
  verifyAtCheckout: boolean;
  creditCardVerification: CreditCard;
}
