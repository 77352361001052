import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CoreConfiguration } from 'src/app/core/core-configuration';

@Injectable({
  providedIn: 'root',
})
export class CartCookieService {
  private cookieName = this.config.cookies.cart;
  private cookieCounterProperty = this.config.cartCookieKeys.itemsCount;

  constructor(public cookieService: CookieService,
    private readonly config: CoreConfiguration) {}

  /**
   * Gets an object representation of a cookie.
   * @param cookieName
   * @returns
   */
  public getCookieObject(cookieName = ''): {} {
    const cookieToFind = cookieName || this.cookieName;
    if (!this.cookieService.check(cookieToFind)) {
      return {};
    }

    const cookieString = this.cookieService.get(cookieToFind);
    const cookie = decodeURIComponent(cookieString);

    const cookieValues = cookie.split('&');
    const cookieObject = {};
    for (const cookieValue of cookieValues) {
      const kvp = cookieValue.split('=');
      if (!kvp[0]) {
        continue;
      }

      cookieObject[kvp[0]] = kvp.length > 1 ? kvp[1] : '';
    }

    return cookieObject;
  }

  /**
   * Sets a cookie based on an object representation of the cookie.
   * @param cookieName
   * @param cookieObject
   * @param expirationDays
   */
  public setCookieFromObject(cookieName: string, cookieObject: {}, expirationDays: number): void {
    const cookieToSet = cookieName || this.cookieName;

    const hostParts = location.host.split('.');
    hostParts.shift();
    const domain = '.' + hostParts.join('.');

    this.cookieService.set(cookieToSet, $.param(cookieObject), expirationDays, '/', domain);
  }

  /**
   * Gets the items count from the cookie.
   */
   public getItemsInCartCount() {
    const cookie = this.getCookieObject();
    const count = Number(cookie[this.cookieCounterProperty]);
    return isNaN(count) ? 0 : count;
  }

  /**
   * Gets value from from the cookie.
   * @param key
   */
  public getCookieValue(key: string) {
    const cookie = this.getCookieObject();
    return cookie[key];
  }

  public removeCookieByName(cookieName: string) {
    this.cookieService.delete(cookieName,'/', this.getDomain());
  }

  public setContentSiteCookie(cookieName: string, cookieValue: string, expirationDays?: number, expirationHours?: number,expirationMinutes?: number, encodeCookie?: any, secure?: any, sameSite?: any): void{
    let expires = "";
    if (expirationDays > 0) {
      var date = new Date();
      date.setTime(date.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }else if(expirationHours > 0){
      var date = new Date();
      date.setTime(date.getTime() + (expirationHours * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    else if(expirationMinutes > 1){
      var date = new Date();
      date.setTime(date.getTime() + (expirationMinutes * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }

    let domain = this.getDomain();
    let fullCookieText = "";
    if (encodeCookie) {
        fullCookieText = encodeURIComponent(cookieName) + "=" + encodeURIComponent((cookieValue || "")) + expires + "; path=/; domain=" + domain;
    }

    else {
        fullCookieText = cookieName + "=" + (cookieValue || "") + expires + "; path=/; domain=" + domain;
    }

    if (secure) {
        fullCookieText += ";secure";
    }

    if (sameSite && sameSite.length > 0) {
        fullCookieText += ";SameSite=" + sameSite;
    }
    document.cookie = fullCookieText;
  }

  public getCookieFromName(cookieName: string): string {
    return this.cookieService.get(cookieName);
  }

  private getDomain(): string {
    let hostParts = location.host.split(".");
    hostParts.shift();
    return "." + hostParts.join(".");
  }
}
