<form class="gen-chat-wrap gen-chat-wrap-surv" [formGroup]="form" (ngSubmit)="sendChat($event)">
    <div class="gen-chat-header d-flex justify-content-between">
        <span>Rate Our Service</span>
        <button type="button" class="btn-chat-close" (click)="onCloseSurvey()"><i
               class="lef-close icon-expanded"></i></button>
    </div>

    <div class="gen-chat-content-wrap">
        <div class="gen-chat-content py-3">
            <div class="text-center">
                <p>How was your experience with our Agent?</p>
            </div>
            <div class="d-flex justify-content-center">
                <button class="gen-chat-like" type="button" (click)="setServiceScore(1)"><i
                       class="lef-thumbs-up text-info"></i></button>
                <button class="gen-chat-like" type="button" (click)="setServiceScore(-1)"><i
                       class="lef-thumbs-down text-info"></i></button>
            </div>
        </div>
        <div class="gen-chat-ftr gen-text-box">
            <textarea placeholder="Send your comments..." class="gen-chat-mesg" formControlName="comments"></textarea>
            <label *ngIf="form?.get('comments')?.errors && form?.get('comments')?.dirty"
                   class="gen-val pl-3 mb-0">Please enter your
                comments</label>
        </div>
    </div>
    <div class="text-right gen-send-btn-wrap">
        <button type="submit" class="gen-send-btn">Submit</button>
    </div>
</form>