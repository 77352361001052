import { UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

export class ValidationMessages {
  public static RequiredMessage = 'This field is required.';
  public static EmailMessage = 'Enter a valid email address.';
  public static EmailMismatchMessage = 'Email do not match.';
  public static PasswordMismatchMessage = 'Passwords do not match.';
  public static PasswordStrengthMessage = 'Password needs to have letters and numbers.';
  public static PasswordMinLengthMessage = 'Password needs to have at least 6 characters.';
  public static NotNullOrWhitespaceMessage = 'Enter a valid value.';
}

export function maxLengthValidationMessage(err, field) {
  return `Maximum Characters: ${field.templateOptions.maxLength}`;
}

export class AppValidators {
  ValidatorFn;
  public static passwordMismatch(passwordControlName: string): ValidatorFn {
    return (control: UntypedFormControl | any) => {
      if (!control.parent) {
        return null;
      }

      const myValue = control.value;
      const compareValue = control.parent.controls[passwordControlName].value;

      return myValue && compareValue && myValue !== compareValue ? { passwordMismatch: passwordControlName } : null;
    };
  }

  public static passwordMismatchTrigger(confirmationControlName: string): ValidatorFn {
    return (control: UntypedFormControl | any) => {
      if (control.parent) {
        control.parent.controls[confirmationControlName].updateValueAndValidity();
      }
      return null;
    };
  }

  public static passwordStrength(control: UntypedFormControl) {
    if (!control?.value) {
      return null;
    }

    // We Required Letters and Numbers
    const letters = /[a-zA-Z]+/;
    const numbers = /[0-9]+/;

    return letters.test(control.value) && numbers.test(control.value) ? null : { passwordStrength: true };
  }

  public static passwordMinLength(control: UntypedFormControl) {
    if (!control?.value) {
      return null;
    }
    return control.value.length >= 6 ? null : { passwordMinLength: true };
  }

  public static emailMismatch(group: UntypedFormGroup, controlName: string) {
    return (control: UntypedFormControl) => {
      const myValue = control.value;
      const compareValue = group.controls[controlName].value;

      return myValue && compareValue && myValue !== compareValue ? { emailMismatch: controlName } : null;
    };
  }

  public static notNumber(control: UntypedFormControl) {
    return control.value && !isNaN(control.value) ? { notNumber: true } : null;
  }

  public static notNullOrWhitespace(control: UntypedFormControl) {
    if (!control?.value) {
      return { notNullOrWhitespace: true };
    }

    const value = control.value as string;
    return value.trim().length > 0 ? null : { notNullOrWhitespace: true };
  }
}

export function EmailAddressValidator(control: UntypedFormControl): ValidationErrors {
  return !control.value || /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(control.value) ? null : { 'email-address': true };
}

export function EmailAddressValidatorMessage(err, field: FormlyFieldConfig) {
  return `"${field.formControl.value}" is not a valid Email Address`;
}
