<form [ngClass]="showInitialChat ? 'gen-chat-wrap mb-3' : 'gen-chat-wrap mb-3 d-none'" [formGroup]="form"
      (ngSubmit)="sendChat($event)" (keydown.enter)="sendChat($event)">
    <div *ngIf="showSpinner" id="genChatSpinner" class="gen-chat-spinner">
        <div class="spinner-border mb-2" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <p class="font-lg">Connecting to Agent...</p>
    </div>
    <div class="gen-chat-header">
        <span>Message Us</span>
    </div>
    <div class="gen-chat-content-wrap">
        <div class="gen-chat-content">
            <div class="mb-3 pb-2 border-bottom">
                <p class="m-0">
                    <strong>Chat Hours:</strong> {{ startDayLabel }} - {{ endDayLabel }}<span class="px-1">|</span>{{
                    startTimeLabel }} - {{ endTimeLabel }}
                    (ET)
                </p>
            </div>
            <div *ngIf="!isUserLoggedIn" class="mb-3">
                <div class="pr-4">
                    <input placeholder="Enter Name" class="form-control" formControlName="name">
                </div>
                <label *ngIf="this.form?.get('name')?.errors && this.form?.get('name')?.dirty"
                       class="gen-val mb-0">Please
                    enter a customer
                    name</label>
            </div>
            <div *ngIf="!isUserLoggedIn" class="mb-3">
                <div class="pr-4">
                    <input placeholder="Enter Email Address" class="form-control" formControlName="email">
                </div>
                <label *ngIf="this.form?.get('email')?.errors && this.form?.get('email')?.dirty"
                       class="gen-val mb-0">Please enter a valid
                    email
                    address</label>
            </div>
            <div class="mb-3">
                <select class="form-control" (change)="chatTalkToChange()" #chatTalkTo>
                    <option selected="" disabled="" #chatTalkToOption>Who would you like to talk to?</option>
                    <option value="service">Customer Service, Pricing, Other</option>
                    <option value="wellness">Health Concern, Product Related</option>
                </select>
                <label class="gen-val mb-0" #chatTalkToLabel>Please select a
                    department</label>
            </div>
        </div>
        <div class="gen-chat-ftr gen-text-box">
            <textarea placeholder="Send a message..." class="gen-chat-mesg" formControlName="message"></textarea>
            <label *ngIf="this.form?.get('message')?.errors && this.form?.get('message')?.dirty"
                   class="gen-val pl-3 mb-0">Please enter a
                message</label>
        </div>
    </div>
    <div class="text-right gen-send-btn-wrap">
        <button type="submit" class="gen-send-btn"><i class="lef-send"></i></button>
    </div>
</form>

<button *ngIf="showInitialButton" (click)="toggleGenesysChat()" class="gen-chat-btn d-flex align-items-center"><i
       [ngClass]="showInitialChat ? 'lef-chevron-down' : 'lef-comment'" aria-hidden="true"></i> <span
          class="ml-1">Chat</span></button>

<div #survey>
    <lef-genesys-survey [messageId]="messageId" (closeSurveyEvent)="onCloseSurvey()"></lef-genesys-survey>
</div>