const contentSiteUrl = 'https://qa3.lifeextension.com';

export const environment = {
  production: false,
  api: 'https://cart-api-local.lifeextension.com/',
  contentSite: contentSiteUrl,
  assetSite: 'https://www.lifeextension.com',
  logging: 'https://cart-api-local.lifeextension.com/api/logging',

  useApiLogging: false,
  useInsights: false,
  googleUIId: 'UA-2208667-2',
  enablePinterestTracking: false,
  enableGoogleTagTracking: true,
  enableCriteoTracking: false,
  enableSelligentTracking: false,
  enableFacebookTracking: false,
  enableGrinTracking: false,
  enableWholesaleResellerApp: true,
  enablePremierSubscription: true,
  showApplicationVersion: true,

  myOffers: {
    enabled: true,
    offersVerificationUrl: `${contentSiteUrl}/api/myoffers/OffersNotification`,
    myLifeExtensionPageUrl: `${contentSiteUrl}/mylifeextension`,
    myLifeExtensionWholesalePageUrl: `${contentSiteUrl}/mylifeextension-wholesale`,
  },

  lifeExtensionTracking: {
    enabled: false,
    registerPageVisitEndPoint: '/cart/registercartpagevisit?customernumber=_customernumber_&page=_page_',
    registerPurchaseGoalEndPoint:
      '/cart/registercartpurchasegoal?customernumber=_customernumber_&ordernumber=_ordernumber_&newcustomer=_newcustomer_',
    registerCustomerRegistrationGoalEndPoint: '/cart/registercustomerregistrationgoal?customernumber=_customernumber_',
  },

  commissionJunction: {
    enableCJTracking: true,
    cookieName: 'cje',
    cookieID: 'cje',
    affiliateImageUrl: `https://www.emjcd.com/tags/c?CID=1501702&_items_&OID=_order_&TYPE=413968&CURRENCY=USD&CJEVENT=_idValue_&LOYALTY_STATUS=_LOYALTY_STATUS_&CUST_COUNTRY=_CUST_COUNTRY_&LOCATION=_LOCATION_&CUST_STATUS=_CUST_STATUS_`,
  },

  powerReviews: {
    enabled: false,
    includedOn: ['/checkout/success'],
    merchantId: '217083',
    checkoutPageId: '616642',
    orderTrackerEnabled: false,
  },

  connexityConversionTracking: {
    enabled: false,
    cookieName: 'CX',
    memberId: '314194',
  },

  abtastyTracking: {
    enabled: false,
  },

  nortonShoppingGuaranteeTracking: {
    enabled: false,
    includedOn: ['/', '/checkout'],
  },

  yahooGeminiConversionTracking: {
    enabled: false,
  },

  bingConversionTracking: {
    enabled: false,
  },

  selligentClearCartUrl:
    'https://lifeextension.slgnt.us/renderers/body.ashx?ID=PbkPp_xKvfa2GfsqPrJkoqyVEwhfybUZKfTVOmJ6VoREMuzzz5U_bL_FfhvXNcusnFTJE2Ld6IFMRVRSxf',

  appInsights: {
    instrumentationKey: '22c62ffa-e1f4-4302-93e2-8d655a69bbde',
  },

  payPal: {
    clientId: 'ATSl9RWqZF6QODNLBQDc0EIGRysRm-zg2FC7KvidC2wIJegpSOKDq9UYM7qf1pDPPRZHWEPM2gLKfL2Q',
  },

  customItemUrlTemplates: [
    {
      itemNumbers: ['PREMIERST', 'PREMIERSTVIP', 'PREMIEREX', 'PREMIEREXVIP'],
      itemUrlTemplate: contentSiteUrl + '/about/premier',
    },
  ],

  itemUrlTemplates: {
    itemImageUrl: contentSiteUrl + '/-/media/lifeextension/products/small/{itemNumber}.png',
    itemUrlTemplate: contentSiteUrl + '/vitamins-supplements/item{itemNumber}',
  },

  featureFlags: {
    placeOrder: true,
  },

  orderHistory: {
    limitItemsPerRequest: 20,
  },

  autoShipDaysThreshold: 10,
  captchaSiteKey: '',
  proposition65Url: 'https://oehha.ca.gov/proposition-65',
  customerServicePhone: '1-800-678-8989',
  wholesaleServiceEmail: 'wholesale@lifeextension.com',
  feedbackUrl: 'http://test-health.lifeextension.com/LEFSurvey/ContactUs.aspx',
  writeReviewUrl: `${contentSiteUrl}/writeareview`,
  bestSellersLink: '/vitamins-supplements/best-sellers',
  supplementQuizLink: '/health-basics/supplement-guides?quiz=essentials',

  nonHeadersOrFooterPaths: [/^\/pci\/.*$/],

  coveoConfiguration: {
    organization: 'lifeextensioncloudsandbox6hpjxsqe',
    restApiUrl: 'https://lifeextensioncloudsandbox6hpjxsqe.org.coveo.com/rest/search/',
    analyticsApiUrl: 'https://lifeextensioncloudsandbox6hpjxsqe.analytics.org.coveo.com/rest/ua/',
    apiKey: 'xx36ad2ad7-4f95-4e7c-a5b1-d0b6e4e2e807',
    source: 'Coveo_LE_index - sc102QA',
    loggerLevel: 6, // TRACE = 1; DEBUG = 2; INFO = 3; WARN = 4; ERROR = 5; NOTHING = 6;
    searchHub: 'Search',
  },

  queryStrings: {
    emailId: 'eid',
    cid: 'cid',
    contactId: 'ct',
    prospect: 'p',
  },

  refreshConfiguration: {
    refreshOnNavigation: true,
    refreshOnIdle: true,
    refreshTimeout: 22,
  },

  genesysConfiguration: {
    enable: true,
    urlSendChat: 'https://apps.mypurecloud.com/genesys-bootstrap/genesys.min.js',
    workingDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    startTime: 0,
    endTime: 24,
    wellnessId: '43cbdf78-f9c6-4600-a937-4340a7ac38f7',
    serviceId: 'e2e53ad0-6a4f-4b79-9277-f499ff61a412',
  },

  googlepayConfiguration: {
    environment: 'TEST',
    merchantId: '12345678901234567890',
  },

  reselleracknowledgementEnabled: false,
};
