<span *ngIf="isLoadingInitialData && !paymentSelected" class="k-i-loading k-icon k-font-icon font-weight-medium"
      style="font-size: 34px;"></span>
<ng-container *ngIf="!isLoadingInitialData && !hasPaymentOptions">
  <div *ngIf="isCheckOut && !gateway.usingSecondaryGateway && isPayPalEnabled"
       class="d-flex align-items-center justify-content-between pt-3 paypal-selector-container">
    <div class="payment-opts-wrap">
      <span>
        <img src="assets/images/payment-method-paypal.png" class="payment-opts-img" alt="PayPal" />
      </span>
    </div>
    <button class="btn btn-link" type="button" (click)="selectNewPayment('Paypal')">Select</button>
  </div>
  <div *ngIf="isCheckOut && !gateway.usingSecondaryGateway && isApplePayEnabled"
       [ngClass]="['d-flex align-items-center justify-content-between pt-3 paypal-selector-container', (isPayPalEnabled) ? 'payment-option-with-no-top-style' : '']">
    <div class="payment-opts-wrap">
      <span>
        <img src="assets/images/payment-method-apple-pay.png" class="payment-opts-img" alt="ApplePay" />
      </span>
    </div>
    <button class="btn btn-link" type="button" (click)="selectNewPayment('ApplePay')">Select</button>
  </div>
  <div *ngIf="isCheckOut && !gateway.usingSecondaryGateway && isGooglePayEnabled"
       [ngClass]="['d-flex align-items-center justify-content-between pt-3 paypal-selector-container', (isPayPalEnabled || isApplePayEnabled) ? 'payment-option-with-no-top-style' : '']">
    <div class="payment-opts-wrap">
      <span>
        <img src="assets/images/payment-method-google-pay.png" class="payment-opts-img" alt="GooglePay" />
      </span>
    </div>
    <button class="btn btn-link" type="button" (click)="selectNewPayment('GooglePay')">Select</button>
  </div>
  <div *ngIf="isCheckOut && !gateway.usingSecondaryGateway"
       class="d-flex align-items-center justify-content-between pt-3">
    <p><strong>{{newCreditCardLabel}}</strong></p>
    <button class="btn btn-link" type="button" (click)="selectNewPayment('Card')">Select</button>
  </div>
  <lef-payment-new-method *ngIf="!isCheckOut || gateway.usingSecondaryGateway" [gateway]="gateway" [embedded]="true"
                          [isCheckOut]="isCheckOut" [isReturn]="isReturn"
                          [isPayPalCheckoutFlowEnabled]="usePayPalCheckoutFlow"
                          (paymentOptionsChanged)="onPaymentOptionsChanged($event)">
  </lef-payment-new-method>
</ng-container>

<ng-container *ngIf="hasPaymentOptions">
  <div *ngIf="paymentSelected || paymentMethodLabel" class="payment-select-opts-wrap">
    <div class="co-payment-wrap">
      <div class="">
        <p *ngIf="paymentMethodLabel" [ngClass]="labelClass" class="mb-3">{{paymentMethodLabel}}</p>
        <ng-container *ngIf="paymentSelected">
          <div class="d-flex" *ngIf="!showNewCardPaymentBox">
            <div>
              <div class="co-payment-descrip">
                <img *ngIf="!hideSelectedMethodImage" src="assets/images/payment-method-{{paymentSelected.image}}.png"
                     class="co-payment-img  payment-type-{{paymentSelected?.type}}"
                     alt="{{paymentSelected?.subTypeName}}" />
                <div *ngIf="!isPayPalPaymentSelected" class="co-payment-cardinfo mr-sm-3 font-md"
                     [innerHTML]="paymentSelected.description"></div>

                <span
                      *ngIf="paymentSelected.type != 0 && paymentSelected.type != 12 && paymentSelected.type != 13">{{paymentSelected?.description}}</span>

              </div>
            </div>
          </div>
          <div *ngIf="showNewCardPaymentBox">
            <p><strong>{{newCreditCardLabel}}</strong></p>
          </div>
        </ng-container>
      </div>
      <ng-container *ngIf="paymentSelected">
        <div class="d-flex align-items-center">
          <div *ngIf="!hidePaymentActions" class="d-flex align-items-center mr-3">
            <input id="savePaymentMethod" type="checkbox" [ngModel]="paymentSelected.saveOnFile"
                   [disabled]="!paymentSelected.isAllowSaveOnFile"
                   (ngModelChange)="onChangeSaveSelectedPaymentMethod($event)" />
            <label for="savePaymentMethod" [ngClass]="{'text-muted': !paymentSelected.isAllowSaveOnFile}"
                   class="font-md font-weight-bold">
              Save Method</label>
          </div>
          <div *ngIf="!hidePaymentActions" class="d-flex align-items-center">
            <input id="setAsDefault" type="checkbox" [disabled]="!isAllowSetAsDefault"
                   [ngModel]="paymentSelected.setAsDefault"
                   (ngModelChange)="onChangeSetDefaultSelectedPaymentMethod($event)" />
            <label for="setAsDefault" [ngClass]="{'text-muted': !isAllowSetAsDefault}" class="font-md font-weight-bold">
              Default</label>
          </div>
        </div>
      </ng-container>
    </div>

    <div *ngIf="paymentSelected && !preventChange" class="payment-select-opts-act">
      <span *ngIf="isLoadingInitialData && paymentSelected" class="k-i-loading k-icon k-font-icon"
            style="font-size: 34px;"></span>
      <button *ngIf="!isLoadingInitialData" id="paymentSelectOpts" aria-controls="paymentSelectOptsPanel" type="button"
              style="margin-top: -2px" class="btn btn-link py-0" (click)="toggleSelectionBox()"
              [attr.aria-expanded]="isSelectionBoxOpen">
        Change <i [ngClass]="{'lef-chevron-up': isSelectionBoxOpen, 'lef-chevron-down': !isSelectionBoxOpen }"></i>
      </button>

    </div>
  </div>

  <ng-container *ngIf="showNewCardPaymentBox && !isSelectionBoxOpen">
    <lef-payment-new-method [gateway]="gateway" [embedded]="true" [isCheckOut]="isCheckOut" [isReturn]="isReturn"
                            [isPayPalCheckoutFlowEnabled]="usePayPalCheckoutFlow"
                            (paymentOptionsChanged)="onPaymentOptionsChanged($event)">
    </lef-payment-new-method>
  </ng-container>

  <div [ngClass]="{'d-none': !isSelectionBoxOpen }" class="row mb-3 co-payment-opts-wrap" id="paymentSelectOptsPanel"
       aria-labelledby="paymentSelectOpts" [attr.aria-expanded]="isSelectionBoxOpen">
    <div *ngIf="!isCheckOut && gateway.usingSecondaryGateway" class="m-3 alert alert-warning" role="alert">
      Payment management is temporarily unavailable. We are in the process of making some quick updates. Please check
      back shortly.
    </div>
    <div [ngClass]="{'col': (isCheckOut || !gateway.usingSecondaryGateway), 'col-md-12': !(isCheckOut || !gateway.usingSecondaryGateway), 'co-payment-opts-border': (isCheckOut && !gateway.usingSecondaryGateway)}"
         class="co-payment-opts">
      <lef-payment-option-selector [paymentOptions]="paymentOptions" [paymentOptionSelectedId]="paymentOptionSelectedId"
                                   [isCheckOut]="isCheckOut" [isReturn]="isReturn"
                                   (paymentOptionSelected)="selectPaymentOption($event)">
      </lef-payment-option-selector>
      <div *ngIf="isCheckOut && !gateway.usingSecondaryGateway && isPayPalEnabled"
           class="d-flex align-items-center justify-content-between pt-3 paypal-selector-container">

        <div class="payment-opts-wrap">
          <span>
            <img src="assets/images/payment-method-paypal.png" class="payment-opts-img" alt="PayPal" />
          </span>
        </div>
        <button class="btn btn-link" type="button" (click)="selectNewPayment('Paypal')">Select</button>
      </div>
      <div *ngIf="isCheckOut && !gateway.usingSecondaryGateway && isApplePayEnabled"
           [ngClass]="['d-flex align-items-center justify-content-between pt-3 paypal-selector-container', (isPayPalEnabled) ? 'payment-option-with-no-top-style' : '']">

        <div class="payment-opts-wrap">
          <span>
            <img src="assets/images/payment-method-apple-pay.png" class="payment-opts-img" alt="ApplePay" />
          </span>
        </div>
        <button class="btn btn-link" type="button" (click)="selectNewPayment('ApplePay')">Select</button>
      </div>
      <div *ngIf="isCheckOut && !gateway.usingSecondaryGateway && isGooglePayEnabled"
           [ngClass]="['d-flex align-items-center justify-content-between pt-3 paypal-selector-container', (isPayPalEnabled || isApplePayEnabled) ? 'payment-option-with-no-top-style' : '']">

        <div class="payment-opts-wrap">
          <span>
            <img src="assets/images/payment-method-google-pay.png" class="payment-opts-img" alt="GooglePay" />
          </span>
        </div>
        <button class="btn btn-link" type="button" (click)="selectNewPayment('GooglePay')">Select</button>
      </div>
      <div *ngIf="isCheckOut && !gateway.usingSecondaryGateway"
           class="d-flex align-items-center justify-content-between pt-3">
        <p><strong>{{newCreditCardLabel}}</strong></p>
        <button class="btn btn-link" type="button" (click)="selectNewPayment('Card')">Select</button>
      </div>
    </div>
    <div *ngIf="(!isCheckOut && !gateway.usingSecondaryGateway) || (isCheckOut && gateway.usingSecondaryGateway)"
         class="col-md-5 co-payment-opts-select">
      <button type="button" class="btn btn-warning" (click)="openNewPaymentMethodDialog()">
        Add New Payment Method</button>
    </div>
  </div>

</ng-container>