import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IShippingMethod } from 'src/app/shipping/model';

@Component({
  selector: 'lef-auto-ship-shipping-method-selection',
  templateUrl: './auto-ship-shipping-method-selection.component.html',
  styleUrls: ['./auto-ship-shipping-method-selection.component.scss']
})
export class AutoShipShippingMethodSelectionComponent implements OnInit {

  @Input() shippingMethods: IShippingMethod[] = [];
  @Input() selectedMethod: string;
  @Input() shippingDay: string;

  @Output()
  methodSelected: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }

  onMethodSelected(): void {
    this.methodSelected.emit(this.selectedMethod);
  }
}
