import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogService } from '@progress/kendo-angular-dialog';
import { ICartPromotionMessage } from 'src/app/cart-common/model';
import { Cart, ICart } from '../../model';
import { CartPromotionCategory } from '../../model/cart-promotion-category';
import { CartCommonService } from '../../service';

@Component({
  selector: 'lef-cart-discount',
  templateUrl: './cart-discount.component.html',
  styleUrls: ['./cart-discount.component.scss'],
})
export class CartDiscountComponent implements OnInit {
  @Input() marketingMessages: ICartPromotionMessage[] = [];
  @Input() includeShipments = false;
  @Input() readOnly = false;
  @Input() promotionCategories: CartPromotionCategory[] = [];
  @Input() isOrderPlaced = false;

  @Output() discountApplied: EventEmitter<ICart> = new EventEmitter();

  @ViewChild('discountField', { static: false }) discountField: ElementRef<any>;
  @ViewChild('discountClose', { static: false }) discountClose: ElementRef<any>;

  private stopKeyListner: () => void;

  discountForm: UntypedFormGroup;
  showForm = false;
  showVerify = false;
  invalidMessage = '';
  marketingMessageToShow: ICartPromotionMessage;

  get discountText(): string {
    return this.marketingMessages.length > 0 ? '' : 'Discount Code';
  }

  get hasCode(): boolean {
    return this.marketingMessages.length > 0;
  }

  get addCodeText(): string {
    return this.hasCode ? 'Add Another Code' : 'Add Code';
  }

  get appliedCategories(): CartPromotionCategory[] {
    return this.promotionCategories?.filter(x => x.isApplied);
  }

  get availableCategories(): CartPromotionCategory[] {
    return this.promotionCategories?.filter(x => !x.isApplied && x !== this.nextCategory);
  }

  get nextCategory(): CartPromotionCategory {
    return this.promotionCategories?.find(x => !x.isApplied);
  }

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly cartService: CartCommonService,
    private readonly renderer: Renderer2,
    private readonly dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.generateForm();
  }

  onSubmit() {
    if (!this.discountForm.valid) {
      return;
    }
    this.applyDiscount(false);
  }

  onVerify() {
    this.applyDiscount(true);
  }

  onCancelVerify() {
    this.invalidMessage = '';
    this.showForm = true;
    this.showVerify = false;
  }

  private applyDiscount(force: boolean) {
    this.invalidMessage = '';

    this.cartService.applyMarketingCode(this.discountForm.value.discountCode, force, this.includeShipments).subscribe((cart: Cart) => {
      if (cart.showMarketingMessage) {
        this.showForm = false;
        this.showVerify = true;
        this.invalidMessage = cart.marketingDescription;
        return;
      }

      if (cart.messages && cart.messages.length > 0) {
        const message = cart.messages[0];
        if (message.type !== 'Success') {
          this.invalidMessage = message.message;
          return;
        }
      }

      this.discountApplied.emit(cart);
      this.showForm = false;
      this.showVerify = false;
      this.discountForm.reset();
    });
  }

  toggleForm() {
    if (this.showForm) {
      this.discountForm.reset();
      this.invalidMessage = '';
    }
    this.showForm = !this.showForm;

    if (!this.showForm) {
      if (this.stopKeyListner) {
        this.stopKeyListner();
      }
    } else {
      this.stopKeyListner = this.renderer.listen('document', 'keydown', ($event: KeyboardEvent) => {
        const isTabKey = $event.key === 'Tab';
        if (isTabKey) {
          if ($event.shiftKey) {
            if (this.discountField.nativeElement === document.activeElement) {
              this.discountClose?.nativeElement.focus();
              $event.preventDefault();
              return;
            }
          } else if (this.discountClose?.nativeElement === document.activeElement) {
            this.discountField.nativeElement.focus();
            $event.preventDefault();
          }
        }
      });
    }
  }

  private generateForm(): void {
    this.discountForm = this.formBuilder.group({
      discountCode: ['', [Validators.required]],
    });
  }

  showAutoShipInfo($event: MouseEvent, promotionInfo: TemplateRef<any>, marketingMessage: ICartPromotionMessage): void {
    this.marketingMessageToShow = marketingMessage;
    this.dialogService.open({
      title: 'Code: ' + this.marketingMessages[0].discountCode,
      width: 900,
      maxHeight: '90%',
      maxWidth: '90%',
      content: promotionInfo,
      htmlAttributes: { id: 'promotionInfo' }
    });

    $event.preventDefault();
    $event.stopPropagation();
  }
}
