import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { BaseService } from 'src/app/shared/service/base-service';
import { CaptchaVerification } from '../model';

@Injectable({
  providedIn: 'root',
})
export class CaptchaValidatorService extends BaseService<CaptchaVerification> {
  protected apiUrl: string;
  constructor(protected override readonly httpClient: HttpClient, private readonly config: CoreConfiguration) {
    super(httpClient);

    this.apiUrl = config.environment.api + config.apiRoutes.captcha;
  }

  verify(verification: CaptchaVerification): Observable<CaptchaVerification> {
    return this.post(`${this.apiUrl}/verify`, verification);
  }
}
