<div>
  <p class="m-0"><strong>Item ship within 1 business day</strong></p>
  <p class="mb-3">
    Note: This item will be added to your cart for purchase now.
    This does not affect your schedule of future AutoShip &amp; Save shipments.
  </p>
  <div class="row">
    <div class="col-md-3 text-center">
      <img class="auto-ship-img-add" alt="Product Image" onerror="" src={{itemImageUrl}} />
    </div>
    <div class="col-md-9">
      <h4 class="roboto">{{item.friendlyName}}</h4>
      <p>{{item.subtitle}}</p>
      <div class="row mb-3">
        <div class="col-6 auto-ship-qty-add">
          <lef-quantity-input [label]="'Quantity:'" [quantity]="quantity" (quantityUpdate)="updateQuantity($event)">
          </lef-quantity-input>
        </div>
        <div class="col-6">
          <lef-cart-item-price [extendedPrice]="price" [totalBaseStandardPrice]="basePrice"
                               [percentSavings]="percentageSavings">
          </lef-cart-item-price>
        </div>
      </div>
      <div class="text-right">
        <button class="btn btn-action" (click)="addToCart()">Add To Cart</button>
      </div>
    </div>
  </div>
</div>