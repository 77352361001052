import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lef-dialog-alert-message',
  templateUrl: './dialog-alert-message.component.html',
  styleUrls: ['./dialog-alert-message.component.scss'],
})
export class DialogAlertMessageComponent implements OnInit {
  @Input() htmlMessage: string;
  @Input() showButtons: boolean;

  @Output() confirmPressed: EventEmitter<void> = new EventEmitter();
  @Output() cancelPressed: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
