import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CoreConfiguration } from 'src/app/core/core-configuration';

@Component({
  selector: 'lef-prop-65-message',
  templateUrl: './prop-65-message.component.html',
  styleUrls: ['./prop-65-message.component.css']
})
export class Prop65MessageComponent implements OnInit {

  @Output()
  closeDialog: EventEmitter<any> = new EventEmitter();

  constructor(private readonly config: CoreConfiguration) { }

  ngOnInit() {
  }

  openConsent() {
    window.open(this.config.webRoutes.officerInfo, 'officerInfo');
    this.closeDialog.emit();
  }

}
