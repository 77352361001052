<div class="position-relative">
  <div class="d-flex justify-content-around align-items-center absolute-overlay" *ngIf="formLoading">
    <span class="k-i-loading k-icon k-font-icon font-weight-medium text-info font-4x"></span>
  </div>
  <form class="k-form" [formGroup]="labSubjectForm" (ngSubmit)="saveLabSubject()">
    <formly-form [model]="labSubject" [fields]="labSubjectFormFields" [form]="labSubjectForm">
    </formly-form>
    <div class="messages">
      <!-- <div *ngFor="let message of registrationModel.messages">
      <p>{{message.message}}</p>
    </div> -->
    </div>

    <div class="text-right pt-3">
      <button type="submit" class="btn btn btn-warning w-100 w-md-u" [disabled]="!labSubjectForm.dirty">
        <strong>Save Lab Test Subject</strong>
      </button>
    </div>
    <!-- <button type="button" class="btn btn btn-warning m-2" (click)="validateAddress()"
          *ngIf="registrationModel.hasErrorInAddress">Verify Again</button> -->
    <!-- <button type="button" class="btn btn btn-warning m-2" (click)="saveAsEnter()"
          *ngIf="registrationModel.hasErrorInAddress">Continue as Entered</button> -->
  </form>
  <div class="bg-light p-3 d-flex mt-3">
    <i class="lef-warning text-blue font-lg"></i>
    <span class="ml-3">
      <p>A <b>Lab Kit</b> will be required for customers living in the following states: <b>New York, New Jersey,
          Maryland, Massachusetts, and Rhode Island,</b> <i>or</i> in the case in which there is no LabCorp location
        within 50 miles of the subject's address.</p>
      <p>For <b>Pennsylvania</b> customers: According to <b>Pennsylvania state law we are not authorized to send any
          type
          of Lab Kit to residents in your state.</b> You must go to a LabCorp PSC in order to have your lab drawn.</p>
      <p>
        <b>Alaska Customers: We cannot offer kits to Alaska due to time-sensitive stability requirements.</b> You must
        go to a LabCorp location to have your blood drawn.
      </p>
      <p>Fax and Email requisition options are not available for subjects under the age of 18 or subjects living in
        <b>New
          York, New Jersey, Maryland, Massachusetts, or Rhode Island.</b>
      </p>
    </span>
  </div>
</div>

<ng-template #requisitionDetails>
  <p>
    Your Lab Test Requisition Options are a LabCorp Requisition Form or a Lab Kit. If you choose “LabCorp Requisition
    Form”, you will receive a form to present to a Patient Service Center in your area. The requisition you receive will
    have all the ordering information necessary for you to have your blood drawn. Please note that this option is not
    allowed in the states of <b>New York, New Jersey, Maryland, Massachusetts or Rhode Island.</b>
  </p>

  <p>
    Alternatively, if you choose Lab Kit, you will receive a kit containing all the necessary blood collection tubes,
    instructions and pre-paid shipping labels. It will be necessary for you to find a local doctors office, hospital, or
    local clinic to draw and process your blood. A local draw fee may be incurred.
  </p>
</ng-template>