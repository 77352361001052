<div class="login-page">
  <h1>{{title}}</h1>
  <div class="row" *ngIf="showForm">
    <div class="form-group  col-12 col-sm-6 p-5">
      <form class="k-form" [formGroup]="impForm" (ngSubmit)="submitForm()">
        <formly-form [form]="impForm" [fields]="impFormFields"></formly-form>
        <div class="float-right">
          <span class="p-5">
          </span>
          <button type="submit" class="btn btn-warning" [disabled]="!impForm.valid">Impersonate Customer</button>
        </div>
      </form>
    </div>
  </div>
</div>