import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { BaseService } from 'src/app/shared/service/base-service';

@Injectable({
  providedIn: 'root'
})
export class GenesysService extends BaseService<object>  {

  protected apiUrl: string;

  constructor(protected override readonly httpClient: HttpClient, private readonly config: CoreConfiguration) { 
    super(httpClient);
    this.apiUrl = config.environment.api + config.apiRoutes.genesys;
  }

  insertSurvey(form: any): Observable<any> {
    return this.httpPost<any>(`${this.apiUrl}/insertSurvey`,form).pipe(catchError(this.handleError));
  }
}
