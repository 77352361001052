import { Injectable } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { AddressVerificationComponent } from '../component/address-verification/address-verification.component';
import { EmailVerificationComponent } from '../component/email-verification/email-verification.component';
import { IAddressValidation } from '../model/address-validation';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root',
})
export class CartDialogService {
  constructor(private readonly dialogService: DialogService) {}

  showAddressValidationDialog(addressValidation: IAddressValidation, onClose: (confirm: boolean) => void) {
    const dialogRef: DialogRef = this.dialogService.open({
      title: 'Verify your Address',
      content: AddressVerificationComponent,
      height: 500,
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
    });

    const componentRef: AddressVerificationComponent = dialogRef.content.instance;
    componentRef.addressValidation = addressValidation;

    componentRef.closeDialog.subscribe((confirm: boolean) => {
      dialogRef.close();
      onClose(confirm);
    });
  }

  showEmailVerificationDialog(email: string, onClose: (confirm: boolean) => void) {
    const dialogRef: DialogRef = this.dialogService.open({
      title: 'Email Verification',
      content: EmailVerificationComponent,
      width: 600,
      maxHeight: '90%',
      maxWidth: '90%',
    });

    const componentRef: EmailVerificationComponent = dialogRef.content.instance;
    componentRef.email = email;

    componentRef.closeDialog.subscribe((confirm: boolean) => {
      dialogRef.close();
      onClose(confirm);
    });
  }
}
