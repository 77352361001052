import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { CaptchaTriggerService } from '../../service/captcha-trigger.service';
import { ReCaptchaComponent } from '../re-captcha/re-captcha.component';

@Component({
  selector: 'lef-captcha-placeholder',
  templateUrl: './captcha-placeholder.component.html',
  styleUrls: ['./captcha-placeholder.component.scss'],
})
export class CaptchaPlaceholderComponent implements OnInit, OnDestroy {
  private done$ = new Subject<void>();

  dialogRef: DialogRef;
  reCaptchaComponent: ReCaptchaComponent;

  constructor(
    private readonly trigger: CaptchaTriggerService,
    private readonly dialogService: DialogService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.trigger.verificationTrigger$.pipe(takeUntil(this.done$)).subscribe({ next: (event) => this.onCaptchaTriggerFired(event) });
  }

  ngOnDestroy(): void {
    this.done$.next();
    this.done$.complete();
  }

  onCaptchaTriggerFired(fired: boolean) {
    if (fired) {
      this.loadingService.hide();

      this.dialogRef = this.dialogService.open({
        title: 'Captcha Verification Required',
        width: 720,
        maxHeight: '90%',
        maxWidth: '90%',
        content: ReCaptchaComponent,
      });

      this.reCaptchaComponent = this.dialogRef.content.instance;

      this.reCaptchaComponent.verificationPassed.subscribe({ next: (event) => this.dialogRef.close() });

      this.dialogRef.result.subscribe((result) => {
        this.dialogRef = null;
        this.reCaptchaComponent = null;
        this.changeDetector.detectChanges();
      });
    }
  }
}
