<div class="qty-input">
  <ng-container *ngIf="canChangeQuantity" class="d-flex">
    <label [attr.for]="'selectQty-'+componentId">{{label}}
      <select [id]="'selectQty-'+componentId" *ngIf="showQuantityDropDown" [(ngModel)]="quantityValue"
              (ngModelChange)="updateQuantity($event)">
        <ng-container *ngFor="let opt of quantityDropDownOptions">
          <option [ngValue]="opt">{{ opt }}</option>
        </ng-container>
      </select>
    </label>
    <ng-container *ngIf="!showQuantityDropDown">
      <span><input [id]="'selectQty-'+componentId" #quantityInput="ngModel" class="form-control" type="number"
               [ngModel]="quantityValue" min="1" max="999" maxlength="3" pattern="^([1-9]|[1-9][0-9]|[1-9][0-9][0-9])$"
               (change)="updateQuantity($event?.currentTarget?.value)"
               [ngClass]="{'is-invalid': (quantityInput.dirty && !quantityInput.valid)}" />
      </span>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!canChangeQuantity">
    <span class="pr-1">{{label}}</span>
    <span class="font-weight-medium">{{quantityValue}}</span>
  </ng-container>
</div>