import { Component } from '@angular/core';
import { CoreConfiguration } from 'src/app/core/core-configuration';

@Component({
  selector: 'lef-shipping-information',
  templateUrl: './shipping-information.component.html',
  styleUrls: ['./shipping-information.component.scss']
})
export class ShippingInformationComponent {

  internationalShippingUrl: string;

  constructor(private config: CoreConfiguration) {
    this.internationalShippingUrl = `${config.environment.contentSite}/vitamins-supplements/shipping/shipping-information`;
  }
}
