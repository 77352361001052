<div class="shipping-info-popup">
  <ul>
    <li><strong>Shipment Method</strong></li>
    <li><strong>Cost</strong></li>
  </ul>
  <ul>
    <li>USA Standard</li>
    <li>$5.50</li>
  </ul>
  <ul>
    <li>USA Expedited</li>
    <li>$18.00</li>
  </ul>
  <ul>
    <li>USA Overnight</li>
    <li>$29.50</li>
  </ul>
  <ul>
    <li>Puerto Rico, Virgin Islands</li>
    <li>$5.50</li>
  </ul>
  <ul>
    <li>International (not Canada)</li>
    <li class="text-right">Air Mail Costs will be added to the Order Total</li>
  </ul>
  <ul>
    <li>Canada USPS Priority International</li>
    <li>$19.50</li>
  </ul>
  <div class="shipping-info-popup-ftr pt-2">
    <p class="text-red">Prices for domestic shipments</p>
    <a [href]="internationalShippingUrl" target="_blank"><strong class="text-primary pr-1">International Shipping
        Information</strong> <i class="lef-chevron-circle-right text-orange"></i></a>
  </div>
</div>