import { Subject } from 'rxjs';
import { IPaymentGateway, PaymentType } from '.';
import { PaymentMethod } from './payment-method';
import { WalletPayment } from './payment-wallet';
declare global {
  interface Window {
    paypal: any;
  }
}

export interface IPayPalPaymentProcessor {
  gateway: IPaymentGateway;
  onPaymentDataReceived: Subject<WalletPayment>;
  isProcessingPayment: Subject<boolean>;
  onButtonsReady: Subject<boolean>;
  onError: Subject<string>;
  onCheckOutPaymentSelected: Subject<WalletPayment>;
  refresh(paypalCheckoutInstance, usingCheckoutFlow: boolean, amount: number, buttonName: string, layout: string);
  clearResources(): void;
}

export class EmptyPayPalPaymentProcessor implements IPayPalPaymentProcessor {
  gateway: IPaymentGateway;
  amount: number;
  onPaymentDataReceived: Subject<WalletPayment> = new Subject<WalletPayment>();
  onButtonsReady: Subject<boolean> = new Subject<boolean>();
  onCheckOutPaymentSelected: Subject<WalletPayment> = new Subject<WalletPayment>();
  isProcessingPayment: Subject<boolean> = new Subject<boolean>();
  onError: Subject<string> = new Subject<string>();
  refresh(paypalCheckoutInstance: any, usingCheckoutFlow: boolean, amount: number, buttonName: string, layout: string){}
  clearResources(): void {}
}

export class BraintreePayPalPaymentProcessor implements IPayPalPaymentProcessor {
  paypalCheckoutInstance: any;
  onPaymentDataReceived: Subject<WalletPayment> = new Subject<WalletPayment>();
  onCheckOutPaymentSelected: Subject<WalletPayment> = new Subject<WalletPayment>();
  isProcessingPayment: Subject<boolean> = new Subject<boolean>();
  onButtonsReady: Subject<boolean> = new Subject<boolean>();
  onError: Subject<string> = new Subject<string>();
  usingCheckoutFlow: boolean;
  amount: number;
  payPalBtnId: string;
  layout = 'vertical';
  buttons: any;
  gateway: IPaymentGateway;
  private loadingRetryLimit = 3;

  constructor(gateway: IPaymentGateway) {
    this.gateway = gateway;
  }

  refresh(paypalCheckoutInstance: any, usingCheckoutFlow: boolean, amount: number, buttonName: string, layout: string) {
    this.paypalCheckoutInstance = paypalCheckoutInstance;
    this.usingCheckoutFlow = usingCheckoutFlow;
    this.amount = amount;
    this.payPalBtnId = buttonName;
    this.layout = layout;
    this.init();
  }

  clearResources(): void {
    if (!!this.buttons) {
      try {
        this.buttons.close();
      } catch (error) {}
    }
  }

  init() {
    this.initWithRetry(this.loadingRetryLimit);
  }

  initWithRetry(iteration) {
    if (iteration > 0) {
      if (!!window.paypal) {
        this.clearResources();
        if (this.usingCheckoutFlow) {
          this.initPaypalButton();
        } else {
          this.initSimplePayment();
        }
      } else {
        setTimeout(() => {
          this.initWithRetry(iteration - 1);
        }, 1000);
      }
    } else {
      this.onError.next('PayPal not available');
    }
  }

  initPaypalButton() {
    this.buttons = window.paypal.Buttons({
      style: {
        color: 'gold',
        shape: 'rect',
        layout: this.layout,
      },
      createOrder: () => {
        return this.paypalCheckoutInstance.createPayment({
          flow: 'checkout',
          enableShippingAddress: false,
          amount: this.amount,
          currency: 'USD',
          intent: 'authorize',
          requestBillingAgreement: true,
        });
      },
      onApprove: (data, actions) => {
        return this.paypalCheckoutInstance.tokenizePayment(data).then((payload) => {
          const payment = new WalletPayment();
          payment.usingCheckoutFlow = true;
          payment.email = payload.details.email;
          payment.firstName = payload.details.firstName;
          payment.lastName = payload.details.lastName;
          payment.payerId = payload.details.payerId;
          payment.nonce = payload.nonce;
          payment.paymentType = PaymentType.PayPal;
          payment.paymentMethod = PaymentMethod.PayPal;
          this.onCheckOutPaymentSelected.next(payment);
        });
      },

      onCancel: (data) => {
        this.isProcessingPayment.next(false);
      },

      onError: (err) => {
        this.isProcessingPayment.next(false);
      },
    });
    if (!this.buttons.isEligible()) {
      console.error(`No eligible`);
      return;
    }
    setTimeout(() => {
      this.buttons
        .render(`#${!!this.payPalBtnId ? this.payPalBtnId : 'paypal-button'}`)
        .then(() => {
          this.onButtonsReady.next(true);
        })
        .catch((err) => {
          console.warn(`PayPalPaymentProcessor - initPaypalButton`, err);
        });
    }, 500);
  }

  initSimplePayment() {
    this.buttons = window.paypal.Buttons({
      fundingSource: window.paypal.FUNDING.PAYPAL,
      createBillingAgreement: () => {
        return this.paypalCheckoutInstance.createPayment({
          flow: 'vault', // Required
          intent: 'tokenize',
        });
      },

      onApprove: (data, actions) => {
        return this.paypalCheckoutInstance.tokenizePayment(data).then((payload) => {
          const payment = new WalletPayment();
          payment.email = payload.details.email;
          payment.firstName = payload.details.firstName;
          payment.lastName = payload.details.lastName;
          payment.payerId = payload.details.payerId;
          payment.nonce = payload.nonce;
          payment.paymentType = PaymentType.PayPal;
          payment.paymentMethod = PaymentMethod.PayPal;
          this.onPaymentDataReceived.next(payment);
        });
      },

      onCancel: (data) => {
        this.isProcessingPayment.next(false);
      },

      onError: (err) => {
        console.error('PayPal error', err);
        this.isProcessingPayment.next(false);
      },
    });
    setTimeout(() => {
      this.buttons
        .render(`#${!!this.payPalBtnId ? this.payPalBtnId : 'paypal-button'}`)
        .then(() => {
          this.onButtonsReady.next(true);
        })
        .catch((err) => {
          console.warn(err);
        });
    }, 500);
  }
}
