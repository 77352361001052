export interface IMessage {
  message: string;
  showIcon: boolean;
  type: string;
  source: string;
  tag: string;
}

export interface ICommonMessage {
  messages: IMessage[];
  refreshGlobalMessages: boolean;
  success: boolean;
}

export class CommonMessage implements ICommonMessage {
  messages: IMessage[];
  success: boolean;
  refreshGlobalMessages: boolean;
  constructor() {
    this.messages = [];
    this.success = null;
    this.refreshGlobalMessages = false;
  }
}
