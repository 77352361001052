<div class="order-summary-wrapper">
  <div class="order-summary">
    <div class="order-sum-items">
      <ul class="d-flex">
        <li><strong>Order Summary</strong></li>
        <li>{{cart.quantity}} Item(s)</li>
      </ul>
      <div>
        {{cart.totalItems | currency}}
      </div>
    </div>
    <div *ngIf="cart.savings && cart.savings > 0">
      <ul class="d-flex justify-content-between">
        <li>Savings</li>
        <li>-{{cart.savings | currency}}</li>
      </ul>
    </div>
    <div *ngIf="cart.isPromoAvailable" class="focus-box">
      <lef-cart-discount [marketingMessages]="cart.marketingMessages" [promotionCategories]="cart.promotionCategories"
                         (discountApplied)="onDiscountApplied($event)">
      </lef-cart-discount>
    </div>
    <div>
      <ul class="d-flex justify-content-between">
        <li><strong>Subtotal</strong></li>
        <li>{{cart.subTotal | currency}}</li>
      </ul>
    </div>
    <div class="focus-box">
      <lef-cart-shipping [shipping]="cart.shipping" [showPremierDiscount]="showPremierDiscount"
                         (shippingMethodUpdated)="onShippingMethodUpdate($event)">
      </lef-cart-shipping>
    </div>
    <div>
      <ul class="d-flex justify-content-between">
        <li><strong>Order Total</strong></li>
        <li>{{cart.totalOrder | currency}}</li>
      </ul>
    </div>
  </div>

  <div class="order-sum-total">
    <div *ngIf="cart.rewardsApplied && cart.rewardsApplied > 0" class="payment">
      <div>LE Rewards Applied</div>
      <div>-{{cart.rewardsApplied | currency}}</div>
    </div>
    <ul class="d-flex justify-content-between">
      <li>Amount Due</li>
      <li>{{cart.amountDue | currency}}</li>
    </ul>
    <div class="text-right">
      <button class="btn btn-action px-5" [disabled]="cart.isEmployee"
              [ngClass]="{'w-100': screenSize < screenSizes.LG}" (click)="onCheckout()">Checkout</button>
    </div>
  </div>
  <div *ngIf="cart.paymentInfo.isPayPalCheckoutFlowEnabled" class="os-paypal-summary-msg">
    <lef-paypal-pay-later-messages [amount]="cart.amountDue"></lef-paypal-pay-later-messages>
  </div>

</div>