import { Injectable } from '@angular/core';
import { GenericCacheService } from 'src/app/shared/service/generic-cache.service';
import { FloatingCart } from '../model/floating-cart.model';

@Injectable({
  providedIn: 'root',
})
export class FloatingCartCacheService extends GenericCacheService<FloatingCart> {
  constructor() {
    super();
  }
}
