<div>
  <div *ngFor="let marketingMessage of marketingMessages" class="mb-2">
    <div>
      <div class="d-flex justify-content-between">
        <div>Code: {{marketingMessage.discountCode}}</div>
        <div *ngIf="marketingMessage.discountAmount > 0 && marketingMessage.type === 'Success'">
          -{{marketingMessage.discountAmount | currency}}
        </div>
      </div>
      <ng-container *ngFor="let appliedCategory of appliedCategories; let last = last;">
        <div *ngIf="!isOrderPlaced || last"
             [ngClass]="[(last && appliedCategories.length === promotionCategories.length) || isOrderPlaced? '' : 'underline', 'font-sm d-flex justify-content-between py-1']">
          <strong>{{appliedCategory.description}}</strong>
          <div *ngIf="last; else betterOffer" class="d-flex flex-nowrap"><i
               class="k-i-check-circle k-icon k-font-icon text-green"></i> Applied
          </div>
          <ng-template #betterOffer>
            <div class="text-end">Better Offer Applied</div>
          </ng-template>
        </div>
      </ng-container>
      <div *ngIf="nextCategory && !isOrderPlaced">
        <p class="text-green font-md font-weight-bold mb-0 mt-1">
          {{nextCategory.description}}</p>
        <kendo-progressbar [value]="nextCategory.percentageFulfilled" [label]="{visible: false}">
        </kendo-progressbar>
        <div [ngClass]="[availableCategories.length ? 'underline' : '', 'pb-1 font-md']"
             [innerHTML]="nextCategory.missingAmountDescription|trustHtml"></div>
      </div>
      <ng-container *ngIf="!isOrderPlaced">
        <div *ngFor="let promotionCategory of availableCategories; index as i; let last = last;"
             [ngClass]="[last ? '' : 'underline', 'd-flex justify-content-between font-md py-1']">
          <div>{{promotionCategory.description}}</div>
          <div>Add {{promotionCategory.missingAmount | number | currency :'USD':'symbol':'1.2-2'}}</div>
        </div>
      </ng-container>
      <button class="btn-link-txt p-0 font-sm" aria-haspopup="dialog" aria-controls="promotionInfo"
              (click)="showAutoShipInfo($event, promotionInfo, marketingMessage)">
        <span class="font-sm">Terms and Conditions</span>
        <i class="lef-information-solid text-info ml-1"></i>
      </button>
    </div>
  </div>
  <div *ngIf="!readOnly" class="d-flex justify-content-between">
    <div>{{discountText}}</div>
    <button class="btn btn-link p-0" aria-haspopup="dialog" (click)="toggleForm()" aria-controls="add_code_modal">
      <i *ngIf="hasCode" class="lef-plus pr-1"></i>{{addCodeText}}
      <i *ngIf="!hasCode" class="lef-chevron-down pl-1"></i>
    </button>
  </div>
</div>
<div *ngIf="showForm || showVerify" class="k-overlay"></div>
<div *ngIf="!readOnly && (showForm || showVerify)" class="focus-element" id="add_code_modal" role="dialog"
     aria-modal="true" aria-label="Discount Code">
  <div *ngIf="showForm" class="add-discount-code" id="addDiscountCode">
    <form class="k-form" [formGroup]="discountForm" (ngSubmit)="onSubmit()">
      <div class="d-flex justify-content-between mb-2">
        <input #discountField kendoTextBox lefSetFocus type="text" class="form-control mr-4"
               placeholder="Type Discount Code" formControlName="discountCode" />
        <button type="submit" class="btn btn-action" [disabled]="!discountForm.valid">Apply</button>
      </div>
    </form>
    <div class="d-flex justify-content-between">
      <div class="text-red font-md w-75" role="alert">{{invalidMessage}}</div>
      <button #discountClose class="btn btn-link" (click)="toggleForm()">
        Close <i class="lef-chevron-up"></i>
      </button>
    </div>
  </div>
  <div *ngIf="showVerify" class="focus-box add-discount-code">
    <div><i class="lef-warning text-warning"></i> You are applying a promotion: {{discountForm.value.discountCode}}
    </div>
    <div class="font-sm">"{{invalidMessage}}"</div>
    <div>Doing so will remove your current exclusive promotion from your Cart.</div>
    <div class="mt-3 d-flex justify-content-between align-content-center flex-nowrap">
      <button type="button" class="btn btn-action" (click)="onVerify()">Ok</button>
      <button class="btn btn-link mr-2" (click)="onCancelVerify()">Do Not Apply</button>
    </div>
  </div>
</div>

<ng-template #promotionInfo>
  <div>
    <p>Offers do not apply to sale items. </p>
    <br>
    <p>
      To receive this discount, your purchase must meet the stated minimum
      requirement in a single order. Premier Rewards program fees, gift cards, and Life
      Extension Magazine® subscriptions do not apply to the order total. Discounts
      available for nonstandard and international shipping. Each offer valid for one time
      use only.
    </p>
    <br>
    <p>
      FREE standard shipping (regularly $5.50 USD) to any U.S. mailing address,
      excluding U.S. territories and wholesalers. Discounted flat-rate shipping of $12 USD
      for Canadian orders of $50 USD or more ($17.50 USD shipping for orders below
      $50 USD). Discounts available for nonstandard and international shipping. Each
      offer valid for one time use only.
    </p>
  </div>
</ng-template>