<div class="p-3 mb-4" [ngClass]="componentClass">
  <div *ngIf="!isEditionEnabled && form && form.disabled && !model.isBilling" class="alert alert-primary m-0"
       role="alert">
    <p class="d-flex align-items-center m-0"><i class="lef-warning"></i>
      This ship-to address belongs to a customer and cannot be edited.</p>
  </div>
  <div *ngIf="!isEditionEnabled && form && form.disabled && model.isBilling" class="alert alert-primary m-0"
       role="alert">
    <p><i class="lef-info-hex"></i>
      For any changes to your account contact information – company name – address, email address, or phone number,
      please contact Life Extension wholesale department at
      <a href="mailto:{{config.environment.wholesaleServiceEmail}}" class="text-info">
        {{config.environment.wholesaleServiceEmail}}.
      </a> Please note company name changes will require submitting an updated application.
      Thank you.
    </p>
  </div>
  <form class="k-form" [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="d-flex justify-content-around my-4" *ngIf="formLoading">
      <span class="k-i-loading k-icon k-font-icon font-weight-medium text-info font-2x"></span>
    </div>
    <formly-form [model]="model" [fields]="formFields" [form]="form">
    </formly-form>
    <div class="messages">
      <div *ngFor="let message of model.messages">
        <p class="text-red">{{message.message}}</p>
      </div>
    </div>
    <div class="text-right" *ngIf="isEditionEnabled">
      <button type="submit" class="btn btn-warning btn-action" *ngIf="!model.hasErrorInAddress"
              [disabled]="actionExecuting">
        Save
      </button>
      <button type="button" class="btn btn btn-warning btn-action mr-3" (click)="submitForm()"
              *ngIf="model.hasErrorInAddress" [disabled]="actionExecuting">
        Verify Again
      </button>
      <button type="button" class="btn btn btn-warning btn-action" (click)="saveAsEnter()"
              *ngIf="model.hasErrorInAddress" [disabled]="actionExecuting">
        Save as Entered
      </button>
    </div>
  </form>
</div>