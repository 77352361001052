export interface IPaymentGateway {
  name: string;
  signature: string;
  auth: string;
  pciUrl: string;
  usingSecondaryGateway: boolean;
  isPayPalEnabled: boolean;
  isApplePayEnabled: boolean;
  isGooglePayEnabled: boolean;
  usePostalCodeInCreditCardValidation: boolean;
}

export class PaymentGateway implements IPaymentGateway {
  name: string;
  signature: string;
  auth: string;
  pciUrl: string;
  usingSecondaryGateway: boolean;
  isPayPalEnabled: boolean;
  isApplePayEnabled: boolean;
  isGooglePayEnabled: boolean;
  usePostalCodeInCreditCardValidation: boolean;
}
