import { BusinessTaxFileInformation } from './business-tax-file-information.model';

export class BusinessTaxInformation {
  federalTaxId: string;
  stateResaleTaxId: string;
  licenseNumber?: string;
  businessTaxFiles?: BusinessTaxFileInformation[];
  month: string;
  day: string;
  year: string;
}
