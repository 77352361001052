import { AfterViewInit, Component, ElementRef, NgZone, Renderer2, ViewChild } from '@angular/core';
import { SelectControlValueAccessor } from '@angular/forms';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { take } from 'rxjs/operators';
import { ValueTypeHelper } from 'src/app/shared/helpers/value-type.helper';

@Component({
  selector: 'lef-form-dropdown',
  template: `
    <!-- <kendo-dropdownlist
      [class.k-state-invalid]="showError"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [data]="to.options | observed | async"
      [textField]="to.labelProp"
      [valueField]="to.valueProp"
      [defaultItem]="defaultItem"
      [valuePrimitive]="to.valuePrimitive"
      [popupSettings]="{ width: 'auto' }"
      (valueChange)="to.change && to.change(field, $event)"
    >
    </kendo-dropdownlist> -->

    <select
      #dropDown
      class="form-control"
      [formControl]="formControl"
      [compareWith]="to.compareWith"
      [class.custom-select]="to.customSelect"
      [class.is-invalid]="showError"
      [formlyAttributes]="field"
    >
      <option *ngIf="to.placeholder" class="text-medium-grey" [ngValue]="placeHolderValue">{{ placeHolder }}</option>
      <ng-container *ngIf="to.options | observed | async as opts">
        <ng-container *ngIf="to._flatOptions; else grouplist">
          <ng-container *ngFor="let opt of opts">
            <option [ngValue]="opt[to.valueProp]" [disabled]="opt.disabled">{{ opt[to.labelProp] }}</option>
          </ng-container>
        </ng-container>
        <ng-template #grouplist>
          <ng-container *ngFor="let opt of opts">
            <option *ngIf="!opt.group; else optgroup" [ngValue]="opt[to.valueProp]" [disabled]="opt.disabled">
              {{ opt[to.labelProp] }}
            </option>
            <ng-template #optgroup>
              <optgroup [label]="opt[to.labelProp]">
                <option *ngFor="let child of opt.group" [ngValue]="child[to.valueProp]" [disabled]="child.disabled">
                  {{ child[to.labelProp] }}
                </option>
              </optgroup>
            </ng-template>
          </ng-container>
        </ng-template>
      </ng-container>
    </select>
  `,
})
export class FormDropdownComponent extends FieldType<FieldTypeConfig> implements AfterViewInit {
  // defaultItem = {};
  // defaultOptions = {
  //   templateOptions: {
  //     labelProp: 'label',
  //     valueProp: 'value',
  //     valuePrimitive: true,
  //   },
  // };

  // ngOnInit(): void {
  //   this.defaultItem[this.to.labelProp] = this.to.placeholder ? this.to.placeholder : 'Select';
  //   this.defaultItem[this.to.valueProp] = this.to.placeholderValue ? this.to.placeholderValue : null;
  //   if (this.to.required && !(this.defaultItem[this.to.labelProp] as string).endsWith('*')) {
  //     this.defaultItem[this.to.labelProp] = this.defaultItem[this.to.labelProp] + '*';
  //   }
  // }
  @ViewChild('dropDown', { read: ElementRef }) dropDown: ElementRef;

  override defaultOptions = {
    templateOptions: {
      options: [],
      compareWith(o1: any, o2: any) {
        return o1 === o2;
      },
      labelProp: 'label',
      valueProp: 'value',
    },
  };

  get placeHolder(): string {
    const placeholder = this.props.placeholder ? this.props.placeholder : 'Select';
    return this.props.required && !placeholder.endsWith('*') ? `${placeholder}*` : placeholder;
  }

  get placeHolderValue(): string {
    return this.props.placeholderValue || ValueTypeHelper.isString(this.props.placeholderValue) ? this.props.placeholderValue : null;
  }

  // workaround for https://github.com/angular/angular/issues/10010
  @ViewChild(SelectControlValueAccessor, { static: false }) set selectAccessor(s: any) {
    if (!s) {
      return;
    }

    const writeValue = s.writeValue.bind(s);
    if (s._getOptionId(s.value) === null) {
      writeValue(s.value);
    }

    s.writeValue = (value: any) => {
      const id = s._idCounter;
      writeValue(value);
      if (value === null) {
        this.ngZone.onStable
          .asObservable()
          .pipe(take(1))
          .subscribe(() => {
            if (id !== s._idCounter && s._getOptionId(value) === null && s._elementRef.nativeElement.selectedIndex !== -1) {
              writeValue(value);
            }
          });
      }
    };
  }

  constructor(private readonly renderer: Renderer2, private ngZone: NgZone) {
    super();
  }

  ngAfterViewInit(): void {
    if (this.dropDown) {
      if (!this.props.label) {
        this.renderer.setAttribute(this.dropDown.nativeElement, 'aria-label', this.props.placeholder);
      }
      if (this.props.autocomplete) {
        this.renderer.setAttribute(this.dropDown.nativeElement, 'autocomplete', this.props.autocomplete);
      }
    }
  }
}
