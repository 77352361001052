import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { CommonService } from 'src/app/shared/service/common.service';
import { ErrorConfigModel, IErrorConfig } from '../model';

@Injectable()
export class ErrorsHandler extends ErrorHandler {
  private headers: HttpHeaders;
  private errorConfig: IErrorConfig;
  private errorExclution: string[] = [];

  constructor(
    configuration: ErrorConfigModel,
    private readonly http: HttpClient,
    private readonly config: CoreConfiguration,
    private readonly commonService: CommonService) {
    super();
    
    this.errorConfig = configuration.getConfig();
    this.headers = new HttpHeaders();
    this.headers.append('Content-Type', 'application/json');
    this.loadErrorExclution();
  }

  public override handleError(error: Error | HttpErrorResponse) {
    if (this.allowToSendError(error)) {
      const errorMsg = {
        clientError:
          `Client AppVersion: ${this.config.runtimeAppVersion}\n` +
          `Error : ${error.name}\n` +
          `Error Code: ${(error as HttpErrorResponse).status}\n` +
          `Url: ${window.location?.href}\n` +
          `Message: ${error.message}\n` +
          `Stack: ${(error as Error).stack}`,
      };

      this.http.post(`${this.errorConfig.baseUrl}${this.errorConfig.errorPath}`, errorMsg, { headers: this.headers }).subscribe({
        next: () => {},
        error: (loggingError: any) => super.handleError(loggingError),
      });
    }

    super.handleError(error);
  }

  private allowToSendError(error: any) {
    return this.errorConfig.enableApiLogging && error && !this.ignoreErrorMessage(error) && !this.ignoreErrorCode(error);
  }

  private ignoreErrorMessage(error: any): boolean {
    if (!error?.message) {
      return true;
    }
    return this.errorExclution.some((ex) => error.message.indexOf(ex) > -1);
  }

  private ignoreErrorCode(error: any) {
    const status = error?.status;
    return status === 0 || status === 401 || status === 421 || status === 307 || status > 500;
  }  

  loadErrorExclution() {
    setTimeout(() => {
      this.commonService.getErrorExclution().subscribe({
        next: (result: string[]) => {
          this.errorExclution = result;
        },
        error: (error) => {},
        complete: () => {},
      });
    }, 1500);
  }
}
