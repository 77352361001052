import { Injectable } from '@angular/core';
import { IListItem } from 'src/app/shared/model/list-item';

@Injectable({
  providedIn: 'root',
})
export class CustomCalendarService {
  constructor() {}

  getYears(): IListItem[] {
    const years = [];
    for (let index = 1900; index <= new Date().getFullYear(); index++) {
      years.push({ value: `${index}`, description: `${index}` });
    }
    return years;
  }

  getMonths(): IListItem[] {
    return [
      { value: '01', description: 'January' },
      { value: '02', description: 'February' },
      { value: '03', description: 'March' },
      { value: '04', description: 'April' },
      { value: '05', description: 'May' },
      { value: '06', description: 'June' },
      { value: '07', description: 'July' },
      { value: '08', description: 'August' },
      { value: '09', description: 'September' },
      { value: '10', description: 'October' },
      { value: '11', description: 'November' },
      { value: '12', description: 'December' },
    ];
  }

  getDays(month: string, year: string): IListItem[] {
    if (!year) {
      year = `${new Date().getFullYear()}`;
    }
    const maxDay = new Date(parseInt(year, 0), parseInt(month, 0), 0).getDate();
    const days = [];
    for (let index = 1; index <= maxDay; index++) {
      days.push({ value: `${index}`, description: `${index}` });
    }
    return days;
  }
}
