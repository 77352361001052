export class BusinessTaxFileInformation {
    fileId?: string;
    file?: string;
    fileSource?: any;
    fileType?: string;
    isDeleted?: boolean;

    public constructor(fileId: string, file: string, fileSource: string, fileType: string) {
        this.fileId = fileId;
        this.file = file;
        this.fileSource = fileSource;
        this.fileType = fileType;
        this.isDeleted = false;
    }
}