import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAddressValidation } from '../../model/address-validation';

@Component({
  selector: 'lef-address-verification',
  templateUrl: './address-verification.component.html',
  styleUrls: ['./address-verification.component.scss']
})
export class AddressVerificationComponent implements OnInit {

  @Input()
  addressValidation: IAddressValidation = {};

  @Output()
  closeDialog: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  getRecommendedAddress() {
    if (!this.addressValidation) { return ''; }

    return `${this.addressValidation.cnvAddress1} ${this.addressValidation.cnvAddress2} ` +
      `${this.addressValidation.cnvCity}, ${this.addressValidation.cnvState} ` +
      `${this.addressValidation.cnvZip}`;

  }
  getOriginalAddress() {
    if (!this.addressValidation) { return ''; }

    return `${this.addressValidation.orgAddress1} ${this.addressValidation.orgAddress2} ` +
      `${this.addressValidation.orgCity}, ${this.addressValidation.orgState} ` +
      `${this.addressValidation.orgZip}`;
  }

  confirm(confirm: boolean) {
    this.closeDialog.emit(confirm);
  }
}
