import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { MaskedTextBoxComponent } from '@progress/kendo-angular-inputs';

@Component({
  selector: 'lef-form-masked-text-box',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./form-masked-text-box.scss'],
  template: `
    <kendo-floatinglabel [text]="placeholder">
      <kendo-maskedtextbox
        #kMaskedTextBox
        *ngIf="to"
        [formControl]="formControl"
        [mask]="to.mask"
        [maskOnFocus]="true"
        [includeLiterals]="to.includeLiterals"
        [formlyAttributes]="field"
        (valueChange)="to.change && to.change(field, $event)"
        (blur)="onBlur()"
        (focus)="onFocus()"
      ></kendo-maskedtextbox>
    </kendo-floatinglabel>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormMaskedTextBoxComponent extends FieldType<FieldTypeConfig> implements OnInit, AfterViewInit {
  placeholder = '';
  placeholderText = '';
  override defaultOptions = {
    templateOptions: {
      mask: '',
      includeLiterals: false,
      setFocus: false,
      type: 'text',
    },
  };

  @ViewChild('kMaskedTextBox', { read: MaskedTextBoxComponent }) kMaskedTextBox: MaskedTextBoxComponent;
  @HostBinding('class.form-masked-text-box') componentClass = true;
  @HostBinding('class.disabled') componentDisabled: boolean;

  constructor(private readonly renderer: Renderer2) {
    super();
  }

  ngOnInit(): void {
    this.placeholderText = this.props.placeholder;
    if (!this.formControl.value) {
      this.placeholder = this.placeholderText;
    }

    this.componentDisabled = this.formControl.disabled;
    (this.formControl as UntypedFormControl).registerOnDisabledChange((isDisabled: boolean) => (this.componentDisabled = isDisabled));
  }

  ngAfterViewInit(): void {
    if (this.kMaskedTextBox) {
      (this.kMaskedTextBox.input.nativeElement as HTMLInputElement).type = this.props.type || 'text';
      if (!this.props.label) {
        this.renderer.setAttribute(this.kMaskedTextBox.input.nativeElement, 'aria-label', this.props.placeholder);
      }
      if (this.props.setFocus) {
        this.kMaskedTextBox.focus();
      }
    }
  }

  onBlur() {
    if (!this.formControl.value) {
      this.placeholder = this.placeholderText;
    }
  }

  onFocus() {
    this.placeholder = '';
  }
}
