import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { CaptchaVerification } from '../../model';
import { CaptchaValidatorService } from '../../service/captcha-validator.service';

@Component({
  selector: 'lef-re-captcha',
  templateUrl: './re-captcha.component.html',
  styleUrls: ['./re-captcha.component.scss'],
})
export class ReCaptchaComponent implements OnInit {
  @Output() verificationPassed: EventEmitter<boolean> = new EventEmitter();
  isNetworkRequestInProgress = false;
  @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;

  constructor(private readonly captchaValidator: CaptchaValidatorService) { }

  ngOnInit(): void {
    this.addRecaptchaScript();
  }

  addRecaptchaScript() {
    (window as any).greCaptchaCallback = () => {
      this.renderReCaptcha();
    };

    ((d, s, id, obj) => {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        const parent = d.getElementById(id).parentElement;
        parent.removeChild(d.getElementById(id));
        //  return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://www.google.com/recaptcha/api.js?onload=greCaptchaCallback&amp;render=explicit';
      fjs.parentNode?.insertBefore(js, fjs);
    })(document, 'script', 'recaptcha-jssdk', this);
  }

  renderReCaptcha() {
    (window as any).grecaptcha.render(this.recaptchaElement?.nativeElement, {
      sitekey: '6LfzBMkZAAAAAA_oxObeIiS-xmUCcbahH-0Gmu_X',
      callback: (response) => {
        this.verify(response);
      },
    });
  }

  verify(capture: string) {
    this.isNetworkRequestInProgress = true;
    const verification = new CaptchaVerification();
    verification.signature = capture;
    this.captchaValidator.verify(verification).subscribe(
      (result) => {
        this.verificationPassed.emit(true);
        this.isNetworkRequestInProgress = false;
      },
      (error) => {
        this.isNetworkRequestInProgress = false;
        this.verificationPassed.emit(false);
      }
    );
  }
}
