import { Subject } from 'rxjs';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { IPaymentGateway, PaymentType, WalletPayment } from '.';
import { PaymentMethod } from './payment-method';
declare global {
  interface Window {
    GooglePaySession: any;
  }
}

export interface IGooglePayPaymentProcessor {
  gateway: IPaymentGateway;
  onPaymentDataReceived: Subject<WalletPayment>;
  isProcessingPayment: Subject<boolean>;
  onError: Subject<string>;
  onCheckOutPaymentSelected: Subject<WalletPayment>;
  refresh(googlePayInstance: any, googlePayClient: any, usingCheckoutFlow: boolean, amount: number, googlePayButton: HTMLDivElement): void;
}

export class EmptyGooglePayPaymentProcessor implements IGooglePayPaymentProcessor {
  gateway: IPaymentGateway;
  amount: number;
  onPaymentDataReceived: Subject<WalletPayment> = new Subject<WalletPayment>();
  onCheckOutPaymentSelected: Subject<WalletPayment> = new Subject<WalletPayment>();
  isProcessingPayment: Subject<boolean> = new Subject<boolean>();
  onError: Subject<string> = new Subject<string>();
  refresh(googlePayInstance: any, googlePayClient: any, usingCheckoutFlow: boolean, amount: number, googlePayButton: HTMLDivElement): void { }
}

export class BraintreeGooglePayPaymentProcessor implements IGooglePayPaymentProcessor {
  googlePayInstance: any;
  googlePayClient: any;
  onPaymentDataReceived: Subject<WalletPayment> = new Subject<WalletPayment>();
  onCheckOutPaymentSelected: Subject<WalletPayment> = new Subject<WalletPayment>();
  isProcessingPayment: Subject<boolean> = new Subject<boolean>();
  onError: Subject<string> = new Subject<string>();
  usingCheckoutFlow: boolean;
  amount: number;
  gateway: IPaymentGateway;
  googlePayButton: HTMLDivElement;

  constructor(gateway: IPaymentGateway, private config: CoreConfiguration) {
    this.gateway = gateway;
  }

  refresh(googlePayInstance: any, googlePayClient: any, usingCheckoutFlow: boolean, amount: number, googlePayButton: HTMLDivElement): void {
    this.googlePayInstance = googlePayInstance;
    this.googlePayClient = googlePayClient;
    this.usingCheckoutFlow = usingCheckoutFlow;
    this.amount = amount;
    this.googlePayButton = googlePayButton;
    this.initGooglePayButton();
  }

  initGooglePayButton() {
    var allowedPayments = this.googlePayInstance.createPaymentDataRequest().allowedPaymentMethods;
    allowedPayments[0].parameters.allowedCardNetworks = ['AMEX', 'DISCOVER', 'MASTERCARD', 'VISA'];

    const buttonHolder = this.googlePayButton;
    const googlePayInstance = this.googlePayInstance;
    const googlePayClient = this.googlePayClient;
    const amount = +this.amount.toFixed(2);
    const onCheckOutPaymentSelected = this.onCheckOutPaymentSelected;
    const merchantId = this.config?.googlepayConfiguration?.merchantId;

    this.googlePayClient
      .isReadyToPay({
        // see https://developers.google.com/pay/api/web/reference/object#IsReadyToPayRequest
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: allowedPayments,
        existingPaymentMethodRequired: true, // Optional
      })
      .then(function (response) {
        if (response.result) {
          const button = googlePayClient.createButton({
            buttonColor: 'default',
            buttonType: 'pay',
            buttonLocale: 'en',
            onClick: () => {
              var paymentDataRequest = googlePayInstance.createPaymentDataRequest({
                transactionInfo: {
                  currencyCode: 'USD',
                  totalPriceStatus: 'FINAL',
                  totalPrice: `${amount}`, // Your amount
                },
                merchantInfo: {
                  merchantName: 'Life Extension',
                  merchantId: merchantId,
                },
              });

              paymentDataRequest.callbackIntents = ['PAYMENT_AUTHORIZATION'];

              // We recommend collecting billing address information, at minimum
              // billing postal code, and passing that billing postal code with all
              // Google Pay card transactions as a best practice.
              // See all available options at https://developers.google.com/pay/api/web/reference/object

              var cardPaymentMethod = paymentDataRequest.allowedPaymentMethods[0];
              cardPaymentMethod.parameters.billingAddressRequired = true;
              cardPaymentMethod.parameters.billingAddressParameters = {
                format: 'FULL',
                phoneNumberRequired: true,
              };

              googlePayClient
                .loadPaymentData(paymentDataRequest)
                .then(function (paymentData) {
                  googlePayInstance.parseResponse(paymentData, function (err, result) {
                    if (err) {
                      // Handle parsing error
                    }

                    const payment = new WalletPayment();
                    payment.usingCheckoutFlow = true;
                    payment.name = paymentData?.paymentMethodData?.info.billingAddress?.name;
                    payment.postalCode = paymentData?.paymentMethodData?.info.billingAddress?.postalCode;
                    payment.nonce = result.nonce;
                    payment.paymentType = PaymentType.GooglePay;
                    payment.paymentMethod = PaymentMethod.GooglePay;
                    onCheckOutPaymentSelected.next(payment);
                  });
                })
                .catch(function (err) {
                  // Handle errors
                });
            },
            allowedPaymentMethods: allowedPayments, // use the same payment methods as for the loadPaymentData() API call
          });

          if (buttonHolder.firstChild) {
            buttonHolder.removeChild(buttonHolder.lastChild);
          }
          buttonHolder.appendChild(button);
        }
      });
  }
}
