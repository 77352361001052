import { Component, OnInit } from '@angular/core';
import { ICart } from 'src/app/cart-common/model/cart';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { TrackingService } from '../../service/tracking.service';

declare global {
  interface Window {
    dotq: any;
  }
}

@Component({
  selector: 'lef-yahoo-gemini-conversion',
  templateUrl: './yahoo-gemini-conversion.component.html',
  styleUrls: ['./yahoo-gemini-conversion.component.scss'],
})
export class YahooGeminiConversionComponent implements OnInit {
  constructor(private readonly trackingService: TrackingService, private readonly config: CoreConfiguration) {}

  ngOnInit(): void {
    if (this.config.environment.yahooGeminiConversionTracking.enabled && this.trackingService.checkOneTrustActiveGroup(this.config.oneTrustGroups.targeting)) {
      this.trackingService.orderPlaced.subscribe({ next: (cart) => this.onOrderPlaced(cart) });
    }
  }

  onOrderPlaced(cart: ICart) {
    if (!window.dotq) {
      return;
    }
    window.dotq = window.dotq || [];
    window.dotq.push({
      projectId: '10000',
      properties: {
        pixelId: '10032080',
        qstrings: {
          et: 'custom',
          ec: 'conversion',
          ea: 'purchase',
          el: 'transaction',
          gv: cart.totalOrder,
        },
      },
    });
  }
}
