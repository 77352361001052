import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'lef-focus-input',
  template: `
    <input
      #focusInput
      lefSetFocus
      kendoTextBox
      [type]="type"
      [formControl]="formControl"
      class="form-control"
      [formlyAttributes]="field"
      [class.is-invalid]="showError"
    />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FocusInputComponent extends FieldType<FieldTypeConfig> implements AfterViewInit {
  @ViewChild('focusInput', { read: ElementRef }) focusInput: ElementRef;

  get type() {
    return this.props.type || 'text';
  }

  constructor(private readonly renderer: Renderer2) {
    super();
  }

  ngAfterViewInit(): void {
    if (this.focusInput) {
      if (!this.props.label) {
        this.renderer.setAttribute(this.focusInput.nativeElement, 'aria-label', this.props.placeholder);
      }
    }
  }
}
