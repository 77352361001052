import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CaptchaTriggerService {
  private triggerCaptchaVerification = new Subject<boolean>();

  verificationTrigger$ = this.triggerCaptchaVerification.asObservable();

  triggerVerification() {
    this.triggerCaptchaVerification.next(true);
  }
}
