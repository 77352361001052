import { Component, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { RefreshService } from '../service/refresh.service';

@Injectable({
  providedIn: 'root',
})
export class RefreshGuard  {

  constructor(
    private readonly refreshService: RefreshService,
    private readonly config: CoreConfiguration
  ) { }

  canDeactivate(
    component: Component,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    const nextRoute = this.getChild(nextState.root);
    if (this.config.refreshConfiguration.refreshOnNavigation && !nextRoute?.data?.skipRefreshOnNavigation) {
      return !this.refreshService.refreshIfNeeded(nextState.url);
    }

    return true;
  }

  private getChild(activatedRoute: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
}
