import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICartItem } from 'src/app/cart-common/model';

@Component({
  selector: 'lef-cart-items',
  templateUrl: './cart-items.component.html',
  styleUrls: ['./cart-items.component.scss'],
})
export class CartItemsComponent {
  @Input() items: ICartItem[];
  @Output() updateCart: EventEmitter<any> = new EventEmitter();
  @Output() removeItem: EventEmitter<ICartItem> = new EventEmitter();
  @Output() autoShipUpdated: EventEmitter<ICartItem> = new EventEmitter();
  @Output() addToWishListItem: EventEmitter<ICartItem> = new EventEmitter();
}
