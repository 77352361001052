import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { share } from 'rxjs/operators';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { CreditCard } from 'src/app/payment/model/payment-credit-card';
import { BaseService } from 'src/app/shared/service/base-service';
import { IPaymentOption, PaymentType, WalletPayment } from '../model';
import { PaymentMethod } from '../model/payment-method';
import { PaymentOptionCollection } from '../model/payment-option-collection';

@Injectable({
  providedIn: 'root',
})
export class PaymentOptionService extends BaseService<PaymentOptionCollection> {
  protected apiUrl: string;

  constructor(protected override readonly httpClient: HttpClient, private readonly config: CoreConfiguration) {
    super(httpClient);
    this.apiUrl = config.environment.api + config.apiRoutes.paymentOptions;
    this.paymentOptionsData$ = this.httpGet<PaymentOptionCollection>(`${this.apiUrl}/PaymentOptions`).pipe(share());
  }

  private paymentOptionsData$: Observable<PaymentOptionCollection>;

  public requestCreditCardVerification: Subject<any> = new Subject();
  public onCreditCardVerification: Subject<CreditCard> = new Subject();

  paymentOptions(isReturn = false, duringCheckOut = false): Observable<PaymentOptionCollection> {
    if (!isReturn && !duringCheckOut) {
      return this.paymentOptionsData$;
    }
    return this.httpGet(`${this.apiUrl}/PaymentOptions?isReturn=${isReturn}&duringCheckOut=${duringCheckOut}`);
  }

  addCreditCardPaymentOption(creditCard: CreditCard, isReturn = false): Observable<PaymentOptionCollection> {
    return this.post(`${this.apiUrl}/AddCreditCardPaymentOption?isReturn=${isReturn}`, creditCard);
  }

  updateWalletPaymentOption(payment: WalletPayment, isReturn = false, isCheckOut = false): Observable<PaymentOptionCollection> {
    return this.post(`${this.apiUrl}/UpdateWalletPaymentOption?isReturn=${isReturn}&isCheckOut=${isCheckOut}`, payment);
  }

  updatePaymentOption(payment: IPaymentOption, isCheckOut = false): Observable<PaymentOptionCollection> {
    return this.post(`${this.apiUrl}/UpdatePaymentOption?isCheckOut=${isCheckOut}`, payment);
  }

  addWalletPaymentOption(payment: WalletPayment, isReturn = false): Observable<PaymentOptionCollection> {
    return this.post(`${this.apiUrl}/AddWalletPaymentOption?isReturn=${isReturn}`, payment);
  }

  selectPaymentOptionId(paymentId: string, isReturn = false): Observable<PaymentOptionCollection> {
    return this.httpGet(`${this.apiUrl}/SelectPaymentOptionId?paymentId=${paymentId}&isReturn=${isReturn}`);
  }

  selectWalletCheckoutFlowOption(paymentType: PaymentType, paymentMethod: PaymentMethod): Observable<PaymentOptionCollection> {
    return this.httpGet(`${this.apiUrl}/SelectWalletCheckoutFlowOption?paymentType=${PaymentType[paymentType]}&paymentMethod=${PaymentMethod[paymentMethod]}`);
  }

  selectNewCreditCardCheckoutFlowOption(): Observable<PaymentOptionCollection> {
    return this.httpGet(`${this.apiUrl}/selectNewCreditCardCheckoutFlowOption`);
  }

  processCreditCardUsingSecondaryGateway(id: string, params: string, duringCheckOut: boolean) {
    return this.httpGet(
      `${this.apiUrl}/ProcessCreditCardUsingSecondaryGateway?${params}&id=${id}&duringCheckOut=${duringCheckOut ? '1' : '0'}`
    );
  }
}
