<div class="w-100 d-md-flex justify-content-md-between align-items-md-basline">
    <div class="wra-company-registration w-100">
        <div class="d-flex justify-content-between align-items-center d-md-block">
            <div class="d-flex justify-content-between">
                <p class="wra-step-wrap m-0">
                    <span class="wra-step-number">{{stepNumber}}</span><span class="wra-step-title">{{stepTitle}}</span>
                </p>
                <div style="text-align: end;">
                    <!-- <div *ngIf="hasContent && !isContentValid">
                        <span class="text-red"> Section include invalid items!</span>
                    </div> -->
                    <ng-container *ngIf="!isActive">
                        <div *ngIf="hasContent && !isEditable">
                            <button class="btn btn-link" type="button"
                                    (click)="activateCompanySection()">Review</button>
                        </div>
                        <div *ngIf="hasContent && isEditable">
                            <button class="btn btn-link" type="button"
                                    (click)="activateCompanySection()">Review/Edit</button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div *ngIf="isActive" class="w-100">
            <form *ngIf="isDataAlreadyInitialized" [formGroup]="applicationForm" (ngSubmit)="onSubmit()"
                  autocomplete="off">
                <input id="id" type="hidden" name="id" formControlName="id">
                <input id="applicationId" type="hidden" name="applicationId" formControlName="applicationId">
                <div class="mt-3 w-100 bg-light p-3 status-{{applicationForm.controls['companyInformation'].status}}">
                    <div class="w-100 d-flex justify-content-between">
                        <p class="font-lg font-weight-bold text-primary">Company Information</p>
                        <p class="font-sm required-message">* Indicated required fields</p>
                    </div>
                    <div class="d-flex align-items-center border-bottom mb-3 pb-3">
                        <p class="m-0 mr-4">Does your business have offices or warehouses outside of the US?</p>
                        <div class="d-flex align-items-center">
                            <div class="d-flex align-items-center mr-3">
                                <input id="noHasOfficesOutsideUS" type="radio" class="k-radio" value="N"
                                       name="hasOfficesOutsideUS" formControlName="hasOfficesOutsideUS">
                                <label for="noHasOfficesOutsideUS">No</label>
                            </div>
                            <div class="d-flex align-items-center">
                                <input id="yesHasOfficesOutsideUS" type="radio" class="k-radio" value="Y"
                                       name="hasOfficesOutsideUS" formControlName="hasOfficesOutsideUS">
                                <label for="yesHasOfficesOutsideUS">Yes</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center border-bottom mb-3 pb-3"
                         *ngIf="applicationForm.controls['hasOfficesOutsideUS'].value === 'Y'">
                        <p class="m-0 mr-4">Does your business export outside the United States?</p>
                        <div class="d-flex align-items-center">
                            <div class="d-flex align-items-center mr-3">
                                <input id="noExportOutsideUS" type="radio" class="k-radio" value="N"
                                       name="exportOutsideUS" formControlName="exportOutsideUS">
                                <label for="noExportOutsideUS">No</label>
                            </div>
                            <div class="d-flex align-items-center">
                                <input id="yesExportOutsideUS" type="radio" class="k-radio" value="Y"
                                       name="exportOutsideUS" formControlName="exportOutsideUS">
                                <label for="yesExportOutsideUS">Yes</label>
                            </div>
                        </div>
                    </div>
                    <div class="alert alert-danger" role="alert"
                         *ngIf="applicationForm.controls['exportOutsideUS'].value === 'Y'">
                        <span>If you will export outside the U.S.A. Please contact us at 1-888-884-3657 or email <a
                               href="mailto:wholesaleInternational@lifeextension.com">wholesaleInternational&#64;lifeextension.com</a>
                            to obtain international application.</span>
                    </div>
                    <!-- <div *ngIf="applicationForm.controls['exportOutsideUS'].value !== 'Y'"
                         class="d-flex align-items-center mb-3"
                         [ngClass]="{'border-bottom': applicationForm.controls['companyInformationSameAsPersonal'].value}">
                        <input type="checkbox" class="k-checkbox" id="companyInformationSameAsPersonal"
                               formControlName="companyInformationSameAsPersonal"
                               (click)="companyInformationSameAsPersonalChange()">
                        <label class="form-check-label" for="companyInformationSameAsPersonal">Click if same as Personal
                            Information</label>
                    </div> -->
                    <div formGroupName="companyInformation"
                         *ngIf="applicationForm.controls['exportOutsideUS'].value !== 'Y' && !applicationForm.controls['companyInformationSameAsPersonal'].value"
                         class="border-bottom form-group">
                        <div class="mb-3">
                            <input type="text" class="form-control" placeholder="Company Name*"
                                   formControlName="name" />
                        </div>
                        <div class="mb-3">
                            <input type="text" class="form-control" placeholder="Billing Address*"
                                   formControlName="address" />
                        </div>
                        <div class="row mb-3">
                            <div class="col-6">
                                <input type="text" class="form-control" placeholder="City*" formControlName="city" />
                            </div>
                            <div class="col-6">
                                <select formControlName="state" name="state" class="form-control" placeholder="State*">
                                    <option [ngValue]="null">State*</option>
                                    <option *ngFor="let opt of statesCompany" [ngValue]="opt.value">{{opt.description}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-6">
                                <select formControlName="country" name="country" class="form-control"
                                        (change)="countrySelectedChange('companyInformation', 'company')">
                                    <option [ngValue]="null">Country*</option>
                                    <option *ngFor="let opt of countries" [ngValue]="opt.code">{{opt.description}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-6">
                                <input type="text" class="form-control" placeholder="Postal Code*"
                                       formControlName="postalCode" />
                            </div>
                        </div>
                    </div>
                    <div *ngIf="applicationForm.controls['exportOutsideUS'].value !== 'Y'" class="row mb-3">
                        <div class="col-6">
                            <input type="text" class="form-control" placeholder="Contact Person*"
                                   formControlName="contactPerson" />
                        </div>
                        <div class="col-6">
                            <input type="text" class="form-control" placeholder="Title" formControlName="title" />
                        </div>
                    </div>
                    <div *ngIf="applicationForm.controls['exportOutsideUS'].value !== 'Y'" class="row mb-3">
                        <div class="col-6">
                            <input type="text" class="form-control" placeholder="Email Address*"
                                   formControlName="emailAddress" />
                        </div>
                        <div class="col-6">
                            <input type="text" class="form-control" placeholder="Company Website"
                                   formControlName="website" />
                        </div>
                    </div>
                    <div *ngIf="applicationForm.controls['exportOutsideUS'].value !== 'Y'" class="row mb-3">
                        <div class="col-6">
                            <input type="text" class="form-control" placeholder="Phone*" formControlName="phone" />
                        </div>
                        <div class="col-6">
                            <select formControlName="stateOnIncorporation" name="stateOnIncorporation"
                                    class="form-control">
                                <option [ngValue]="null">State On Incorporation*</option>
                                <option *ngFor="let opt of usCountryState" [ngValue]="opt.value">{{opt.description}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div *ngIf="applicationForm.controls['exportOutsideUS'].value !== 'Y'"
                     class="w-100 bg-light p-3 mt-3 status-{{applicationForm.controls['corporateOfficeInformation'].status}}">
                    <div class="d-flex justify-content-between">
                        <p class="font-lg font-weight-bold text-primary">Corporate Office Information</p>
                        <p class="font-sm required-message">* Indicated required fields</p>
                    </div>
                    <div class="d-flex align-items-center mb-3">
                        <input type="checkbox" class="k-checkbox" id="corporateContactSameAsContact"
                               formControlName="corporateContactSameAsContact">
                        <label class="form-check-label" for="corporateContactSameAsContact">Click if same as Company
                            Information</label>
                    </div>
                    <div formGroupName="corporateOfficeInformation"
                         *ngIf="!applicationForm.controls['corporateContactSameAsContact'].value"
                         class="border-bottom form-group">
                        <div class="mb-3">
                            <input type="text" class="form-control" placeholder="Parent Company*"
                                   formControlName="name" />
                        </div>
                        <div class="mb-3">
                            <input type="text" class="form-control" placeholder="Address*" formControlName="address" />
                        </div>
                        <div class="row mb-3">
                            <div class="col-6">
                                <input type="text" class="form-control" placeholder="City*" formControlName="city" />
                            </div>
                            <div class="col-6">
                                <select formControlName="state" name="state" class="form-control" placeholder="State*">
                                    <option [ngValue]="null">State*</option>
                                    <option *ngFor="let opt of statesCorporate" [ngValue]="opt.value">
                                        {{opt.description}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-6">
                                <select formControlName="country" name="country" class="form-control"
                                        (change)="countrySelectedChange('corporateOfficeInformation', 'corporate')">
                                    <option [ngValue]="null">Country*</option>
                                    <option *ngFor="let opt of countries" [ngValue]="opt.code">{{opt.description}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-6">
                                <input type="text" class="form-control" placeholder="Postal Code*"
                                       formControlName="postalCode" />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-6">
                                <input type="text" class="form-control" placeholder="Phone*" formControlName="phone" />
                            </div>
                            <div class="col-6">
                                <input type="text" class="form-control" placeholder="Email Address*"
                                       formControlName="emailAddress" />
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="applicationForm.controls['exportOutsideUS'].value !== 'Y'"
                     class="w-100 bg-light p-3 mt-3 status-{{applicationForm.controls['ownerContactInformation'].status}}">
                    <div class="d-flex justify-content-between">
                        <p class="font-lg font-weight-bold text-primary">Owner Contact Information</p>
                        <p class="font-sm required-message">* Indicated required fields</p>
                    </div>
                    <div class="d-flex align-items-center mb-3">
                        <input type="checkbox" class="k-checkbox" id="ownerInformationSameAsPersonal"
                               formControlName="ownerInformationSameAsPersonal">
                        <label class="form-check-label" for="ownerInformationSameAsPersonal">Click if same as Personal
                            Information</label>
                    </div>
                    <div formGroupName="ownerContactInformation" class="border-bottom form-group"
                         *ngIf="!applicationForm.controls['ownerInformationSameAsPersonal'].value">
                        <div class=" mb-3">
                            <input type="text" class="form-control" placeholder="Owner's Name*"
                                   formControlName="name" />
                        </div>
                        <div class=" mb-3">
                            <input type="text" class="form-control" placeholder="Address*" formControlName="address" />
                        </div>
                        <div class="row mb-3">
                            <div class="col-6">
                                <input type="text" class="form-control" placeholder="City*" formControlName="city" />
                            </div>
                            <div class="col-6">
                                <select formControlName="state" name="state" class="form-control" placeholder="State*">
                                    <option [ngValue]="null">State*</option>
                                    <option *ngFor="let opt of statesBuyerContact" [ngValue]="opt.value">
                                        {{opt.description}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-6">
                                <select formControlName="country" name="country" class="form-control"
                                        (change)="countrySelectedChange('ownerContactInformation', 'owner')">
                                    <option [ngValue]="null">Country*</option>
                                    <option *ngFor="let opt of countries" [ngValue]="opt.code">{{opt.description}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-6">
                                <input type="text" class="form-control" placeholder="Postal Code"
                                       formControlName="postalCode" />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-6">
                                <input type="text" class="form-control" placeholder="Phone*" formControlName="phone" />
                            </div>
                            <div class="col-6">
                                <input type="text" class="form-control" placeholder="Email Address*"
                                       formControlName="emailAddress" />
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="applicationForm.controls['exportOutsideUS'].value !== 'Y'"
                     class="w-100 bg-light p-3 mt-3 status-{{applicationForm.controls['buyerContactInformation'].status}}">
                    <div class="d-flex justify-content-between">
                        <p class="font-lg font-weight-bold text-primary">Buyer Contact Information</p>
                        <p class="font-sm required-message">* Indicated required fields</p>
                    </div>
                    <div formGroupName="buyerContactInformation" class="border-bottom form-group">
                        <div class=" mb-3">
                            <input type="text" class="form-control" placeholder="Buyer's Name*"
                                   formControlName="name" />
                        </div>
                        <div class="row mb-3">
                            <div class="col-6">
                                <input type="text" class="form-control" placeholder="Phone*" formControlName="phone" />
                            </div>
                            <div class="col-6">
                                <input type="text" class="form-control" placeholder="Email Address*"
                                       formControlName="emailAddress" />
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="applicationForm.controls['exportOutsideUS'].value !== 'Y' && isEditable" class="w-100 my-3">
                    <div class="d-flex justify-content-end">
                        <button type="submit" class="btn btn btn-warning mb-2 co-ar-btn">Next</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>