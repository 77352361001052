import { Component, Input, OnInit } from '@angular/core';
import { CoreConfiguration } from 'src/app/core/core-configuration';

@Component({
  selector: 'lef-premier-renew-banner',
  templateUrl: './premier-renew-banner.component.html',
  styleUrls: ['./premier-renew-banner.component.scss']
})
export class PremierRenewBannerComponent implements OnInit {
  
  @Input() premierItemNumber: string = "PREMIERST"

  addAsAutoShip: boolean = false;

  constructor(config: CoreConfiguration) { 
    this.addAsAutoShip = config.environment.enablePremierSubscription;
  }

  ngOnInit() {
  }

}
