import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BloodLabSubjectComponent } from 'src/app/blood-lab/component/blood-lab-subject/blood-lab-subject.component';
import { LabSubjectConsentComponent } from 'src/app/blood-lab/component/lab-subject-consent/lab-subject-consent.component';
import { ILabSubject } from 'src/app/blood-lab/model';
import { ILabSubjectInfo } from 'src/app/blood-lab/model/lab-subject-info';
import { LabTestService } from 'src/app/blood-lab/service/lab-test.service';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { ICart, ILabTestItem } from '../../model';
import { CartLabEmailInfo } from '../../model/cart-lab-email-info.model';

@Component({
  selector: 'lef-cart-item-blood-lab',
  templateUrl: './cart-item-blood-lab.component.html',
  styleUrls: ['./cart-item-blood-lab.component.scss'],
})
export class CartItemBloodLabComponent implements OnInit {
  @Input() labSubjects: ILabSubject[];
  @Input() labTest: ILabTestItem;
  @Input() itemNumber: string;
  @Input() isEditable: boolean;
  @Input() isSystemControlled: boolean;

  @Output() labSubjectUpdated: EventEmitter<ICart> = new EventEmitter<ICart>();

  labSubject: ILabSubject = { name: 'Select a subject...', subjectNumber: null };
  emailRequiredForm: UntypedFormGroup = new UntypedFormGroup({});
  requireEmailAddress = false;
  showNonLongerMailAvailableMsg = false;
  enableEmailSubmitButton = false;

  get consentDisabled(): boolean {
    return !this.isEditable || !!this.labTest.consentBy;
  }

  private previousSubjectNumber: number = null;

  constructor(
    private readonly dialogService: DialogService,
    private readonly labTestService: LabTestService,
    private readonly loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    if (!this.labSubjects.some((labSubject: ILabSubject) => labSubject.subjectNumber === 0)) {
      this.labSubjects = [this.labSubject, ...this.labSubjects];
      this.labSubjects.push({ name: 'Add New Lab Test Subject', subjectNumber: 0 });
    }
    this.setLabSubject(this.labTest.subjectNumber);
  }

  checkSubject($event: ILabSubjectInfo) {
    if ($event.subjectNumber === 0) {
      this.editSubject($event.subjectNumber);
    } else if (!$event.subjectNumber) {
      setTimeout(() => {
        this.setLabSubject(this.previousSubjectNumber);
      });
    } else {
      this.setSubject($event);
    }
  }

  editSubject(subjectNumber: number) {
    const subjectDialogRef = this.dialogService.open({
      title: 'Lab Test Subject',
      width: 800,
      maxHeight: '90%',
      maxWidth: '90%',
      content: BloodLabSubjectComponent,
    });

    const labSubjectComponent: BloodLabSubjectComponent = subjectDialogRef.content.instance;
    labSubjectComponent.isForCart = true;
    if (subjectNumber !== 0) {
      labSubjectComponent.customerNumber = this.labSubject.customerNumber;
      labSubjectComponent.subjectNumber = this.labSubject.subjectNumber;
    } else {
      labSubjectComponent.itemId = this.labTest.itemId;
    }

    labSubjectComponent.cartSubjectSaved.subscribe((cart: ICart) => {
      this.labSubjectUpdated.emit(cart);
      subjectDialogRef.close();
    });

    labSubjectComponent.infoLabelClicked.subscribe((requisitionDetails: TemplateRef<any>) => {
      const infoDialogRef = this.dialogService.open({
        title: 'Lab Test Requisition Options',
        width: 700,
        maxHeight: '90%',
        maxWidth: '90%',
        content: requisitionDetails,
      });
    });

    subjectDialogRef.result.subscribe((result) => {
      if (this.previousSubjectNumber === null || (!(this.labSubject.subjectNumber > 0) && this.previousSubjectNumber > 0)) {
        this.setLabSubject(this.previousSubjectNumber);
      }
    });
  }

  setSubject($event: ILabSubjectInfo) {
    this.labTest.subjectNumber = $event.subjectNumber;
    this.labTestService.setSubject(this.labTest).subscribe((cart: ICart) => {
      this.labSubjectUpdated.next(cart);
    });
  }

  giveConsent($event: MouseEvent) {
    if (($event.target as HTMLInputElement).checked) {
      this.showConsent();
    }
  }

  showConsent() {
    const consentDialogRef = this.dialogService.open({
      title: 'Minor Consent',
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
      content: LabSubjectConsentComponent,
    });

    const consentComponent: LabSubjectConsentComponent = consentDialogRef.content.instance;
    consentComponent.labTest = this.labTest;
    consentComponent.consentFormSubmitted.subscribe((labTest: ILabTestItem) => {
      this.labTestService.setConsent(labTest).subscribe((cart: ICart) => {
        this.labSubjectUpdated.next(cart);
      });
      consentDialogRef.close();
    });

    consentDialogRef.result.subscribe((result) => {
      if (!this.labTest.consentBy) {
        this.labTest.hasConsent = false;
      }
    });
  }

  private setLabSubject(subjectNumber: number) {
    const labSubject = this.labSubjects.find((subject: ILabSubject) => subject.subjectNumber === subjectNumber);
    this.requireEmailAddress = false;
    if (labSubject && (labSubject.subjectNumber > 0 || labSubject.subjectNumber === null)) {
      this.labSubject = labSubject;
      this.previousSubjectNumber = labSubject.subjectNumber;
      this.requireEmailAddress =
        labSubject.mailResult && (!labSubject.emailResult || !labSubject.emailAddress || labSubject.emailAddress === '');
    }
    this.showNonLongerMailAvailableMsg = this.labSubject && this.labSubject.mailResult && !this.requireEmailAddress;
    this.initEmailRequiredForm(this.labSubject);
  }

  initEmailRequiredForm(subject: ILabSubject): void {
    this.emailRequiredForm = new UntypedFormGroup({
      email: new UntypedFormControl(subject.emailAddress, [Validators.email, Validators.required]),
      sameAsOnAccount: new UntypedFormControl(false, []),
      subjectNumber: new UntypedFormControl(subject.subjectNumber, []),
      customerNumber: new UntypedFormControl(subject.customerNumber, []),
    });
  }

  updateSubjectEmail(onSubmit: boolean): void {
    if (!onSubmit || this.emailRequiredForm.valid) {
      const info = new CartLabEmailInfo(this.emailRequiredForm.value);
      info.sameAsOnAccount = !onSubmit;
      this.loadingService.show('Updating email information');
      this.labTestService.updateSubjectEmail(info).subscribe(
        (cart: ICart) => {
          this.labSubjectUpdated.next(cart);
          this.loadingService.hide();
        },
        () => {
          this.loadingService.hide();
        }
      );
    }
  }

  emailResultsFocusOut() {
    if (this.emailRequiredForm.valid) {
      this.updateSubjectEmail(true);
    }
  }
}
