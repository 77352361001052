<a href="?item={{premierItemNumber}}&qty=1&autoship={{addAsAutoShip}}">
  <div class="d-flex align-items-center bg-primary p-2 prem-rewards-banner d-none">
    <div class="pr-2">
      <img src="../../../../assets/images/premier_rewards_inverse.svg" width="150" height="150" />
    </div>
    <div class="flex-auto">
      <h3>
        <span class="roboto text-uppercase font-xl text-info font-weight-bold">Premier</span><br />
        <span class="zilla text-uppercase text-white">Rewards</span>
      </h3>
      <p class="text-white">
        Unlimited free shipping, 4% back &amp; exclusive perks. Expiring soon!
      </p>
      <div>
        Renew Today <i class="lef-chevron-circle-right text-orange "></i>
      </div>
    </div>
  </div>
</a>