import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthorizationService } from 'src/app/authorization';
import { Cart, ICart, ICartItem } from 'src/app/cart-common/model';
import { FloatingCartService } from 'src/app/common/service';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { BaseService } from 'src/app/shared/service/base-service';
import { CaptureEmailModel } from '../model/capture-email.model';
import { CheckoutAuthenticateModel } from '../model/checkout-authenticate.model';
import { Constants } from '../model/constants';
import { CustomerData } from '../model/customer-data.model';
import { Activity, Visit } from '../model/visitor.model';
import { CartExplorerService } from './cart-explorer.service';

declare global {
  interface Window {
    OptanonActiveGroups: string;
    Genesys: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class TrackingService extends BaseService<any> {
  // These calls will go against the content site.
  protected apiUrl: string;

  storage: Map<string, string> = new Map();
  currentCartItems: ICartItem[] = [];

  itemAddedToCart: Subject<ICartItem> = new Subject<ICartItem>();
  itemsAddedToCart: Subject<ICartItem[]> = new Subject<ICartItem[]>();
  itemRemovedFromCart: Subject<ICartItem> = new Subject<ICartItem>();
  itemsRemovedFromCart: Subject<ICartItem[]> = new Subject<ICartItem[]>();
  clearCart: Subject<ICart> = new Subject<ICart>();

  checkoutBegin: Subject<ICart> = new Subject<ICart>();
  checkoutAuthenticate: Subject<CheckoutAuthenticateModel> = new Subject<CheckoutAuthenticateModel>();
  checkoutAddShippingInfo: Subject<ICart> = new Subject<ICart>();
  checkoutAddPaymentInfo: Subject<ICart> = new Subject<ICart>();
  orderPlaced: Subject<ICart> = new Subject<ICart>();

  navigationChanged: Subject<NavigationEnd> = new Subject<NavigationEnd>();
  viewCart: Subject<ICart> = new Subject<ICart>();
  customerRegistration: Subject<number> = new Subject<number>();
  customerDataChanged: Subject<CustomerData> = new Subject<CustomerData>();
  customerNumberChanged: Subject<string> = new Subject<string>();

  captureEmail: Subject<CaptureEmailModel> = new Subject<CaptureEmailModel>();
  signUp: Subject<string> = new Subject<string>();
  logIn: Subject<string> = new Subject<string>();
  logOut: Subject<string> = new Subject<string>();
  generateLead: Subject<void> = new Subject<void>();
  addToWishList: Subject<ICartItem> = new Subject<ICartItem>();
  loadTracking: Subject<null> = new Subject<null>();

  private previousUrl: string;
  private currentUrl: string;

  constructor(
    readonly router: Router,
    private readonly cartExplorer: CartExplorerService,
    private readonly floatingCartService: FloatingCartService,
    protected override readonly httpClient: HttpClient,
    private readonly authorizationService: AuthorizationService,
    readonly config: CoreConfiguration
  ) {
    super(httpClient);
    this.apiUrl = config.environment.contentSite + config.contentSiteApiRoutes.tracking;
    this.storage = new Map();
    const navEndEvents = router.events.pipe(filter((event) => event instanceof NavigationEnd));
    navEndEvents.subscribe((event: NavigationEnd | any) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      this.navigationChanged.next(event);
    });
    this.startTracking();
  }

  triggerOrderPlaced(cart: ICart) {
    if (this.previousUrl === '/checkout') {
      this.orderPlaced.next(cart);
    }
  }

  setCart(cart: Cart) {
    this.currentCartItems = JSON.parse(JSON.stringify(cart.items));
    this.floatingCartService.refreshItemsCount();
  }

  detectChanges(cart: Cart) {
    const newCartItems = cart.items;
    const changes = this.cartExplorer.verifyItemsOnCartChanges(this.currentCartItems, newCartItems);
    if (changes.itemsAdded.length > 0) {
      this.itemsAddedToCart.next(changes.itemsAdded);
    }
    if (changes.itemsRemoved.length > 0) {
      this.itemsRemovedFromCart.next(changes.itemsRemoved);
      if (newCartItems.length === 0) {
        this.clearCart.next(cart);
      }
    }
    this.setCart(cart);
    if (changes.itemsAdded.length > 0 || changes.itemsRemoved.length > 0) {
      this.floatingCartService.refreshItemsCount();
    }
  }

  updateUserData(queryParameters: string) {
    const decodeParameters = window.atob(queryParameters);
    const parameters = decodeParameters.split('&').reduce((q, query) => {
      const chunks = query.split('=');
      const key = chunks[0];
      const value = decodeURIComponent(chunks[1]);
      return (q[key] = value), q;
    }, {});
    const dataBefore = this.getCurrentCustomerData();
    this.storage = new Map();
    Object.keys(parameters).forEach((k) => {
      this.storage.set(k, parameters[k]);
    });
    const dataActual = this.getCurrentCustomerData();
    this.checkCurrentCustomerData(dataBefore, dataActual);
  }

  checkCurrentCustomerData(before: CustomerData, actual: CustomerData) {
    if (before.customerNumber !== actual.customerNumber) {
      this.customerNumberChanged.next(actual.customerNumber);
      this.authorizationService.authorizationChange.next(null);
    }
    if (!(JSON.stringify(before) === JSON.stringify(actual))) {
      this.customerDataChanged.next(actual);
    }
  }

  getCurrentCustomerData(): CustomerData {
    return {
      customerNumber: this.storage.get(Constants.CustomerNumber),
      customerRecurrence: this.storage.get(Constants.CustomerRecurrence),
      customerType: this.storage.get(Constants.CustomerType),
      retentionStage: this.storage.get(Constants.RetentionStage),
      email: this.storage.get(Constants.Email),
      firstName: this.storage.get(Constants.FirstName),
      lastName: this.storage.get(Constants.LastName),
      isVipPremierCustomer: this.storage.get(Constants.IsVipPremierCustomer),
      trackingId: this.storage.get(Constants.TrackingId),
      customerRegion: this.storage.get(Constants.CustomerRegion),
      customerIP: this.storage.get(Constants.CustomerIP),
      customerFirstSaleDate: this.storage.get(Constants.CustomerFirstSaleDate),
      experianPersona: this.storage.get(Constants.ExperianPersona),
      isAutoShipCustomer: this.storage.get(Constants.IsAutoShipCustomer),
      isPremierAutoRenew: this.storage.get(Constants.IsPremierAutoRenew),
      isSMS: this.storage.get(Constants.IsSMS),
      lifeCycleGroup: this.storage.get(Constants.LifeCycleGroup),
      wholesaleType: this.storage.get(Constants.WholesaleType),
      wholesaleTypeGroup: this.storage.get(Constants.WholesaleTypeGroup),
      wholesalerPriceGroup: this.storage.get(Constants.WholesalerPriceGroup),
      hashEmail: this.storage.get(Constants.HashEmail),
      hashFirstName: this.storage.get(Constants.HashFirstName),
      hashLastName: this.storage.get(Constants.HashLastName),
      hashPhoneNumber: this.storage.get(Constants.HashPhoneNumber),
      hashZipCode: this.storage.get(Constants.HashZipCode)
    };
  }

  // The content site needs data in form format.
  registerVisit(visit: Visit): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this.httpClient.post(`${this.apiUrl}/RegisterVisit`, $.param(visit), { headers });
  }

  registerActivity(activity: Activity): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const userActivities = { activities: [activity] };
    return this.httpClient.post(`${this.apiUrl}/RegisterActivity`, $.param(userActivities), { headers });
  }

  checkOneTrustActiveGroup(group: string): boolean {
    if (window?.OptanonActiveGroups == undefined || window?.OptanonActiveGroups.includes(group)) {
      return true;
    }
    return false;
  }

  startTracking(count: number = 0) {
    let enable = true;

    if (!window?.OptanonActiveGroups || !window.OptanonActiveGroups.includes('landingPath')) {
      enable = false;
    }

    if (enable || count == 4) {
      this.loadTracking.next(null);
      return;
    } else {
      count++;
      setTimeout(() => {
        count++;
        this.startTracking(count);
      }, 500);
    }
  }
}
