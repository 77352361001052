import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'lef-rendering-content',
  templateUrl: './rendering-content.component.html',
  styleUrls: ['./rendering-content.component.scss'],
})
export class RenderingContentComponent implements OnChanges {
  @Input() htmlContent = '';
  @Output() contentInitialized = new EventEmitter<void>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.htmlContent && changes.htmlContent.currentValue !== '') {
      // wait a cycle to make sure the content is actually in the dom.
      setTimeout(() => {
        this.contentInitialized.emit();
      });
    }
  }
}
