import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as sanitizeHtml from 'sanitize-html';

@Pipe({
  name: 'trustHtml',
})
export class TrustHtmlPipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  public transform(value: any): any {
    const sanitizedContent = sanitizeHtml(value, { allowedTags: false, allowedAttributes: false });
    // return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}
