<div class="productCollection prod-collection text-left">
  <div class="prod-slide-img">
    <a class="productImageLink" [attr.href]="itemUrl" tabindex="-1" aria-hidden="true">
      <!-- Remove OnSale Ribbon -->
      <!-- <div *ngIf="isOnSale" class="prod-slide-ribbon zilla bg-red">On Sale</div> -->
      <img class="productImageSource" alt="Product Image" [defaultImage]="defaultImage" [errorImage]="defaultImage"
           [lazyLoad]="itemImageUrl" />
    </a>
  </div>
  <div class="prod-slide-text">
    <div class="best-badges">
      <div *ngIf="carouselItem.bestInClass || carouselItem.topSeller"
           class="productBestOfBadges prod-bestof-badges text-green text-uppercase">
        <ng-container *ngIf="carouselItem.bestInClass">Best In Class</ng-container>
        <ng-container *ngIf="carouselItem.bestInClass && carouselItem.topSeller"> | </ng-container>
        <ng-container *ngIf="carouselItem.topSeller">Best Seller</ng-container>
      </div>
    </div>

    <h3 class="related-prod-name roboto mb-2"><a class="text-primary productFriendlyNameLink"
         [attr.href]="itemUrl">{{carouselItem.productName}}</a></h3>
    <p class="benefitStatement" [innerHTML]="carouselItem.benefitStatement"></p>
    <div class="variant-option mb-2">
      <span class="variantOption">{{carouselItem.nameSuffix}}</span>
    </div>
    <a class="productReviewLink" [attr.href]="itemUrl + '#reviewsqa'" [attr.href]="itemUrl"
       [attr.aria-label]="generateAriaLabel(carouselItem)">
      <div class="product-rating d-flex align-items-start justify-content-start">
        <div class="star-ratings-css mr-2">
          <div class="ratingPercent star-ratings-css-top related"
               [attr.style]="'width:' + carouselItem.ratingPercent + '%'">
            <i class="lef-star"></i><i class="lef-star"></i><i class="lef-star"></i><i class="lef-star"></i><i
               class="lef-star"></i>
          </div>
          <div class="star-ratings-css-bottom">
            <i class="lef-star"></i><i class="lef-star"></i><i class="lef-star"></i><i class="lef-star"></i><i
               class="lef-star"></i>
          </div>
        </div>
        <div class="star-rating-count">
          <strong>
            <span class="averageOverallRating text-green">{{carouselItem.averageOverallRating}}</span>
            <span class="fullReviews text-green"> ({{carouselItem.fullReviews}})</span>
          </strong>
        </div>
      </div>
    </a>

  </div>
  <div class="prod-slide-add">
    <div class="product-slide-price">
      <div *ngIf="carouselItem.showPriceInCart; else pricing;"
           class="showPriceInCart text-primary font-md font-weight-bold mb-3">Add to cart to see your price</div>
      <ng-template #pricing>
        <div *ngIf="isOnSale && carouselItem.isSuperSale">
          <div class="text-red font-lg font-weight-bold">
            Super Sale
          </div>
        </div>
        <div *ngIf="isOnSale">
          <ul class="salePrice">
            <li class="text-primary">
              <s class="basePrice" *ngIf="!carouselItem.isWholeSale">
                {{carouselItem.pricing.baseRetailPrice | currency}}
              </s>
              <s class="basePrice" *ngIf="carouselItem.isWholeSale">
                {{carouselItem.pricing.baseWholesalePrice | currency}}
              </s>
            </li>
            <li class="text-red font-lg font-weight-bold">
              <span class="currentPrice">{{carouselItem.pricing.currentRetailPrice | currency}}</span>
            </li>
            <li class="text-red">
              Save <span class="savingsAmount">
                {{carouselItem.isWholeSale ? carouselItem.pricing.wholesalePercentSavings :
                carouselItem.pricing.percentSavings}}%
              </span>
            </li>
          </ul>
        </div>
        <p *ngIf="carouselItem.pricing.savingsAmount === 0 && carouselItem.pricing.wholesaleSavingsAmount === 0"
           class="nonSalePrice text-primary font-lg font-weight-bold">
          <span *ngIf="carouselItem.isWholeSale" class="wholesaleLabel">Wholesale </span>
          <span class="currentPrice">{{carouselItem.pricing.currentRetailPrice | currency}}</span>
        </p>
      </ng-template>
      <button class="btn btn-action" (click)="addToCartClicked.emit(carouselItem.itemNumber)"
              [attr.aria-label]="carouselItem.productName">{{buttonText}}</button>
    </div>
  </div>
</div>