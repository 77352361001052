<div *ngIf="shippedTo" class="order-shipto-address"><strong class="text-primary font-md">Shipped To:</strong>
    {{shippedTo}}</div>
<div class="order-ship-wrapper">
    <div class="d-flex justify-content-between order-ship-top">

        <div *ngIf="trackingNumbers.length > 0">
            <div class="d-flex flex-column">
                <p>Tracking Number(s)</p>
                <a *ngFor="let tracking of trackingNumbers" href="{{tracking.link}}" target="_blank">
                    {{tracking.trackingNumber}}
                </a>
            </div>
        </div>
    </div>
    <ng-container *ngFor="let item of items">
        <lef-order-item [item]="item" [embeddedView]="true" (goToOrderDetails)="openOrderDetails($event)">
        </lef-order-item>
    </ng-container>
</div>