import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICartMessage } from 'src/app/cart-common/model';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { IShippingAddress } from '../../model';
import { ShippingDialogService } from '../../service/shipping-dialog.service';
import { ShippingService } from '../../service/shipping.service';

@Component({
  selector: 'lef-shipping-address-list',
  templateUrl: './shipping-address-list.component.html',
  styleUrls: ['./shipping-address-list.component.scss'],
})
export class ShippingAddressListComponent implements OnInit {
  shippingAddresses: IShippingAddress[] = [];

  loading = false;

  searchEnable = false;
  searchText = '';
  searchMessage =
    'Your Account has over 20 shipping destinations. ' +
    'You may search for the shipping destination of your choice ' +
    'by customer number, nickname or last name below.';

  headerText = 'My Shipping Addresses';
  messages: ICartMessage[];

  constructor(
    private readonly shippingService: ShippingService,
    private readonly shippingDialogService: ShippingDialogService,
    private readonly loadingService: LoadingService
  ) { }

  ngOnInit() {
    this.init();
  }

  init(): void {
    this.messages = [];
    this.loading = true;
    this.loadingService.show('');

    forkJoin([this.shippingService.isAddressSearchEnable(), this.shippingService.getShippingAddressList(this.searchText, false)])
      .pipe(
        map(([isSearchEnable, addressList]) => {
          return { isSearchEnable, addressList };
        })
      )
      .subscribe({
        next: (data) => {
          this.shippingAddresses = data.addressList;
          this.searchEnable = data.isSearchEnable;
        },
        error: () => {
          this.loading = false;
          this.loadingService.hide();
        },
        complete: () => {
          this.loading = false;
          this.loadingService.hide();
        },
      });
  }

  editAddress(shippingAddress: IShippingAddress) {
    this.messages = [];
    this.openShippingAddressDialog(shippingAddress.shipToNumber);
  }

  addAddress(): void {
    this.messages = [];
    this.openShippingAddressDialog(0);
  }

  deleteAddress(address: IShippingAddress) {
    this.messages = [];

    if (address.hasAutoShipItems) {
      this.shippingDialogService.openAddressInUseErrorDialog();
      return;
    }

    this.loadingService.show('');

    this.shippingService.deleteShippingAddress(address.shipToNumber).subscribe({
      next: (response) => {
        if (response?.success) {
          const message: ICartMessage = { type: 'Success', message: 'Address successfully deleted!', showIcon: true };
          this.messages = [message];
          this.shippingAddresses = this.shippingAddresses.filter((a) => a.shipToNumber !== address.shipToNumber);
        }

        if (response?.messages?.length) {
          this.messages = response.messages;
        }
      },
      error: () => {
        this.loadingService.hide();
      },
      complete: () => {
        this.loadingService.hide();
      },
    });
  }

  search(): void {
    this.messages = [];
    this.loadingService.show('');
    this.shippingService.getShippingAddressList(this.searchText, false).subscribe({
      next: (addressList) => {
        this.shippingAddresses = addressList;
      },
      error: (error) => {
        this.loadingService.hide();
      },
      complete: () => {
        this.loadingService.hide();
      },
    });
  }

  openShippingAddressDialog = (shipToNumber: number) => {
    this.shippingDialogService.openShippingAddressDialog(shipToNumber, this.onShippingAddressUpdated);
  }

  onShippingAddressUpdated = (address: IShippingAddress) => {
    if (!address) {
      return;
    }

    if (address.isDefault) {
      this.shippingAddresses.forEach((a) => {
        a.isDefault = false;
      });
    }

    const message: ICartMessage = { type: 'Success', message: 'Address successfully updated!', showIcon: true };
    const existingAddress = this.shippingAddresses.find((a) => a.shipToNumber === address.shipToNumber);
    if (existingAddress) {
      // Remove undesired keys before assign values
      delete address.hasAutoShipItems;

      Object.assign(existingAddress, address);
    } else {
      this.shippingAddresses.push(address);
      message.message = 'Address successfully added!';
    }

    this.messages = [message];
  }

  getFullAddress(address: IShippingAddress) {
    return `${address.address}${address.address2 ? ' ' + address.address2 : ''}, ` +
      `${address.state ? address.state : ''} ${address.postalCode}` +
      `${address.countryCode ? ', ' + address.countryCode : ''}`;
  }
}
