import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { BaseService } from 'src/app/shared/service/base-service';
import { RegionVerification } from '../model/region-verification';

@Injectable({
  providedIn: 'root',
})
export class RegionService extends BaseService<object> {
  protected apiUrl: string;
  public itemsCountChange: Subject<any> = new Subject();

  constructor(protected override readonly httpClient: HttpClient, private readonly config: CoreConfiguration) {
    super(httpClient);
    this.apiUrl = config.environment.api + config.apiRoutes.region;
  }

  verifyDistributorRegion(): Observable<RegionVerification> {
    return this.httpGet<RegionVerification>(`${this.apiUrl}/verifyDistributorRegion`).pipe(catchError(this.handleError));
  }

  saveRegionVerificationCustomerAnswer(answer: string): Observable<any> {
    const url = `${this.apiUrl}/saveRegionVerificationCustomerAnswer?answer=${answer}`;
    return this.update(url, {}).pipe(catchError(this.handleError));
  }
}
