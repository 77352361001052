<lef-cart-messages *ngIf="consent" [messages]="consent.messages"></lef-cart-messages>

<ng-container *ngIf="hasRequisitions">
    <h3>Requisitions</h3>
    <div>*Indicates required fields.</div>
    <hr>
    <ng-container *ngFor="let requisition of consent.requisitions">
        <lef-minor-consent-requisition [requisition]="requisition" (consentByChanged)="onConsentByChanged($event)">
        </lef-minor-consent-requisition>
        <hr>
    </ng-container>

    <p>
        By Typing my full name in the space below, I represent that I am the Parent or Legal Guardian of the Laboratory
        Test
        Subject selected.
    </p>
    <p>
        I acknowledge and agree that this Parental Consent to Laboratory Testing is being provided to, and will be
        relied
        upon
        by, Forever Health National Diagnostics, Inc., the service provider receiving the order of the Laboratory Test,
        and
        its representatives, consultants (including Medivo Services, LLC), agents and their authorizing physicians and
        laboratories, to order, authorize and deliver the Laboratory Test for the Test Recipient, and, accordingly I
        represent
        and warrant that:
    </p>
    <ul class="standard-list">
        <li>The Test Subject is a minor and resides in the State named in the order;</li>
        <li>
            I am the parent or legal guardian of the Test Subject and I have the requisite authority to provide the
            consent
            herein;
        </li>
        <li>I agree to have the Laboratory Test performed for and on the Test Subject;</li>
        <li>I am delivering this consent voluntarily.</li>
    </ul>
    <button type="submit" class="btn btn-action" [disabled]="!isValid" (click)="submitConsent()">Confirm</button>
</ng-container>