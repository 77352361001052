import { Component, OnInit } from '@angular/core';
import { ICart } from 'src/app/cart-common/model/cart';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { TrackingService } from '../../service/tracking.service';

declare global {
  interface Window {
    uetq: any;
  }
}

@Component({
  selector: 'lef-bing-conversion',
  templateUrl: './bing-conversion.component.html',
  styleUrls: ['./bing-conversion.component.scss'],
})
export class BingConversionComponent implements OnInit {
  constructor(private readonly trackingService: TrackingService, private readonly config: CoreConfiguration) {}

  ngOnInit(): void {
    if (this.config.environment.bingConversionTracking.enabled && this.trackingService.checkOneTrustActiveGroup(this.config.oneTrustGroups.targeting)) {
      this.trackingService.orderPlaced.subscribe({ next: (cart) => this.onOrderPlaced(cart) });
    }
  }

  onOrderPlaced(cart: ICart) {
    if (!window.uetq) {
      return;
    }
    window.uetq = window.uetq || [];
    window.uetq.push('event', '', {
      event_category: 'transaction',
      revenue_value: cart.totalOrder,
      currency: 'USD',
    });
  }
}
