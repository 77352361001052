<div>
  <div class="d-flex align-items-center">
    <i class="lef-warning text-blue font-2x mr-3"></i>
    <div>
      <div class="text-blue font-sm" id="defaultMessage">{{verification.message}}</div>
    </div>
  </div>
  <div>
    <div class="mb-2"><button class="btn btn-action m-auto" (click)="close()">Chose another country</button></div>
    <button class="btn btn-action m-auto" (click)="goToDistributorSite()">Go to {{ verification.distributor}}</button>
  </div>

</div>