<div class="row auto-ship-item">
  <div class="col-md-8 border-md-right auto-ship-item-lt">
    <div>
      <p class="m-0"><a [attr.href]="itemUrl" class="text-primary font-md"><strong>{{item.friendlyName}}</strong></a>
      </p>
      <p class="cart-item-number mb-3">
        <span>{{item.itemDescription}}</span>
      </p>
    </div>
    <div class="row mb-3">
      <div class="col-md-6">
        <div class="auto-ship-qty mb-3">
          <lef-quantity-input [label]="'Quantity:'" [quantity]="item.quantity"
                              [componentId]="'autoship-qty-input-'+item.itemNumber"
                              [canChangeQuantity]="item.isQuantityChangeable" (quantityUpdate)="updateQuantity($event)">
          </lef-quantity-input>
        </div>
        <div class="auto-ship-deltimes">
          <p><strong class="text-primary">
              AutoShip &amp; Save</strong> delivery every:
            <span *ngIf="!item.isIntervalChangeable" class="font-weight-medium pl-1">{{this.frequencyValue.text}}</span>
          </p>
          <select [id]="intervalId" *ngIf="item.isIntervalChangeable" name="frequency"
                  aria-label="Select frequency in months" [(ngModel)]="item.interval"
                  (ngModelChange)="updateInterval()">
            <ng-container *ngFor="let opt of frequencyValues">
              <option [ngValue]="opt.value">{{ opt.text }}</option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex align-items-center justify-content-end">
          <button class="font-weight-bold btn-link btn-hover-underline" aria-haspopup="dialog"
                  (click)="showPriceInformation()" tabindex="0">
            <span>AutoShip &amp; Save Price</span><i class="lef-information-solid text-info ml-1"></i>
          </button>

        </div>
        <div class="auto-ship-item-price">
          <lef-cart-item-price [extendedPrice]="item.itemPrice" [totalBaseStandardPrice]="item.baseStandardPrice"
                               [percentSavings]="item.percentageSavings">
          </lef-cart-item-price>
        </div>
      </div>
    </div>
    <div>
      <lef-payment-method-selector [paymentOptionSelectedId]="item.paymentOptionId" [isCheckOut]="false"
                                   [instantSave]="true" [paymentMethodLabel]="'Payment Method:'"
                                   [hidePaymentMethodActions]="true" [labelClass]="'pr-3'"
                                   (paymentOptionSelected)="onPaymentSelected($event)">
      </lef-payment-method-selector>
    </div>
    <div class="mt-2">
      <lef-cart-messages [messages]="item.messages"></lef-cart-messages>
    </div>
  </div>
  <div class="col-md-4 text-right auto-ship-item-rt">
    <div *ngIf="item.isActive">
      <p *ngIf="item.lastShippedDate">Last Shipment: {{ item.lastShippedDate | date }}</p>
      <p>Next Shipment: {{ item.nextShipDate | date }}</p>
      <div>
        <button class="btn btn-link" aria-haspopup="dialog" (click)="addToCart()" [disabled]="item.isItemAlreadyInCart">
          Need It Now
        </button>
      </div>
      <div><button class="btn btn-link" aria-haspopup="dialog" (click)="reschedule()">Change Next Shipment Date</button>
      </div>
      <div *ngIf="item.isAddressChangeable">
        <button class="btn btn-link" aria-haspopup="dialog" (click)="changeShippingAddress()">Change Shipping
          Address</button>
      </div>
      <div><button class="btn btn-link" aria-haspopup="dialog" (click)="deactivate()">Deactivate</button></div>
    </div>
    <div *ngIf="!item.isActive" class="auto-ship-item-rt-react">
      <p>Last Shipment: {{ item.lastShippedDate | date }}</p>
      <div><button class="btn btn-link" (click)="delete()">Delete Item</button></div>
      <div><button class="btn btn-action" (click)="reactivate()">Reactivate Item</button></div>
    </div>
  </div>
</div>