<div *ngIf="messages && messages.length > 0" id="cart-messages" class="cart-alerts" role="alert">
    <div *ngFor="let message of messages">
        <div [class]="getStyleClass(message)">
            <div class="d-flex align-items-center flex-wrap ">
                <div>
                    <i [class]="getIconClass(message)"></i>
                    <span [innerHTML]="getMessageText(message)|trustHtml" class="mr-md-2"></span>
                </div>
                <div class="ml-sm-1">
                    <span *ngIf="message.hasExtendedInformation" (click)="showInfo(message)">
                        <button class="btn btn-link mr-1" type="button">{{message.infoLinkTitle}}</button>
                        <!--<i class="lef-information-solid"></i>-->
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>



<ng-template #bottleCapBanner>
    <div class="row">
        <div class="col-12 col-lg-6">
            <p>Does your supplement bottle look different than usual? Unfortunately, the plastics industry is
                experiencing significant supply shortages, and Life Extension has had to use different closures for
                certain products.</p>
        </div>
        <div class="col-12 col-lg-6">
            <img width="300px"
                 src="{{config.environment.contentSite}}/~/media/LifeExtension/Lpages/2021/bottle_closure_variations/blue-black-caps.jpg" />
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            Some bottles may include:
            <ul class="ml-5" style="list-style: unset;">
                <li>A black closure instead of our standard blue closure</li>
                <li>Poor print quality related to the silver ink on bottle closures</li>
                <li>Plain black lids, some with flip tops (no logo) </li>
            </ul>
            <p class="font-weight-bold">You can rest assured that these temporary bottle manufacturing challenges will
                not
                impact the quality and safety of the supplements they contain. </p>
            <p>We appreciate your patience as we do everything we can to return to our normal
                aesthetic standards as soon as possible. </p>
        </div>
    </div>
</ng-template>