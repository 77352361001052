import { Router } from '@angular/router';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { CartService } from 'src/app/cart/service';
import { FloatingCartService } from 'src/app/common/service/floating-cart.service';
import { IOrderItem } from 'src/app/order-by-item/model/order-item';
import { CartNotificationService } from 'src/app/shared/service/cart-notification.service';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { TrackingService } from 'src/app/tracking/service/tracking.service';
import { ReturnsAlertComponent } from '../component/returns-alert/returns-alert.component';

export class BaseOrderDetails {
  private alertDialogRef: DialogRef;
  constructor(
    protected readonly router: Router,
    protected readonly floatingCartService: FloatingCartService,
    protected readonly cartService: CartService,
    protected readonly trackingService: TrackingService,
    protected readonly notificationService: CartNotificationService,
    protected readonly loadingService: LoadingService,
    protected readonly dialogService: DialogService
  ) { }

  addToCart(items: IOrderItem[]) {
    this.loadingService.show();
    this.cartService.addItemsWithConfirmationDialog(items, null);
  }

  showReturnsAlert() {
    this.alertDialogRef = this.dialogService.open({
      title: `Sorry! You can't return this order.`,
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
      content: ReturnsAlertComponent,
    });
    const alertDialog: ReturnsAlertComponent = this.alertDialogRef.content.instance;

    this.alertDialogRef.result.subscribe(() => {
      this.alertDialogRef = null;
    });
  }
}
