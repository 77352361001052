import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { FindOrderCriteria } from '../../model/find-order-criteria.model';
import { OrderValidation } from '../../model/order-validation.model';
import { OrderHistoryService } from '../../service/order-history.service';

@Component({
  selector: 'lef-find-my-order',
  templateUrl: './find-my-order.component.html',
  styleUrls: ['./find-my-order.component.scss'],
})
export class FindMyOrderComponent implements OnInit {
  searchForm: UntypedFormGroup;
  criteria: FindOrderCriteria = new FindOrderCriteria();
  orderValidationFails: boolean;
  showOrderDetails: boolean;
  orderNumber: number;
  constructor(
    private readonly router: Router,
    private readonly orderHistoryService: OrderHistoryService,
    private readonly loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.searchForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.criteria.name, [Validators.required, Validators.minLength(3)]),
      orderNumber: new UntypedFormControl(this.criteria.orderNumber, [Validators.required, Validators.pattern('^[0-9]{6,15}$')]),
    });
  }

  findMyOrder(criteria: FindOrderCriteria) {
    this.loadingService.show();
    this.orderHistoryService.checkOrderValidity(criteria.name, criteria.orderNumber).subscribe(
      (order: OrderValidation) => {
        this.loadingService.hide();
        this.orderValidationFails = !order.isValid;
        if (order.isValid) {
          this.showOrderDetails = true;
          this.orderNumber = order.orderNumber;
        }
      },
      () => {
        this.loadingService.hide();
      }
    );
  }

  onHideDetails() {
    this.showOrderDetails = false;
    this.searchForm.reset();
  }
}
