import { AfterViewInit, Component, ElementRef, Input, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ResponsiveService, ScreenSizes } from '../../service/responsive.service';

@Component({
  selector: 'lef-scroll-container',
  templateUrl: './scroll-container.component.html',
  styleUrls: ['./scroll-container.component.scss'],
})
export class ScrollContainerComponent implements AfterViewInit, OnDestroy {
  @Input() scrollContainerClass: string;
  @Input() scrollContentClass = '';
  @Input() scrollOffset = 0;
  @Input() isResponsive = false;
  @Input() minScreenSize: ScreenSizes = ScreenSizes.XS;
  @Input() maxScreenSize: ScreenSizes = ScreenSizes.XL;

  @ViewChild('scrollContent', { read: ElementRef }) private scrollContent: ElementRef;
  private scrollContentDiv: HTMLDivElement;
  private responsiveSubscription: Subscription;

  constructor(private readonly renderer: Renderer2, private readonly responsiveService: ResponsiveService) { }

  ngAfterViewInit(): void {
    // Commented by Devon on Commit 2cd576a18be8fba5c6f11150478a65e49f98d796 05/25/2024
    // Review and Delete if not needed

    // this.scrollContentDiv = this.scrollContent?.nativeElement as HTMLDivElement;
    // if (this.isResponsive) {
    //   this.subscribeToResponsive();
    // } else {
    //   this.renderer.addClass(this.scrollContentDiv, 'scroll-content-sticky');
    // }
    // this.renderer.setStyle(this.scrollContentDiv, 'top', this.scrollOffset + 'px');
  }

  ngOnDestroy(): void {
    if (this.responsiveSubscription) {
      this.responsiveSubscription.unsubscribe();
    }
  }

  private subscribeToResponsive() {
    this.responsiveSubscription = this.responsiveService.onResize$.subscribe((screenSize: ScreenSizes) => {
      if (screenSize < this.minScreenSize || screenSize > this.maxScreenSize) {
        this.renderer.removeClass(this.scrollContentDiv, 'scroll-content-sticky');
      } else {
        this.renderer.addClass(this.scrollContentDiv, 'scroll-content-sticky');
      }
    });
    this.responsiveService.checkCurrentSize();
  }
}
