import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject } from 'rxjs/internal/Subject';
import { FavoriteService } from 'src/app/account/service/favorite.service';
import { AutoShipValuesService } from 'src/app/auto-ship/service';
import { Cart, ICartItem } from 'src/app/cart-common/model';
import { CartCommonDialogService } from 'src/app/cart-common/service';
import { ScreenSizes } from 'src/app/common/service/responsive.service';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { FeaturesService } from 'src/app/shared/service/features.service';
import { HtmlUtilityService } from 'src/app/shared/service/html-utilitity.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { TrackingService } from 'src/app/tracking/service/tracking.service';
import { CartService } from '../../service';

@Component({
  selector: 'lef-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CartComponent implements OnInit, OnDestroy {
  private itemsToAddFromQueryString: ICartItem[];
  cart: Cart = new Cart();
  screenSizes = ScreenSizes;
  isMobile: boolean = false;
  cartLoaded: boolean = false;

  @ViewChild('prop65', { read: TemplateRef }) prop65: TemplateRef<any>;
  @ViewChild('cartContainer', { read: ElementRef }) cartContainer: ElementRef;

  reloadCarouselItems: Subject<any> = new Subject();

  constructor(
    private readonly cartService: CartService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly trackingService: TrackingService,
    private readonly autoShipValuesService: AutoShipValuesService,
    public readonly config: CoreConfiguration,
    private readonly loadingService: LoadingService,
    private readonly favoriteService: FavoriteService,
    private readonly cartCommonDialogService: CartCommonDialogService,
    private deviceService: DeviceDetectorService,
    private readonly htmlUtilityService: HtmlUtilityService,
    private readonly featuresService: FeaturesService
  ) { }

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
    if (this.redirectToCheckoutNeeded()) {
      this.router.navigateByUrl(this.config.webRoutes.checkout);
    } else {
      this.initializeCart();
    }
  }

  ngOnDestroy() {
    if (this.reloadCarouselItems) {
      this.reloadCarouselItems.complete();
    }
  }

  initializeCart() {
    this.itemsToAddFromQueryString = this.getItemsFromQueryString();
    this.initFeaturesFromQueryString();
    this.getCart();
  }

  redirectToCheckoutNeeded(): boolean {
    const checkout = this.getQuerystringParam('checkout');
    return !!checkout;
  }

  getItemsFromQueryString(): ICartItem[] {
    const item = this.getQuerystringParam('item');
    if (!!item) {
      const isAutoship = this.getQuerystringParam('autoship')?.toLowerCase() == 'true';
      const qty = this.getQuerystringParam('qty');
      const items = item.split(',');
      const quantities = qty ? qty.split(',') : new Array<number>(items.length).map((i) => 1);
      const sourceCode = this.getQuerystringParam('sourcecode');
      const cartItems: ICartItem[] = [];
      items.forEach((value, index) => {
        cartItems.push({
          itemNumber: value,
          quantity: +(quantities[index] ? quantities[index] : 1),
          marketingCode: sourceCode,
          isAutoShip: isAutoship,
        });
      });
      return cartItems;
    }
    return null;
  }

  initFeaturesFromQueryString(): void {
    const features = this.getQuerystringParam('features');
    if (features) {
      this.featuresService.setFeatures(features);
    }
  }

  getQuerystringParam(key: string) {
    let value = this.route.snapshot.queryParamMap.get(key);
    if (!value) {
      value = this.route.snapshot.queryParamMap.get(`amp;${key}`);
    }
    return value;
  }

  getCart() {
    this.loadingService.show('Loading Your Cart...');
    this.cartService.getCart().subscribe({
      next: (result) => {
        this.onCartUpdated(result);
        this.fireViewCartEvent();

        if (this.cart.autoShipDayOfMonth > 0) {
          this.autoShipValuesService.setAdjustedAutoShipStartDates(this.cart.autoShipDayOfMonth);
          this.autoShipValuesService.useAdjustedDates = true;
        } else {
          this.autoShipValuesService.useAdjustedDates = false;
        }
        this.loadingService.hide();
        if (this.itemsToAddFromQueryString) {
          this.addItems(this.itemsToAddFromQueryString);
          this.itemsToAddFromQueryString = [];
        }
      },
      error: () => {
        this.loadingService.hide();
      },
    });
  }

  updateCart() {
    if (this.cart?.items?.length > 10) {
      this.loadingService.show();
    }

    this.cartService.updateCart(this.cart).subscribe((result) => {
      this.onCartUpdated(result);
      this.loadingService.hide();
    },
      (error: any) => {
        this.loadingService.hide();
      });
  }

  addItemFromCarousel(itemNumber: string) {
    this.addItems([{ itemNumber, quantity: 1 }]);
    window.scrollTo(0, 0);
  }

  addItems(items: ICartItem[]) {
    this.loadingService.show();
    this.cartService.addItems(items).subscribe({
      next: (result) => {
        this.onCartUpdated(result);
        this.refreshCarouselItems(result);
        this.loadingService.hide();
      },
      error: () => {
        this.loadingService.hide();
      },
    });
  }

  removeItem(item: ICartItem) {
    if (this.cart?.items?.length > 10) {
      this.loadingService.show();
    }

    this.cartService.deleteItem(item.itemId).subscribe((result) => {
      this.onCartUpdated(result);
      this.refreshCarouselItems(result);
      this.loadingService.hide();
    },
      (error: any) => {
        this.loadingService.hide();
      });
  }

  updateAutoShip(item: ICartItem) {
    this.cartService.updateAutoShip(item).subscribe((result) => {
      this.onCartUpdated(result);
    });
  }

  addToWishListItem(item: ICartItem) {
    this.loadingService.show();
    this.favoriteService.addItemToFavorites(item.itemNumber, item.quantity, '').subscribe(
      (response) => {
        if (response?.success) {
          item.itemIsInFavorites = true;
          if (response?.Messages?.length > 0) {
            item.itemMessages.push(response?.Messages);
          }
        } else {
          if (response?.Messages?.length > 0) {
            item.itemMessages.push(response?.Messages);
          }
        }
        this.trackingService.addToWishList.next(item);
        this.loadingService.hide();
      },
      (error: any) => {
        this.loadingService.hide();
      }
    );
  }

  onCartUpdated(cart: Cart) {
    if (this.cartContainer) {
      const height = this.cartContainer.nativeElement.clientHeight;
      this.cart = cart;
      this.cartLoaded = true;
      // Prevent summary from hanging over footer.
      setTimeout(() => {
        if (height > this.cartContainer.nativeElement.clientHeight) {
          window.scrollBy(0, this.cartContainer.nativeElement.clientHeight - height);
        }
      });
    } else {
      this.cart = cart;
    }

    this.htmlUtilityService.restoreFocusPoint();
  }

  onCheckout() {
    if (this.cart.isEmployee) {
    }
    if (!this.cart.checkProp65Consent) {
      this.router.navigateByUrl(this.config.webRoutes.checkout);
    } else {
      this.cartService.checkProp65().subscribe({
        next: (result) => {
          this.onCartUpdated(result);

          if (!this.cart.requiresProp65Consent) {
            this.router.navigateByUrl(this.config.webRoutes.checkout);
            return;
          }

          this.cartCommonDialogService.openProp65ConcernDialog();
        },
        error: () => {
          this.loadingService.hide();
        },
      });
    }
  }

  private refreshCarouselItems(cart: Cart) {
    if (!cart?.items?.length) return;

    this.reloadCarouselItems.next(null);
  }

  private fireViewCartEvent(count: number = 0) {
    let enable = true;

    if (!window?.OptanonActiveGroups || !window.OptanonActiveGroups.includes('landingPath')) {
      enable = false;
    }

    if (enable || count == 4) {
      this.trackingService.viewCart.next(this.cart);
      return;
    } else {
      count++;
      setTimeout(() => {
        count++;
        this.fireViewCartEvent(count);
      }, 500);
    }
  }
}
