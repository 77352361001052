import { Component, OnInit } from '@angular/core';
import { ICart } from 'src/app/cart-common/model/cart';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { Constants } from '../../model/constants';
import { TrackingService } from '../../service/tracking.service';

declare global {
  interface Window {
    fbq: any;
    _fbq: any;
  }
}

@Component({
  selector: 'lef-facebook-pixel',
  templateUrl: './facebook-pixel.component.html',
  styleUrls: ['./facebook-pixel.component.scss'],
})
export class FacebookPixelComponent implements OnInit {
  private email: string;
  private firstName: string;

  constructor(private readonly config: CoreConfiguration, private readonly trackingService: TrackingService) {}

  ngOnInit(): void {
    if (this.config.environment.enableFacebookTracking && this.trackingService.checkOneTrustActiveGroup(this.config.oneTrustGroups.socialMedia)) {
      this.trackingService.navigationChanged.subscribe({ next: (event) => this.onNavigationChanged() });
      this.trackingService.orderPlaced.subscribe({ next: (cart) => this.onOrderPlaced(cart) });
    }
  }

  private onOrderPlaced(cart: ICart) {
    this.initialize();
    this.trackPurchase(cart);
  }

  private onNavigationChanged() {
    this.initialize();
    this.trackPageView();
  }

  private initialize() {
    if (!window.fbq) {
      return;
    }

    const email = this.trackingService.storage.get(Constants.Email) ?? '';
    const firstName = this.trackingService.storage.get(Constants.FirstName) ?? '';
    if (email === this.email && firstName === this.firstName) {
      return;
    }

    this.email = email;
    this.firstName = firstName;
    window.fbq('init', '885974991418405', {
      em: email,
      fn: firstName,
    });
  }

  private trackPageView() {
    if (!window.fbq) {
      return;
    }
    window.fbq('track', 'PageView');
  }

  private trackPurchase(cart: ICart) {
    if (!window.fbq) {
      return;
    }

    const contentId = cart.items.map((i) => i.itemNumber).join(',');
    const totalOrder = cart.totalOrder;

    window.fbq('track', 'Purchase', {
      content_ids: [contentId],
      content_type: 'product',
      value: totalOrder,
      currency: 'USD',
    });
  }
}
