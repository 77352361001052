<lef-cart-messages [messages]="creditCardMessages"></lef-cart-messages>
<div *ngIf="!gateway.usingSecondaryGateway" class="row mb-md-3">
  <div [ngClass]="{'col-md-9': isPayPalEnabled, 'col-12': !isPayPalEnabled}" class=" border-md-right">
    <lef-payment-credit-card [gateway]="gateway" [isNetworkRequestInProgress]="isNetworkRequestInProgress"
                             [isCheckOut]="isCheckOut" [isReturn]="isReturn" [instantSave]="instantSave"
                             (paymentOptionsChanged)="onPaymentOptionsChanged($event)"
                             (executingNetworkRequest)="onExecutingNetworkRequest($event)">
    </lef-payment-credit-card>
  </div>
  <div *ngIf="isPayPalEnabled" class="col-md-3">
    <div [ngClass]="{'d-none': isPayPalCheckoutFlowEnabled}">
      <lef-payment-paypal [gateway]="gateway" [isNetworkRequestInProgress]="isNetworkRequestInProgress"
                          [isCheckOut]="isCheckOut" [isReturn]="isReturn" [instantSave]="instantSave"
                          [customButtonId]="'paypal-btn-new-payment-method'"
                          [usingCheckoutFlow]="isPayPalCheckoutFlowEnabled"
                          (paymentOptionsChanged)="onPaymentOptionsChanged($event)"
                          (executingNetworkRequest)="onExecutingNetworkRequest($event)">
      </lef-payment-paypal>
    </div>
    <ng-container *ngIf="isPayPalCheckoutFlowEnabled">
      <button id="btn-paypal-payment-option" class="btn btn-primary-outline pp-btn"
              (click)="usePayPalCheckoutFlowOption()">
        <img src="assets/images/paypal-logo-btn.png" alt="PayPal" />
      </button>
    </ng-container>
    <div *ngIf="isNetworkRequestInProgress" class="d-flex align-items-center justify-content-center h-100">
      <span class="k-i-loading k-icon k-font-icon font-weight-medium font-4x"></span>
    </div>
  </div>
</div>

<div *ngIf="gateway.usingSecondaryGateway" class="row mb-md-3">
  <div *ngIf="isNetworkRequestInProgress" class="d-flex justify-content-center p-5">
    <span class="k-i-loading k-icon k-font-icon font-weight-medium" style="font-size: 34px;"></span>
  </div>
  <iframe *ngIf="!isNetworkRequestInProgress" #creditCardIframe width="95%" height="300px" style="overflow: hidden;"
          [src]="gateway.pciUrl|safe"></iframe>
</div>