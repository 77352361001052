import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { ICart } from 'src/app/cart-common/model/cart';
import { ICartItem } from 'src/app/cart-common/model/cart-item';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { CustomerData } from '../../model/customer-data.model';
import { TrackingService } from '../../service/tracking.service';

declare global {
  interface Window {
    wa: any;
    BT: any;
  }
}

@Component({
  selector: 'lef-selligent',
  templateUrl: './selligent.component.html',
  styleUrls: ['./selligent.component.scss'],
})
export class SelligentComponent implements OnInit {
  cartName = 'abandoned_cart';
  isTargeting = true;
  private readonly imgPixelClassName = 'lef-img-pixel';
  constructor(
    private readonly renderer: Renderer2,
    private readonly trackingService: TrackingService,
    private readonly config: CoreConfiguration,
    protected readonly httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    if (this.config.environment.enableSelligentTracking) {
      this.trackingService.customerDataChanged.subscribe({ next: (customer) => this.onCustomerDataChanged(customer) });
      this.trackingService.itemAddedToCart.subscribe({ next: (item) => this.onItemAddedToCart(item) });
      this.trackingService.itemRemovedFromCart.subscribe({ next: (item) => this.onItemRemovedFromCart(item) });
      this.trackingService.itemsRemovedFromCart.subscribe({ next: (items) => this.onItemsRemovedFromCart(items) });
      this.trackingService.orderPlaced.subscribe({ next: (cart) => this.onOrderPlaced(cart) });
      this.trackingService.itemsAddedToCart.subscribe({ next: (items) => this.onItemsAddedToCart(items) });
      this.trackingService.clearCart.subscribe({ next: (cart) => this.onClear(cart) });
    }
  }

  private onCustomerDataChanged(customer: CustomerData) {
    if (!!window.wa && !!window.wa.bt_queue) {
      try {
        const customerNumber = customer.customerNumber;
        if (customerNumber && parseInt(customerNumber, 10) !== 0) {
          const data = { isEvent: false, isTargeting: true, async: true, customIdentifier: customerNumber };
          window.wa.bt_queue.push(data);
        } else {
          const data = { isEvent: false, isTargeting: true, async: true };
          window.wa.bt_queue.push(data);
        }
      } catch (e) {}
    }
  }

  onItemsAddedToCart(items: ICartItem[]) {
    const cartItems = items.map((i) => ({
      id: i.itemNumber,
      count: i.quantity,
      value: i.yourPrice,
    }));
    try {
      if (!!window.BT?.addCartItems) {
        window.BT.addCartItems(this.cartName, cartItems, this.isTargeting);
      }
    } catch (e) {}
  }

  onItemAddedToCart(item: ICartItem) {
    this.onItemsAddedToCart([item]);
  }

  onItemRemovedFromCart(item: ICartItem) {
    this.onItemsRemovedFromCart([item]);
  }

  onItemsRemovedFromCart(items: ICartItem[]) {
    const cartItems = items.map((i) => ({
      id: i.itemNumber,
      count: i.quantity,
    }));
    try {
      if (window.BT && window.BT.removeCartItems) {
        window.BT.removeCartItems(this.cartName, cartItems, this.isTargeting);
      }
    } catch (e) {}
  }

  onClear(cart: ICart) {
    this.selligentClearCart();
  }

  onOrderPlaced(cart: ICart) {
    try {
      if (window.BT && window.BT.clearCart) {
        window.BT.clearCart(this.cartName, true);
      }
    } catch (e) {}
    this.selligentClearCart();
  }

  selligentClearCart() {
    const customer = this.trackingService.getCurrentCustomerData();
    const url = this.config.environment.selligentClearCartUrl;
    if (url) {
      const imageUrl = `${url}&CONTACT_NUMBER=${customer.customerNumber}&CLEAR_CART=1`;
      this.addPixelImage(imageUrl);
    }
  }

  generateImageId(): string {
    return 'xxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      return r.toString(16);
    });
  }

  addPixelImage(imageUrl: string) {
    new Promise((resolve, reject) => {
      const imgPixel = document.createElement('img');
      imgPixel.id = this.generateImageId();
      imgPixel.src = `${imageUrl}&lef_request_id=${imgPixel.id}`;
      imgPixel.width = 1;
      imgPixel.height = 1;
      imgPixel.className = `d-none ${this.imgPixelClassName}`;
      imgPixel.onload = () => resolve(imgPixel);
      imgPixel.onerror = reject;
      this.renderer.appendChild(document.body, imgPixel);
    }).then(
      (image: HTMLImageElement | any) => {
        document.body.removeChild(image);
      },
      (error) => {
        document.body.removeChild(error.target);
      }
    );
  }
}
