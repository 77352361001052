import { ConfigOption, FormlyModule } from '@ngx-formly/core';
import { FormlyKendoModule } from '@ngx-formly/kendo';
import {
  FocusInputComponent,
  FormDateInputComponent,
  FormWrapperFormFieldComponent,
  HorizontalWrapperComponent,
  IconLabelComponent
} from './component';
import { FormCheckboxComponent } from './component/form-checkbox/form-checkbox.component';
import { FormDropdownComponent } from './component/form-dropdown/form-dropdown.component';
import { FormMaskedTextBoxComponent } from './component/form-masked-text-box/form-masked-text-box.component';
import { FormTextBoxComponent } from './component/form-text-box/form-text-box.component';
import { IconCheckboxComponent } from './component/icon-checkbox/icon-checkbox.component';
import { CheckboxExtension } from './form-extensions/checkbox-extension';
import { EmailAddressValidator, EmailAddressValidatorMessage, ValidationMessages, maxLengthValidationMessage } from './form-validations';

export const formlyConfigOption: ConfigOption | any = {
  validators: [{ name: 'email-address', validation: EmailAddressValidator }],
  validationMessages: [
    { name: 'email-address', message: EmailAddressValidatorMessage },
    { name: 'required', message: ValidationMessages.RequiredMessage },
    { name: 'email', message: ValidationMessages.EmailMessage },
    { name: 'emailMismatch', message: ValidationMessages.EmailMismatchMessage },
    { name: 'passwordMismatch', message: ValidationMessages.PasswordMismatchMessage },
    { name: 'passwordStrength', message: ValidationMessages.PasswordStrengthMessage },
    { name: 'passwordMinLength', message: ValidationMessages.PasswordMinLengthMessage },
    { name: 'notNullOrWhitespace', message: ValidationMessages.NotNullOrWhitespaceMessage },
    { name: 'maxLength', message: maxLengthValidationMessage },
  ],
  types: [
    { name: 'date', component: FormDateInputComponent, wrappers: ['form-field-html-error'] },
    { name: 'focusInput', component: FocusInputComponent, wrappers: ['form-field-html-error'] },
    { name: 'iconLabel', component: IconLabelComponent },
    { name: 'iconCheckbox', component: IconCheckboxComponent, wrappers: ['form-field-html-error'] },
    { name: 'kDropdown', component: FormDropdownComponent, wrappers: ['form-field-html-error'] },
    { name: 'kTextBox', component: FormTextBoxComponent, wrappers: ['form-field-html-error'] },
    { name: 'kMaskedTextBox', component: FormMaskedTextBoxComponent, wrappers: ['form-field-html-error'] },
    { name: 'kCheckbox', component: FormCheckboxComponent, wrappers: ['form-field-html-error'] },
  ],
  wrappers: [
    { name: 'form-field-html-error', component: FormWrapperFormFieldComponent },
    { name: 'form-field-horizontal', component: HorizontalWrapperComponent },
  ],
  extensions: [
    { name: 'form-checkbox-extension', extension: CheckboxExtension}
  ],
  // Compativility from v5 to v6 -- We need to investigate if all this settings are needed
  // https://github.com/ngx-formly/ngx-formly/blob/main/UPGRADE-6.0.md
  extras: { 
   checkExpressionOn: 'changeDetectionCheck',
   lazyRender: false,
   resetFieldOnHide: false,
  },
};

export const formlyModules = [FormlyModule.forRoot(formlyConfigOption), FormlyKendoModule];
