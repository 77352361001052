export interface ICartItemConfig {
  showImage?: boolean;
  showActions?: boolean;
  showPurchaseOptions?: boolean;
  showExtender?: boolean;
  isEditable?: boolean;
}

export class DefaultCartItemConfig implements ICartItemConfig {
  showImage = true;
  showActions = true;
  showPurchaseOptions = true;
  showExtender = false;
  isEditable = true;
}
