import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { AuthorizationService } from 'src/app/authorization';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { CartCookieService } from 'src/app/shared/service/cart-cookie-service.service';
import { LoginModel } from '../../model/login-model.model';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';

@Component({
  selector: 'lef-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit, AfterViewInit {
  title = 'Secure Sign In';
  showLoginContent = true;

  private returnUrl: string;
  private recoveryId: string;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly changeRef: ChangeDetectorRef,
    private readonly dialogService: DialogService,
    private readonly cookieService: CartCookieService,
    private readonly authService: AuthorizationService,
    private readonly router: Router,
    private readonly config: CoreConfiguration
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.returnUrl = params.returnUrl;
      this.recoveryId = params.prid;
    });
  }

  ngAfterViewInit() {
    if (this.recoveryId) {
      setTimeout(() => {
        this.showResetPasswordDialog(this.recoveryId);
      }, 1000);
    }

    this.changeRef.detectChanges();
  }

  showResetPasswordDialog(recoveryId: string) {
    const dialogRef = this.dialogService.open({
      title: 'Password Assistance',
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
      content: ResetPasswordComponent,
    });

    const componentRef = dialogRef.content.instance;
    componentRef.recoveryId = recoveryId;
    componentRef.closeDialog.subscribe(() => {
      dialogRef.close();
    });

    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        this.router.navigateByUrl(this.config.webRoutes.login);
      }
    });
  }

  onLoginUpdate(model: LoginModel) {
    const isFullLogin = model.loginStatus === 'FullLogin';
    if (isFullLogin) {
      if (this.returnUrl && this.returnUrl.toLowerCase().indexOf('https') === 0) {
        this.showLoginContent = false;
        window.location.href = this.returnUrl;
        return;
      }

      if (this.returnUrl) {
        this.router.navigateByUrl(this.returnUrl);
        return;
      }

      if(!this.cookieService.getItemsInCartCount() && 
         !this.authService.isAnonymousCustomer()) {
          if(this.authService.isWholesaler()) {
            window.location.href = this.config.webRoutes.myLifeExtensionWholesale;
          } else {
            window.location.href = this.config.webRoutes.myLifeExtension;
          }
      }
      else {
        this.router.navigateByUrl(this.config.webRoutes.root);
      }
      
    }
  }
}
