import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { errorConfigFactory } from './factory/error-config.factory';
import { errorsHandlerFactory } from './factory/errors-handler.factory';
import { ErrorMonitoringHandler } from './handler';
import { ErrorsHandler } from './handler/errors.handler';
import { ErrorConfigModel, ErrorRootConfigModel } from './model';

@NgModule({
  providers: [
    {
      deps: [HttpClient, ErrorConfigModel],
      provide: ErrorsHandler,
      useFactory: errorsHandlerFactory,
    },
  ],
})
export class ErrorsModule {
  public static forRoot(rootConfig: ErrorRootConfigModel): ModuleWithProviders<ErrorsModule> {
    return {
      ngModule: ErrorsModule,
      providers: [
        {
          provide: ErrorRootConfigModel,
          useValue: rootConfig,
        },
        {
          deps: [ErrorRootConfigModel],
          provide: ErrorConfigModel,
          useFactory: errorConfigFactory,
        },
        ErrorMonitoringHandler,
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ErrorsModule) {
    if (parentModule) {
      throw new Error('ErrorsModule is already loaded. Import it in the Root Module only.');
    }
  }
}
