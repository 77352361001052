import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoveoModule } from '../coveo/coveo.module';
import { SharedModule } from '../shared/shared.module';
import { CartCommonModule } from './../cart-common/cart-common.module';
import { ApplicationVersionInfoComponent } from './component/application-version-info/application-version-info.component';
import { CollapseBoxComponent } from './component/collapse-box/collapse-box.component';
import { ContactUsComponent } from './component/contact-us/contact-us.component';
import { DistributorLocationNoticeComponent } from './component/distributor-location-notice/distributor-location-notice.component';
import { ErrorPageComponent } from './component/error-page/error-page.component';
import { GenesysChatComponent } from './component/genesys-chat/genesys-chat.component';
import { GenesysSurveyComponent } from './component/genesys-survey/genesys-survey.component';
import { GlobalCartMessagesComponent } from './component/global-cart-messages/global-cart-messages.component';
import { HeaderAccountMobileComponent } from './component/header-account-mobile/header-account-mobile.component';
import { HeaderAccountComponent } from './component/header-account/header-account.component';
import { HeaderFloatingCartComponent } from './component/header-floating-cart/header-floating-cart.component';
import { HeaderNavBarComponent } from './component/header-nav-bar/header-nav-bar.component';
import { HeaderComponent } from './component/header/header.component';
import { OffersNotificationComponent } from './component/offers-notification/offers-notification.component';
import { PremierRenewBannerComponent } from './component/premier-renew-banner/premier-renew-banner.component';
import { RenderingContentComponent } from './component/rendering-content/rendering-content.component';
import { ScrollContainerComponent } from './component/scroll-container/scroll-container.component';
import { SectionTitleComponent } from './component/section-title/section-title.component';

const components = [
  RenderingContentComponent,
  HeaderComponent,
  HeaderAccountComponent,
  HeaderAccountMobileComponent,
  HeaderNavBarComponent,
  CollapseBoxComponent,
  ScrollContainerComponent,
  HeaderFloatingCartComponent,
  ErrorPageComponent,
  DistributorLocationNoticeComponent,
  SectionTitleComponent,
  ContactUsComponent,
  GlobalCartMessagesComponent,
  OffersNotificationComponent,
  ApplicationVersionInfoComponent,
  PremierRenewBannerComponent,
  GenesysChatComponent,
  GenesysSurveyComponent
];

@NgModule({
  declarations: components,
  imports: [CommonModule, RouterModule, SharedModule, CoveoModule, CartCommonModule],
  exports: components,
})
export class CommonsModule {}
