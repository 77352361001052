import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IAutoShipScheduleDelivery } from '../../model/auto-ship-schedule';

@Component({
  selector: 'lef-auto-ship-schedule-delivery',
  templateUrl: './auto-ship-schedule-delivery.component.html',
  styleUrls: ['./auto-ship-schedule-delivery.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AutoShipScheduleDeliveryComponent implements OnInit {

  @Input()
  delivery: IAutoShipScheduleDelivery;

  constructor() { }

  ngOnInit() {
  }

}
