import { Component, HostListener, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CartMessageType, ICartMessage } from '../../model';

@Component({
  selector: 'lef-cart-messages',
  templateUrl: './cart-messages.component.html',
  styleUrls: ['./cart-messages.component.scss'],
})
export class CartMessagesComponent implements OnChanges {
  @Input() messages: ICartMessage[] | any = [];
  @Input() messagesTypesToHide: CartMessageType[] = [];
  @Input() hideDelayInSeconds = 3;

  private hideTimer;

  constructor(private readonly renderer: Renderer2, private readonly router: Router) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.messages) {
      this.processMessages();
    }
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    // If we don't have an anchor tag, we don't need to do anything.
    if (event.target instanceof HTMLAnchorElement === false) {
      return;
    }

    let target = <HTMLAnchorElement>event.target;
    if (!target?.href && target?.href.toLowerCase().indexOf('https') !== 0) {
      // Prevent page from reloading when it´s a relative Url
      event.preventDefault();
    }

    // Navigate to the path in the link
    this.router.navigate([target.pathname]);
  }

  getIconClass(message: ICartMessage): string {
    let styleClass = '';
    switch (message.type) {
      case 'Default': {
        styleClass = '';
        break;
      }
      case 'Info': {
        styleClass = 'lef-info-hex'
        break;
      }
      case 'Warning': {
        styleClass = 'lef-warning';
        break;
      }
      case 'Success': {
        styleClass = 'k-icon k-font-icon k-i-check-circle';
        break;
      }
      case 'Error': {
        styleClass = 'lef-warning';
        break;
      }
      default: {
        styleClass = 'd-none';
        break;
      }
    }

    return styleClass;
  }

  getStyleClass(message: ICartMessage): string {
    let styleClass = 'alert alert-light';
    switch (message.type) {
      case 'Default': {
        styleClass = 'alert alert-light';
        break;
      }
      case 'Info': {
        styleClass = 'alert alert-info';
        break;
      }
      case 'Warning': {
        styleClass = 'alert alert-warning';
        break;
      }
      case 'Success': {
        styleClass = 'alert alert-success';
        break;
      }
      case 'Error': {
        styleClass = 'alert alert-danger';
        break;
      }
      default: {
        styleClass = 'alert alert-light';
        break;
      }
    }

    $(".alert").attr("role", "alert");

    return styleClass;
  }


  getMessageText(message: ICartMessage): string {
    if (!message.message.match(/&[A-z]+;/g)) {
      return message.message;
    }
    const text = this.renderer.createElement('textarea') as HTMLTextAreaElement;
    text.innerHTML = message.message;
    return text.value;
  }

  private processMessages() {
    this.hideMessages();
  }

  private hideMessages() {
    if (!this.messages?.length || !this.messagesTypesToHide?.length) {
      if (this.hideTimer) {
        clearTimeout(this.hideTimer);
        this.hideTimer = null;
      }

      return;
    }

    this.hideTimer = setTimeout(() => {
      this.messages = this.messages.filter((m) => this.messagesTypesToHide.indexOf(m.type) === -1);
    }, this.hideDelayInSeconds * 1000);
  }
}
