import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lef-pci-callback',
  templateUrl: './pci-callback.component.html',
  styleUrls: ['./pci-callback.component.scss'],
})
export class PciCallbackComponent implements OnInit, AfterViewInit {
  operation: string;
  id: string;

  get queryParams(): string {
    const params = window.location.search;
    return params.replace(/^\?/, '');
  }

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    this.operation = this.route.snapshot.params.operation;
    this.id = this.route.snapshot.params.id;
  }

  ngAfterViewInit(): void {
    const pciMessage = {
      operation: this.operation,
      id: this.id,
      params: this.queryParams,
    };
    window.parent.postMessage(pciMessage, '*');
  }
}
