<div>
  <p class="message">
    Please verify that the email address {{email}} is correct.
  </p>
  <div class="email-ver-controls">
    <button type="button" class="btn btn btn-primary" (click)="cancel()">
      No, I will make the correction
    </button>
    <button type="button" class="btn btn btn-warning" (click)="confirm()">
      Yes, this is my Email
    </button>
  </div>
</div>