import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { PrintService } from 'src/app/common/service/print.service';
import { WarehouseExpectedItem } from 'src/app/order-history/model/warehouse-expected-item.model';

@Component({
  selector: 'lef-packaging-slip',
  templateUrl: './packaging-slip.component.html',
  styleUrls: ['./packaging-slip.component.scss'],
})
export class PackagingSlipComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() orderNumber: string;
  @Input() items: WarehouseExpectedItem[] = [];

  @ViewChild('printable', { read: ElementRef }) printable: ElementRef;

  constructor(private readonly renderer: Renderer2, private readonly printService: PrintService) {}

  ngAfterViewInit(): void {
    this.print();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    const footer = document.getElementsByTagName('footer')[0];
    this.renderer.removeClass(footer, 'd-print-none');
  }

  print() {
    this.printService.printElement(this.printable.nativeElement as HTMLElement);
  }
}
