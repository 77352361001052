import { Injectable } from '@angular/core';
import { IAutoShipItem } from 'src/app/auto-ship/model/auto-ship-item';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { IReturnItem } from 'src/app/returns/model/return-item';
import { ICarouselItem, ICartItem } from '../model';

@Injectable({
  providedIn: 'root',
})
export class ItemConfigurationService {
  constructor(private readonly baseConfig: CoreConfiguration) {}

  getItemUrl(item: ICartItem | IAutoShipItem | ICarouselItem | IReturnItem): string {
    if (!item && !item.itemNumber) {
      return '';
    }

    const itemConfig = this.baseConfig.environment.customItemUrlTemplates.find((config) => {
      return config.itemNumbers.indexOf(item.itemNumber.toUpperCase()) > -1;
    });

    if (itemConfig) {
      return itemConfig.itemUrlTemplate;
    }
    return this.baseConfig.environment.itemUrlTemplates.itemUrlTemplate.replace('{itemNumber}', item.itemNumber);
  }
}
