import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { ICarouselItem } from '../../model';
import { CartCommonService } from '../../service';

@Component({
  selector: 'lef-item-carousel',
  templateUrl: './item-carousel.component.html',
  styleUrls: ['./item-carousel.component.scss'],
})
export class ItemCarouselComponent implements OnInit, OnDestroy {
  @Input() buttonText = 'Add to Cart';
  @Input() title = '';
  @Input() carouselId = '';
  @Input() reload: Subject<any> = null;
  @Input() maxSlidesToShow = 4;

  @Output() addToCartClicked = new EventEmitter<string>();

  reloadSubscription: Subscription = null;
  loading = false;
  carouselItems: ICarouselItem[];

  slideConfig = null;

  constructor(private readonly cartService: CartCommonService) {}

  ngOnInit(): void {
    this.initSlideConfig();
    this.loadCarouselItems();

    if (this.reload) {
      this.reloadSubscription = this.reload.subscribe((event) => {
        this.loadCarouselItems();
      });
    }
  }

  ngOnDestroy(): void {
    if (this.reloadSubscription) {
      this.reloadSubscription.unsubscribe();
      this.reloadSubscription = null;
    }
  }

  initSlideConfig() {
    this.slideConfig = {
      accessibility: false,
      infinite: false,
      slidesToShow: 1,
      rows: 0,
      arrows: true,
      dots: true,
      dotsClass: 'slick-dots',
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: Math.min(this.maxSlidesToShow, 2),
            slidesToScroll: Math.min(this.maxSlidesToShow, 2),
            arrows: true,
            dots: false,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: Math.min(this.maxSlidesToShow, 3),
            slidesToScroll: Math.min(this.maxSlidesToShow, 3),
            arrows: true,
            dots: false,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: Math.min(this.maxSlidesToShow, 4),
            slidesToScroll: Math.min(this.maxSlidesToShow, 4),
            arrows: true,
            dots: false,
          },
        },
      ],
    };
  }

  loadCarouselItems() {
    this.loading = true;
    this.cartService.getCartCarouselItems(this.carouselId).subscribe({
      next: (items: ICarouselItem[]) => {
        this.carouselItems = items;
      },
      error: (error) => {
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }
}
