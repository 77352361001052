import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { OrderItem } from '../../model/order-item.model';
import { OrderTracking } from '../../model/order-tracking.model';

@Component({
  selector: 'lef-order-shipment',
  templateUrl: './order-shipment.component.html',
  styleUrls: ['./order-shipment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderShipmentComponent implements OnInit {
  @Input() shippedTo: string;
  @Input() items: OrderItem[] = [];
  @Input() trackingNumbers: OrderTracking[] = [];
  @Output() goToOrderDetails: EventEmitter<string> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  openOrderDetails(orderNumber: string) {
    this.goToOrderDetails.emit(orderNumber);
  }
}
