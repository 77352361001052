import { Injectable } from '@angular/core';
import { NotificationService, NotificationSettings } from '@progress/kendo-angular-notification';

export type NotificationStyle = 'none' | 'success' | 'warning' | 'error' | 'info';

@Injectable({
  providedIn: 'root',
})
export class CartNotificationService {
  constructor(private notificationService: NotificationService) {}

  public showError(message: string) {
    if (!message) {
      return;
    }
    this.show(message, 'error', true);
  }

  public showInfo(message: string) {
    if (!message) {
      return;
    }
    this.show(message, 'info', true);
  }

  public showWarning(message: string) {
    if (!message) {
      return;
    }
    this.show(message, 'warning', true);
  }

  public showSuccess(message: string) {
    if (!message) {
      return;
    }
    this.show(message, 'success', true);
  }

  public show(message: string, style: NotificationStyle, icon: boolean = true): void {
    const settings: NotificationSettings = {
      content: message,
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'slide', duration: 500 },
      type: { style, icon },
    };

    if (style !== 'error') {
      settings.hideAfter = 1000;
    } else {
      settings.closable = true;
    }

    this.notificationService.show(settings);
  }
}
