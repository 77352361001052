import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { ICartMessage } from 'src/app/cart-common/model/cart-message';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { PaymentType } from '../../model';
import { IPaymentGateway, PaymentGateway } from '../../model/payment-gateway';
import { PaymentMethod } from '../../model/payment-method';
import { PaymentOptionCollection } from '../../model/payment-option-collection';
import { PayPalCheckoutService } from '../../service/pay-pal-checkout-service.service';
import { PaymentOptionService } from '../../service/payment-option.service';

@Component({
  selector: 'lef-payment-new-method',
  templateUrl: './payment-new-method.component.html',
  styleUrls: ['./payment-new-method.component.scss'],
})
export class PaymentNewMethodComponent implements AfterViewInit, OnDestroy {
  @Input() gateway: IPaymentGateway = new PaymentGateway();
  @Input() embedded = false;
  @Input() isCheckOut: boolean;
  @Input() isReturn: boolean;
  @Input() instantSave: boolean;
  @Input() isPayPalCheckoutFlowEnabled: boolean;

  @Output() paymentOptionsChanged: EventEmitter<PaymentOptionCollection> = new EventEmitter();
  @ViewChild('creditCardIframe') iframe: ElementRef;

  creditCardMessages: ICartMessage[];
  isNetworkRequestInProgress: boolean;

  get isPayPalEnabled(): boolean {
    return this.payPalCheckOutService.isPayPalServiceActive() && !this.isCheckOut;
  }

  private gatewayEventListener: (event: any) => void;

  constructor(
    private readonly paymentOptionService: PaymentOptionService,
    private readonly loadingService: LoadingService,
    private readonly payPalCheckOutService: PayPalCheckoutService
  ) {}

  ngAfterViewInit(): void {
    if (this.gateway.usingSecondaryGateway) {
      this.gatewayEventListener = (event: any) => {
        this.processSecondaryPaymentGatewayMessage(event);
      };
      this.startListeningSecondaryGatewayMessages();
    }
  }

  onPaymentOptionsChanged(paymentCollection: PaymentOptionCollection) {
    this.paymentOptionsChanged.emit(paymentCollection);
  }

  onExecutingNetworkRequest(inProgress: boolean) {
    setTimeout(() => {
      this.isNetworkRequestInProgress = inProgress;
    }, 10);
  }

  ngOnDestroy(): void {
    if (this.gateway.usingSecondaryGateway) {
      this.stopListeningSecondaryGatewayMessages();
    }
  }

  processSecondaryPaymentGatewayMessage(event: any) {
    const allowedOperations = ['return', 'cancel', 'checkouterror'];
    if (allowedOperations.includes(event.data.operation)) {
      if (event.data.operation === 'return') {
        this.processCreditCardUsingSecondaryGateway(event.data);
      } else if (event.data.operation === 'checkouterror') {
        const params = event.data.params as string;
        const msgRegex = /RESPTEXT=([^&]+)/gi;
        const match = params.match(msgRegex);
        const message = match.length > 1 ? decodeURI(match[1]) : 'Error adding Credit Card';

        this.creditCardMessages = [
          {
            type: 'Error',
            message: `${message}`,
            showIcon: true,
          },
        ];
        this.iframe.nativeElement.src = this.gateway.pciUrl;
      }
    }
  }

  processCreditCardUsingSecondaryGateway(data: any) {
    this.isNetworkRequestInProgress = true;
    this.loadingService.show();
    this.paymentOptionService.processCreditCardUsingSecondaryGateway(data.id, data.params, this.isCheckOut).subscribe({
      next: (result: PaymentOptionCollection | any) => {
        this.creditCardMessages = result.messages;
        this.gateway = result.gateway;
        if (result.messages.length === 0) {
          this.paymentOptionsChanged.emit(result);
        } else {
          this.isNetworkRequestInProgress = false;
        }
        this.loadingService.hide();
      },
      error: (error) => {
        this.loadingService.hide();
      },
    });
  }

  usePayPalCheckoutFlowOption() {
    this.isNetworkRequestInProgress = true;
    this.loadingService.show();
    this.paymentOptionService.selectWalletCheckoutFlowOption(PaymentType.PayPal, PaymentMethod.PayPal).subscribe({
      next: (result: PaymentOptionCollection) => {
        this.creditCardMessages = result.messages;
        this.gateway = result.gateway;
        if (result.messages.length === 0) {
          this.paymentOptionsChanged.emit(result);
        } else {
          this.isNetworkRequestInProgress = false;
        }
        this.loadingService.hide();
      },
      error: (error) => {
        this.loadingService.hide();
      },
    });
  }

  stopListeningSecondaryGatewayMessages() {
    const eventMethod = window.removeEventListener ? 'removeEventListener' : 'detachEvent';
    const unSubscriber = window[eventMethod];
    const messageEvent = eventMethod === 'detachEvent' ? 'onmessage' : 'message';
    unSubscriber(messageEvent, this.gatewayEventListener);
  }

  startListeningSecondaryGatewayMessages() {
    const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
    const eventSubscriber = window[eventMethod];
    const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';
    eventSubscriber(messageEvent, this.gatewayEventListener);
  }
}
