import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentType } from '../../model';
import { IPaymentOption } from '../../model/payment-option';
import { PaymentOptionService } from '../../service/payment-option.service';

@Component({
  selector: 'lef-payment-option-selector',
  templateUrl: './payment-option-selector.component.html',
  styleUrls: ['./payment-option-selector.component.scss'],
})
export class PaymentOptionSelectorComponent implements OnInit {
  @Input() paymentOptions: IPaymentOption[] = [];
  @Input() paymentOptionSelectedId: string;
  @Input() isCheckOut: boolean;
  @Input() isReturn: boolean;

  @Output() paymentOptionSelected: EventEmitter<IPaymentOption> = new EventEmitter();

  constructor(private readonly paymentOptionService: PaymentOptionService) {}

  ngOnInit(): void {}

  selectPaymentOption(option: IPaymentOption) {
    if (!option.isExpired) {
      this.paymentOptionSelected.emit(option);
      this.paymentOptionService.selectPaymentOptionId(option.id, this.isReturn).subscribe((result) => {});
    }
  }

  excludeOnCheckoutPayments(paymentOptions:IPaymentOption[]):IPaymentOption[] {
    if(this.isCheckOut || this.isReturn){
      return paymentOptions.filter(p => !p.verifyAtCheckout && !p.payAtCheckout && p.type != PaymentType.GooglePay && p.type != PaymentType.ApplePay);
    }
    return paymentOptions.filter(p => !p.verifyAtCheckout && !p.payAtCheckout);
  }    
}
