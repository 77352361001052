import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SharedModule } from '../shared/shared.module';
import {
  AddToCartConfirmationDialogComponent,
  CartDiscountComponent,
  CartItemComponent,
  CartItemHostComponent,
  CartItemMessagesComponent,
  CartItemPremierComponent,
  CartItemPriceComponent,
  CartItemSubscriptionComponent,
  CartMessagesComponent,
  Prop65MessageComponent
} from './component';
import { CartExtendedMessageComponent } from './component/cart-extended-message/cart-extended-message.component';
import { CartItemBloodLabComponent } from './component/cart-item-blood-lab/cart-item-blood-lab.component';
import { CartSuperSaleBannerComponent } from './component/cart-super-sale-banner/cart-super-sale-banner.component';
import { ItemCarouselItemComponent } from './component/item-carousel-item/item-carousel-item.component';
import { ItemCarouselComponent } from './component/item-carousel/item-carousel.component';
import { CtrlTemplateDirective } from './directive/ctrl-template.directive';
import { NoDblClickDirective } from './directive/no-dbl-click.directive';

const modules = [RouterModule, CommonModule, SharedModule, SlickCarouselModule];

const components = [
  AddToCartConfirmationDialogComponent,
  CartDiscountComponent,
  CartItemComponent,
  CartItemHostComponent,
  CartItemMessagesComponent,
  CartItemPriceComponent,
  CartItemSubscriptionComponent,
  CartItemPremierComponent,
  CartMessagesComponent,
  CartExtendedMessageComponent,
  ItemCarouselItemComponent,
  ItemCarouselComponent,
  CartSuperSaleBannerComponent,
  Prop65MessageComponent,
  CtrlTemplateDirective,
  NoDblClickDirective,
];

@NgModule({
    imports: [modules],
    declarations: [components, CartItemBloodLabComponent],
    exports: [components]
})
export class CartCommonModule {}
