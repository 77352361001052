import { Component, OnInit } from '@angular/core';
import { ICart } from 'src/app/cart-common/model/cart';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { TrackingService } from '../../service/tracking.service';

declare global {
  interface Window {
    abtasty: any;
  }
}

@Component({
  selector: 'lef-a-b-tasty',
  templateUrl: './a-b-tasty.component.html',
  styleUrls: ['./a-b-tasty.component.scss'],
})
export class ABTastyComponent implements OnInit {
  constructor(private readonly trackingService: TrackingService, private readonly config: CoreConfiguration) {}

  ngOnInit(): void {
    if (this.config.environment.abtastyTracking.enabled && this.trackingService.checkOneTrustActiveGroup(this.config.oneTrustGroups.targeting)) {
      this.trackingService.orderPlaced.subscribe({ next: (cart) => this.onOrderPlaced(cart) });
    }
  }

  onOrderPlaced(cart: ICart) {
    if (!window.abtasty || !window.abtasty.send || typeof window.abtasty.send != 'function') {
      return;
    }

    const quantity = cart.items.map((i) => i.quantity).reduce((a, b) => a + b);
    const coupon = this.getCoupon(cart);
    const transaction = {
      tid: cart.orderNumber,
      ta: 'Purchase',
      tr: cart.totalOrder,
      ts: cart.totalFreight,
      tt: 0,
      tc: 'US',
      tcc: coupon,
      pm: 'CC',
      sm: '',
      icn: quantity,
    };
    window.abtasty.send('transaction', transaction);

    cart.items.forEach((i) => {
      const item = {
        tid: cart.orderNumber,
        in: i.itemNumber,
        ip: i.yourPrice,
        iq: i.quantity,
        ic: i.itemNumber,
        iv: 'Product',
      };
      window.abtasty.send('item', item);
    });
  }

  getCoupon(cart: ICart) {
    if (cart.exclusiveMarketingCode !== '') {
      return cart.exclusiveMarketingCode;
    }
    const intRegex = new RegExp(/^\s*INT\s*/);
    const item = cart.items.find((i) => !intRegex.test(i.marketingCode));
    return item ? item.marketingCode.trim() : '';
  }
}
