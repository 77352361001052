import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auto-ship-subscription-info',
  templateUrl: './auto-ship-subscription-info.component.html',
  styleUrls: ['./auto-ship-subscription-info.component.scss']
})
export class AutoShipSubscriptionInfoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
