import { Component, OnInit } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { ICart } from 'src/app/cart-common/model';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { CartCookieService } from 'src/app/shared/service/cart-cookie-service.service';
import { v4 as uuidv4 } from 'uuid';
import { Constants } from '../../model/constants';
import { Activity, Visit, Visitor, VisitorCookie } from '../../model/visitor.model';
import { TrackingService } from '../../service/tracking.service';

@Component({
  selector: 'lef-lifeextension-tracker',
  templateUrl: './lifeextension-tracker.component.html',
  styleUrls: ['./lifeextension-tracker.component.scss'],
})
export class LifeExtensionTrackerComponent implements OnInit {
  constructor(
    private readonly tracking: TrackingService,
    private readonly cartCookieService: CartCookieService,
    private readonly config: CoreConfiguration
  ) {}

  ngOnInit(): void {
    if (this.config.environment.lifeExtensionTracking.enabled && this.tracking.checkOneTrustActiveGroup(this.config.oneTrustGroups.mandatory)) {
      this.tracking.navigationChanged.subscribe({ next: (event) => this.onNavigationChanged(event) });
      this.tracking.orderPlaced.subscribe({ next: (cart) => this.onOrderPlaced(cart) });
      this.tracking.customerRegistration.subscribe({ next: () => this.onCustomerRegistration() });
    }
  }

  onCustomerRegistration(): void {
    this.registerActivity('customer registration', {});
  }

  onOrderPlaced(cart: ICart): void {
    this.registerActivity('order placed', {
      orderNumber: cart.orderNumber,
      newCustomer: this.tracking.storage.get(Constants.CustomerRecurrence) === 'New' ? 'y' : 'n',
    });
  }

  onNavigationChanged(event: NavigationEnd): void {
    this.registerActivity('cart page view', { url: event.urlAfterRedirects });
  }

  private checkLeVisitor(alwaysTrack: boolean): Visitor {
    const visitorCookie = this.cartCookieService.getCookieObject(this.config.cookies.visitor) as VisitorCookie;
    const customerCookie = this.cartCookieService.getCookieObject();
    const customerNumber =
      customerCookie[this.config.cartCookieKeys.customerNumber] || this.tracking.storage.get(Constants.CustomerNumber) || '0';
    const now = new Date().getTime();

    if (!visitorCookie.LEV_VSTR /*|| (visitorCookie.LEV_C !== '0' && customerNumber !== visitorCookie.LEV_C)*/) {
      visitorCookie.LEV_VSTR = uuidv4();
      visitorCookie.LEV_V = uuidv4();
      visitorCookie.LEV_T = 'N';
    } else {
      const lastCheckedDate = new Date(parseInt(visitorCookie.LEV_D, 10)).getTime();
      // More than twenty minutes?
      if (now - lastCheckedDate > 20 * 60000) {
        visitorCookie.LEV_V = uuidv4();
        visitorCookie.LEV_T = 'N';
      }
    }

    const newVisit = visitorCookie.LEV_T !== 'Y';
    visitorCookie.LEV_C = customerNumber;

    // Update the cookie if the caller wants to track or if this is a new visit.
    if (alwaysTrack || newVisit) {
      // Update the time on each check.
      visitorCookie.LEV_D = now.toString();
      // Save the cookie.
      this.cartCookieService.setCookieFromObject(this.config.cookies.visitor, visitorCookie, 3650);
    }
    return new Visitor(visitorCookie, newVisit);
  }

  private registerActivity(activityType, activityData): void {
    const visitor = this.checkLeVisitor(true);

    // If this visit just started, let's make sure it's tracked.
    if (visitor.newVisit) {
      const visit = new Visit();
      visit.visitId = visitor.visitorCookie.LEV_V;
      visit.visitorId = visitor.visitorCookie.LEV_VSTR;
      visit.customerNumber = visitor.visitorCookie.LEV_C;
      visit.visitStart = new Date().toISOString();
      visit.referrer = document.referrer;
      visit.firstPage = location.href;
      visit.initialQueryString = location.search;
      this.tracking.registerVisit(visit).subscribe({
        next: () => {
          const visitorCookie = this.cartCookieService.getCookieObject(this.config.cookies.visitor) as VisitorCookie;
          visitorCookie.LEV_T = 'Y';
          this.cartCookieService.setCookieFromObject(this.config.cookies.visitor, visitorCookie, 3650);
        },
      });
    }

    // Track all activity.
    const activity = new Activity();
    activity.visitId = visitor.visitorCookie.LEV_V;
    activity.customerNumber = visitor.visitorCookie.LEV_C;
    activity.activityDate = new Date().toISOString();
    activity.activityType = activityType;
    activity.activityData = JSON.stringify(activityData);

    this.tracking.registerActivity(activity).subscribe();
  }
}
