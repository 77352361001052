<lef-cart-messages [messages]="creditCardMessages"></lef-cart-messages>
<div class="payment-methods">
    <img src="assets/images/payment-method-VISA.png" alt="Visa" />
    <img src="assets/images/payment-method-DISC.png" alt="Discover" />
    <img src="assets/images/payment-method-MSTC.png" alt="Mastercard" />
    <img src="assets/images/payment-method-AMEX.png" alt="American Express" />
</div>
<div class="co-payment-methods">
    <div class="d-md-flex mb-2 mb-md-3 co-cardnum-field">
        <label for="cc-number" class="hosted-field--label font-md" id="creditCardNumber">Credit Card Number:</label>
        <div>
            <div class="form-control mb-0 mr-3" id="cc-number" aria-labelledby="creditCardNumber"></div>
            <div class="invalid-feedback" role="alert">
                Not Valid Credit card number
            </div>
        </div>
    </div>
    <div class="d-flex mb-3 cc-exp-wrap">
        <div class="d-md-flex mr-3">
            <label for="cc-expiration" class="hosted-field--label font-md" id="expField">Expiration:</label>
            <div>
                <div class="form-control mb-0" id="cc-expiration" aria-labelledby="expField">
                </div>
                <div class="invalid-feedback" role="alert">
                    Not Valid Expiration date
                </div>
            </div>
        </div>
        <div class="d-md-flex">
            <label for="cc-cvv" class="hosted-field--label font-md" id="cvvField">CVV:</label>
            <div>
                <div class="form-control mb-0" id="cc-cvv" aria-labelledby="cvvField">
                </div>
                <div class="invalid-feedback" role="alert">
                    Not Valid Security
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="gateway.usePostalCodeInCreditCardValidation" class="mb-3 cc-zip-code-wrap">
        <div class="d-flex mb-2 mb-md-0 co-zip-code">
            <div class="d-flex align-items-center">
                <div class="cc-radio-wrap">
                    <input type="radio" name="billingType" value="local" [(ngModel)]="billingType" kendoRadioButton
                           tabindex="0" />
                </div>
                <label for="cc-postal-code" class="hosted-field--label font-md" id="zipCode">Zip Code:</label>
            </div>
            <div>
                <div class="form-control mb-0" id="cc-postal-code-container">
                    <input id="cc-postal-code" placeholder="*****" [disabled]="billingType !== 'local'"
                           [(ngModel)]="postalCode" autocomplete="postal-code" />
                </div>
                <div class="invalid-feedback" role="alert">
                    Invalid Postal Code
                </div>
            </div>
        </div>
        <div class="co-billing-outside">
            <div class="d-flex align-items-center">
                <div>
                    <div id="billing-type-container" class="cc-radio-wrap" aria-labelledby="billingOut">
                        <input id="cc-international-billing" type="radio" name="billingType" value="international"
                               tabindex="0" [(ngModel)]="billingType" kendoRadioButton />
                    </div>
                    <div class="invalid-feedback" role="alert">
                        Not Valid Selection
                    </div>
                </div>
                <label for="cc-international-billing" class="hosted-field--label font-md" id="billingOut">Billing
                    outside of US</label>
            </div>
        </div>
    </div>
    <div class="co-payment-methods-action text-right" *ngIf="useInstantVerification">
        <button [disabled]="isNetworkRequestInProgress" type="button" class="btn btn-warning px-4"
                (click)="verifyCreditCard()">Apply</button>
    </div>
</div>