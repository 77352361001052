import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { forkJoin } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { CommonDialogService } from 'src/app/common/service/common-dialog.service';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { AppValidators } from 'src/app/shared/form/form-validations';
import { FormlyHelper } from 'src/app/shared/form/formly-helper';
import { IListItem } from 'src/app/shared/model/list-item';
import { CartDialogService } from 'src/app/shared/service/cart-dialog.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { IShippingAddress } from '../../model';
import { ShippingService } from '../../service/shipping.service';

@Component({
  selector: 'lef-shipping-address',
  templateUrl: './shipping-address.component.html',
  styleUrls: ['./shipping-address.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ShippingAddressComponent implements OnInit {
  @Input() shipToNumber = 0;
  @Input() isGuestCheckout = false;
  @Input() componentClass: string;

  @Output() closeDialog: EventEmitter<IShippingAddress> = new EventEmitter();

  salutationList: IListItem[] = [];
  countries: IListItem[] = [];
  states: IListItem[] = [];
  aboutUsOptions: IListItem[] = [];

  isDefault = false;
  model: IShippingAddress = {};

  form: UntypedFormGroup = new UntypedFormGroup({});
  formFields: FormlyFieldConfig[] = [];
  formLoading = false;

  actionExecuting = false;

  get isEditionEnabled(): boolean {
    return this.isGuestCheckout || this.model.isEditableOnWeb;
  }

  constructor(
    private readonly shippingService: ShippingService,
    private readonly cartDialogService: CartDialogService,
    private readonly commonService: CommonService,
    private readonly commonDialogService: CommonDialogService,
    private readonly loadingService: LoadingService,
    public readonly config: CoreConfiguration,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.init();
  }

  private init() {
    this.formLoading = true;
    if (!this.model) {
      this.model = {};
    }

    const shippingAddressSubscription = this.isGuestCheckout
      ? this.shippingService.getGuestShippingDestination()
      : this.shippingService.getShippingAddress(this.shipToNumber);

    forkJoin([
      this.commonService.getSalutationList(),
      this.commonService.getCountryList(),
      this.commonService.getStateList(''),
      this.commonService.getAboutUsList(),
      shippingAddressSubscription,
    ])
      .pipe(
        map(([salutationList, countries, states, aboutUs, shippingAddress]) => {
          return { salutationList, countries, states, aboutUs, shippingAddress };
        })
      )
      .subscribe((data) => {
        this.salutationList = data.salutationList;
        this.countries = data.countries;
        this.states = data.states;
        this.aboutUsOptions = data.aboutUs;
        this.model = data.shippingAddress;
        this.isDefault = data.shippingAddress.isDefault;

        if (this.model.countryCode === 'CA') {
          this.commonService.getStateList(this.model.countryCode).subscribe((states) => {
            this.states = states;

            this.setDefaultValues();
            this.initForm();
          });
        } else {
          this.setDefaultValues();
          this.initForm();
        }
      });
  }

  private onCountryChange(country) {
    if (this.form.get('state')) {
      this.form.get('state').setValue('');
    } else {
      this.model.state = null;
    }

    if (country === '' || country === 'CA') {
      FormlyHelper.getField('postalCode', this.formFields).templateOptions.required = true;
      this.commonService.getStateList(country).subscribe((states) => {
        this.states = states;
        FormlyHelper.getField('state', this.formFields).templateOptions.options = this.states;
      });
    } else {
      FormlyHelper.getField('postalCode', this.formFields).templateOptions.required = false;
    }
  }

  private initForm() {
    this.form = new UntypedFormGroup({});

    this.formFields = [
      {
        fieldGroupClassName: this.isEditionEnabled ? 'address-form' : 'address-form dis-address-form',
        hooks: {
          onInit: () => {
            if (!this.isEditionEnabled) {
              this.form.disable();
              this.changeDetectorRef.detectChanges();
            }
          },
        },
        fieldGroup: [
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                className: 'col-12',
                type: 'checkbox',
                key: 'isCompany',
                templateOptions: {
                  label: 'Company',
                },
              },
            ],
          },
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                className: 'col-6',
                type: 'kTextBox',
                key: 'alias',
                templateOptions: {
                  placeholder: 'Nickname*',
                  disabled: !this.model.canDelete,
                },
                validators: {
                  validation: [Validators.required, AppValidators.notNullOrWhitespace],
                },
                hideExpression: () => this.isGuestCheckout,
              },
              {
                className: 'col-6',
                type: 'kDropdown',
                key: 'salutation',
                templateOptions: {
                  placeholder: 'Salutation',
                  options: this.salutationList,
                  valueProp: 'value',
                  labelProp: 'description',
                  autocomplete: 'honorific-prefix'
                },
                hideExpression: (model: IShippingAddress) => model.isCompany,
              },
            ],
          },
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                className: 'col-6',
                type: 'kTextBox',
                key: 'firstName',
                templateOptions: {
                  placeholder: 'First Name*',
                  autocomplete: 'given-name'
                },
                validators: {
                  validation: [Validators.required, AppValidators.notNullOrWhitespace],
                },
                hideExpression: (model: IShippingAddress) => model.isCompany,
              },
              {
                className: 'col-6',
                type: 'kTextBox',
                key: 'lastName',
                templateOptions: {
                  placeholder: 'Last Name*',
                  autocomplete: 'family-name'
                },
                validators: {
                  validation: [Validators.required, AppValidators.notNullOrWhitespace],
                },
                hideExpression: (model: IShippingAddress) => model.isCompany,
              },
              {
                className: 'col-12',
                type: 'kTextBox',
                key: 'mailingName',
                templateOptions: {
                  placeholder: 'Company Name*',
                },
                validators: {
                  validation: [Validators.required, AppValidators.notNullOrWhitespace],
                },
                hideExpression: (model: IShippingAddress) => !model.isCompany,
              },
            ],
          },
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                className: 'col-12',
                type: 'checkbox',
                key: 'isPoBox',
                templateOptions: {
                  label: 'P.O. Box',
                },
              },
            ],
          },
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                className: 'col-12',
                type: 'kTextBox',
                key: 'address',
                templateOptions: {
                  placeholder: 'Street Address 1*',
                  autocomplete: 'address-line1'
                },
                validators: {
                  validation: [Validators.required],
                },
              },
              {
                className: 'col-12',
                type: 'kTextBox',
                key: 'address2',
                templateOptions: {
                  placeholder: 'Street Address 2',
                  autocomplete: 'address-line2'
                },
              },
            ],
          },
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                className: 'col-6',
                type: 'kTextBox',
                key: 'city',
                templateOptions: {
                  placeholder: 'City*',
                  autocomplete: 'city'
                },
                validators: {
                  validation: [Validators.required],
                },
              },
              {
                className: 'col-6',
                type: 'kDropdown',
                key: 'state',
                templateOptions: {
                  placeholder: 'State*',
                  options: this.states,
                  valueProp: 'value',
                  labelProp: 'description',
                  autocomplete: 'state'
                },
                hideExpression: (model: IShippingAddress) => model.countryCode !== '' && model.countryCode !== 'CA',
                validators: {
                  validation: [Validators.required],
                },
              },
            ],
          },
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                className: 'col-6',
                type: 'kTextBox',
                key: 'postalCode',
                templateOptions: {
                  placeholder: 'ZIP Code',
                  autocomplete: 'postal-code',
                  required: true,
                },
              },
              {
                className: 'col-6',
                type: 'kDropdown',
                key: 'countryCode',
                templateOptions: {
                  placeholder: 'Country',
                  options: this.countries,
                  valueProp: 'code',
                  labelProp: 'description',
                  autocomplete: 'country-name',
                  change: () => {
                    if (this.model.countryCode !== '') {
                      this.model.isSmsPhoneNumber = false;
                      this.model.isSmsTransactionalPhoneNumber = false;
                    }
                  },
                },
                hooks: {
                  onInit: (field) => {
                    return field.formControl.valueChanges.pipe(debounceTime(400)).subscribe((country) => this.onCountryChange(country));
                  },
                },
              },
            ],
          },
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                className: 'col-6',
                type: 'kTextBox',
                key: 'phoneNumber',
                templateOptions: {
                  placeholder: 'Phone',
                  autocomplete: 'tel',
                  type: 'tel'
                },
                expressionProperties: {
                  'templateOptions.required': () =>
                    (this.model.isSmsTransactionalPhoneNumber || this.model.isSmsPhoneNumber) && this.model.countryCode === '',
                },
              },
              {
                className: 'col-6',
                type: 'checkbox',
                key: 'isDefault',
                templateOptions: {
                  label: 'Default Address',
                  disabled: this.isDefault,
                },
                hideExpression: () => this.isGuestCheckout,
              },
            ],
          },
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                className: 'col-12',
                type: 'checkbox',
                key: 'isSmsTransactionalPhoneNumber',
                templateOptions: {
                  label: 'I want to receive text updates about the status of my order(s).',
                },
                hideExpression: () =>
                  this.model.countryCode !== '' || !this.model.showSmsTransactionalPhoneNumber || !this.model.isPhoneSubscriptionEnabled,
              },
            ],
          },
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                className: 'col-12',
                type: 'checkbox',
                key: 'isSmsPhoneNumber',
                templateOptions: {
                  label: 'Text me about deals and promotions.',
                },
                hideExpression: () =>
                  this.model.countryCode !== '' || !this.model.showSmsPhoneNumber || !this.model.isPhoneSubscriptionEnabled,
              },
            ],
          },
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                className: 'col-12',
                template: `<p class="font-sm text-left pt-2">By checking the appropriate boxes above, you are agreeing to receive reoccurring automated transactional and/or promotional text messages from Life Extension at the mobile number provided. Consent is not a condition of purchase. Reply STOP to unsubscribe and HELP for help. Msg frequency varies. Msg & data rates may apply. <a href="https://www.lifeextension.com/legal/legal-notices-01" target=”_blank” class="text-primary">Terms</a> and <a href="https://www.lifeextension.com/legal/privacy-notice" target=”_blank” class="text-primary">Privacy Policy apply.</a></p>`,
                hideExpression: () =>
                  this.model.countryCode !== '' || (!this.model.showSmsPhoneNumber && !this.model.showSmsTransactionalPhoneNumber),
              },
            ],
          },
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                className: 'col-12',
                type: 'kDropdown',
                key: 'aboutUs',
                templateOptions: {
                  placeholder: 'How did you hear about us?*',
                  options: this.aboutUsOptions,
                  valueProp: 'value',
                  labelProp: 'description'
                },
                hideExpression: (model: IShippingAddress) => !this.isGuestCheckout || !model.showAboutUsDropDown,
                validators: {
                  validation: [Validators.required],
                },
              },
            ],
          },
        ],
      },
    ];

    this.formLoading = false;
  }

  submitForm() {
    if (!this.form.valid || !this.model) {
      return;
    }
    this.save();
  }

  saveAsEnter() {
    this.model.addressVerified = true;
    this.model.addressValidation.addressSelected = '';
    this.save();
  }

  private save() {
    this.showLoading(true);

    if (this.isGuestCheckout) {
      this.saveGuestShippingDestination();
    } else {
      this.saveShippingAddress();
    }
  }

  private saveGuestShippingDestination() {
    this.shippingService.saveGuestShippingDestination(this.model).subscribe(
      (data) => {
        this.processResponse(data);
        this.showLoading(false);
      },
      () => {
        this.showLoading(false);
      }
    );
  }

  private saveShippingAddress() {
    this.shippingService.saveShippingAddress(this.model).subscribe(
      (data) => {
        this.processResponse(data);
        this.showLoading(false);
      },
      () => {
        this.showLoading(false);
      }
    );
  }

  private processResponse(shippingAddress: IShippingAddress) {
    this.model = shippingAddress;

    if (this.model.isServiceByDistributor) {
      this.showDistributorDialog(shippingAddress);
      return;
    }

    if (this.model.showAddressValidation) {
      this.showAddressValidationDialog();
      return;
    }

    if (this.model.hasErrorInAddress) {
      // Show Error Message
      return;
    }

    if (this.model.isValid) {
      this.closeDialog.emit(this.model);
    }
  }

  private showDistributorDialog(shippingAddress: IShippingAddress) {
    this.commonDialogService.openDistributorCountryVerificationDialog({
      showDialog: shippingAddress.isServiceByDistributor,
      message: shippingAddress.distributorRegionMessage,
      distributor: shippingAddress.distributorName,
      distributorSiteUrl: shippingAddress.distributorSiteUrl,
      region: shippingAddress.distributorRegion,
    });
  }

  private showAddressValidationDialog() {
    this.cartDialogService.showAddressValidationDialog(this.model.addressValidation, (confirm) => {
      if (confirm) {
        this.model.addressVerified = true;
        this.save();
      }
    });
  }

  private setDefaultValues() {
    if (this.model.aboutUs === '') {
      this.model.aboutUs = null;
    }

    if (this.model.state === '') {
      this.model.state = null;
    }
  }

  private showLoading(show: boolean) {
    if (show) {
      this.actionExecuting = true;
      this.loadingService.show();
    } else {
      this.actionExecuting = false;
      this.loadingService.hide();
    }
  }
}
