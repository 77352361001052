<lef-section-title [title]="headerText"></lef-section-title>
<div *ngIf="!loading">
  <div *ngIf="searchEnable" class="mb-2 p-2">
    <div class="cart-box">
      <p>{{ searchMessage }}</p>
      <div class="d-flex justify-content-between mx-5">
        <input type="text" class="form-control mr-3" [(ngModel)]="searchText"
               placeholder="Ship To Number / Last Name / Nickname" />
        <button type="button" class="btn btn-action" [disabled]="!this.searchText || this.searchText.length < 2"
                (click)="search()">Search</button>
      </div>
    </div>
  </div>

  <div class="text-center text-md-right">
    <button class="btn btn-link" aria-haspopup="dialog" (click)="addAddress()"><i class="lef-plus mr-1"></i>Add New
      Address</button>
  </div>
  <div class="cart-grid-tb bg-light">
    <div class="cart-grid-tb-hdr">
      <ul>
        <li>Nickname</li>
        <li>Name</li>
        <li>Address</li>
        <li>Details</li>
        <li>Actions</li>
      </ul>
    </div>
    <div [ngClass]="{'cart-grid-message': messages.length }">
      <lef-cart-messages [messages]="messages"></lef-cart-messages>
    </div>
    <div class="cart-grid-tb-content">
      <div *ngFor='let address of shippingAddresses'>
        <ul>
          <li>
            <strong class="d-md-none mr-2">Nickname:</strong>
            <span>{{address.alias}}</span>
          </li>
          <li>
            <strong class="d-md-none mr-2">Name:</strong>
            <span>{{address.isCompany? address.mailingName: address.firstName +' '+ address.lastName}}</span>
          </li>
          <li>
            <strong class="d-md-none mr-2">Address:</strong>
            <span>{{getFullAddress(address)}}</span>
          </li>
          <li>
            <strong class="d-md-none mr-2">Details:</strong>
            <span *ngIf="address.isDefault">Default</span>
            <span *ngIf="address.isDefault && address.hasAutoShipItems"> | </span>
            <span *ngIf="address.hasAutoShipItems">AutoShip</span>
          </li>
          <li>
            <span>
              <button (click)="editAddress(address)" class="btn btn-link" aria-haspopup="dialog">
                {{ address.isEditableOnWeb? "Edit": "View"}}
              </button>
            </span>
            <span *ngIf="!address.isBilling"> |
              <button (click)="deleteAddress(address)" class="btn btn-link" aria-haspopup="dialog">Delete</button>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>