import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { ILabSubject } from 'src/app/blood-lab/model';
import { ResponsiveService, ScreenSizes } from 'src/app/common/service/responsive.service';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { ItemConfigurationService } from '../service/item-configuration.service';
import { ICartItem } from './cart-item';
import { ICartItemConfig } from './cart-item-config';

export interface IItemComponent {
  cartItemConfig: ICartItemConfig;
  item: ICartItem;
  labSubjects: ILabSubject[];
  currentAddressHasAutoShip: boolean;
  wrapperClass: string;
  updateCart: EventEmitter<any>;
  removeItem: EventEmitter<ICartItem>;
  autoShipUpdated: EventEmitter<ICartItem>;
  extenderUpdated: EventEmitter<ICartItem>;
  addToWishListItem: EventEmitter<ICartItem>;
}

@Component({
  selector: 'lef-base-item',
  template: '<div></div>',
})
export class ItemComponent implements OnInit, OnChanges, OnDestroy, IItemComponent {
  @Input() cartItemConfig: ICartItemConfig = {};
  @Input() item: ICartItem;
  @Input() labSubjects: ILabSubject[] = [];
  @Input() currentAddressHasAutoShip = false;
  @Input() wrapperClass: string;

  @Output() updateCart: EventEmitter<any> = new EventEmitter();
  @Output() removeItem: EventEmitter<ICartItem> = new EventEmitter();
  @Output() autoShipUpdated: EventEmitter<ICartItem> = new EventEmitter();
  @Output() extenderUpdated: EventEmitter<ICartItem> = new EventEmitter();
  @Output() addToWishListItem: EventEmitter<ICartItem> = new EventEmitter();

  screenSize: ScreenSizes;
  screenSizes = ScreenSizes;
  defaultImage = 'assets/images/ph-sm.png';

  get itemUrl(): string {
    return this.itemConfig.getItemUrl(this.item);
  }

  get itemImageUrl(): string {
    return this.item && this.item.itemNumber
      ? this.baseConfig.environment.itemUrlTemplates.itemImageUrl.replace('{itemNumber}', this.item.itemNumber)
      : '';
  }

  get purchaseOptionAutoShipId(): string {
    return this.item
      ? `IsAutoShip-${this.item.itemNumber}-True`
      : '';
  }

  get purchaseOptionNonAutoShipId(): string {
    return this.item
      ? `IsAutoShip-${this.item.itemNumber}-False`
      : '';
  }

  
  private responsiveSubscription: Subscription;

  constructor(
    private readonly baseConfig: CoreConfiguration,
    private readonly itemConfig: ItemConfigurationService,
    private readonly responsiveService: ResponsiveService
  ) {}

  ngOnInit() {
    this.responsiveSubscription = this.responsiveService.onResize$.subscribe((screenSize: ScreenSizes) => {
      this.screenSize = screenSize;
    });
    this.responsiveService.checkCurrentSize();
  }

  ngOnChanges(changes: SimpleChanges) {}

  ngOnDestroy(): void {
    if (this.responsiveSubscription) {
      this.responsiveSubscription.unsubscribe();
    }
  }

  remove() {
    this.removeItem.emit(this.item);
  }

  onQuantityUpdate(newQuantity) {
    if (!newQuantity || +newQuantity === this.item.quantity) {
      return;
    }

    this.item.quantity = newQuantity;
    this.updateCart.emit();
  }

  addToWishList() {
    this.addToWishListItem.emit(this.item);
  }
}
