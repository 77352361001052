import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PremierService } from 'src/app/cart-common/service';
import { IShippingMethod } from '../../model/shipping-method';

@Component({
  selector: 'lef-shipping-method-selection',
  templateUrl: './shipping-method-selection.component.html',
  styleUrls: ['./shipping-method-selection.component.scss'],
})
export class ShippingMethodSelectionComponent implements OnInit {
  @Input() shippingMethods: IShippingMethod[] = [];
  @Input() selectedMethod: string;
  @Input() componentClass: string;
  @Input() showPremierDiscount = false;

  @Output()
  methodSelected: EventEmitter<string> = new EventEmitter();

  constructor(public readonly premierService: PremierService) {}

  ngOnInit(): void {}

  onMethodSelected(): void {
    this.methodSelected.emit(this.selectedMethod);
  }
}
