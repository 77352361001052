<div class="d-flex cart-item-wrapper" [ngClass]='wrapperClass'>
  <div *ngIf="cartItemConfig.showImage && screenSize > screenSizes.MD" class="cart-item-image-wrapper">
    <a [attr.href]="itemUrl">
      <img class="cart-item-image" alt="Product Image" [defaultImage]="defaultImage" [errorImage]="defaultImage"
           [lazyLoad]="itemImageUrl" />
    </a>
  </div>

  <div class="cart-item-content-wrapper">
    <div class="cart-item-content">
      <div class="d-flex">
        <div *ngIf="cartItemConfig.showImage && screenSize < screenSizes.LG" class="cart-item-image-wrapper">
          <a [attr.href]="itemUrl">
            <img class="cart-item-image-sm" alt="Product Image" [defaultImage]="defaultImage"
                 [errorImage]="defaultImage" [lazyLoad]="itemImageUrl" />
          </a>
        </div>
        <div class="w-100">
          <p class="cart-item-title"><a [attr.href]="itemUrl">{{item.itemDescription}}</a></p>
          <p class="cart-item-number">
            <span *ngIf="item.nameSuffix">{{item.nameSuffix}} | </span>
            <span>#{{item.itemNumber}} | <b>{{item.yourPrice | currency}}</b></span>
          </p>
          <p class="cart-item-component" *ngFor="let component of item.components">{{component.longDescription}}</p>

          <div class="cart-item-actions-wrap">
            <div class="d-md-flex align-items-baseline">
              <div class="cart-item-qty">
                <lef-quantity-input [label]="'Quantity:'" [quantity]="item.quantity"
                                    [componentId]="'selectQtycccc-'+item.itemNumber"
                                    [canChangeQuantity]="cartItemConfig.isEditable && item.canChangeQuantity"
                                    (quantityUpdate)="onQuantityUpdate($event)">
                </lef-quantity-input>
              </div>

              <ul *ngIf="cartItemConfig.isEditable && !item.isSystemControlled" class="cart-item-actions ml-md-2">
                <li>
                  <button (click)="remove()" class="btn btn-link">Remove</button>
                </li>
                <li *ngIf="cartItemConfig.showActions && item.itemIsInFavorites">Added to Wish List</li>
                <li
                    *ngIf="cartItemConfig.showActions && !item.itemIsInFavorites && item.itemType !=='M' && item.itemType !=='X'">
                  <button (click)="addToWishList()" class="btn btn-link"
                          [attr.role]="authorizationService.isLoggedIn() ? null: 'link'">Add to Wish List</button>
                </li>
              </ul>
            </div>
            <div>
              <div class="text-red text-right ml-lg-1" [innerHTML]="item.discountMessage"></div>
              <lef-cart-item-price *ngIf="!cartItemConfig.showPurchaseOptions" [extendedPrice]="item.extendedPrice"
                                   [totalBaseStandardPrice]="item.totalBaseStandardPrice"
                                   [percentSavings]="item.percentSavings" [isSuperSale]="item.isSuperSale"
                                   [isExcludedFromPromotion]="item.isExcludedFromPromotion"
                                   [excludedFromPromotionMessage]="item.excludedFromPromotionMessage">
              </lef-cart-item-price>
            </div>
          </div>
          <div *ngIf="cartItemConfig.showPurchaseOptions else renewMessage"
               class="p-3 mb-2 premier-subscription-wrapper d-flex border border-grey">
            <div class="row">
              <div class="col-auto premier-subscription-options">
                <label class="cart-radio-opt mr-3">
                  <span class="pr-1">Yes</span>
                  <input type="radio" [attr.name]="'isAutoShip' + item.itemNumber" [value]="true"
                         [id]="purchaseOptionAutoShipId" [(ngModel)]="item.isAutoShip"
                         (ngModelChange)="updateAutoShip(purchaseOptionAutoShipId)" kendoRadioButton />
                </label>
                <label class="cart-radio-opt">
                  <span class="pr-1">No</span>
                  <input type="radio" [attr.name]="'isAutoShip' + item.itemNumber" [value]="false"
                         [id]="purchaseOptionNonAutoShipId" [(ngModel)]="item.isAutoShip"
                         (ngModelChange)="updateAutoShip(purchaseOptionNonAutoShipId)" kendoRadioButton />
                </label>
              </div>
              <div class="col">
                <p>
                  <strong class="text-primary">Auto-Renew Premier</strong>
                </p>
                <p *ngIf="item.isAutoShip; else noRenew" class="m-0">
                  Your Premier Reward renews automatically every year on {{renewalDate}},
                  You'll get $50 LE Dollars instantly and a $10 LE Dollar bonus with each renewal, plus access to
                  unlimited FREE shipping, exclusive sales and premium content. Cancel at any time! <button
                          (click)="showAutoShipInfo($event, autoShipInfo)" class="btn-link-txt d-inline"><span>Learn
                      more<i class="lef-information-solid text-info ml-1" tabindex="0"></i></span></button></p>

                <ng-template #noRenew>
                  <p class="m-0">
                    Your Premier Rewards will expire on {{renewalDate}} and will NOT renew automatically.<button
                            (click)="showAutoShipInfo($event, autoShipInfo)" class="btn-link-txt d-inline"><span>Learn
                        more<i class="lef-information-solid text-info ml-1" tabindex="0"></i></span></button></p>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <lef-cart-messages [messages]="item.itemMessages"></lef-cart-messages>
      </div>
    </div>
    <div *ngIf="cartItemConfig.showPurchaseOptions" class="cart-item-purchase-block">
      <div class="cart-item-one-purchase-wrapper" class="cart-item-active-purchase-type">
        <div class="flex-container cart-purchase-opt">
          <div class="cart-item-one-purchase">
            <label class="cart-radio-opt">
              <!-- One Time Purchase -->
            </label>
          </div>
          <div>
            <lef-cart-item-price [extendedPrice]="item.extendedPrice"
                                 [totalBaseStandardPrice]="item.totalBaseStandardPrice"
                                 [percentSavings]="item.percentSavings" [isSuperSale]="item.isSuperSale"
                                 [isExcludedFromPromotion]="item.isExcludedFromPromotion"
                                 [excludedFromPromotionMessage]="item.excludedFromPromotionMessage">
            </lef-cart-item-price>
          </div>
        </div>

        <lef-cart-item-messages [messages]="item.messages"></lef-cart-item-messages>
      </div>
    </div>
  </div>
</div>

<ng-template #autoShipInfo>
  <div class="autoship-info">
    <p class="mb-3">
      Enjoy uninterrupted benefits (4% back, free shipping, exclusive savings and Premier-only content),
      plus $60 LE Dollars upon enrollment. Renews every year. Cancel anytime.<strong>1 LE Dollar = $1</strong>
    </p>
  </div>
</ng-template>

<ng-template #renewMessage>
  <p *ngIf="item.isAutoShip" class="text-red">
    Your Premier renews automatically every year on {{renewalDate}}.
  </p>
</ng-template>