import { Component, HostBinding } from '@angular/core';
import { AuthorizationService } from 'src/app/authorization';
import { ResponsiveService } from 'src/app/common/service/responsive.service';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { ItemComponent } from '../../model';
import { ItemConfigurationService } from '../../service/item-configuration.service';

@Component({
  selector: 'lef-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss', '../cart-item-host/cart-item-host.component.scss'],
})
export class CartItemComponent extends ItemComponent {
  constructor(
    readonly config: CoreConfiguration,
    readonly service: ResponsiveService,
    readonly itemConfigService: ItemConfigurationService,
    readonly authorizationService: AuthorizationService
  ) {
    super(config, itemConfigService, service);
  }

  get isWholesaler(): boolean {
    return this.authorizationService?.userCustomerType()?.toLowerCase() === 'w'
  }

  @HostBinding('class') hostClass = 'cart-item';
}
