import { Component, Input } from '@angular/core';

@Component({
  selector: 'lef-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {

  @Input()
  text = '';

  get displayText(): string {
    return this.text ? this.text : '';
  }

}
