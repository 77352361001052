import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { RegionVerification } from '../../model/region-verification';
import { RegionService } from '../../service/region.service';

@Component({
  selector: 'lef-distributor-location-notice',
  templateUrl: './distributor-location-notice.component.html',
  styleUrls: ['./distributor-location-notice.component.scss'],
})
export class DistributorLocationNoticeComponent implements OnInit {
  @Input()
  verification: RegionVerification;

  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter();

  cookieStatement: string;
  distributor: string;

  constructor(private readonly config: CoreConfiguration, private readonly regionService: RegionService) {}

  ngOnInit() {
    this.cookieStatement = this.config.environment.contentSite + '/legal/cookies-statement';
  }

  stay() {
    this.regionService.saveRegionVerificationCustomerAnswer('LE').subscribe(() => {
      this.closeDialog.emit();
    });
  }

  goToDistributorSite(url: string) {
    this.regionService.saveRegionVerificationCustomerAnswer(this.verification.region).subscribe(() => {
      window.location.href = url;
      this.closeDialog.emit();
    });
  }
}
