import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { environment as env } from '../../environments/environment';
import { CartCookieService } from '../shared/service/cart-cookie-service.service';
import { authorizationConfigFactory } from './factory/authorization-config.factory';
import { AuthorizationConfigModel, AuthorizationRootConfigModel } from './model';

export const currentEnvironment = env;

@NgModule({
  declarations: [],
  imports: [HttpClientModule],
  providers: [CartCookieService],
})
export class AuthorizationModule {
  public static forRoot(rootConfig: AuthorizationRootConfigModel): ModuleWithProviders<AuthorizationModule> {
    return {
      ngModule: AuthorizationModule,
      providers: [
        {
          provide: AuthorizationRootConfigModel,
          useValue: rootConfig,
        },
        {
          deps: [AuthorizationRootConfigModel],
          provide: AuthorizationConfigModel,
          useFactory: authorizationConfigFactory,
        },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: AuthorizationModule) {
    if (parentModule) {
      throw new Error('Authorization Module is already loaded. Import it in the AppModule/CoreModule only.');
    }
  }
}
