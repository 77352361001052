import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ABTastyComponent } from './component/a-b-tasty/a-b-tasty.component';
import { BingConversionComponent } from './component/bing-conversion/bing-conversion.component';
import { CJComponent } from './component/cj/cj.component';
import { ConnexityConversionComponent } from './component/connexity-conversion/connexity-conversion.component';
import { CriteoComponent } from './component/criteo/criteo.component';
import { FacebookPixelComponent } from './component/facebook-pixel/facebook-pixel.component';
import { GlobalSiteTagComponent } from './component/global-site-tag/global-site-tag.component';
import { GrinComponent } from './component/grin/grin.component';
import { LifeExtensionTrackerComponent } from './component/lifeextension-tracker/lifeextension-tracker.component';
import { NortonShoppingGuaranteeComponent } from './component/norton-shopping-guarantee/norton-shopping-guarantee.component';
import { PinterestComponent } from './component/pinterest/pinterest.component';
import { PowerReviewsComponent } from './component/power-reviews/power-reviews.component';
import { SelligentComponent } from './component/selligent/selligent.component';
import { TrackingComponent } from './component/tracking/tracking.component';
import { YahooGeminiConversionComponent } from './component/yahoo-gemini-conversion/yahoo-gemini-conversion.component';

const components = [
  TrackingComponent,
  GlobalSiteTagComponent,
  SelligentComponent,
  CriteoComponent,
  CJComponent,
  LifeExtensionTrackerComponent,
  FacebookPixelComponent,
  PinterestComponent,
  PowerReviewsComponent,
  ConnexityConversionComponent,
  ABTastyComponent,
  NortonShoppingGuaranteeComponent,
  YahooGeminiConversionComponent,
  BingConversionComponent,
  GrinComponent,
];

@NgModule({
  declarations: [components],
  imports: [CommonModule],
  exports: components,
})
export class TrackingModule {}
