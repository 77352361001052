<div class="w-100 d-md-flex justify-content-md-between align-items-md-basline">
    <div class="co-cust-login">
        <div class="d-flex justify-content-between align-items-center d-md-block">
            <div class="d-flex align-items-center">
                <p class="co-step-wrap m-0">
                    <span class="wra-step-number">{{stepNumber}}</span><span class="wra-step-title">{{stepTitle}}</span>
                </p>
                <div *ngIf="isLoading" class="k-icon k-font-icon k-i-loading text-info font-2x"></div>
            </div>
            <button *ngIf="!disableChangeButton && showChangeButton" class="d-md-none btn btn-link m-0"
                    (click)="changeCredentials()">
                Change
            </button>
        </div>
        <div class="co-login-info">
            <lef-login (loginUpdate)="onLoginUpdate($event)" [reset]="resetSubject.asObservable()"
                       [isCheckoutFlow]="false" [isResellerFlow]="true">
            </lef-login>
        </div>
    </div>
    <div class="co-guest-actions co-cust-login-2">
        <p *ngIf="showSignInButton" class="co-guest-or">or</p>
        <div class="text-center">
            <button *ngIf="!disableChangeButton && showChangeButton" class="d-none d-md-block btn btn-link mr-3"
                    (click)="changeCredentials()">
                Change
            </button>
            <div><input type="button" *ngIf="showSignInButton" (click)="signIn()" value="Already registered? Sign in."
                       class="btn btn-warning mb-2 co-ar-btn" /></div>
        </div>
    </div>
</div>