<div *ngIf="username" class="nav-account-menu" #accountMenu>
  <div *ngIf="menuOnHeader">
    <a href="#" class="dropdown-toggle nav-account-menu-link hdr-nav-icon logged-in-icon" data-toggle="dropdown"
       aria-expanded="false">
      <div class="nav-account-menu-icon-wrap">
        <i class="lef-user-outline nav-account-menu-icon sidenav-icon-pos"></i>
        <i class="account-menu-arrow lef-chevron-down sidenav-icon-pos"></i>
      </div>
      <span>Account</span>
    </a>
    <div class="dropdown-menu">
      <ng-container *ngFor="let menuLink of menuLinks">
        <a *ngIf="!menuLink.external" [routerLink]="menuLink.route" (click)="handleLinkClick(menuLink)"
           class="dropdown-item">{{menuLink.linkText}}</a>
        <a *ngIf="menuLink.external" rel="nofollow" href="{{menuLink.route}}"
           class="dropdown-item external-menu-link">{{menuLink.linkText}}</a>
      </ng-container>
    </div>
  </div>

  <div *ngIf="!menuOnHeader">
    <div id="loggedInAccountMenu" class="dropdown side-nav-account-menu">
      <button (click)="toggleAccountMenu()" id="loggedInIcon" class="side-nav-account-menu-link logged-in-icon"
              aria-expanded="false" role="button">
        Hello, <span id="menuCustomerName" class="blue pl-1">{{username}}</span>
        <i id="accountMenuOpenCloseIcon" class="ml-2 lef-chevron-right"></i>
      </button>
      <div id="accountMenuPanel" class="side-nav-account-drop" style="display: none;">
        <ul>
          <li class="font-weight-bold font-md">Your Account</li>
          <ng-container *ngFor="let menuLink of menuLinks">
            <li class="account-menu-item">
              <a *ngIf="!menuLink.external" [routerLink]="menuLink.route" (click)="handleLinkClick(menuLink)"
                 class="dropdown-item">{{menuLink.linkText}}</a>
              <a *ngIf="menuLink.external" rel="nofollow" href="{{menuLink.route}}"
                 class="dropdown-item external-menu-link">{{menuLink.linkText}}</a>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</div>