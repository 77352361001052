<div class="container px-lg-0">
    <lef-section-title [title]="'Wholesale Reseller Application'"></lef-section-title>
    <div class="row position-relative">
        <div class="col-12 col-lg-8 checkout-pg">
            <div>
                <lef-reseller-customer [disableChangeButton]="disableChangeButton"
                                       (stepValidationChange)="onStepValidationChange($event)">
                </lef-reseller-customer>
            </div>
            <hr />
            <div>
                <lef-reseller-company-registration [applicationId]="applicationId"
                                                   [isActive]="companySectionStatus.isActive"
                                                   [isEditable]="companySectionStatus.isEditable"
                                                   [hasContent]="companySectionStatus.hasContent"
                                                   [isContentValid]="companySectionStatus.isContentValid"
                                                   (applicationProcessContinue)="onApplicationProcessContinue($event)"
                                                   (activateSection)="onActivateSection($event)">
                </lef-reseller-company-registration>
            </div>
            <hr />
            <div>
                <lef-reseller-business-information [applicationId]="applicationId"
                                                   [isActive]="businessSectionStatus.isActive"
                                                   [isEditable]="businessSectionStatus.isEditable"
                                                   [hasContent]="businessSectionStatus.hasContent"
                                                   [isContentValid]="businessSectionStatus.isContentValid"
                                                   (applicationProcessContinue)="onApplicationProcessContinue($event)"
                                                   (activateSection)="onActivateSection($event)">
                </lef-reseller-business-information>
            </div>
            <hr />
            <div>
                <lef-reseller-application-complete [applicationId]="applicationId"
                                                   [isActive]="completeApplicationStatus.isActive"
                                                   [isEditable]="completeApplicationStatus.isEditable"
                                                   [isApplicationValid]="completeApplicationStatus.isContentValid"
                                                   [isApplicationApproved]="workflowState == 'Approved'"
                                                   [hasContent]="completeApplicationStatus.hasContent"
                                                   (applicationProcessContinue)="onApplicationProcessContinue($event)"
                                                   (activateSection)="onActivateSection($event)">
                </lef-reseller-application-complete>
            </div>
        </div>
        <div class="col-lg-4 col-12">
            <div *ngIf="enableSidebar" class="save-app-section-wrap">
                <div class="save-app-section">
                    <ng-container *ngIf="workflowState == 'Declined'">
                        <div class="font-weight-bold">
                            We are sorry to inform you that we were not able to approve your application. Please review
                            the notice that was sent to you via email.
                        </div>
                    </ng-container>
                    <ng-container *ngIf="workflowState == 'Approved'">
                        <div class="font-weight-bold">
                            Great News! We’ve approved your application. Welcome to the Life Extension Family!
                        </div>
                    </ng-container>
                    <ng-container *ngIf="isWaitingFeedback">
                        <div class="font-weight-bold">
                            <p>Thank you for submitting your application to become a Life Extension Wholesale Reseller!
                                We look forward to partnering with you.</p>
                            <p>Please Visit <a routerLink="/account">Account Options</a> to view status.</p>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="isEditable">
                        <p *ngIf="!lastTicketResponse">
                            Be sure to hit "save" to save your progress.
                            Feel free to exit and complete your application at your convenience after saving.
                        </p>
                        <p *ngIf="lastTicketResponse" [innerHTML]="lastTicketResponse | trustHtml">
                        </p>
                    </ng-container>
                    <div *ngIf="enableSaveApplicationSection" class="text-right">
                        <button class="btn btn-warning" type="button" (click)="saveActiveSection()">
                            Save
                        </button>
                    </div>

                    <div *ngIf="enableResetApplication" class="text-right">
                        <button class="btn btn-warning" type="button" (click)="restartApplicationProcess()">
                            Reapply
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>