import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IListItem } from 'src/app/shared/model/list-item';
import { AutoShipValuesService } from '../../service';

@Component({
  selector: 'lef-auto-ship-date-selection',
  templateUrl: './auto-ship-date-selection.component.html',
  styleUrls: ['./auto-ship-date-selection.component.scss']
})
export class AutoShipDateSelectionComponent implements OnInit {

  @Input()
  shippingDay: number;

  @Output()
  selectionComplete: EventEmitter<number> = new EventEmitter();

  shippingDaysOptions: IListItem[];

  constructor(private readonly valuesService: AutoShipValuesService) {
  }

  ngOnInit() {
    this.shippingDaysOptions = this.valuesService.getShipDayOptions();
  }

  submit() {
    this.selectionComplete.emit(this.shippingDay);
  }
}
