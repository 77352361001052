<div class="reset-password">
  <div class="reset-password-form" *ngIf="this.showForm">
    <p>
      Please create a new Password. Remember that passwords are case-sensitive.
    </p>
    <form class="k-form" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
      <formly-form [form]="resetPasswordForm" [fields]="resetPasswordFormFields" [model]="resetPasswordModel">
      </formly-form>
      <div class="invalid-feedbackk" *ngIf="resetPasswordForm.hasError('passwordMismatch')" role="alert">
        <span>The passwords don't match</span>
      </div>
      <div class="float-right">
        <button type="submit" class="btn btn-warning" [disabled]="!resetPasswordForm.valid">
          Reset Password
        </button>
      </div>
    </form>
  </div>
  <div class="forgot-password-error Message" *ngIf="this.showMessage">
    <p>{{this.message}}</p>
    <div class="float-right">
      <button class="btn btn-warning" (click)="close()">
        Close
      </button>
    </div>
  </div>
</div>