<div class="address-verification">
  <div class="alert alert-danger" role="alert">
    <i class="fa fa-flag"></i>
    The street address you entered may not be accurate. Review and make corrections, use recommended address or use
    street address as entered.
  </div>

  <div class="radio">
    <label>
      <input kendoradiobutton id="RadioCnvAddress" class="k-radio" type="radio" name="Address" value="cnv"
             [(ngModel)]="addressValidation.addressSelected" />
      <span>
        <strong>Recommended Address</strong><br />
        {{getRecommendedAddress()}}
      </span>
    </label>
  </div>

  <div class="radio">
    <label>
      <input kendoradiobutton id="RadioOriginalAddress" class="k-radio" type="radio" name="Address" value="orig"
             [(ngModel)]="addressValidation.addressSelected" />
      <span>
        <strong>Use street address as entered</strong><br />
        {{getOriginalAddress()}}
      </span>
    </label>
  </div>
  <div class="row">
    <div class="col-6">
      <button type="button" class="btn btn btn-warning" (click)="confirm(true)">
        Continue
      </button>
    </div>
    <div class="col-6 text-right">
      <button type="button" class="btn btn btn-light" (click)="confirm(false)">
        Cancel
      </button>
    </div>
  </div>
</div>