<div>
  <h1>Shop Online with Life Extension</h1>
  <p>
    The web site has generated an error. This may have been caused by your browser's cookie settings.
    Please verify that your browser is enabled for cookies.
  </p>
  <span>
    We have received notification about this error. However,
    please feel free to send us a
    <a class="btn btn-link" [href]="config.environment.feedbackUrl" target="_blank">message</a>
    or contact customer service regarding this issue.
  </span>
  <br />
  <br />
  <span>Customer service is available at <strong>{{config.environment.customerServicePhone}}. </strong></span>
  <span></span>

  <a href="/" class="btn btn-info">Continue</a>
</div>