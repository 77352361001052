import { IPaymentGateway } from './payment-gateway';
import { BraintreePayPalPaymentProcessor, EmptyPayPalPaymentProcessor, IPayPalPaymentProcessor } from './payment-paypal-processor';

export class PayPalPaymentProcessorFactory {
  GetProcessor(
    gateway: IPaymentGateway
    // usingCheckoutFlow: boolean,
    // amount: number,
    // buttonId: string,
    // buttonType: string,
    // PayPalCheckoutService: PayPalCheckoutService
  ): IPayPalPaymentProcessor {
    switch (gateway.name) {
      case 'Braintree':
        return new BraintreePayPalPaymentProcessor(gateway);
      // PayPalCheckoutService.paypalCheckoutInstance,
      // usingCheckoutFlow,
      // amount,
      // buttonId,
      // buttonType
      default:
        return new EmptyPayPalPaymentProcessor();
    }
  }
}
