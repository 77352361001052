import { Component, HostBinding, TemplateRef } from '@angular/core';
import { AuthorizationService } from 'src/app/authorization';
import { AutoShipDialogService } from 'src/app/auto-ship/service/auto-ship-dialog.service';
import { ResponsiveService } from 'src/app/common/service/responsive.service';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { CartCookieService } from 'src/app/shared/service/cart-cookie-service.service';
import { HtmlUtilityService } from 'src/app/shared/service/html-utilitity.service';
import { ItemComponent } from '../../model';
import { ItemConfigurationService } from '../../service/item-configuration.service';

@Component({
  selector: 'lef-cart-item',
  templateUrl: './cart-item-premier.component.html',
  styleUrls: ['./cart-item-premier.component.scss', '../cart-item-host/cart-item-host.component.scss'],
})
export class CartItemPremierComponent extends ItemComponent {
  
  renewalDate: string  = '';

  constructor(
    readonly config: CoreConfiguration,
    readonly service: ResponsiveService,
    readonly itemConfigService: ItemConfigurationService,
    readonly authorizationService: AuthorizationService,
    private readonly dialogService: AutoShipDialogService,
    private readonly cartCookieService: CartCookieService,
    private readonly htmlUtilityService: HtmlUtilityService
  ) {
    super(config, itemConfigService, service);
  }

  @HostBinding('class') hostClass = 'cart-item';

  override ngOnInit() {
    super.ngOnInit();
    this.setPurchasingOptions();
    this.setRenewalDate();
  }

  private setPurchasingOptions(){
    if(!this.config.environment.enablePremierSubscription ||
       this.item?.itemNumber?.toLowerCase() != 'premierst' ) {
      this.cartItemConfig.showPurchaseOptions = false;
    }
  }

  private setRenewalDate() {
    let date = new Date();
    if(this.cartCookieService?.getCookieValue(this.config.cartCookieKeys.customerType)?.toLowerCase() === 'p' 
    && this.cartCookieService?.getCookieValue(this.config.cartCookieKeys.date)) {
      const cookieDate = new Date(this.cartCookieService.getCookieValue(this.config.cartCookieKeys.date));
      if(cookieDate > date){
        date = cookieDate;
      }
    }
    this.renewalDate = `${('0' + (date.getMonth()+1)).slice(-2)}/${('0' + date.getDate()).slice(-2)}`;
  }

  showAutoShipInfo($event: MouseEvent, autoShipInfo: TemplateRef<any>): void {
    this.dialogService.openSubscriptionInformationDialog();
    $event.preventDefault();
    $event.stopPropagation();
  }

  updateAutoShip(elementId: string = "") {
    if(elementId){
      this.htmlUtilityService.setFocusPoint(elementId);
    }
    this.autoShipUpdated.emit(this.item);
  }

}
