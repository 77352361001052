<div class="auto-deativate-item">
  <h4 class="roboto mb-3">{{ itemName }}</h4>
  <p class="text-red">
    <strong>Are you sure you want to deactivate this item from your subscription
      for shipment on the {{ shippingDay}}</strong>
  </p>
  <p>Item will be placed in the inactive list until you activate again.</p>
  <p>You can activate again at any time.</p>
  <p><span>You can also</span> <a (click)="reschedule.emit()" class="text-info p-0" tabindex="0"> Change Next Shipment
      Date</a></p>
  <div class="text-right">
    <button type="button" (click)="deactivate.emit()" class="btn btn-action">Yes, Deactivate</button>
  </div>
</div>