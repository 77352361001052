import { AfterViewInit, ChangeDetectionStrategy, Component, Renderer2, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';

@Component({
  selector: 'lef-form-text-box',
  template: `
    <kendo-textbox
      #kTextBox
      *ngIf="to"
      [formControl]="formControl"
      [placeholder]="to.placeholder"
      [formlyAttributes]="field"
      (valueChange)="to.change && to.change(field, $event)"
    ></kendo-textbox>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormTextBoxComponent extends FieldType<FieldTypeConfig> implements AfterViewInit {
  @ViewChild('kTextBox', { read: TextBoxComponent }) kTextBox: TextBoxComponent;

  constructor(private readonly renderer: Renderer2) {
    super();
  }

  ngAfterViewInit(): void {
    if (this.kTextBox) {
      (this.kTextBox.input.nativeElement as HTMLInputElement).type = this.props.type || 'text';
      if (!this.props.label) {
        this.renderer.setAttribute(this.kTextBox.input.nativeElement, 'aria-label', this.props.placeholder);
      }
      if (this.props.ariaLabelBy) {
        this.renderer.setAttribute(this.kTextBox.input.nativeElement, 'aria-labelledby', this.props.ariaLabelBy);
      }
      if (this.props.for) {
        this.renderer.setAttribute(this.kTextBox.input.nativeElement, 'for', this.props.for);
      }
      if (this.props.autocomplete) {
        this.renderer.setAttribute(this.kTextBox.input.nativeElement, 'autocomplete', this.props.autocomplete);
      }
      if (this.props.setFocus) {
        this.kTextBox.focus();
      }
    }
  }
}