import { BraintreeCreditCardProcessor, EmptyCreditCardProcessor, ICreditCardProcessor } from './payment-credit-card-processor';
import { IPaymentGateway } from './payment-gateway';

export class CreditCardProcessorFactory {
  GetGateway(gateway: IPaymentGateway): ICreditCardProcessor {
    switch (gateway.name) {
      case 'Braintree':
        return new BraintreeCreditCardProcessor(gateway.auth);
      default:
        return new EmptyCreditCardProcessor();
    }
  }
}
