<div class="auto-ship-add-modal">
  <div class="autoship-modal-header">
    <lef-auto-ship-ribbon-info [alias]='alias' [showAlias]='true' [shippingDay]='shippingDay' [showShippingDay]='true'
                               [shippingAddress]='shippingAddress' [showShippingAddress]='true'>
    </lef-auto-ship-ribbon-info>
  </div>

  <lef-cart-messages [messages]="addItemModel.messages"></lef-cart-messages>

  <div id="autoShipSearchInterface" class='CoveoSearchInterface' #autoShipSearchInterface>
    <div class='coveo-search-section'>
      <div class='CoveoSearchbox' data-enable-partial-match='true' data-enable-query-syntax='true'
           data-enable-question-marks='true' data-enable-wildcards='true' data-enable-search-as-you-type='true'
           data-search-as-you-type-delay='1000'>
      </div>
    </div>
    <div class="CoveoAnalytics" data-search-hub="Search" [attr.data-endpoint]="analyticsEndPoint"></div>
    <div class="coveo-main-section lef-search-results" style="min-width: 95%;">
      <div class="coveo-results-column">
        <div class="coveo-results-header">
          <div class="coveo-summary-section">
            <span class="CoveoQuerySummary"></span>
            <span class="CoveoQueryDuration pl-1"></span>
          </div>
        </div>
        <div class="CoveoHiddenQuery"></div>
        <div class="CoveoDidYouMean"></div>
        <div class="CoveoErrorReport"></div>
        <div id="autoShipSearchResultList" #autoShipSearchResultList class="CoveoResultList" data-layout="list"
             data-wait-animation="fade" data-auto-select-fields-to-include="true">
        </div>
        <div class="CoveoPager"></div>
        <div class="CoveoResultsPerPage"></div>
      </div>
    </div>

  </div>
</div>