import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { PaymentOptionCollection, WalletPayment } from '../../model';
import { IPaymentGateway, PaymentGateway } from '../../model/payment-gateway';
import { IGooglePayPaymentProcessor } from '../../model/payment-google-pay-processor';
import { GooglePayPaymentProcessorFactory } from '../../model/payment-google-pay-processor-factory';
import { IPaymentOption } from '../../model/payment-option';
import { GooglePayService } from '../../service/google-pay.service';
import { PaymentOptionService } from '../../service/payment-option.service';

@Component({
  selector: 'lef-payment-google-pay',
  templateUrl: './payment-google-pay.component.html',
  styleUrls: ['./payment-google-pay.component.scss'],
})
export class PaymentGooglePayComponent implements OnInit {
  @Input() gateway: IPaymentGateway = new PaymentGateway();
  @Input() isNetworkRequestInProgress = false;
  @Input() isCheckOut: boolean;
  @Input() isReturn: boolean;
  @Input() instantSave: boolean;
  @Input() usingCheckoutFlow: boolean;
  @Input() amount: number;
  @Input() customButtonId: string;
  @Input() layout: string;
  @Input() paymentOptionSelected: IPaymentOption;

  @Output() paymentOptionsChanged: EventEmitter<PaymentOptionCollection> = new EventEmitter();
  @Output() executingNetworkRequest: EventEmitter<boolean> = new EventEmitter();
  @Output() checkOutPaymentSelected: EventEmitter<WalletPayment> = new EventEmitter();

  @ViewChild('googlePayBtn', { read: ElementRef, static: false }) private googlePayBtn: ElementRef;

  paymentProcessor: IGooglePayPaymentProcessor;
  savePayment = false;
  previousPayPalComponentArguments: string[];
  payPalAvailabilitySubscription: Subscription;
  errorMessage: string;

  constructor(private readonly paymentOptionService: PaymentOptionService,
    private readonly googlePayService: GooglePayService,
    private readonly config: CoreConfiguration) {}

  ngAfterViewInit() {
    this.refreshGooglePayComponents();
  }

  ngOnInit(): void {
    this.initGooglePayPaymentProcessor();
    this.payPalAvailabilitySubscription = this.googlePayService.googlePayAvailabilityChange.subscribe((available) => {
      this.refreshGooglePayComponents();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!this.googlePayBtn) {
      if (changes.gateway) {
        this.initGooglePayPaymentProcessor();
      }
      if (changes.gateway || changes.amount) {
        this.refreshGooglePayComponents();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.payPalAvailabilitySubscription) {
      this.payPalAvailabilitySubscription.unsubscribe();
      this.payPalAvailabilitySubscription = null;
    }
  }

  onExecutingNetworkRequest(inProgress: boolean) {
    this.executingNetworkRequest.emit(inProgress);
  }

  initGooglePayPaymentProcessor() {
    if (this.paymentProcessor == null || this.paymentProcessor.gateway?.name !== this.gateway?.name) {
      this.paymentProcessor = new GooglePayPaymentProcessorFactory(this.config).GetProcessor(this.gateway);
      this.paymentProcessor.isProcessingPayment.subscribe({
        next: (result) => this.onExecutingNetworkRequest(result),
      });
      this.paymentProcessor.onPaymentDataReceived.subscribe({
        next: (result) => {
          result.savePayment = this.savePayment;
          this.validateGooglePayPaymentMethod(result);
        },
      });
      this.paymentProcessor.onCheckOutPaymentSelected.subscribe({
        next: (payment) => {
          this.onExecutingNetworkRequest(true);
          if (!!this.paymentOptionSelected) {
            payment.id = this.paymentOptionSelected.id;
          }
          payment.isCheckOut = true;
          payment.instantSave = this.instantSave;
          payment.deviceData = this.googlePayService?.deviceData;
          this.paymentOptionService.updateWalletPaymentOption(payment, this.isReturn, this.isCheckOut).subscribe(
            (result) => {
              this.onExecutingNetworkRequest(false);
              this.checkOutPaymentSelected.emit(payment);
            },
            (error) => {
              this.onExecutingNetworkRequest(false);
            }
          );
        },
      });
      this.paymentProcessor.onError.subscribe((message) => (this.errorMessage = message));
    }
  }

  refreshGooglePayComponents() {
    if (!!this.paymentProcessor) {
      let amount = this.amount == 0 ? 1 : this.amount;
      if (this.googlePayService.IsStatusOnline) {
        this.paymentProcessor.refresh(
          this.googlePayService.googlePayInstance,
          this.googlePayService.googlePayClient,
          this.usingCheckoutFlow,
          amount,
          this.googlePayBtn.nativeElement as HTMLDivElement
        );
      } else {
        //this.paymentProcessor.clearResources();
      }
    }
  }

  validateGooglePayPaymentMethod(payment: WalletPayment) {
    this.onExecutingNetworkRequest(true);
    payment.isCheckOut = true;
    payment.instantSave = this.instantSave;
    this.paymentOptionService.addWalletPaymentOption(payment, this.isReturn).subscribe(
      (result) => {
        this.onExecutingNetworkRequest(false);
        this.paymentOptionsChanged.emit(result);
      },
      (error) => {
        this.onExecutingNetworkRequest(false);
      }
    );
  }

  authorizePayment(): void {}
}
