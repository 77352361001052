import { FormlyFieldConfig } from '@ngx-formly/core';

export class FormlyHelper {
  public static getField(key: string, fields: FormlyFieldConfig[]): FormlyFieldConfig | any {
    for (let i = 0, len = fields.length; i < len; i++) {
      const f = fields[i];
      if (f.key === key) {
        return f;
      }

      if (f.fieldGroup && !f.key) {
        const cf = this.getField(key, f.fieldGroup);
        if (cf) {
          return cf;
        }
      }
    }
  }
}
