<div class="payment-options">
  <div *ngFor="let option of excludeOnCheckoutPayments(paymentOptions)"
       [ngClass]="{'payment-method-expired':option.isExpired}"
       class="d-flex align-items-center justify-content-between">
    <div>
      <div *ngIf="option.type == 0 || option.type == 12 || option.type == 13" class="payment-opts-wrap">
        <img src="assets/images/payment-method-{{option.image}}.png" class="payment-opts-img"
             alt="{{option.subTypeName}}" />
        <p [innerHTML]="option.description"></p>
      </div>
      <span *ngIf="option.type == 10"><img src="assets/images/payment-method-{{option.image}}.png"
             class="payment-opts-img" alt="PayPal" />
        {{option.description}}</span>
      <span *ngIf="option.type == 6"><img src="assets/images/payment-method-onAccount.png" class="payment-opts-img"
             alt="OnAccount" />
        {{option.description}}</span>
      <span *ngIf="[0,6,10,12,13].indexOf(option.type) === -1">{{option.description}}</span>
    </div>
    <button *ngIf="!option.isExpired && option.id !== paymentOptionSelectedId" class="btn btn-link" type="button"
            (click)="selectPaymentOption(option)">Select</button>
  </div>
</div>