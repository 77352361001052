<div #anchor></div>

<div>
  <div class="shipping-options" id="shippingOptions" *ngIf="selectedShippingMethod">
    <div class="shipping-opt-block d-flex">
      <div>
        <div>
          <button type="button" class="link-underline clickable btn-link-txt"
                  (click)="showShippingInformation()"><span>{{selectedShippingMethod.description}}</span> <i
               class="lef-information-solid text-info ml-1"></i></button>
        </div>
        <div *ngIf="showPremierDiscount">
          {{premierService.getDiscountText(selectedShippingMethod.rate)}} with <span class="premier">Premier</span>
        </div>
      </div>
      <div class="shipping-opt-price">
        {{premierService.getRateText(selectedShippingMethod.rate)}}
      </div>
    </div>
  </div>
  <div class="text-right">
    <button class="btn btn-link p-0" (click)="toggleShippingEdition()">
      Change Shipping <i class="lef-chevron-down"></i>
    </button>
  </div>
</div>

<ng-container *ngIf="editingShipping">
  <div class="k-overlay"></div>
  <div class="focus-element">

    <div class="shipping-opt-head mb-3">
      <button #showShipping type="button" class="link-underline btn-link-txt d-flex align-items-center clickable"
              (click)="showShippingInformation()"><span>Shipping</span>
        <i class="lef-information-solid text-info ml-1"></i></button>
      <p class="font-sm text-red m-0">Price for domestic shipments</p>
    </div>
    <div class="shipping-options" id="shippingOptions">
      <div [ngClass]="{'shipping-opt-wrap': editingShipping }">
        <div class="shipping-opt" *ngFor="let sm of shippingMethods">
          <div class="cart-radio-input w-100" (click)="onShippingMethodClick(sm)">
            <div class="d-flex w-100">
              <div class="d-flex align-items-center">
                <input kendoradiobutton class="k-radio" type="radio" [value]="sm.shippingMethod" name="shipping-method"
                       [(ngModel)]="shippingMethod" />
                <label class=" form-check-label">
                  <span class="">{{sm.description}}</span>
                  <br />
                  <ng-container *ngIf="showPremierDiscount">
                    <br />
                    <span>
                      {{premierService.getDiscountText(sm.rate)}} with <span class="premier">Premier</span>
                    </span>
                  </ng-container>
                </label>
              </div>
              <div class="shipping-opt-price ml-auto">
                {{premierService.getRateText(sm.rate)}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-right">
      <button #confirmShipping class="btn btn-link p-0 mt-2" (click)="toggleShippingEdition()">
        Confirm Shipping <i class="lef-chevron-up"></i>
      </button>

    </div>
  </div>
</ng-container>