import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { PayPalCheckoutService } from '../../service/pay-pal-checkout-service.service';

declare global {
  interface Window {
    paypal: any;
    PayPalMessageSDK: any;
  }
}
@Component({
  selector: 'lef-paypal-pay-later-messages',
  templateUrl: './paypal-pay-later-messages.component.html',
  styleUrls: ['./paypal-pay-later-messages.component.scss'],
})
export class PaypalPayLaterMessagesComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() amount: number;
  @ViewChild('payPalMessages', { read: ElementRef, static: false }) private payPalMessages: ElementRef;

  runningOnIE11 = /msie\s|trident\//i.test(window.navigator.userAgent);

  get isPayPalEnabled(): boolean {
    return this.payPalCheckOutService.isPayPalServiceActive();
  }

  constructor(private readonly payPalCheckOutService: PayPalCheckoutService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.renderMessages();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.amount) {
      this.renderMessages();
    }
  }

  renderMessages() {
    if (this.runningOnIE11 || !this.isPayPalEnabled) {
      return;
    }
    setTimeout(() => {
      if (!!window.PayPalMessageSDK && typeof window.PayPalMessageSDK.Messages === 'function') {
        window.PayPalMessageSDK.Messages({
          amount: this.amount,
          placement: 'cart',
          style: {
            layout: 'text',
            logo: {
              type: 'primary',
            },
          },
        })
          .render(this.payPalMessages?.nativeElement)
          .then(() => {})
          .catch((err) => {
            console.error(err);
          });
      }
    }, 320);
  }
}
