import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { IPaymentGateway, PaymentGateway } from '../../model/payment-gateway';
import { IPaymentOption } from '../../model/payment-option';
import { PaymentOptionCollection } from '../../model/payment-option-collection';
import { IPayPalPaymentProcessor } from '../../model/payment-paypal-processor';
import { PayPalPaymentProcessorFactory } from '../../model/payment-paypal-processor-factory';
import { WalletPayment } from '../../model/payment-wallet';
import { PayPalCheckoutService } from '../../service/pay-pal-checkout-service.service';
import { PaymentOptionService } from '../../service/payment-option.service';

@Component({
  selector: 'lef-payment-paypal',
  templateUrl: './payment-paypal.component.html',
  styleUrls: ['./payment-paypal.component.scss'],
})
export class PaymentPayPalComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() gateway: IPaymentGateway = new PaymentGateway();
  @Input() isNetworkRequestInProgress = false;
  @Input() isCheckOut: boolean;
  @Input() isReturn: boolean;
  @Input() instantSave: boolean;
  @Input() usingCheckoutFlow: boolean;
  @Input() amount: number;
  @Input() customButtonId: string;
  @Input() layout: string;
  @Input() paymentOptionSelected: IPaymentOption;

  @Output() paymentOptionsChanged: EventEmitter<PaymentOptionCollection> = new EventEmitter();
  @Output() executingNetworkRequest: EventEmitter<boolean> = new EventEmitter();
  @Output() checkOutPaymentSelected: EventEmitter<WalletPayment> = new EventEmitter();

  @ViewChild('paypalBtn', { read: ElementRef, static: false }) private paypalBtn: ElementRef;

  paymentProcessor: IPayPalPaymentProcessor;
  savePayment = false;
  previousPayPalComponentArguments: string[];
  payPalAvailabilitySubscription: Subscription;
  errorMessage: string;

  get buttonId(): string {
    return !!this.customButtonId ? this.customButtonId : 'paypal-button';
  }

  get buttonLayout(): string {
    return !!this.layout ? this.layout : 'vertical';
  }

  constructor(private readonly paymentOptionService: PaymentOptionService, private readonly payPalCheckoutService: PayPalCheckoutService) {}

  ngAfterViewInit() {
    this.refreshPayPalComponents();
  }

  ngOnInit(): void {
    this.initPayPalPaymentProcessor();
    this.payPalAvailabilitySubscription = this.payPalCheckoutService.payPalAvailabilityChange.subscribe((available) => {
      this.refreshPayPalComponents();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!this.paypalBtn) {
      if (changes.gateway) {
        this.initPayPalPaymentProcessor();
      }
      if (changes.gateway || changes.amount) {
        this.refreshPayPalComponents();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.payPalAvailabilitySubscription) {
      this.payPalAvailabilitySubscription.unsubscribe();
      this.payPalAvailabilitySubscription = null;
    }
  }

  onExecutingNetworkRequest(inProgress: boolean) {
    this.executingNetworkRequest.emit(inProgress);
  }

  initPayPalPaymentProcessor() {
    if (this.paymentProcessor == null || this.paymentProcessor.gateway?.name !== this.gateway?.name) {
      this.paymentProcessor = new PayPalPaymentProcessorFactory().GetProcessor(this.gateway);
      this.paymentProcessor.isProcessingPayment.subscribe({
        next: (result) => this.onExecutingNetworkRequest(result),
      });
      this.paymentProcessor.onPaymentDataReceived.subscribe({
        next: (result) => {
          result.savePayment = this.savePayment;
          this.validatePayPalPaymentMethod(result);
        },
      });
      this.paymentProcessor.onCheckOutPaymentSelected.subscribe({
        next: (payment) => {
          this.onExecutingNetworkRequest(true);
          if (!!this.paymentOptionSelected) {
            payment.id = this.paymentOptionSelected.id;
          }
          payment.isCheckOut = true;
          payment.instantSave = this.instantSave;
          payment.deviceData = this.payPalCheckoutService?.deviceData;
          this.paymentOptionService.updateWalletPaymentOption(payment, this.isReturn, this.isCheckOut).subscribe(
            (result) => {
              this.onExecutingNetworkRequest(false);
              this.checkOutPaymentSelected.emit(payment);
            },
            (error) => {
              this.onExecutingNetworkRequest(false);
            }
          );
        },
      });
      this.paymentProcessor.onButtonsReady.subscribe((result) => this.payPalCheckoutService.onButtonsReady.next(result));
      this.paymentProcessor.onError.subscribe((message) => (this.errorMessage = message));
    }
  }

  refreshPayPalComponents() {
    if (!!this.paymentProcessor) {
      let amount = this.amount == 0 ? 1 : this.amount;
      if (this.payPalCheckoutService.IsStatusOnline) {
        this.paymentProcessor.refresh(
          this.payPalCheckoutService.paypalCheckoutInstance,
          this.usingCheckoutFlow,
          amount,
          this.buttonId,
          this.buttonLayout
        );
      } else {
        this.paymentProcessor.clearResources();
      }
    }
  }

  validatePayPalPaymentMethod(payment: WalletPayment) {
    this.onExecutingNetworkRequest(true);
    payment.isCheckOut = true;
    payment.instantSave = this.instantSave;
    this.paymentOptionService.addWalletPaymentOption(payment, this.isReturn).subscribe(
      (result) => {
        this.onExecutingNetworkRequest(false);
        this.paymentOptionsChanged.emit(result);
      },
      (error) => {
        this.onExecutingNetworkRequest(false);
      }
    );
  }
}
