import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FeaturesService {
  public readonly featuresList = {
    applePay: 'applePay',
    googlePay: 'googlePay',
  };

  private readonly storageKey: string = 'test-features';
  private enabledFeatures: string[] = [];
  private loadFeatures: boolean = true;
  constructor() {}

  setFeatures(features: string) {
    localStorage.setItem(this.storageKey, features);
  }

  isFeatureEnable(feature: string): boolean {
    if (!feature) return false;

    if (this.loadFeatures && !this.enabledFeatures?.length) {
      const features = localStorage.getItem(this.storageKey);
      if (features) {
        this.enabledFeatures = features.split(',').map((f) => f.trim().toLowerCase());
      }
      this.loadFeatures = false;
    }

    return this.enabledFeatures.includes(feature.toLowerCase());
  }
}
