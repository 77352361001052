<div class="w-100 d-md-flex justify-content-md-between align-items-md-basline">
    <div class="wra-business-information w-100">
        <div class="d-flex justify-content-between align-items-center d-md-block">
            <div class="d-flex justify-content-between">
                <p class="wra-step-wrap m-0">
                    <span class="wra-step-number">{{stepNumber}}</span><span class="wra-step-title">{{stepTitle}}</span>
                </p>
                <div style="text-align: end;">
                    <!-- <div *ngIf="hasContent && !isContentValid">
                        <span class="text-red"> Section include invalid items!</span>
                    </div> -->
                    <ng-container *ngIf="!isActive">
                        <div *ngIf="hasContent && !isEditable">
                            <button class="btn btn-link" type="button"
                                    (click)="activateBusinessSection()">Review</button>
                        </div>
                        <div *ngIf="hasContent && isEditable">
                            <button class="btn btn-link" type="button"
                                    (click)="activateBusinessSection()">Review/Edit</button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div *ngIf="isActive" class="w-100 mt-2">
            <form *ngIf="isDataAlreadyInitialized" [formGroup]="applicationForm" (ngSubmit)="onSubmit()">
                <input id="id" type="hidden" name="id" formControlName="id">
                <input id="applicationId" type="hidden" name="applicationId" formControlName="applicationId">
                <div class="w-100 bg-light p-3 form-group" formGroupName="businessDetails">
                    <div class="d-flex justify-content-between mb-2">
                        <p class="font-lg font-weight-bold text-primary">Business Details</p>
                        <p class="font-sm required-message">* Indicated required fields</p>
                    </div>
                    <div class="mb-3">
                        <select formControlName="primaryBusiness" name="primaryBusiness" class="form-control"
                                (change)="onPrimaryBusinessChange()">
                            <option [ngValue]="null">Primary Business:*</option>
                            <option *ngFor="let opt of primaryBusiness" [ngValue]="opt.value">{{opt.description}}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="labelForBusinessTypeSelected" class="row p2 mt-2">
                        <div class="col-12">
                            <p>{{labelForBusinessTypeSelected}}</p>
                        </div>
                    </div>
                    <div *ngIf="businessSubType?.length > 0" class="mb-3">
                        <select formControlName="businessSubType" name="businessSubType" class="form-control"
                                (change)="onBusinessSubTypeChange()">
                            <option [ngValue]="null">Type of Physician/CAM*</option>
                            <option *ngFor="let opt of businessSubType" [ngValue]="opt.value">{{opt.description}}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="isBusinessSubTypeOther" class="row p2 mt-3 mb-3">
                        <div class="col-12">
                            <textarea class="form-control" placeholder="Enter Details*"
                                      formControlName="businessSubtypeDetails" maxlength="50"></textarea>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div class="border-bottom pb-3 mb-3">
                            <p class="m-0"><strong>Note:</strong> If your business is at least 50% web-based, you MUST
                                select Internet/Catalog/Direct Mail, (Amazon and Walmart Resale not Allowed.)</p>
                        </div>
                    </div>
                    <div *ngIf="isOtherPrimaryBusiness" class="row p2 mt-3 mb-3">
                        <div class="col-12">
                            <input type="text" class="form-control" placeholder="Other Description*"
                                   formControlName="details" />
                        </div>
                    </div>
                    <div class="mb-3">
                        <div>
                            <select formControlName="typeOfOwnership" name="typeOfOwnership" class="form-control">
                                <option [ngValue]="null">Type Of Ownership:*</option>
                                <option *ngFor="let opt of typeOfOwnership" [ngValue]="opt.value">{{opt.description}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <!-- <ng-container *ngIf="isInternetCatalog">
                        <div class="alert alert-danger mb-3">
                            <span>Please list all online platforms you sell on along with the storefront URL.</span>
                        </div>
                        <ng-container *ngFor="let control of onlinePlatforms.controls; index as i">
                            <div class="row p-2 form-group" [formGroup]="control">
                                <div class="col-5">
                                    <input type="text" class="form-control" placeholder="Online Platforms*"
                                           formControlName="platform" />
                                </div>
                                <div class="col-5">
                                    <input type="text" class="form-control" placeholder="Store Front URL*"
                                           formControlName="storefrontUrl" />
                                </div>
                                <div class="col-1">
                                    <button *ngIf="i > 0" (click)="removeOnlinePlatforms(i)" type="button"
                                            class="btn-circle"><i
                                           class="lef-close btn-circle icon-expanded"></i></button>
                                </div>
                            </div>
                        </ng-container>
                        <div class="row">
                            <div class="col-12">
                                <button class="btn btn-link" type="button" (click)="addOnlinePlatforms()">+Add
                                    Another</button>
                            </div>
                        </div>
                    </ng-container> -->
                    <ng-container *ngIf="isWholesalerDistributor">
                        <div class="row p2 mt-2">
                            <div class="col-12">
                                <input type="number" class="form-control" min="0"
                                       placeholder="What is your anticipated order volume each year?*"
                                       formControlName="anticipatedOrderVolume" />
                            </div>
                        </div>
                        <div class="row p2 mt-4">
                            <div class="col-12">
                                <p>List up to 5 brands you currently distribute*:</p>
                            </div>
                        </div>
                        <ng-container *ngFor="let control of businessBrands.controls; index as i">
                            <div class="row form-group" [formGroup]="control">
                                <div class="col-11">
                                    <input type="text" class="form-control" placeholder="Brand name*"
                                           formControlName="name" />
                                </div>
                                <div class="col-1">
                                    <button *ngIf="i > 0" (click)="removeBusinessBrand(i)" type="button"
                                            class="btn-circle"><i class="lef-close icon-expanded"></i></button>
                                </div>
                            </div>
                        </ng-container>
                        <div class="row">
                            <div class="col-12" *ngIf="businessBrands.controls.length < 5">
                                <button class="btn btn-link" type="button" (click)="addBusinessBrand()">+Add
                                    Another</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                How many retail locations do you currently distribute to?
                                <input type="number" class="form-control" min="0"
                                       formControlName="numberOfRetailLocations" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <p>Please provide 5 references of retail locations you distribute to and/or Vendors that
                                    you work with.
                                    We will only make contact to verify you are valid wholesale distributor.*</p>
                            </div>
                        </div>
                        <ng-container *ngFor="let control of businessReferences.controls; index as i">
                            <div class="w-100 form-group" [formGroup]="control">
                                <div *ngIf="i > 0" class="row p2">
                                    <div class="col-12">
                                        <button (click)="removeBusinessReferences(i)" type="button"
                                                class="btn-circle"><i class="lef-close icon-expanded"></i></button>
                                    </div>
                                </div>
                                <div class="row p-2">
                                    <div class="col-6">
                                        <input type="text" class="form-control" placeholder="Business"
                                               formControlName="business" />
                                    </div>
                                    <div class="col-6">
                                        <input type="text" class="form-control" placeholder="Contact"
                                               formControlName="contact" />
                                    </div>
                                </div>
                                <div class="row p-2">
                                    <div class="col-12">
                                        <input type="text" class="form-control" placeholder="Address"
                                               formControlName="address" />
                                    </div>
                                </div>
                                <div class="row p-2">
                                    <div class="col-6">
                                        <input type="text" class="form-control" placeholder="City"
                                               formControlName="city" />
                                    </div>
                                    <div class="col-6">
                                        <select formControlName="state" name="state" class="form-control"
                                                autocomplete="address-level1">
                                            <option [ngValue]="null">State</option>
                                            <option *ngFor="let opt of businessReferencesStates[i]"
                                                    [ngValue]="opt.value">
                                                {{opt.description}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row p-2">
                                    <div class="col-6">
                                        <select formControlName="country" name="country" class="form-control"
                                                (change)="onBusinessReferencesCountrySelectedChange(i)">
                                            <option [ngValue]="null">Country</option>
                                            <option *ngFor="let opt of countries" [ngValue]="opt.code">
                                                {{opt.description}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-6">
                                        <input type="text" class="form-control" placeholder="Zip Code"
                                               formControlName="zipCode" />
                                    </div>
                                </div>
                                <div class="row p-2">
                                    <div class="col-6">
                                        <input type="text" class="form-control" placeholder="Phone"
                                               formControlName="phone" />
                                    </div>
                                    <div class="col-6">
                                        <input type="text" class="form-control" placeholder="Fax"
                                               formControlName="fax" />
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="row">
                            <div class="col-12">
                                <button class="btn btn-link" type="button" (click)="addBusinessReferences()">+Add
                                    Another</button>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <div class="w-100 bg-light p-3 form-group" formGroupName="taxInformation">
                    <div class="d-flex justify-content-between">
                        <p class="font-lg font-weight-bold text-primary">Tax Information</p>
                        <p class="font-sm required-message">* Indicated required fields</p>
                    </div>
                    <div class="mb-3">
                        <p>
                            Please upload your valid certificate of resale, business license, state tax certificate, or
                            physician/practitioner
                            license. Your business name and address on the application must match your supporting
                            documents.
                        </p>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 d-flex justify-content-between flex-wrap p-0">
                            <div class="col-12 col-md-6">
                                <input type="text" class="form-control" placeholder="Federal Tax ID #*"
                                       formControlName="federalTaxId" />
                                <div
                                     class="col-12 d-flex justify-content-start align-items-center flex-nowrap p-0 pt-2">
                                    <p class="m-0">What is a Federal Tax ID? </p>
                                    <button type="button" class="link-underline clickable btn-link-txt"
                                            (click)="showFederalTaxInformation()">
                                        <i class="lef-information-solid text-info ml-1"></i></button>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 mt-4 mt-md-0 d-flex flex-column align-items-start">
                                <div *ngIf="federaltaxfile?.file; else federalTaxFileControl"
                                     class="w-100 d-flex align-items-center justify-content-between">
                                    <div
                                         class="col-11 p-0 form-control bg-transparent border-0 d-flex align-items-center">
                                        <button class="btn btn-link" type="button">{{federaltaxfile?.file}}</button>
                                    </div>
                                    <button *ngIf="isEditable" type="button"
                                            (click)="onTaxFileRemoved(federaltaxfile.fileId)" class="btn-circle"><i
                                           class="lef-close icon-expanded"></i></button>
                                </div>
                                <ng-template #federalTaxFileControl>
                                    <div *ngIf="isEditable" class="border-0 col-12 p-0"
                                         formArrayName="businessTaxFiles">
                                        <div *ngFor="let file of businessTaxFiles?.controls; let i = index"
                                             class="col-12 p-0">
                                            <div *ngIf="i === federaltaxfileid">
                                                <div class="d-flex justify-content-start col-12 p-0">
                                                    <button class="btn btn-link text-start col-3" type="button"
                                                            (click)="openFileInput('federalTaxFileInputId')">Choose
                                                        File</button>
                                                    <input type="text" [formControlName]="i"
                                                           class="form-control col-8 hide-cursor"
                                                           (keydown)="handleKeydown($event)"
                                                           placeholder="Upload Federal Tax ID#*" />
                                                    <input type="file" id="federalTaxFileInputId"
                                                           accept=".pdf,.jpg,.jpeg,.png"
                                                           (change)="onFileSourceChange($event, 'FederalTax', i)"
                                                           hidden />
                                                </div>
                                                <p class="m-0">File upload must be in PDF or image format</p>
                                                <p>(.jpg, .jpg, .png - max size 1 MB)</p>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 col-md-6">
                            <input type="text" class="form-control" placeholder="State Resale Tax ID #*"
                                   formControlName="stateResaleTaxId" autocomplete="companyTaxId" />
                        </div>
                        <div class="col-12 col-md-6 mt-4 mt-md-0 d-flex flex-column align-items-start">
                            <div *ngIf="stateresaletaxfile?.file; else stateResaleTaxFileControl"
                                 class="w-100 d-flex align-items-center justify-content-between">
                                <div class="col-11 p-0 form-control bg-transparent border-0 d-flex align-items-center">
                                    <button class="btn btn-link" type="button">{{stateresaletaxfile?.file}}</button>
                                </div>
                                <button *ngIf="isEditable" type="button"
                                        (click)="onTaxFileRemoved(stateresaletaxfile.fileId)" class="btn-circle"><i
                                       class="lef-close icon-expanded"></i></button>
                            </div>
                            <ng-template #stateResaleTaxFileControl>
                                <div *ngIf="isEditable" class="border-0 col-12 p-0" formArrayName="businessTaxFiles">
                                    <div *ngFor="let file of businessTaxFiles?.controls; let i = index">
                                        <div *ngIf="i === stateresaletaxfileid">
                                            <div class="d-flex justify-content-start col-12 p-0">
                                                <button class="btn btn-link text-start col-3" type="button"
                                                        (click)="openFileInput('stateResaleTaxFileInputId')">Choose
                                                    File</button>
                                                <input type="text" [formControlName]="i"
                                                       class="form-control col-8 hide-cursor"
                                                       (keydown)="handleKeydown($event)"
                                                       placeholder="Upload State Resale Tax ID#*" />
                                                <input type="file" accept=".pdf,.jpg,.jpeg,.png"
                                                       id="stateResaleTaxFileInputId"
                                                       (change)="onFileSourceChange($event, 'StateResaleTax', i)"
                                                       hidden />
                                            </div>
                                            <p class="m-0">File upload must be in PDF or image format</p>
                                            <p>(.jpg, .jpg, .png - max size 1 MB)</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <div class="row mb-3 d-flex">
                        <div class="col-12 col-md-6">
                            <strong>Sales Tax Exemption Certificate</strong>
                        </div>
                        <div class="col-12 col-md-6 mt-4 mt-md-0 d-flex flex-column">
                            <div *ngIf="modelTaxExemptionFiles?.length > 0" class="border-0">
                                <div *ngFor="let taxExemption of modelTaxExemptionFiles">
                                    <div *ngIf="taxExemption?.file"
                                         class="w-100 d-flex align-items-center justify-content-between mb-2 btn-link">
                                        <div
                                             class="col-11 p-0 form-control bg-transparent border-0 d-flex align-items-center">
                                            <button class="btn btn-link" type="button">{{taxExemption?.file}}</button>
                                        </div>
                                        <button *ngIf="isEditable" type="button"
                                                (click)="onTaxExemptionRemoveFromModel(taxExemption.fileId)"
                                                class="btn-circle"><i class="lef-close icon-expanded"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div class="border-0" formArrayName="businessTaxFiles" *ngIf="isEditable">
                                <div *ngFor="let file of businessTaxFiles?.controls; let i = index">
                                    <div
                                         *ngIf="i !== federaltaxfileid && i !== stateresaletaxfileid && i !== licensenumberfileid">
                                        <div class="d-flex justify-content-start align-items-center col-12 p-0">
                                            <button class="btn btn-link text-start col-3" type="button"
                                                    (click)="openFileInput('taxExemptionId_' + i)">Choose
                                                File</button>
                                            <input type="text" class="form-control col-8 hide-cursor mr-1"
                                                   (keydown)="handleKeydown($event)" [formControlName]="i"
                                                   placeholder="Upload Sales Tax Exemption Certi*" />
                                            <input type="file" accept=".pdf,.jpg,.jpeg,.png"
                                                   [id]="'taxExemptionId_' + i"
                                                   (change)="onTaxExemptionFileSourceChange($event, i)" hidden />
                                            <button *ngIf="isEditable && (modelTaxExemptionFiles?.length > 0 || i !== firstTaxExemptionIndex)"
                                                    type="button" (click)="onTaxExemptionRemove(i)" class="btn-circle">
                                                <i class="lef-close icon-expanded"></i>
                                            </button>
                                        </div>
                                        <p class="m-0">File upload must be in PDF or image format</p>
                                        <p>(.jpg, .jpg, .png - max size 1 MB)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isEditable" class="row d-flex justify-content-end mb-5">
                        <button [disabled]="disableAddTaxExemptionControl()" type="button" class="btn btn-link col-3"
                                (click)="addTaxFileControl('TaxExemption')">+ Add
                            Another</button>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 col-md-6">
                            <input type="text" class="form-control" placeholder="Business or Physician License #"
                                   formControlName="licenseNumber" autocomplete="companyTaxId" />
                        </div>
                        <div class="col-12 col-md-6 mt-4 mt-md-0 d-flex flex-column align-items-start">
                            <div *ngIf="licensenumberfile?.file; else licenseNumberFileControl"
                                 class="w-100 d-flex align-items-center justify-content-between">
                                <div class="col-11 p-0 form-control bg-transparent border-0 d-flex align-items-center">
                                    <button class="btn btn-link" type="button">{{licensenumberfile?.file}}</button>
                                </div>
                                <button *ngIf="isEditable" type="button"
                                        (click)="onTaxFileRemoved(licensenumberfile.fileId)" class="btn-circle"><i
                                       class="lef-close icon-expanded"></i></button>
                            </div>
                            <ng-template #licenseNumberFileControl>
                                <div *ngIf="isEditable" class="border-0 col-12 p-0" formArrayName="businessTaxFiles">
                                    <div *ngFor="let file of businessTaxFiles?.controls; let i = index">
                                        <div *ngIf="i === licensenumberfileid">
                                            <div class="d-flex justify-content-start col-12 p-0">
                                                <button class="btn btn-link text-start col-3" type="button"
                                                        (click)="openFileInput('licenseNumberTaxFileInputId')">Choose
                                                    File</button>
                                                <input type="text" class="form-control col-8 hide-cursor"
                                                       (keydown)="handleKeydown($event)" [formControlName]="i"
                                                       placeholder="Upload Business or Physician License" />
                                                <input type="file" accept=".pdf,.jpg,.jpeg,.png"
                                                       id="licenseNumberTaxFileInputId"
                                                       (change)="onFileSourceChange($event, 'LicenseNumber', i)"
                                                       hidden />
                                            </div>
                                            <p class="m-0">File upload must be in PDF or image format</p>
                                            <p>(.jpg, .jpg, .png - max size 1 MB)</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="d-flex align-items-center">
                                <span class="pr-2">Year Established*: </span>
                                <select formControlName="year" name="Year" class="form-control" style="width: 120px"
                                        (change)="onTaxEstablishedDateChange()">
                                    <option [ngValue]="null">Year</option>
                                    <option *ngFor="let opt of taxYears" [ngValue]="opt.value">{{opt.description}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="w-100 bg-light p-3 form-group" formGroupName="onlineSales">
                    <div class="d-flex justify-content-between">
                        <p class="font-lg font-weight-bold text-primary">Online Sales</p>
                        <p class="font-sm required-message">* Indicated required fields</p>
                    </div>
                    <div class="mb-3">
                        <p>You must comply with California Proposition 65 requirements if you sell products to
                            purchasers in the
                            state of California, even if you are located outside California. Label and online warnings
                            are required when
                            selling Products on the LifeExtension Proposition 65 List to California customers.
                        </p>
                        <p>*Resale on Amazon or Walmart not allowed.</p>
                    </div>
                    <div class="mb-3">
                        <div class="d-flex align-items-center">
                            <input type="checkbox" class="k-checkbox" id="readAndAgreed"
                                   formControlName="readAndAgreed">
                            <label class="form-check-label" for="readAndAgreed">I have read and agreed to the terms
                                above.*</label>
                        </div>
                    </div>
                    <div class="mb-3">
                        <p class="m-0">
                            Please list all selling platforms along with URLs for each platform.
                        </p>
                    </div>
                    <ng-container *ngFor="let control of sellingPlatforms.controls; index as i">
                        <div class="row form-group mb-0" [formGroup]="control">
                            <div class="col-6">
                                <input type="text" class="form-control" placeholder="Selling Platform*"
                                       formControlName="platform" />
                            </div>
                            <div class="col-5">
                                <input type="text" class="form-control" placeholder="Storefront URL*"
                                       formControlName="storefrontUrl" />
                            </div>
                            <div class="col-1">
                                <button *ngIf="i > 0" (click)="removeSellingPlatforms(i)" type="button"
                                        class="btn-circle"><i class="lef-close icon-expanded"></i></button>
                            </div>
                        </div>
                    </ng-container>
                    <div>
                        <div class="text-right" style="padding-right: 4rem;">
                            <button class="btn btn-link" type="button" (click)="addSellingPlatforms()">+Add
                                Another</button>
                        </div>
                        <div>
                            <p>
                                <strong>Note:</strong> You Must notify us within 30 days if obtain a new selling
                                platform and/or URL.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="w-100 bg-light p-3 form-group" formGroupName="orderPreferences">
                    <div class="d-flex justify-content-between">
                        <p class="font-lg font-weight-bold text-primary">Order Preferences</p>
                        <p class="font-sm required-message">* Indicated required fields</p>
                    </div>
                    <div class="rsa-order-prefs mb-2 d-flex justify-content-start flex-wrap">
                        <p>
                            Do you accept back orders?*
                        </p>
                        <div class="d-flex align-items-center">
                            <div class="d-flex align-items-center mr-3">
                                <input id="noAcceptBackorders" type="radio" class="k-radio" value="N"
                                       name="acceptBackorders" formControlName="acceptBackorders">
                                <label for="noAcceptBackorders">No</label>
                            </div>
                            <div class="d-flex align-items-center">
                                <input id="yesAcceptBackorders" type="radio" class="k-radio" value="Y"
                                       name="acceptBackorders" formControlName="acceptBackorders">
                                <label for="yesAcceptBackorders">Yes</label>
                            </div>
                        </div>
                    </div>
                    <div class="rsa-order-prefs mb-2 d-flex justify-content-start flex-wrap">
                        <p>
                            Do you accept replacements?*
                        </p>
                        <div class="d-flex align-items-center">
                            <div class="d-flex align-items-center mr-3">
                                <input id="noAcceptReplacements" type="radio" class="k-radio" value="N"
                                       name="acceptReplacements" formControlName="acceptReplacements">
                                <label for="noAcceptReplacements">No</label>
                            </div>
                            <div class="d-flex align-items-center">
                                <input id="yesAcceptReplacements" type="radio" class="k-radio" value="Y"
                                       name="acceptReplacements" formControlName="acceptReplacements">
                                <label for="yesAcceptReplacements">Yes</label>
                            </div>
                        </div>
                    </div>

                    <div class="rsa-order-prefs mb-3 d-flex justify-content-start flex-wrap">
                        <p>
                            Do you accept partial shipments?*
                        </p>
                        <div class="d-flex align-items-center">
                            <div class="d-flex align-items-center mr-3">
                                <input id="noAcceptPartialShipments" type="radio" class="k-radio" value="N"
                                       name="acceptPartialShipments" formControlName="acceptPartialShipments">
                                <label for="noAcceptPartialShipments">No</label>
                            </div>
                            <div class="d-flex align-items-center">
                                <input id="yesAcceptPartialShipments" type="radio" class="k-radio" value="Y"
                                       name="acceptPartialShipments" formControlName="acceptPartialShipments">
                                <label for="yesAcceptPartialShipments">Yes</label>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-6">
                            <select formControlName="howHeardAboutLifeExtension" name="howHeardAboutLifeExtension"
                                    class="form-control" (change)="onHowHeardAboutLifeExtensionChange()">
                                <option [ngValue]="null">How did you hear about Life Extension:*</option>
                                <option *ngFor="let opt of hearAboutLifeExtensionOptions" [ngValue]="opt.value">
                                    {{opt.description}}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="howHeardAboutLifeExtensionThroughtOtherSource" class="col-6">
                            <input type="text" class="form-control" placeholder="Enter Details*"
                                   formControlName="otherDetails" />
                        </div>

                        <div *ngIf="howHeardAboutLifeExtensionThroughtSalesRep" class="col-6">
                            <select formControlName="regionalSalesManager" name="regionalSalesManager"
                                    class="form-control">
                                <option [ngValue]="null">Regional Sales Manager:*</option>
                                <option *ngFor="let opt of regionalSalesManagers" [ngValue]="opt.value">
                                    {{opt.description}}
                                </option>
                            </select>
                        </div>

                    </div>
                </div>

                <div class="w-100 bg-light p-3 form-group" formGroupName="invoicePreferences">
                    <div class="d-flex justify-content-between">
                        <p class="font-lg font-weight-bold text-primary">Invoice Preferences</p>
                    </div>
                    <div class="rsa-order-prefs mb-2  d-flex justify-content-start flex-wrap">
                        <p>
                            Is Invoice Needed?
                        </p>
                        <div class="d-flex align-items-center">
                            <div class="d-flex align-items-center mr-3">
                                <input type="radio" class="k-radio" value="ALWAYS" name="isInvoiceNeeded"
                                       formControlName="isInvoiceNeeded">
                                <label for="alwaysisInvoiceNeeded">Always</label>
                            </div>
                            <div class="d-flex align-items-center mr-3">
                                <input type="radio" class="k-radio" value="NEVER" name="isInvoiceNeeded"
                                       formControlName="isInvoiceNeeded">
                                <label for="neverisInvoiceNeeded">Never</label>
                            </div>
                            <div class="d-flex align-items-center">
                                <input type="radio" class="k-radio" value="OPEN BALANCE" name="isInvoiceNeeded"
                                       formControlName="isInvoiceNeeded">
                                <label for="openBalanceisInvoiceNeeded">Open Balance</label>
                            </div>
                        </div>
                    </div>

                    <div class="rsa-order-prefs mb-3 d-flex justify-content-start flex-wrap">
                        <p>
                            Send By Mail
                        </p>
                        <div class="d-flex align-items-center">
                            <div class="d-flex align-items-center mr-3">
                                <input id="noMailInvoice" type="radio" class="k-radio" value="N" name="mailInvoice"
                                       formControlName="mailInvoice">
                                <label for="noMailInvoice">No</label>
                            </div>
                            <div class="d-flex align-items-center">
                                <input id="yesMailInvoice" type="radio" class="k-radio" value="Y" name="mailInvoice"
                                       formControlName="mailInvoice">
                                <label for="yesMailInvoice">Yes</label>
                            </div>
                        </div>
                    </div>

                    <div class="rsa-order-prefs mb-3 d-flex justify-content-start flex-wrap">
                        <p>
                            Send By Email
                        </p>
                        <div class="d-flex align-items-center">
                            <div class="d-flex align-items-center mr-3">
                                <input id="noEmailInvoice" type="radio" class="k-radio" value="N" name="emailInvoice"
                                       formControlName="emailInvoice" (change)="setEmailInvoice($event.target.value)">
                                <label for="noEmailInvoice">No</label>
                            </div>
                            <div class="d-flex align-items-center">
                                <input id="yesEmailInvoice" type="radio" class="k-radio" value="Y" name="emailInvoice"
                                       formControlName="emailInvoice" (change)="setEmailInvoice($event.target.value)">
                                <label for="yesEmailInvoice">Yes</label>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3" *ngIf="isEmailInvoice">
                        <div class="col-6">
                            <input type="text" class="form-control" placeholder="Invoice Email Address*"
                                   formControlName="invoiceEmailAddress" />
                        </div>
                        <small class="text-danger col-12" role="alert" *ngIf="!model.isValidInvoiceEmail">{{
                            model.invoiceEmailMessage
                            }}</small>
                    </div>

                    <div class="mt-2">
                        <div class="mb-3">
                            <p class="m-0">Fill in this field if you want to receive promotional emails in a different
                                email address</p>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-6">
                            <input type="text" class="form-control" placeholder="Promotional Email Address"
                                   (change)="checkMarketingEmail()" formControlName="marketingEmail" />
                        </div>
                        <small class="text-danger col-12" role="alert" *ngIf="!model.isValidMarketingEmail">{{
                            model.marketingEmailMessage
                            }}</small>
                    </div>
                </div>

                <div *ngIf="isEditable" class="w-100 my-3">
                    <div class="d-flex justify-content-end">
                        <button type="submit" class="btn btn-warning mb-2 co-ar-btn">Next</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<ng-template #federalTaxIdInfo>
    <div>
        Your Federal Tax ID is also referred to as an EIN, or Employer Identification Number. For the purposes of
        identification, the Internal Revenue Service assigns an EIN to businesses operating in the United States.
    </div>
</ng-template>