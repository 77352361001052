import { ICartMessage } from 'src/app/cart-common/model';

export class ResellerApplication {
  id: string;
  isEditable: boolean;
  companyRegistrationReceived: boolean;
  companyRegistrationValid: boolean;
  businessInformationReceived: boolean;
  businessInformationValid: boolean;
  signature: string;
  submitDate: string;
  isWaitingFeedback: boolean;
  workflowState?: string;
  lastTicketResponse?: string;
  messages?: ICartMessage[];
  isCaliforniaCustomer: boolean;
  enableResetApplication: boolean;

  public constructor(init?: Partial<ResellerApplication>) {
    Object.assign(this, init);
  }
}
