import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { AddToCartConfirmationDialogComponent } from '../component/add-to-cart-confirmation-dialog/add-to-cart-confirmation-dialog.component';
import { Prop65MessageComponent } from '../component/prop-65-message/prop-65-message.component';
import { ICart, ICartItem } from '../model';

@Injectable({
  providedIn: 'root'
})
export class CartCommonDialogService {

  constructor(
    private readonly dialogService: DialogService,
    private readonly loadingService: LoadingService,
    private readonly router: Router,
    private readonly config: CoreConfiguration) { }

  openProp65ConcernDialog(onClose: () => void = null): DialogRef {
    const dialogRef = this.dialogService.open({
      title: 'Consent Required',
      width: 700,
      maxWidth: '90%',
      maxHeight: '90%',
      content: Prop65MessageComponent,
    });

    const componentRef: Prop65MessageComponent = dialogRef.content.instance;
    componentRef.closeDialog.subscribe(() => {
      dialogRef.close();
    });

    dialogRef.result.subscribe((result: any) => {
      if (onClose) {
        onClose();
      }
    });

    return dialogRef;
  }

  openAddToCartConfirmationDialog(
    items: ICartItem[],
    cart: ICart,
    onClose: (success: boolean, targetItems: ICartItem[]) => void = null): DialogRef {

    if (!items) { items = []; }

    let dialogTitle = '';
    let dialogMessage = '';

    let areTargetItemsInCart = false;
    const areThereItemsToAdd = !!items.length;
    const areAllTargetItemsAutoShip = areThereItemsToAdd && items.filter(i => i.isAutoShip).length === items.length;
    const areItemsOnTheCart = !!cart?.items?.length;

    if (areItemsOnTheCart && areThereItemsToAdd) {
      items.forEach(item => {
        const cartItem = cart.items.find((i) => i.itemNumber === item.itemNumber);
        if (cartItem) {
          item.itemIsInCart = true;
        }
      });

      areTargetItemsInCart = items.find(i => !i.itemIsInCart) ? false : true;
    }

    if (!areThereItemsToAdd) {
      dialogTitle = 'Unable to add the Item(s) to the cart';
      dialogMessage = 'There is not items selected to be added to the cart.';
    } else if (!areTargetItemsInCart) {
      dialogTitle = 'Unable to add the Item(s) to the cart';
      dialogMessage = 'Unable to add items to cart at this time, please try again later.';
    } else if (areAllTargetItemsAutoShip) {
      dialogTitle = 'Success! AutoShip & Save is added to cart';
      dialogMessage = 'You have added your selected AutoShip & Save to your cart. Subscription details can be updated during checkout. What do you want to do next?';
    } else {
      dialogTitle = 'Success! Item(s) added to cart';
      dialogMessage = 'You have added your selected item(s) to your cart. What do you want to do next?';
    }

    const dialogRef = this.dialogService.open({
      title: dialogTitle,
      width: 700,
      maxWidth: '90%',
      maxHeight: '90%',
      content: AddToCartConfirmationDialogComponent,
    });

    let redirectToCart = false;

    const componentRef: AddToCartConfirmationDialogComponent = dialogRef.content.instance;
    componentRef.message = dialogMessage;
    componentRef.messages = cart?.messages ? cart.messages : [];
    componentRef.continueShopping.subscribe(() => {
      dialogRef.close();
    });

    componentRef.viewCart.subscribe(() => {
      redirectToCart = true;
      this.router.navigateByUrl(this.config.webRoutes.root);
      dialogRef.close();
    });

    dialogRef.result.subscribe((result: any) => {
      if (!redirectToCart && onClose) {
        onClose(areTargetItemsInCart, items);
      }
    });

    this.loadingService.hide();

    return dialogRef;
  }
}
