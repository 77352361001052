import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogService } from '@progress/kendo-angular-dialog';
import { Subscription, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { IListItem } from 'src/app/shared/model/list-item';
import { CartDialogService } from 'src/app/shared/service/cart-dialog.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { HtmlUtilityService } from 'src/app/shared/service/html-utilitity.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { ApplicationSectionStep } from '../../model/application-section-step.enum';
import { IApplicationSection } from '../../model/application-section.model';
import { BusinessInformation } from '../../model/business-information.model';
import { BusinessTaxFileInformation } from '../../model/business-tax-file-information.model';
import { CustomCalendarService } from '../../service/custom-calendar.service';
import { ResellerApplicationService } from '../../service/reseller-application.service';

@Component({
  selector: 'lef-reseller-business-information',
  templateUrl: './reseller-business-information.component.html',
  styleUrls: ['./reseller-business-information.component.scss'],
})
export class ResellerBusinessInformationComponent implements OnInit, OnChanges, OnDestroy, IApplicationSection {
  stepNumber = '3.';
  stepTitle = 'Business Information';
  submitAction = 'continue';
  isDataAlreadyInitialized = false;
  applicationForm: UntypedFormGroup = new UntypedFormGroup({});
  onlinePlatforms = new UntypedFormArray([]);
  sellingPlatforms = new UntypedFormArray([]);
  businessBrands = new UntypedFormArray([]);
  businessReferences = new UntypedFormArray([]);
  countries: IListItem[] = [];
  states: IListItem[] = [];
  businessReferencesStates: IListItem[][] = [];
  primaryBusiness: IListItem[] = [];
  businessTypeLabels: IListItem[] = [];
  labelForBusinessTypeSelected: string;
  typeOfOwnership: IListItem[] = [];
  hearAboutLifeExtensionOptions: IListItem[] = [];
  regionalSalesManagers: IListItem[] = [];
  isInternetCatalog: boolean;
  isOtherPrimaryBusiness: boolean;
  isWholesalerDistributor: boolean;
  howHeardAboutLifeExtensionThroughtOtherSource: boolean;
  howHeardAboutLifeExtensionThroughtSalesRep: boolean;
  taxYears: IListItem[] = [];
  model: BusinessInformation;
  isEmailInvoice: boolean = true;
  isBusinessSubTypeOther: boolean;
  businessSubType: IListItem[] = [];
  businessSubTypeOther: string = 'OH';
  federaltaxfileid: number;
  stateresaletaxfileid: number;
  licensenumberfileid: number;

  @Input()
  applicationId: string;
  @Input()
  isActive = false;
  @Input()
  isEditable = false;
  @Input()
  hasContent = false;
  @Input()
  isContentValid = false;

  @ViewChild('federalTaxIdInfo', { read: TemplateRef }) federalTaxIdInfo: TemplateRef<any>;

  @Output()
  applicationProcessContinue: EventEmitter<IApplicationSection> = new EventEmitter<IApplicationSection>();

  @Output()
  activateSection: EventEmitter<ApplicationSectionStep> = new EventEmitter<ApplicationSectionStep>();

  get initialBusinessTaxFilesAmount(): number {
    return this.model?.taxInformation?.businessTaxFiles.filter(t => t.fileId !== this.config.emptyGuid)?.length;
  }
  customerRequestSaveSectionSubscription: Subscription;

  get businessTaxFiles(): UntypedFormArray {
    return (this.applicationForm.get('taxInformation') as UntypedFormGroup).get('businessTaxFiles') as UntypedFormArray;
  }

  get modelTaxExemptionFiles(): BusinessTaxFileInformation[] {
    return this.model?.taxInformation?.businessTaxFiles.filter(t => !t.isDeleted && t.fileId !== this.config.emptyGuid && t.fileType === 'TaxExemption');
  }

  get firstTaxExemptionIndex(): number {
    return this.businessTaxFiles.controls.findIndex((control, index) => index !== this.federaltaxfileid && index !== this.stateresaletaxfileid && index !== this.licensenumberfileid);
  }

  get federaltaxfile(): BusinessTaxFileInformation {
    return this.model.taxInformation.businessTaxFiles.find(t => t.fileType === 'FederalTax' && !t.isDeleted && t.fileId !== this.config.emptyGuid);
  }

  get stateresaletaxfile(): BusinessTaxFileInformation {
    return this.model.taxInformation.businessTaxFiles.find(t => t.fileType === 'StateResaleTax' && !t.isDeleted && t.fileId !== this.config.emptyGuid);
  }

  get licensenumberfile(): BusinessTaxFileInformation {
    return this.model.taxInformation.businessTaxFiles.find(t => t.fileType === 'LicenseNumber' && !t.isDeleted && t.fileId !== this.config.emptyGuid);
  }

  constructor(
    private readonly commonService: CommonService,
    private readonly loadingService: LoadingService,
    private readonly resellerApplicationService: ResellerApplicationService,
    private readonly customCalendar: CustomCalendarService,
    private readonly DOMElement: ElementRef,
    private readonly htmlUtilityService: HtmlUtilityService,
    private readonly cartDialogService: CartDialogService,
    private readonly config: CoreConfiguration,
    private readonly dialogService: DialogService
  ) { }

  isValid(): boolean {
    return this.applicationForm.valid;
  }

  isDirty(): boolean {
    return this.applicationForm.dirty;
  }

  activateBusinessSection() {
    this.activateSection.emit(ApplicationSectionStep.BusinessInformation);
  }

  saveSection(): void {
    this.loadingService.show('Saving business information');
    if (!this.isInternetCatalog) {
      this.onlinePlatforms.clear();
    }
    if (!this.isWholesalerDistributor) {
      this.businessBrands.clear();
      this.businessReferences.clear();
    }
    const model = new BusinessInformation(this.applicationForm.value);
    model.taxInformation.businessTaxFiles = this.model.taxInformation.businessTaxFiles.filter(t => t?.file);
    model.isValid = this.applicationForm.valid;
    if (!this.isWholesalerDistributor) {
      model.businessDetails.anticipatedOrderVolume = 0;
      model.businessDetails.numberOfRetailLocations = 0;
    }
    if (!this.isOtherPrimaryBusiness) {
      model.businessDetails.details = '';
    }

    model.verifyMarketingEmail = this.model.verifyMarketingEmail;
    model.verifyInvoiceEmail = this.model.verifyInvoiceEmail;

    this.resellerApplicationService.saveBusinessInformation(model).subscribe(
      (information) => {
        this.isContentValid = information.isValid;
        this.loadingService.hide();

        this.model = information;

        if (information.isMarketingEmailConfirmationNeeded && information.verifyMarketingEmail) {
          this.showEmailVerificationDialog(information.invoicePreferences.marketingEmail, 'verifyMarketingEmail');
          return;
        }

        if (information.isInvoiceEmailConfirmationNeeded && information.verifyInvoiceEmail) {
          this.showEmailVerificationDialog(information.invoicePreferences.invoiceEmailAddress, 'verifyInvoiceEmail');
          return;
        }

        if (!information.isValidMarketingEmail || !information.isValidInvoiceEmail) {
          return;
        }

        if (this.submitAction === 'continue') {
          this.applicationProcessContinue.emit(this as IApplicationSection);
        } else {
          this.initForm();
        }
      },
      () => {
        this.loadingService.hide();
      }
    );
  }

  save() {
    this.submitAction = 'save';
    this.saveSection();
  }

  onSubmit() {
    if (this.applicationForm.valid) {
      this.submitAction = 'continue';
      this.saveSection();
      return;
    }
    this.htmlUtilityService.focusFirstInvalidElement(this.DOMElement);
  }

  getCurrentStep(): ApplicationSectionStep {
    return ApplicationSectionStep.BusinessInformation;
  }

  ngOnInit(): void {
    this.customerRequestSaveSectionSubscription = this.resellerApplicationService.customerRequestSaveSection.subscribe(() => {
      if (this.isActive) {
        this.save();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.customerRequestSaveSectionSubscription) {
      this.customerRequestSaveSectionSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isActive) {
      if (changes.isActive.currentValue) {
        this.initializeData();
      }
    }
  }

  initializeData() {
    this.loadingService.show();
    forkJoin([
      this.commonService.getCountryList(),
      this.commonService.getStateList(''),
      this.resellerApplicationService.getBusinessConfigurations(),
      this.resellerApplicationService.getBusinessInformation(this.applicationId),
    ])
      .pipe(
        map(([countries, states, config, model]) => {
          return { countries, states, config, model };
        })
      )
      .subscribe((data) => {
        this.primaryBusiness = data.config.primaryBusiness;
        this.businessTypeLabels = data.config.businessTypeLabels;
        this.typeOfOwnership = data.config.typeOfOwnership;
        this.hearAboutLifeExtensionOptions = data.config.hearAboutLifeExtensionOptions;
        this.regionalSalesManagers = data.config.regionalSalesManagers;
        this.countries = data.countries;
        this.states = data.states;
        this.model = data.model;
        this.initForm();
        this.loadingService.hide();
        this.isDataAlreadyInitialized = true;
        this.taxYears = this.customCalendar.getYears();
        window.scrollTo(0, 0);
      });
  }

  initForm(): void {
    this.initOnlinePlatforms();
    this.initBusinessBrand();
    this.initBusinessReferences();
    this.initSellingPlatforms();
    const primaryBusiness = this.model.businessDetails.primaryBusiness;
    this.isInternetCatalog = primaryBusiness === 'I';
    this.isOtherPrimaryBusiness = primaryBusiness === 'O';
    this.isWholesalerDistributor = primaryBusiness === 'W';
    const businessSubType = this.model.businessDetails?.businessSubType;
    this.isBusinessSubTypeOther = businessSubType === this.businessSubTypeOther;
    this.federaltaxfileid = undefined;
    this.stateresaletaxfileid = undefined;
    this.licensenumberfileid = undefined;

    const detailsValidator = this.isOtherPrimaryBusiness ? [Validators.required] : [];
    const positiveNumberValidator = this.isWholesalerDistributor ? [Validators.required, Validators.min(0)] : [];
    const details = this.model.businessDetails;
    const tax = this.model.taxInformation;
    const preferences = this.model.orderPreferences;
    const invoicePreferences = this.model.invoicePreferences;

    this.applicationForm = new UntypedFormGroup({
      action: new UntypedFormControl(''),
      id: new UntypedFormControl(this.model.id),
      applicationId: new UntypedFormControl(this.model.applicationId),
      businessDetails: new UntypedFormGroup({
        primaryBusiness: new UntypedFormControl(details.primaryBusiness, [Validators.required]),
        typeOfOwnership: new UntypedFormControl(details.typeOfOwnership, [Validators.required]),
        onlinePlatforms: this.onlinePlatforms,
        details: new UntypedFormControl(details.details, detailsValidator),
        anticipatedOrderVolume: new UntypedFormControl(details.anticipatedOrderVolume, positiveNumberValidator),
        numberOfRetailLocations: new UntypedFormControl(details.numberOfRetailLocations, positiveNumberValidator),
        businessBrands: this.businessBrands,
        businessReferences: this.businessReferences,
        businessSubType: new UntypedFormControl(details.businessSubType, []),
        businessSubtypeDetails: new UntypedFormControl(details.businessSubtypeDetails, [])
      }),
      taxInformation: new UntypedFormGroup({
        federalTaxId: new UntypedFormControl(tax.federalTaxId, [Validators.required]),
        stateResaleTaxId: new UntypedFormControl(tax.stateResaleTaxId, [Validators.required]),
        licenseNumber: new UntypedFormControl(tax.licenseNumber, [Validators.required]),
        businessTaxFiles: new UntypedFormArray([]),
        year: new UntypedFormControl(tax.year, [Validators.required]),
      }),
      onlineSales: new UntypedFormGroup({
        readAndAgreed: new UntypedFormControl(this.model.onlineSales.readAndAgreed, [Validators.requiredTrue]),
        sellingPlatforms: this.sellingPlatforms,
      }),
      orderPreferences: new UntypedFormGroup({
        acceptBackorders: new UntypedFormControl(preferences.acceptBackorders, [Validators.required]),
        acceptReplacements: new UntypedFormControl(preferences.acceptReplacements, [Validators.required]),
        acceptPartialShipments: new UntypedFormControl(preferences.acceptPartialShipments, [Validators.required]),
        howHeardAboutLifeExtension: new UntypedFormControl(preferences.howHeardAboutLifeExtension, [Validators.required]),
        regionalSalesManager: new UntypedFormControl(preferences.regionalSalesManager, [Validators.required]),
        otherDetails: new UntypedFormControl(preferences.otherDetails, [Validators.required]),
      }),
      invoicePreferences: new UntypedFormGroup({
        isInvoiceNeeded: new UntypedFormControl(invoicePreferences.isInvoiceNeeded, [Validators.required]),
        mailInvoice: new UntypedFormControl(invoicePreferences.mailInvoice, [Validators.required]),
        emailInvoice: new UntypedFormControl(invoicePreferences.emailInvoice, [Validators.required]),
        invoiceEmailAddress: new UntypedFormControl(invoicePreferences.invoiceEmailAddress),
        marketingEmail: new UntypedFormControl(invoicePreferences.marketingEmail, [Validators.email]),
      }),
    });
    this.onHowHeardAboutLifeExtensionChange();
    this.setEmailInvoice(invoicePreferences.emailInvoice);
    if (!this.isEditable) {
      this.applicationForm.disable();
    }

    this.validateTaxFileControl('StateResaleTax');
    this.validateTaxFileControl('FederalTax');
    this.validateTaxFileControl('LicenseNumber');
    this.validateAddSalesTaxExemptionControl();

    this.getBusinessSubTypeList(primaryBusiness);
  }

  onHowHeardAboutLifeExtensionChange() {
    const howHeardAboutLifeExtension = (this.applicationForm.get('orderPreferences') as UntypedFormGroup).get('howHeardAboutLifeExtension').value;
    this.howHeardAboutLifeExtensionThroughtOtherSource = howHeardAboutLifeExtension === 'other';
    this.howHeardAboutLifeExtensionThroughtSalesRep = howHeardAboutLifeExtension === 'salesRep';

    const otherValidator = this.howHeardAboutLifeExtensionThroughtOtherSource ? [Validators.required] : [];
    const otherControl = (this.applicationForm.get('orderPreferences') as UntypedFormGroup).get('otherDetails');
    otherControl.setValidators(otherValidator);
    otherControl.updateValueAndValidity();

    const salesValidator = this.howHeardAboutLifeExtensionThroughtSalesRep ? [Validators.required] : [];
    const salesControl = (this.applicationForm.get('orderPreferences') as UntypedFormGroup).get('regionalSalesManager');
    salesControl.setValidators(salesValidator);
    salesControl.updateValueAndValidity();
  }

  onPrimaryBusinessChange() {
    const businessDetails = (this.applicationForm.get('businessDetails') as UntypedFormGroup).get('primaryBusiness').value;
    this.isInternetCatalog = businessDetails === 'I';
    this.isOtherPrimaryBusiness = businessDetails === 'O';
    this.isWholesalerDistributor = businessDetails === 'W';
    const label = this.businessTypeLabels.find((t) => t.value === businessDetails);
    this.labelForBusinessTypeSelected = label !== undefined ? label.description : '';
    this.getBusinessSubTypeList(businessDetails);
    this.checkBusinessDetailsDependentValidators();
  }

  checkBusinessDetailsDependentValidators() {
    const validator = this.isInternetCatalog ? [Validators.required] : [];
    for (const control of this.sellingPlatforms.controls) {
      if (control instanceof UntypedFormGroup) {
        ['platform', 'storefrontUrl'].forEach((key) => {
          const formControl = (control as UntypedFormGroup).get(key);
          formControl.setValidators(validator);
          formControl.updateValueAndValidity();
        });
      }
    }

    const otherValidator = this.isOtherPrimaryBusiness ? [Validators.required] : [];
    const details = (this.applicationForm.get('businessDetails') as UntypedFormGroup).get('details');
    details.setValidators(otherValidator);
    details.updateValueAndValidity();

    const requiredValidator = this.isWholesalerDistributor ? [Validators.required] : [];
    const positiveNumberValidator = this.isWholesalerDistributor ? [Validators.required, Validators.min(0)] : [];
    ['anticipatedOrderVolume', 'numberOfRetailLocations'].forEach((key) => {
      const formControl = (this.applicationForm.get('businessDetails') as UntypedFormGroup).get(key);
      formControl.setValidators(positiveNumberValidator);
      formControl.updateValueAndValidity();
    });

    for (const control of this.businessBrands.controls) {
      const brandControl = control.get('name') as UntypedFormControl;
      brandControl.setValidators(requiredValidator);
      brandControl.updateValueAndValidity();
    }
  }

  removeSellingPlatforms(i: number) {
    if (i > 0) {
      this.sellingPlatforms.removeAt(i);
    }
  }

  addSellingPlatforms() {
    const validator = this.isInternetCatalog ? [Validators.required] : [];
    const platform = new UntypedFormGroup({
      platform: new UntypedFormControl('', validator),
      storefrontUrl: new UntypedFormControl('', validator),
    });
    this.sellingPlatforms.push(platform);
  }

  initSellingPlatforms() {
    this.sellingPlatforms.clear();
    const validator = this.isInternetCatalog ? [Validators.required] : [];
    this.model.onlineSales.sellingPlatforms.forEach((s) => {
      const platform = new UntypedFormGroup({
        platform: new UntypedFormControl(s.platform, validator),
        storefrontUrl: new UntypedFormControl(s.storefrontUrl, validator),
      });
      this.sellingPlatforms.push(platform);
    });
  }

  initOnlinePlatforms() {
    this.onlinePlatforms.clear();
    this.model.businessDetails.onlinePlatforms.forEach((p) => {
      const onlinePlatform = new UntypedFormGroup({
        platform: new UntypedFormControl(p.platform, []),
        storefrontUrl: new UntypedFormControl(p.storefrontUrl, []),
      });
      this.onlinePlatforms.push(onlinePlatform);
    });
  }

  removeBusinessBrand(i: number) {
    if (i > 0) {
      this.businessBrands.removeAt(i);
    }
  }

  addBusinessBrand() {
    if (this.businessBrands.length < 5) {
      const wholesaleDistributorValidator = this.isWholesalerDistributor ? [Validators.required] : [];
      const businessBrandName = new UntypedFormGroup({
        name: new UntypedFormControl('', wholesaleDistributorValidator),
      });
      this.businessBrands.push(businessBrandName);
    }
  }

  initBusinessBrand() {
    this.businessBrands.clear();
    const wholesaleDistributorValidator = this.isWholesalerDistributor ? [Validators.required] : [];
    this.model.businessDetails.businessBrands.forEach((b) => {
      const businessBrandName = new UntypedFormGroup({
        name: new UntypedFormControl(b.name, wholesaleDistributorValidator),
      });
      this.businessBrands.push(businessBrandName);
    });
  }

  removeBusinessReferences(i: number) {
    if (i > 0) {
      this.businessReferences.removeAt(i);
    }
  }

  addBusinessReferences() {
    this.businessReferencesStates.push(this.states);
    const reference = new UntypedFormGroup({
      business: new UntypedFormControl(''),
      contact: new UntypedFormControl(''),
      address: new UntypedFormControl(''),
      city: new UntypedFormControl(''),
      state: new UntypedFormControl(''),
      country: new UntypedFormControl(''),
      zipCode: new UntypedFormControl(''),
      phone: new UntypedFormControl(''),
      fax: new UntypedFormControl(''),
    });
    this.businessReferences.push(reference);
  }

  initBusinessReferences() {
    while (this.businessReferencesStates.length < this.model.businessDetails.businessReferences.length) {
      this.businessReferencesStates.push(this.states);
    }
    this.businessReferences.clear();
    this.model.businessDetails.businessReferences.forEach((r) => {
      const reference = new UntypedFormGroup({
        business: new UntypedFormControl(r.business),
        contact: new UntypedFormControl(r.contact),
        address: new UntypedFormControl(r.address),
        city: new UntypedFormControl(r.city),
        state: new UntypedFormControl(r.state),
        country: new UntypedFormControl(r.country),
        zipCode: new UntypedFormControl(r.zipCode),
        phone: new UntypedFormControl(r.phone),
        fax: new UntypedFormControl(r.fax),
      });
      this.businessReferences.push(reference);
    });
  }

  onBusinessReferencesCountrySelectedChange(index: number) {
    const newCountry = this.businessReferences.at(index).get('country').value;
    this.loadingService.show();
    this.commonService.getStateList(newCountry).subscribe(
      (states) => {
        this.businessReferencesStates[index] = states;
        this.loadingService.hide();
      },
      () => {
        this.businessReferencesStates[index] = [];
        this.loadingService.hide();
      }
    );
  }

  onTaxEstablishedDateChange() {
    const year = (this.applicationForm.get('taxInformation') as UntypedFormGroup).get('year').value as string;
  }

  setEmailInvoice(isEmailInvoice: string) {
    if (isEmailInvoice === 'Y') {
      this.isEmailInvoice = true;
      this.applicationForm.get('invoicePreferences').get('invoiceEmailAddress').setValidators([Validators.required, Validators.email]);
    } else {
      this.isEmailInvoice = false;
      this.applicationForm.get('invoicePreferences').get('invoiceEmailAddress').clearValidators();
      this.applicationForm.get('invoicePreferences').get('invoiceEmailAddress').reset();
    }
    this.applicationForm.get('invoicePreferences').get('invoiceEmailAddress').updateValueAndValidity();
  }

  checkMarketingEmail() {
    if (this.applicationForm.get('invoicePreferences').get('marketingEmail').value.length) {
      this.applicationForm.get('invoicePreferences').get('marketingEmail').setValidators([Validators.required, Validators.email]);
    } else {
      this.applicationForm.get('invoicePreferences').get('marketingEmail').clearValidators();
    }
    this.applicationForm.get('invoicePreferences').get('marketingEmail').updateValueAndValidity();
  }

  onBusinessSubTypeChange(): void {
    const businessSubType = (this.applicationForm.get('businessDetails') as UntypedFormGroup).get('businessSubType').value;
    this.isBusinessSubTypeOther = businessSubType === this.businessSubTypeOther;

    const subTypeDetailsControl = (this.applicationForm.get('businessDetails') as UntypedFormGroup).get('businessSubtypeDetails');
    let subTypeDetailsValidator = [Validators.required];
    if (!this.isBusinessSubTypeOther) {
      subTypeDetailsValidator = [];
      subTypeDetailsControl.setValue('');
    }

    subTypeDetailsControl.setValidators(subTypeDetailsValidator);
    subTypeDetailsControl.updateValueAndValidity();
  }

  onFileSourceChange(event: any, fileType: string, index: number) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        const businessTaxFile = new BusinessTaxFileInformation(this.config.emptyGuid, file.name, reader.result.toString(), fileType);
        const modelIndex = this.initialBusinessTaxFilesAmount + index;
        this.model.taxInformation.businessTaxFiles.splice(modelIndex, 1, businessTaxFile);
        this.businessTaxFiles.controls[index].setValue(file.name);
      };
    }
    else {
      this.deleteTaxFileByIndex(index);
      this.validateFileIds(index);
      this.validateTaxFileControl(fileType);
    }
  }

  onTaxExemptionFileSourceChange(event: any, index: number) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        let businessTaxFile = new BusinessTaxFileInformation(this.config.emptyGuid, file.name, reader.result.toString(), 'TaxExemption');
        const modelIndex = this.initialBusinessTaxFilesAmount + index;
        this.model.taxInformation.businessTaxFiles.splice(modelIndex, 1, businessTaxFile);
        this.businessTaxFiles.controls[index].setValue(file.name);
      };
    }
    else {
      this.deleteTaxFileByIndex(index);
      this.validateFileIds(index);
      this.addTaxFileControl('TaxExemption');
    }
  }

  onTaxFileRemoved(fileId: string) {
    const file = this.model.taxInformation.businessTaxFiles.find(t => t.fileId === fileId);
    file.isDeleted = true;
    this.validateTaxFileControl(file.fileType);
  }

  onTaxExemptionRemoveFromModel(fileId: string) {
    this.model.taxInformation.businessTaxFiles.find(t => t.fileId === fileId).isDeleted = true;
    this.validateAddSalesTaxExemptionControl();
  }

  onTaxExemptionRemove(index: number) {
    this.deleteTaxFileByIndex(index);
    this.validateFileIds(index);
    this.validateAddSalesTaxExemptionControl();
  }

  disableAddTaxExemptionControl(): boolean {
    return this.businessTaxFiles?.controls?.filter((control, index) => index !== this.federaltaxfileid && index !== this.stateresaletaxfileid && index !== this.licensenumberfileid).some(control => !control.value)
  }

  addTaxFileControl(fileType: string): void {
    if (!this.isEditable) return;
    this.businessTaxFiles.push(new UntypedFormControl('', fileType !== 'TaxExemption' ? Validators.required : []));
    this.model.taxInformation.businessTaxFiles.push(new BusinessTaxFileInformation(this.config.emptyGuid, '', '', fileType));
  }

  openFileInput(id: string): void {
    document.getElementById(id).click();
  }

  handleKeydown(event: KeyboardEvent) {
    event.preventDefault();
  }

  showFederalTaxInformation(): void {
    const dialogRef = this.dialogService.open({
      title: 'What is a Federal Tax ID?',
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
      content: this.federalTaxIdInfo
    });
  }

  private showEmailVerificationDialog(message: string, propertyName: string): void {
    this.cartDialogService.showEmailVerificationDialog(message, (confirm) => {
      if (confirm) {
        this.model[propertyName] = false;
        this.saveSection();
      }
    });
  }

  private getBusinessSubTypeList(primaryBusiness: string): void {
    this.loadingService.show();
    this.resellerApplicationService.getBusinessSubTypeList(primaryBusiness).subscribe((businessSubType) => {
      this.businessSubType = businessSubType;
      this.validateBusinessSubType();
      this.loadingService.hide();
    },
      (error) => {
        this.businessSubType = [];
        this.validateBusinessSubType();
        this.loadingService.hide();
      });
  }

  private validateBusinessSubType(): void {
    let subTypeValidator = [Validators.required];
    const subTypeControl = (this.applicationForm.get('businessDetails') as UntypedFormGroup).get('businessSubType');
    if (this.businessSubType?.length < 1) {
      subTypeValidator = [];
      subTypeControl.setValue(null);

      const subTypeDetailsControl = (this.applicationForm.get('businessDetails') as UntypedFormGroup).get('businessSubtypeDetails');
      subTypeDetailsControl.setValue('');
      subTypeDetailsControl.setValidators([]);
      subTypeDetailsControl.updateValueAndValidity();
      this.isBusinessSubTypeOther = false;
    }
    subTypeControl.setValidators(subTypeValidator);
    subTypeControl.updateValueAndValidity();
  }

  private validateTaxFileControl(fileType: string): void {
    if (!this[`${fileType.toLocaleLowerCase()}file`]?.file) {
      this.addTaxFileControl(fileType);
      this[`${fileType.toLocaleLowerCase()}fileid`] = this.businessTaxFiles.controls.length - 1;
    }
  }

  private validateFileIds(index: number): void {
    if (this.federaltaxfileid > index) {
      this.federaltaxfileid--;
    }

    if (this.stateresaletaxfileid > index) {
      this.stateresaletaxfileid--;
    }

    if (this.licensenumberfileid > index) {
      this.licensenumberfileid--;
    }
  }

  private validateAddSalesTaxExemptionControl(): void {
    if (this.businessTaxFiles?.controls?.filter((control, index) => index !== this.federaltaxfileid && index !== this.stateresaletaxfileid && index !== this.licensenumberfileid)?.length < 1 &&
      !this.model?.taxInformation?.businessTaxFiles?.some(t => !t.isDeleted && t.fileType === 'TaxExemption' && t?.file)) {
      this.addTaxFileControl('TaxExemption');
    }
  }

  private deleteTaxFileByIndex(index: number): void {
    const modelIndex = this.initialBusinessTaxFilesAmount + index;
    if (this.model.taxInformation.businessTaxFiles?.[modelIndex] !== undefined) {
      this.model.taxInformation.businessTaxFiles.splice(modelIndex, 1);
    }
    this.businessTaxFiles.removeAt(index);
  }
}