import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ICartItemPriceBreak } from 'src/app/cart-common/model';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { BaseService } from 'src/app/shared/service/base-service';
import { IAutoShip } from '../model/auto-ship';
import { IAutoShipAddItem } from '../model/auto-ship-add-item';
import { IAutoShipAddress } from '../model/auto-ship-address';
import { IAutoShipChange } from '../model/auto-ship-change';

@Injectable({
  providedIn: 'root',
})
export class AutoShipService extends BaseService<IAutoShip> {
  protected apiUrl: string;

  public readonly autoShipChange: Subject<IAutoShip> = new Subject();

  constructor(protected override readonly httpClient: HttpClient, private readonly config: CoreConfiguration) {
    super(httpClient);

    this.apiUrl = config.environment.api + config.apiRoutes.autoShip;
  }

  getAutoShip(refresh: boolean): Observable<IAutoShip> {
    return this.getSingle(`${this.apiUrl}/GetAutoShip?refresh=${refresh}`);
  }

  getShippingAddressList(): Observable<IAutoShipAddress[]> {
    return this.httpGet<IAutoShipAddress[]>(`${this.apiUrl}/GetShippingAddressList`);
  }

  getItemPriceBreakList(itemNumber: string): Observable<ICartItemPriceBreak[]> {
    return this.httpGet<ICartItemPriceBreak[]>(`${this.apiUrl}/GetItemPriceBreakList?itemNumber=${itemNumber}`);
  }

  updateAutoShip(autoShip: IAutoShip, autoShipNumber: number): Observable<IAutoShip> {
    return this.post(`${this.apiUrl}/UpdateAutoShip?autoShipNumber=${autoShipNumber}`, autoShip);
  }

  updateRewardsUse(useRewardsOnVip: boolean): Observable<IAutoShip> {
    return this.post(`${this.apiUrl}/updateRewardsUse?useRewardsOnVip=${useRewardsOnVip}`, {});
  }

  changeAddress(changeRequest: IAutoShipChange): Observable<IAutoShip> {
    return this.post(`${this.apiUrl}/ChangeAddress`, changeRequest);
  }

  changePaymentMethod(changeRequest: IAutoShipChange): Observable<IAutoShip> {
    return this.post(`${this.apiUrl}/ChangePaymentMethod`, changeRequest);
  }

  rescheduleDate(changeRequest: IAutoShipChange): Observable<IAutoShip> {
    return this.post(`${this.apiUrl}/rescheduleDate`, changeRequest);
  }

  reactivateItem(itemId: string): Observable<IAutoShip> {
    return this.post(`${this.apiUrl}/ReactivateItem?itemId=${itemId}`, {});
  }

  deactivateItem(itemId: string): Observable<IAutoShip> {
    return this.post(`${this.apiUrl}/DeactivateItem?itemId=${itemId}`, {});
  }

  deleteItem(itemId: string): Observable<IAutoShip> {
    return this.post(`${this.apiUrl}/DeleteItem?itemId=${itemId}`, {});
  }

  addItem(newItemInfo: IAutoShipAddItem): Observable<IAutoShipAddItem> {
    return this.httpClient
      .post<IAutoShipAddItem>(`${this.apiUrl}/AddItem`, newItemInfo, { headers: this.getHeaders })
      .pipe(catchError(this.handleError));
  }

  addPremierItem(paymentOptionId: string): Observable<IAutoShip> {
    return this.post(`${this.apiUrl}/AddPremierItem?paymentOptionId=${paymentOptionId}`, {});
  }
}
