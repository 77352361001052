import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthorizationService } from 'src/app/authorization';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { CustomerData } from 'src/app/tracking/model/customer-data.model';
import { TrackingService } from 'src/app/tracking/service/tracking.service';
import { OffersNotification } from '../../model/offers-notification.model';
import { OffersNotificationService } from '../../service/offers-notification.service';

@Component({
  selector: 'lef-offers-notification',
  templateUrl: './offers-notification.component.html',
  styleUrls: ['./offers-notification.component.scss'],
})
export class OffersNotificationComponent implements OnInit {
  isNotificationEnabled: boolean;
  customerHasOffersAvailable: boolean;
  offersAvailableCounter = 0;
  myLifeExtensionPage = `${this.config.environment.contentSite}/mylifeextension`;
  customerNumber: string;

  @Input() offerNotificationId: string = '';
  @Output() offersCounterUpdated: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private readonly config: CoreConfiguration,
    private readonly trackingService: TrackingService,
    private readonly authorizationService: AuthorizationService,
    private readonly offersNotificationService: OffersNotificationService
  ) { }

  ngOnInit(): void {
    this.customerNumber = this.authorizationService.userCustomerNumber();
    this.isNotificationEnabled = this.config.environment.myOffers.enabled;
    if (this.config.environment.myOffers.enabled) {
      this.trackingService.customerDataChanged.subscribe({ next: (customer) => this.onCustomerDataChanged(customer) });
      this.updateOffersNotification();
    }
  }

  private onCustomerDataChanged(customer: CustomerData) {
    const cn = this.authorizationService.userCustomerNumber();
    if (this.customerNumber !== cn) {
      this.customerNumber = cn;
      this.isNotificationEnabled = this.config.environment.myOffers.enabled && this.customerNumber !== '';
      this.updateOffersNotification();
    }
  }

  private updateOffersNotification() {
    this.customerHasOffersAvailable = true;
    this.offersAvailableCounter = 0;
    this.myLifeExtensionPage = `${this.config.environment.contentSite}/mylifeextension`;
    if (!this.authorizationService.isAnonymousCustomer()) {
      this.offersNotificationService.get(this.customerNumber).subscribe((offersNotification: OffersNotification) => {
        this.offersAvailableCounter = offersNotification.availableOffersCount;
        if (offersNotification?.myLifeExtensionPage) {
          this.myLifeExtensionPage = offersNotification.myLifeExtensionPage;
        }

        this.offersCounterUpdated.emit(this.offersAvailableCounter);
      });
    }
  }
}
