import { Injectable } from '@angular/core';
import * as Coveo from 'coveo-search-ui';
import { CoreConfiguration } from 'src/app/core/core-configuration';

declare const ExtensionUtility: any;

@Injectable({
  providedIn: 'root',
})
export class CoveoService {

  private hasExtensionConflicts = false;

  constructor(private readonly config: CoreConfiguration) { }

  public initSearchBox = (searchBox: HTMLElement, resultPageUrl: string): void => {
    this.createEndpoint();
    this.subscribeToRedirectEvent(searchBox, resultPageUrl);
    this.subscribeToBuildingQueryEvent(searchBox);
    this.subscribetoCheckExtentionCompatibility(searchBox);
    this.subscribeToOverrideAttributes(searchBox);

    try {
      new ExtensionUtility().hasSearchBoxConflicts().then(hasConflicts => {
        this.hasExtensionConflicts = hasConflicts;
        Coveo.initSearchbox(searchBox, resultPageUrl);
      }).catch(() => {
        Coveo.initSearchbox(searchBox, resultPageUrl);
      });
    } catch (e) {
      Coveo.initSearchbox(searchBox, resultPageUrl);
    }
  }

  public initSearchInterface = (searchInterface: HTMLElement, externalSearchBox: HTMLElement = null): void => {
    this.setLoggerLevel();
    this.createEndpoint();
    this.subscribeToBuildingQueryEvent(searchInterface);
    this.subscribetoCheckExtentionCompatibility(searchInterface, true);

    if (externalSearchBox) {
      Coveo.init(searchInterface, { externalComponents: [externalSearchBox] });
    } else {
      Coveo.init(searchInterface);
    }
  }

  private setLoggerLevel() {
    if (!Coveo?.Logger) {
      return;
    }

    let level = this.config.coveoConfiguration.loggerLevel;
    if (!level || level < 1 || level > 6) {
      level = 6;
    }

    Coveo.Logger.level = this.config.coveoConfiguration.loggerLevel;
  }

  private createEndpoint = (): void => {
    if (Coveo && Coveo.SearchEndpoint && Coveo.SearchEndpoint.endpoints && Coveo.SearchEndpoint.endpoints.default) {
      // EndPoint is already initialized;
      return;
    }

    Coveo.SearchEndpoint.configureCloudV2Endpoint(
      this.config.coveoConfiguration.organization,
      this.config.coveoConfiguration.apiKey,
      this.config.coveoConfiguration.restApiUrl,
      {
        queryStringArguments: {
          searchHub: this.config.coveoConfiguration.searchHub,
        },
      }
    );
  }

  private initCustomComponents = (): void => {
    // Initialize Coveo Custom Components
    // Initialization.registerAutoCreateComponent(DemoComponent);
  }

  private subscribeToRedirectEvent = (rootElement: HTMLElement, resultPageUrl: string): void => {
    Coveo.$$(rootElement).on('afterInitialization', () => {
      Coveo.$$(rootElement).on('beforeRedirect', (e, data) => {
        data.cancel = true;
        const currentQuery = Coveo.state(rootElement, 'q');

        if (currentQuery) {
          const redirectionUrl = `${resultPageUrl}#q=${currentQuery}`;
          window.location.href = redirectionUrl;
        }
      });
    });
  }

  private subscribetoCheckExtentionCompatibility = (rootElement: HTMLElement, isSearchBoxNested: boolean = false): void => {
    Coveo.$$(rootElement).on('afterInitialization', () => {
      if (this.hasExtensionConflicts) {
        let searchBox: any;
        if (isSearchBoxNested) {
          searchBox = Coveo.get(rootElement.querySelector('.CoveoSearchbox'), Coveo.Searchbox);
        } else {
          searchBox = Coveo.get(rootElement, Coveo.Searchbox);
        }
        if (!searchBox) return;
        searchBox.searchbox.magicBox.inputManager.onfocus = () => { };
        searchBox.searchbox.magicBox.inputManager.onchange = () => { };
      }
    });
  }

  private subscribeToBuildingQueryEvent = (rootElement: HTMLElement): void => {
    if (!rootElement || !this.config.coveoConfiguration.queryExpressions || !this.config.coveoConfiguration.queryExpressions.length) {
      return;
    }
    const queryExpressions = this.config.coveoConfiguration.queryExpressions;

    Coveo.$$(rootElement).on('afterInitialization', () => {
      Coveo.$$(rootElement).on('buildingQuery', (e, args) => {
        const queryBuilder = args.queryBuilder;
        if (!queryBuilder || !queryBuilder.advancedExpression) {
          return;
        }

        queryExpressions.forEach((qExp) => {
          if (qExp.isNotEqualExpression) {
            queryBuilder.advancedExpression.addFieldNotEqualExpression(qExp.field, qExp.values);
          } else {
            queryBuilder.advancedExpression.addFieldExpression(qExp.field, qExp.operator, qExp.values);
          }
        });
      });
    });
  }

  private subscribeToOverrideAttributes(searchBox: HTMLElement): void {
    Coveo.$$(searchBox).on('afterInitialization', () => {
      const searchBoxElement = searchBox?.children[2];
      if (!searchBoxElement) return;

      searchBoxElement.removeAttribute('role');
      searchBoxElement.removeAttribute('tabindex');
      searchBoxElement.setAttribute("href", "");
      searchBoxElement.setAttribute("onclick", "return false");

      const spanElements = searchBoxElement.getElementsByTagName('span');
      if (spanElements?.length) {
        for (var i = 0; i < spanElements?.length; i++) {
          const svgElements = spanElements[i].getElementsByTagName('svg');
          if (svgElements?.length) {
            for (var j = 0; j < svgElements?.length; j++) {
              const svgElement = svgElements[j];
              if (svgElement) {
                svgElement.removeAttribute('aria-label');
                svgElement.setAttribute('aria-hidden', 'true');
                svgElement.removeAttribute('role');
              }
            }
          }
        }
      }
    });
  }
}
