import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IPaymentOption } from 'src/app/payment/model';
import { IShippingMethod } from 'src/app/shipping/model';
import { IAutoShipAddress } from '../../model/auto-ship-address';
import { IAutoShipChange } from '../../model/auto-ship-change';
import { IAutoShipDelivery } from '../../model/auto-ship-delivery';
import { AutoShipValuesService } from '../../service';
import { AutoShipDialogService } from '../../service/auto-ship-dialog.service';
import { AutoShipService } from '../../service/auto-ship.service';

@Component({
  selector: 'lef-auto-ship-delivery',
  templateUrl: './auto-ship-delivery.component.html',
  styleUrls: ['./auto-ship-delivery.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AutoShipDeliveryComponent implements OnInit {

  @Input()  delivery: IAutoShipDelivery;
  @Input()  isActiveView: boolean;

  @Output()
  updateAutoShip: EventEmitter<number> = new EventEmitter();

  isBodyHidden = false;

  get itemsCount(): number {
    return this.delivery?.items?.length ?? 0;
  }

  get shippingDay(): string {
    return this.delivery?.dayOfMonth
      ? this.valuesService.getShippingDayOfMonth(this.delivery.dayOfMonth, true)
      : '';
  }

  get shippingAddress(): string {
    return this.delivery
      ? `${this.delivery.addressLn1}, ${this.delivery.state} ${this.delivery.countryDescription}`
      : '';
  }

  get shippingMethod(): IShippingMethod {
    return this.delivery.shippingMethods?.find(s => s.shippingMethod === this.delivery.shippingMethod);
  }

  showPaymentOptions = false;

  constructor(
    private readonly autoShipService: AutoShipService,
    private readonly dialogService: AutoShipDialogService,
    private readonly valuesService: AutoShipValuesService,
  ) { }

  ngOnInit() {
  }

  changePaymentMethod() {
    this.showPaymentOptions = !this.showPaymentOptions;
  }

  onPaymentSelected(paymentOption: IPaymentOption) {
    const paymentChange: IAutoShipChange = {
      paymentOptionId: paymentOption.id,
      autoShipNumber: this.delivery.autoShipNumber
    };

    this.autoShipService.changePaymentMethod(paymentChange).subscribe((autoShip) => {
      this.autoShipService.autoShipChange.next(autoShip);
      this.showPaymentOptions = false;
    });
  }

  addItems() {
    this.dialogService.openAddItemDialog(
      this.delivery.autoShipNumber,
      this.delivery.alias,
      this.shippingAddress,
      this.shippingDay,
      this.onAddItem.bind(this)
    );
  }

  onAddItem(itemNumber: string) {
    this.autoShipService.getAutoShip(false).subscribe((autoShip) => {
      this.autoShipService.autoShipChange.next(autoShip);
    });
  }

  changeShippingDate() {
    this.dialogService.openDateSelectionDialog(
      this.delivery.dayOfMonth,
      this.onChangeShippingDate.bind(this)
    );
  }

  onChangeShippingDate(shippingDay: number) {
    this.delivery.dayOfMonth = shippingDay;
    this.doUpdateAutoShip();
  }

  changeShippingAddress() {
    this.autoShipService.getShippingAddressList().subscribe(
      (addressList) => {
        this.openShippingAddressDialog(addressList);
      }
    );
  }

  openShippingAddressDialog(shippingAddresses: IAutoShipAddress[]) {
    shippingAddresses.forEach(a => {
      a.isSelected = a.shipToNumber === this.delivery.shipToNumber ? true : false;
    });

    this.dialogService.openAddressSelectionDialog(
      shippingAddresses,
      this.shippingDay,
      this.delivery.autoShipNumber
    );
  }

  changeShippingMethod() {
    this.dialogService.openShippingMethodSelectionDialog(
      this.delivery.shippingMethod,
      this.delivery.shippingMethods,
      this.shippingDay,
      this.onChangeShippingMethod.bind(this)
    );
  }

  onChangeShippingMethod(shippingMethod: string) {
    this.delivery.shippingMethod = shippingMethod;
    this.doUpdateAutoShip();
  }

  toggleVisibility() {
    this.isBodyHidden = !this.isBodyHidden;
  }

  doUpdateAutoShip() {
    this.updateAutoShip.emit(this.delivery.autoShipNumber);
  }

}
