import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { ICart } from 'src/app/cart-common/model';
import { PremierService } from 'src/app/cart-common/service';
import { ShippingInformationComponent } from 'src/app/shipping/component/shipping-information/shipping-information.component';
import { IShippingMethod } from 'src/app/shipping/model/shipping-method';
import { IShippingSummary } from 'src/app/shipping/model/shipping-summary';
import { CartService } from '../../service';

@Component({
  selector: 'lef-cart-shipping',
  templateUrl: './cart-shipping.component.html',
  styleUrls: ['./cart-shipping.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CartShippingComponent implements OnInit, OnChanges {
  @Input() shipping: IShippingSummary = null;
  @Input() showPremierDiscount = false;

  @Output() shippingMethodUpdated: EventEmitter<ICart> = new EventEmitter();

  @ViewChild('showShipping', {static : false}) showShipping: ElementRef<any>;
  @ViewChild('confirmShipping', {static : false}) confirmShipping: ElementRef<any>;

  private stopKeyListner: () => void;

  editingShipping: boolean;
  shippingMethod: string;
  selectedShippingMethod: IShippingMethod;
  shippingMethods: IShippingMethod[] = [];

  constructor(
    private readonly cartService: CartService,
    private readonly dialogService: DialogService,
    public readonly premierService: PremierService,
    private readonly renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.initShippingMethods();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.shipping) {
      this.initShippingMethods();
    }
  }

  initShippingMethods(): void {
    if (!this.shipping) {
      return;
    }

    this.shippingMethods = this.shipping.shippingMethods ? this.shipping.shippingMethods : [];
    this.shippingMethod = this.shipping.shippingMethod ? this.shipping.shippingMethod.shippingMethod : '';
    this.selectedShippingMethod = this.shipping.shippingMethod;
  }

  onShippingMethodClick(selectedMethod: IShippingMethod) {
    if (!selectedMethod) {
      return;
    }

    this.shippingMethod = selectedMethod.shippingMethod;
  }

  toggleShippingEdition() {
    if (!this.shipping || !this.shipping.shippingMethod || !this.shippingMethod) {
      return;
    }

    this.editingShipping = !this.editingShipping;

    if (!this.editingShipping)  {
      if (this.stopKeyListner) {
        this.stopKeyListner();
      }
      if (this.shippingMethod !== this.shipping.shippingMethod.shippingMethod) {
        this.updateShippingMethod();
      }
    } else {
      this.stopKeyListner = this.renderer.listen('document', 'keydown', ($event: KeyboardEvent) => {
        const isTabKey = $event.key === 'Tab';
        if (isTabKey) {
          if ($event.shiftKey) {
            if (this.showShipping.nativeElement === document.activeElement) {
              this.confirmShipping.nativeElement.focus();
              $event.preventDefault();
              return;
            }
          } else if (this.confirmShipping.nativeElement === document.activeElement) {
            this.showShipping.nativeElement.focus();
            $event.preventDefault();
          }
        }
      });
    }
  }

  updateShippingMethod() {
    this.cartService.setShippingMethod(this.shippingMethod).subscribe(
      (result) => {
        if (result) {
          this.shippingMethodUpdated.emit(result);
        }
      },
      (err) => {
        // console.error(err);
      }
    );
  }

  showShippingInformation() {
    const dialogRef = this.dialogService.open({
      title: 'Shipping Information',
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
      content: ShippingInformationComponent,
    });
  }
}
