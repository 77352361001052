import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CartCommonModule } from '../cart-common/cart-common.module';
import { CommonsModule } from '../common/common.module';
import { SharedModule } from '../shared/shared.module';
import {
  ShippingAddressComponent,
  ShippingAddressInUseComponent,
  ShippingAddressListComponent,
  ShippingAddressSelectionComponent,
  ShippingInformationComponent,
  ShippingMethodSelectionComponent
} from './component';
import { ShippingAddressUndeliverableComponent } from './component/shipping-address-undeliverable/shipping-address-undeliverable.component';

const modules = [CommonModule, SharedModule, CommonsModule, CartCommonModule, RouterModule];

const components = [
  ShippingAddressSelectionComponent,
  ShippingMethodSelectionComponent,
  ShippingInformationComponent,
  ShippingAddressComponent,
  ShippingAddressListComponent,
  ShippingAddressInUseComponent,
  ShippingAddressUndeliverableComponent,
];

@NgModule({
    imports: [modules],
    exports: [SharedModule, components],
    declarations: [components]
})
export class ShippingModule {}
