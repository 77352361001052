import { Component, OnInit } from '@angular/core';
import { ICart } from 'src/app/cart-common/model';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { TrackingService } from '../../service/tracking.service';

declare global {
  interface Window {
    Grin: any;
  }
}

@Component({
  selector: 'lef-grin',
  templateUrl: './grin.component.html',
  styleUrls: ['./grin.component.scss'],
})
export class GrinComponent implements OnInit {
  constructor(private readonly trackingService: TrackingService, private readonly config: CoreConfiguration) {}

  ngOnInit(): void {
    if (this.config.environment.enableGrinTracking && this.trackingService.checkOneTrustActiveGroup(this.config.oneTrustGroups.socialMedia)) {
      this.trackingService.orderPlaced.subscribe({ next: (cart) => this.onOrderPlaced(cart) });
    }
  }

  onOrderPlaced(cart: ICart) {
    if (!window.Grin) {
      return;
    }
    // send the conversion to Grin
    const Grin = window.Grin || (window.Grin = []);
    Grin.push(['conversion', cart.totalOrder, { order_number: cart.orderNumber }]);
  }
}
