import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DialogRef, DialogSettings, DialogService as KendoDialogService } from '@progress/kendo-angular-dialog';

@Injectable({
  providedIn: 'root',
})
export abstract class DialogService {
  private activeDialogs: DialogRef[] = [];
  private renderer: Renderer2;
  private isClickListenerEnabled: boolean;
  private stopDomClickListener: () => void;

  constructor(rendererFactory: RendererFactory2, private readonly dialogService: KendoDialogService) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.restartClickListener();
  }

  open(options: DialogSettings): DialogRef {
    const dialogRef: DialogRef = this.dialogService.open(options);

    dialogRef.result.subscribe((result) => {
      this.removeFromActiveList(dialogRef);
    });

    this.addToActiveDialogList(dialogRef);
    return dialogRef;
  }

  openClickListenerDisbled(options: DialogSettings): DialogRef {
    this.stopDomClickListener();

    return this.open(options);
  }

  public stopClickListener(): void {
    if (this.stopDomClickListener) {
      this.stopDomClickListener();
    }

    this.activeDialogs = [];
  }

  public restartClickListener(): void {
    this.stopClickListener();

    this.isClickListenerEnabled = true;
    this.activeDialogs = [];

    this.stopDomClickListener = this.renderer.listen('document', 'click', ($event): void => {
      this.onClick($event.target);
    });
  }

  private addToActiveDialogList(dialog: DialogRef): void {
    if (!this.isClickListenerEnabled) {
      return;
    }
    this.activeDialogs.push(dialog);
  }

  private onClick(target: any): void {
    if (target &&
      target.className &&
      target.className.indexOf &&
      typeof target.className.indexOf === 'function' &&
      target.className.indexOf('k-overlay') >= 0 &&
      this.activeDialogs &&
      this.activeDialogs.length) {

      const dialog = this.activeDialogs.pop();
      this.closeDialog(dialog);
    }
  }

  private closeDialog(dialog: DialogRef): void {
    dialog.close();
    this.removeFromActiveList(dialog);
  }

  private removeFromActiveList(dialog: DialogRef): void {
    if (!this.isClickListenerEnabled) {
      return;
    }
    this.activeDialogs = this.activeDialogs.filter((d) => d !== dialog);
  }
}
