import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { BaseService } from 'src/app/shared/service/base-service';
import { MinorConsent } from '../model/minor-consent.model';

@Injectable({
  providedIn: 'root',
})
export class MinorConsentService extends BaseService<MinorConsent> {
  protected apiUrl: string;

  constructor(protected override readonly httpClient: HttpClient, private readonly config: CoreConfiguration) {
    super(httpClient);

    this.apiUrl = config.environment.api + config.apiRoutes.minorConsent;
  }

  get(orderNumber: number): Observable<MinorConsent> {
    return this.httpGet<MinorConsent>(`${this.apiUrl}/GetConsent?orderNumber=${orderNumber}`);
  }

  set(consent: MinorConsent): Observable<MinorConsent> {
    return this.httpClient.put<MinorConsent>(`${this.apiUrl}/SetConsent`, consent);
  }
}
