import { BusinessDetails } from './business-detail.model';
import { BusinessTaxInformation } from './business-tax-information.model';
import { InvoicePreference } from './invoice-preference';
import { OnlineSales } from './online-sales';
import { OrderPreference } from './order-preference';

export class BusinessInformation {
  isValid: boolean;
  id: string;
  applicationId: string;
  isInvoiceEmailConfirmationNeeded: boolean;
  isMarketingEmailConfirmationNeeded: boolean;
  verifyMarketingEmail: boolean;
  verifyInvoiceEmail: boolean;
  isValidMarketingEmail: boolean;
  isValidInvoiceEmail: boolean;
  marketingEmailMessage: string;
  invoiceEmailMessage: string;
  businessDetails: BusinessDetails;
  taxInformation: BusinessTaxInformation;
  onlineSales: OnlineSales;
  orderPreferences: OrderPreference;
  invoicePreferences: InvoicePreference;

  public constructor(init?: Partial<BusinessInformation>) {
    this.businessDetails = new BusinessDetails();
    this.taxInformation = new BusinessTaxInformation();
    this.onlineSales = new OnlineSales();
    this.orderPreferences = new OrderPreference();
    this.invoicePreferences = new InvoicePreference();
    Object.assign(this, init);
  }
}
