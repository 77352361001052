import { BusinessBrand } from './business-brand.model';
import { BusinessReference } from './business-reference.model';
import { OnlinePlatform } from './online-platform.model';

export class BusinessDetails {
  primaryBusiness: string;
  businessSubType?: string;
  businessSubtypeDetails?: string;
  typeOfOwnership: string;
  details: string;
  anticipatedOrderVolume: number;
  numberOfRetailLocations: number;
  onlinePlatforms: OnlinePlatform[];
  businessBrands: BusinessBrand[];
  businessReferences: BusinessReference[];

  public constructor() {
    this.onlinePlatforms = [];
    this.businessBrands = [];
    this.businessReferences = [];
  }
}
