<div class="font-md">

    <div class="d-flex justify-content-end mb-3">
        Effective Date: February 1, 2024
    </div>
    <div class="mb-3">
        This Supplemental Privacy Statement & Consent (<strong>“Consent”</strong>) describes how Life Extension
        Foundation
        Buyers Club, Inc.
        and its group of
        affiliated companies and subsidiaries (<strong>“Life Extension,” “our,” “us,”</strong> or <strong>“we”</strong>)
        will
        collect, use, store, transfer,
        and disclose personal information
        about natural persons (<strong>“I,” “my,” “you,”</strong> or <strong>“your”</strong>) made available to us
        through
        our Wholesaler Application process
        (<strong>“Application”</strong>), and on or
        through our business systems, electronic communications (including emails, telephone and video calls, and
        messaging
        services), and
        websites used to provide and support this Application process (each a <strong>“Service,”</strong> together, the
        <strong>“Services”</strong>).
    </div>
    <div class="mb-3">
        <strong>• NOTE: You must indicate your CONSENT at the end of this document for your Wholesale application to
            proceed.</strong>
    </div>
    <div class="mb-3">
        • This Consent should be read in conjunction with our Global Privacy Statement (“Global Statement”) available at
        www.lifeextension.com/
        privacy.
    </div>
    <div class="mb-3">
        • If you encounter difficulty accessing this Consent, we provide additional resources to help you in our
        Accessibility
        Statement available at
        www.lifeextension.com/accessibility.
    </div>
    <div class="mb-3">
        <strong>Consent Recitals</strong>
    </div>
    <div class="mb-3">
        <strong>I Understand</strong> that any information (personal or otherwise) that I provide to Life Extension
        about
        myself, my
        business(es) and my
        employee(s) is voluntary, and will be used to process my Application, and if my Application is approved, will
        continue to be used to provide
        Services that my business(es) have requested.
    </div>
    <div class="mb-3">
        <strong>I Understand</strong> that any false, inaccurate, misleading, or incomplete information may result in my
        Application being
        denied, and where my
        Application has been approved, may result in an immediate suspension or termination of these Services.
    </div>
    <div class="mb-3">
        I Understand that any personal information that I provide to Life Extension about myself, my business(es) and my
        employee(s) will be
        governed by this Consent and the <a [href]="globalPrivacyUrl"><strong class="underline">Global Privacy
                Statement.</strong></a>
    </div>
    <div class="mb-3">• IThis generally means that Life Extension will only collect personal information that is
        relevant,
        necessary, and
        adequate to accomplish the
        purpose for which it is collected and retain this information for as long as necessary to evaluate your
        Application,
        provide our Services to
        your business(es), operate our business (where we have a legitimate interest in retaining certain information),
        and
        for as long as required to
        comply with applicable legal, regulatory, and other compliance requirements.</div>
    <div class="mb-3">
        <strong>I Understand</strong> that Life Extension will collect the following categories of personal information
        as
        part of my
        Application, and if my Application
        has been approved, will may collect additional categories described in the Global Statement:
    </div>
    <div class="d-flex justify-content-center mb-3">
        <table>
            <thead>
                <tr>
                    <th>Category of Personal Information</th>
                    <th>Examples</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Account Information</td>
                    <td>including name, email address, postal address, telephone number, username, user id and contact
                        preferences.</td>
                </tr>
                <tr>
                    <td>Commercial Information</td>
                    <td>including records of property ownership, buying preferences, products and services purchased,
                        obtained, or considered, and other purchasing or consuming histories.</td>
                </tr>
                <tr>
                    <td>Contact Information</td>
                    <td>including name, postal address, email address, mobile SMS address, telephone number, and contact
                        preferences.</td>
                </tr>
                <tr>
                    <td>Device Data</td>
                    <td>including device id, device name, device type, device settings, device usage, and MAC address
                        for
                        devices used with our Services.</td>
                </tr>
                <tr>
                    <td>Electronic Communications</td>
                    <td>including calendaring, emails, live chats, telephone and video calls, and text messaging that we
                        may
                        process when you communicate with us.</td>
                </tr>
                <tr>
                    <td>Financial Information</td>
                    <td>including payment card information and wire transfer information --depending on which method of
                        payment you have selected for your business(es).</td>
                </tr>
                <tr>
                    <td>Identifiers</td>
                    <td>including name, alias, electronic signature, email address, postal address, online identifiers,
                        telephone number, and Internet Protocol (IP) address.</td>
                </tr>
                <tr>
                    <td>Internet or other Electronic Network Activities</td>
                    <td>including browser history, clickstream data, crash data, search history, information on
                        interactions
                        with our Services, emails, or advertisements, referring/exit URLs, and Internet Protocol (IP)
                        addresses.</td>
                </tr>
                <tr>
                    <td>Online Identifiers</td>
                    <td>including advertiser ids, analytic ids, cookie id, pixel tags, scripts, and Internet Protocol
                        (IP)
                        addresses.</td>
                </tr>
                <tr>
                    <td>Personal Information Categories listed in California Customer Records statute, Cal. Civ.
                        1798.80(e)
                    </td>
                    <td>including any information that identifies, relates to, describes, or is capable of being
                        associated
                        with, a particular individual, including name, bank account number, postal address, telephone
                        number, payment card number or other financial information.</td>
                </tr>
                <tr>
                    <td>Wholesaler Account Information</td>
                    <td>including business names, contact preferences, email address, estimated annual revenue, fax
                        number,
                        legal entity formation records, postal address, selling platform details, tax registration
                        number,
                        telephone number, username, user id, and websites.</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="mb-3">
        <strong>I Understand</strong> that Life Extension processes, accesses, and stores any information (personal or
        otherwise) relating to
        my Application and
        these Services on servers and equipment located in the United States, and in other countries, depending on the
        location of Life Extension’s
        tions may not offer the same equivalent level of protection to personal information as the country where I, my
        business(es) and my
        employee(s) are located.
    </div>
    <div class="mb-3">
        <strong>I Understand</strong> that any contact information I provide about myself, my business(es) and my
        employee(s) will be used by
        Life Extension to tion
        or to provide Services my business(es) have requested.
    </div>
    <div class="mb-3">
        <strong>I Understand</strong> that before I disclose any personal information about my employee(s), I need to
        obtain
        their individual
        consent, which I and my
        business(es) are responsible for doing prior to disclosing to Life Extension.
    </div>
    <div class="mb-3">
        <strong>I Understand</strong> that I, my business(es) and my employee(s) may have certain data rights regarding
        the
        collection, use,
        storage, transfer, and
        tion, including the right to withdraw from this Consent at any time, and to opt-out (or unsubscribe) to certain
        electronic communications from
        Life Extension (including promotional and marketing communications). I can learn more about what data rights are
        available and how to
        exercise these rights in the <a [href]="dataRightsUrl"><strong class="underline">Data Rights & Your Privacy
                Choices</strong></a> to the Global
        Statement.
    </div>
    <div class="mb-3">
        <strong>I Understand</strong> the Data Controller for personal information collected under this Consent is:
    </div>
    <div>
        Life Extension Foundation Buyers Club, Inc.
    </div>
    <div>
        3600 West Commercial Blvd.
    </div>
    <div class="mb-3">
        Fort Lauderdale, FL. 33309. USA
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="p-3">
        <div>
            <div class="mb-3">
                <strong>NOTE: You must choose “ACCEPT” for both options below for your Wholesale application to
                    proceed.</strong>
            </div>
            <div class="mb-3 d-flex justify-content-center flex-wrap">
                <div class="col-12 col-md-9 p-0">
                    <strong>I Authorize and Explicitly Consent</strong> to Life Extension and its group of affiliated
                    companies
                    and
                    subsidiaries collecting, processing, storing, transferring, and disclosing my personal information
                    in
                    accordance with this Consent and the Global Privacy Statement.
                </div>
                <div class="d-flex justify-content-start col-12 col-md-3">
                    <div class="d-flex align-items-center  mr-4">
                        <input type="radio" class="k-radio" value="Y" name="explicitlyConsent"
                               formControlName="explicitlyConsent">
                        <label>Accept</label>
                    </div>
                    <div class="d-flex align-items-center">
                        <input type="radio" class="k-radio" value="N" name="explicitlyConsent"
                               formControlName="explicitlyConsent">
                        <label for="oExplicitlyConsent">Decline</label>
                    </div>
                </div>
            </div>
            <div class="mb-3 d-flex justify-content-between flex-wrap">
                <div class="col-12 col-md-9 p-0">
                    <strong>I Represent and Warrant</strong> that I am at least eighteen (18) years of age, and have the
                    right,
                    authority, and
                    capacity to accept this Consent. If I am completing this process on behalf of another natural or
                    legal
                    person, I further represent and warrant that I have the right, authority, and capacity to bind those
                    persons to this Consent, including by obtaining their individual consent to disclose their personal
                    information to Life Extension.
                </div>
                <div class="d-flex justify-content-start col-12 col-md-3">
                    <div class="d-flex align-items-center mr-4">
                        <input type="radio" class="k-radio" value="Y" name="representAndWarrant"
                               formControlName="representAndWarrant">
                        <label>Accept</label>
                    </div>
                    <div class="d-flex align-items-center">
                        <input id="noRepresentAndWarrant" type="radio" class="k-radio" value="N"
                               name="representAndWarrant" formControlName="representAndWarrant">
                        <label for="noRepresentAndWarrant">Decline</label>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end col-12">
                <button class="btn btn-action" type="submit" [disabled]="isSubmitDisabled">Submit</button>
            </div>
        </div>
    </form>
</div>