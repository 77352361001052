import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { BaseService } from 'src/app/shared/service/base-service';
import { Cart, ICarouselItem } from '../model';

@Injectable({
  providedIn: 'root',
})
export class CartCommonService extends BaseService<Cart> {
  protected apiUrl: string;

  constructor(protected override readonly httpClient: HttpClient, private readonly config: CoreConfiguration) {
    super(httpClient);

    this.apiUrl = config.environment.api + config.apiRoutes.cart;
  }

  applyMarketingCode(marketingCode: string, force: boolean, includeShipments: boolean): Observable<Cart> {
    return this.post(
      `${this.apiUrl}/ApplyMarketingCode?marketingCode=${marketingCode}&force=${force}&includeShipments=${includeShipments}`,
      null
    );
  }

  getCartCarouselItems(carouselId: string): Observable<ICarouselItem[]> {
    return this.httpGet<ICarouselItem[]>(`${this.apiUrl}/GetCartCarouselItems?carouselId=${carouselId}`);
  }
}
