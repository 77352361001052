<div>
  <p class="bg-light p-2 text-dark mb-2"><strong>Notice</strong></p>
  <div class="d-flex align-items-center">
    <i class="lef-warning font-2x text-blue mr-3"></i>
    <div>
      <div class="text-blue font-sm">{{verification.message}}</div>
    </div>
  </div>
  <div class="my-3">
    <div class="mb-2"><button class="btn btn-action m-auto" (click)="stay()">Stay at LifeExtension.com</button></div>
    <button class="btn btn-action m-auto" (click)="goToDistributorSite(verification.distributorSiteUrl)">Go to {{
      verification.distributor}}</button>
  </div>
  <div class="my-3" *ngIf="!!verification.wholesalerDistributorURL">
    <div class="text-center">
      <span>If interested in reseller opportunities</span>
      <button class="btn btn-action m-auto" (click)="goToDistributorSite(verification.wholesalerDistributorURL)">Go to
        {{
        verification.wholesalerDistributorName}}</button>
    </div>
  </div>
  <div>
    <p class="font-md m-0">
      We use cookies to enhance site navigation, analyze site usage, and assist in our marketing
      efforts. Our Cookie Statement provides more information and explains how to change your
      cookie settings.
      <a [attr.href]="cookieStatement" rel="nofollow" target="_blank">View our Cookies Statement.</a>
    </p>
  </div>
</div>