import { Component } from '@angular/core';

@Component({
  selector: 'lef-shipping-address-undeliverable',
  templateUrl: './shipping-address-undeliverable.component.html',
  styleUrls: ['./shipping-address-undeliverable.component.scss']
})
export class ShippingAddressUndeliverableComponent {

}
