import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICart, ILabTestItem } from 'src/app/cart-common/model';
import { CartLabEmailInfo } from 'src/app/cart-common/model/cart-lab-email-info.model';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { BaseService } from 'src/app/shared/service/base-service';
import { ILabSubject } from '../model';
import { ILabResult } from '../model/lab-result';
import { ILabSubjectList } from '../model/lab-subject-list';

@Injectable({
  providedIn: 'root',
})
export class LabTestService extends BaseService<ILabSubject> {
  protected apiUrl: string;

  constructor(protected override readonly httpClient: HttpClient, private readonly config: CoreConfiguration) {
    super(httpClient);

    this.apiUrl = config.environment.api + config.apiRoutes.labTest;
  }

  getLabSubjects(): Observable<ILabSubjectList> {
    return this.httpGet<ILabSubjectList>(`${this.apiUrl}/GetLabSubjects`);
  }

  getLabTestResults(subjectNumber: number): Observable<ILabResult[]> {
    return this.httpGet<ILabResult[]>(`${this.apiUrl}/GetLabTestResults?subjectNumber=${subjectNumber}`);
  }

  getLabTestResult(labId: string, resultId: string): Observable<any> {
    return this.getPdf(`${this.apiUrl}/GetLabTestResult?labId=${labId}&resultId=${resultId}`);
  }

  getLabSubject(subjectNumber: number): Observable<ILabSubject> {
    return this.getSingle(`${this.apiUrl}/GetLabSubject?subjectNumber=${subjectNumber}`);
  }

  getNewLabSubject(): Observable<ILabSubject> {
    return this.getSingle(`${this.apiUrl}/GetNewLabSubject`);
  }

  createLabSubject(labSubject: ILabSubject): Observable<ILabSubject> {
    return this.add(`${this.apiUrl}/CreateLabSubject`, labSubject);
  }

  updateLabSubject(labSubject: ILabSubject): Observable<ILabSubject> {
    return this.update(`${this.apiUrl}/UpdateLabSubject`, labSubject);
  }

  validateDob(labSubject: ILabSubject): Observable<ILabSubject> {
    return this.add(`${this.apiUrl}/ValidateDob`, labSubject);
  }

  validatePostalCode(labSubject: ILabSubject): Observable<ILabSubject> {
    return this.add(`${this.apiUrl}/ValidatePostalCode`, labSubject);
  }

  createLabItemSubject(labSubject: ILabSubject): Observable<ICart> {
    return this.httpClient.post<ICart>(`${this.apiUrl}/CreateLabItemSubject`, labSubject);
  }

  updateLabItemSubject(labSubject: ILabSubject): Observable<ICart> {
    return this.httpClient.put<ICart>(`${this.apiUrl}/UpdateLabItemSubject`, labSubject);
  }

  updateSubjectEmail(emailInfo: CartLabEmailInfo): Observable<ICart> {
    return this.httpClient.put<ICart>(`${this.apiUrl}/UpdateSubjectEmail`, emailInfo);
  }

  setSubject(labTest: ILabTestItem): Observable<ICart> {
    return this.httpClient.put<ICart>(`${this.apiUrl}/SetSubject`, labTest);
  }

  setConsent(labTest: ILabTestItem): Observable<ICart> {
    return this.httpClient.put<ICart>(`${this.apiUrl}/SetConsent`, labTest);
  }
}
