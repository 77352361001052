import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

export enum ScreenSizes {
  XS,
  SM,
  MD,
  LG,
  XL,
}

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  breakpoints: { size: ScreenSizes; min: number; max: number }[] = [
    { size: ScreenSizes.XS, min: 0, max: 576 },
    { size: ScreenSizes.SM, min: 576, max: 768 },
    { size: ScreenSizes.MD, min: 768, max: 992 },
    { size: ScreenSizes.LG, min: 992, max: 1200 },
    { size: ScreenSizes.XL, min: 1200, max: undefined },
  ];

  currentSize: ScreenSizes;

  get onResize$(): Observable<ScreenSizes> {
    return this.resizeSubject.asObservable().pipe(distinctUntilChanged());
  }

  private resizeSubject: Subject<ScreenSizes> = new Subject();
  private renderer: Renderer2;

  constructor(private readonly rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.setResizeListener();
  }

  checkCurrentSize() {
    const breakpoint = this.breakpoints.find((b) => {
      return b.min <= window.innerWidth && (b.max === undefined || window.innerWidth < b.max);
    });

    this.currentSize = breakpoint.size;
    this.resizeSubject.next(this.currentSize);
  }

  private setResizeListener() {
    this.renderer.listen('window', 'resize', (): void => {
      this.checkCurrentSize();
    });
  }
}
