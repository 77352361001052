<div class="shipping-address-selection" [ngClass]="componentClass">
  <div *ngIf="shippingMethods" class="selection-body">
    <div *ngFor="let shippingMethod of shippingMethods" class="selection-record d-flex justify-content-between py-2">
      <label class="d-flex justify-content-between w-100 clickable">
        <div class="d-flex justify-content-between">
          <input kendoRadioButton class="k-radio form-check-input" type="radio" [value]="shippingMethod.shippingMethod"
                 [(ngModel)]="selectedMethod" />
          <div>
            <div>
              {{shippingMethod.description}}
            </div>
            <div *ngIf="showPremierDiscount">
              {{premierService.getDiscountText(shippingMethod.rate)}} with <span class="premier">Premier</span>
            </div>
          </div>
        </div>
        <div>{{premierService.getRateText(shippingMethod.rate)}}</div>
      </label>
    </div>
  </div>
  <div class="d-flex justify-content-end">
    <button class="btn btn-action px-5 ng-star-inserted" (click)="onMethodSelected()">Select</button>
  </div>
  <hr>
  <div class="ship-disc pt-1">
    <p><small>Orders placed <strong>before</strong> 2:30 p.m. EST Monday-Friday excluding holidays will be shipped
        out the same day. Orders placed <strong>after</strong> 2:30 p.m. EST Monday-Friday excluding holidays will
        be shipped the following business day. Please note that days in transit exclude weekends and
        holidays.</small></p>
  </div>
</div>