import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICartMessage } from '../../model';

@Component({
  selector: 'lef-add-to-cart-confirmation-dialog',
  templateUrl: './add-to-cart-confirmation-dialog.component.html',
  styleUrls: ['./add-to-cart-confirmation-dialog.component.scss']
})
export class AddToCartConfirmationDialogComponent implements OnInit {

  @Input() message: string;
  @Input() messages: ICartMessage[] = [];

  @Output() continueShopping: EventEmitter<any> = new EventEmitter();
  @Output() viewCart: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void { }

  continue() {
    this.continueShopping.emit(true);
  }

  viewMyCart() {
    this.viewCart.emit(true);
  }
}
