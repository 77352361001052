import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'lef-reseller-acknowledgment',
  templateUrl: './reseller-acknowledgment.component.html',
  styleUrls: ['./reseller-acknowledgment.component.scss']
})
export class ResellerAcknowledgmentComponent implements OnInit {

  @Output() okayClicked: EventEmitter<void> = new EventEmitter<void>();

  globalPrivacyUrl: string = 'https://www.lifeextension.com/legal/global-privacy-notice';
  dataRightsUrl: string = 'https://www.lifeextension.com/legal/global-privacy-notice#14';
  form: FormGroup;

  get isSubmitDisabled(): boolean {
    return this?.form?.get('explicitlyConsent')?.value !== 'Y' || this?.form?.get('representAndWarrant')?.value !== 'Y';
  }

  ngOnInit(): void {
    this.initForm();
  }

  onSubmit(): void {
    this.okayClicked.emit();
  }

  private initForm(): void {
    this.form = new FormGroup({
      explicitlyConsent: new FormControl('Y', [Validators.required]),
      representAndWarrant: new FormControl('Y', [Validators.required])
    })
  }
}
