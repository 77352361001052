import { Component, Input, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { ICartExtendedMessage } from '../../model';

@Component({
  selector: 'lef-cart-extended-message',
  templateUrl: './cart-extended-message.component.html',
  styleUrls: ['./cart-extended-message.component.scss'],
})
export class CartExtendedMessageComponent implements OnInit {
  @Input() messages: ICartExtendedMessage[] = [];
  @ViewChild('bottleCapBanner', { read: TemplateRef }) bottleCapBanner: TemplateRef<any>;

  constructor(private readonly renderer: Renderer2, private readonly dialogService: DialogService, readonly config: CoreConfiguration) { }

  ngOnInit(): void { }

  showInfo(message: ICartExtendedMessage): void {
    const template = this.getTemplate(message.infoMessageTemplate);
    if (template) {
      const infoDialogRef = this.dialogService.open({
        title: message.infoMessageTitle,
        width: 800,
        maxHeight: '90%',
        maxWidth: '90%',
        content: template,
      });
    }
  }

  getTemplate(template: string): TemplateRef<any> {
    if (template === 'bottleCapBanner') {
      return this.bottleCapBanner;
    }
    return null;
  }

  getIconClass(message: ICartExtendedMessage): string {
    let styleClass = '';
    switch (message.type) {
      case 'Default': {
        styleClass = '';
        break;
      }
      case 'Info': {
        styleClass = 'lef-info-hex';
        break;
      }
      case 'Warning': {
        styleClass = 'lef-warning';
        break;
      }
      case 'Success': {
        styleClass = 'k-icon k-font-icon k-i-check-circle';
        break;
      }
      case 'Error': {
        styleClass = 'lef-warning';
        break;
      }
      default: {
        styleClass = '';
        break;
      }
    }

    return styleClass;
  }

  getStyleClass(message: ICartExtendedMessage): string {
    let styleClass = 'alert alert-light';
    switch (message.type) {
      case 'Default': {
        styleClass = 'alert alert-light';
        break;
      }
      case 'Info': {
        styleClass = 'alert alert-info';
        break;
      }
      case 'Warning': {
        styleClass = 'alert alert-warning';
        break;
      }
      case 'Success': {
        styleClass = 'alert alert-success';
        break;
      }
      case 'Error': {
        styleClass = 'alert alert-danger';
        break;
      }
      default: {
        styleClass = 'alert alert-light';
        break;
      }
    }

    $(".alert").attr("role", "alert");
    return styleClass;



  }

  getMessageText(message: ICartExtendedMessage): string {
    if (!message.message.match(/&[A-z]+;/g)) {
      return message.message;
    }
    const text = this.renderer.createElement('textarea') as HTMLTextAreaElement;
    text.innerHTML = message.message;
    return text.value;
  }
}
