import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, timer } from 'rxjs';
import { switchMapTo } from 'rxjs/operators';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { LoadingService } from 'src/app/shared/service/loading.service';

@Injectable({
  providedIn: 'root',
})
export class RefreshService {
  private reset = new EventEmitter<void>();
  private refresh: Observable<number>;
  private timeout: number;
  private timeoutTimer: () => Observable<number>;
  private refreshSubscription: Subscription;

  private appVersion = '';
  private refreshNeeded = false;

  public readonly appVersionHeader = 'ui-app-v';

  constructor(
    private readonly config: CoreConfiguration,
    private readonly activatedRoute: ActivatedRoute,
    private readonly loadingService: LoadingService,
    private readonly router: Router
  ) {
    this.timeout = config.refreshConfiguration.refreshTimeout * 60000;
    this.router.onSameUrlNavigation = 'reload';
    this.resetTimer();
  }

  updateRefreshData(appVersion: string) {
    // Initialize AppVersion
    if (!this.appVersion && appVersion) {
      this.appVersion = appVersion;
      this.config.runtimeAppVersion = appVersion;
      this.resetTimer();
      return;
    }

    // We need to reload , but not right now.
    if (this.appVersion && appVersion && appVersion !== this.appVersion) {
      this.refreshNeeded = true;
    }

    this.resetTimer();
  }

  refreshIfNeeded(url: string = null): boolean {
    if (!this.refreshNeeded) {
      return false;
    }

    this.loadingService.show();
    this.refreshNeeded = false;
    if (url) {
      window.location.href = url;
    } else {
      window.location.reload();
    }
    return true;
  }

  private resetTimer() {
    if (!this.config.refreshConfiguration.refreshOnIdle) {
      return;
    }

    if (this.refreshSubscription) {
      this.reset.next();
    } else {
      this.timeoutTimer = () => timer(this.timeout);
      this.refresh = this.reset.pipe(switchMapTo(this.timeoutTimer()));

      this.refreshSubscription = this.refresh.subscribe((i) => {
        const currentRoute = this.getChild(this.activatedRoute);

        const routeData = currentRoute.snapshot.data;
        if (!routeData.skipRefreshOnIdle) {
          if (routeData.refreshTo) {
            window.location.href = window.location.origin + routeData.refreshTo;
          } else {
            window.location.reload();
          }
        }
      });
    }
  }

  private getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
}
