<div class="row auto-ship-item">
  <div class="col-md-8 border-md-right auto-ship-item-lt">
    <div class="row mt-3 mb-3">
      <div class="col">
        <p class="mb-2"><a [attr.href]="itemUrl" class="text-primary"><strong>{{item.friendlyName}}</strong></a>
        </p>
        <!-- <p class="cart-item-number mb-3">
          <span>{{item.itemDescription}}</span>
        </p> -->
        <div *ngIf="delivery.isBilling">
          <p><strong class="text-primary"> Expiration Date: </strong>
            {{item.subscriptionExpirationDate | date: 'MM/dd/yyyy'}}*
          </p>

        </div>
        <div *ngIf="item.isActive else premierInfo">
          <p>You'll get $50 LE Dollars instantly and a $10 LE Dollar bonus with each renewal, plus access to unlimited
            FREE shipping, exclusive sales and premium content. Cancel at any time! <button
                    class="btn-link-txt d-inline" aria-haspopup="dialog"
                    (click)="showSubscriptionInformation()"><span>Learn
                More</span><i class="lef-information-solid text-info ml-1"></i></button></p>
        </div>
        <ng-template #premierInfo>
          <p>
            Don't let your subscription lapse, so you can keep enjoying unlimited FREE shipping, exclusive sales and
            premium content.
          </p>
          <p>
            <strong class="text-uppercase text-primary">Special Deal:</strong> Get $50 LE Dollars instantly when you
            auto-renew. Sign up TODAY and we'll add
            an extra $10 LE Dollar reward! <button class="btn-link-txt d-inline" aria-haspopup="dialog"
                    (click)="showSubscriptionInformation()"><span>Learn
                More</span><i class="lef-information-solid text-info ml-1"></i></button>
          </p>
          <p class="m-0">
            <small>*We'll email you when your Premier Rewards is due to expire.</small>
          </p>
        </ng-template>
      </div>

      <div class="col-md-2">
        <div class="auto-ship-item-price">
          <lef-cart-item-price [extendedPrice]="item.itemPrice" [totalBaseStandardPrice]="item.baseStandardPrice"
                               [percentSavings]="item.percentageSavings">
          </lef-cart-item-price>
        </div>
      </div>
    </div>
    <div *ngIf="showPaymentMethod || item.isActive || item.showErrorMessages || !delivery?.isBilling">
      <lef-payment-method-selector [paymentOptionSelectedId]="item.paymentOptionId"
                                   [paymentMethodLabel]="'Payment Method:'" [hidePaymentMethodActions]="true"
                                   [labelClass]="'pr-3'" (paymentOptionSelected)="onPaymentSelected($event)">
      </lef-payment-method-selector>
    </div>
    <div *ngIf="!item?.isActive && delivery?.isBilling && !item.showErrorMessages && item.isSubscriptionRenewAvailable">
      <lef-premier-renew-banner></lef-premier-renew-banner>
    </div>
    <div class="mt-2" *ngIf="item.isActive || item.showErrorMessages || !delivery?.isBilling">
      <lef-cart-messages [messages]="item.messages"></lef-cart-messages>
    </div>
  </div>
  <div class="col-md-4 text-right auto-ship-item-rt">
    <div *ngIf="item.isActive">
      <div><button class="mt-2 btn btn-action" (click)="deactivate()">Cancel Auto-Renew</button></div>
      <!-- <div><button class="mt-2 btn btn-link" aria-haspopup="dialog" (click)="showSubscriptionInformation()">Learn
          More</button></div> -->
    </div>
    <div *ngIf="!item.isActive" class="auto-ship-item-rt-react">
      <div><button class="mt-2 btn btn-action" (click)="reactivate()">Activate Auto-Renew</button></div>
      <!-- <div><button class="mt-2 btn btn-link" aria-haspopup="dialog" (click)="showSubscriptionInformation()">Learn
          More</button></div> -->
    </div>
  </div>
</div>