import { ErrorHandler, Injectable } from '@angular/core';
import { AppInsightsMonitoringService } from '../service/app-insights-monitoring.service';

@Injectable()
export class ErrorMonitoringHandler extends ErrorHandler {
  constructor(private monitoringService: AppInsightsMonitoringService) {
    super();
  }

  override handleError(error): void {
    this.monitoringService.trackException(error);
    super.handleError(error);
  }
}
