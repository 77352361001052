// Wrapper Class
export class Visitor {
  visitorCookie: VisitorCookie;
  newVisit: boolean;

  constructor(visitorCookie: VisitorCookie, newVisit: boolean) {
    if (!visitorCookie) {
      return;
    }

    this.visitorCookie = visitorCookie;
    this.newVisit = newVisit;
  }
}

// LEV Cookie
export class VisitorCookie {
  // Visitor
  LEV_VSTR: string;
  // Visit
  LEV_V: string;
  // Visit Date
  LEV_D: string;
  // Customer
  LEV_C: string;
  // Tracked?
  LEV_T: string;
}

// Form Data for a new Visit
export class Visit {
  visitId: string;
  visitorId: string;
  customerNumber: string;
  visitStart: string;
  referrer: string;
  firstPage: string;
  initialQueryString: string;
}

// Form Data for Activity
export class Activity {
  visitId: string;
  customerNumber: string;
  activityType: string;
  activityDate: string;
  activityData: string;
}
