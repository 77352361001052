import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lef-cart-super-sale-banner',
  templateUrl: './cart-super-sale-banner.component.html',
  styleUrls: ['./cart-super-sale-banner.component.scss'],
})
export class CartSuperSaleBannerComponent implements OnInit {
  constructor() {}
  @Input() enabled: boolean;

  ngOnInit(): void {}
}
