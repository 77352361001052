import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthorizationService } from 'src/app/authorization';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { CoveoService } from 'src/app/coveo/service/coveo.service';
import { IMenuLink } from '../../model/menu-link';
import { HeaderService } from '../../service/header.service';

declare var window: any;
declare global {
  interface Window {
    openSideMenuNavigation: any;
    closeSideMenuNavigation: any;
    closeAllSideMenuNavigation: any;
  }
}
@Component({
  selector: 'lef-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() mainNavBar = '';
  @Input() mainMenu = '';
  @Input() mainMenuWS = '';

  @ViewChild('mainSearchBox') searchBoxElementRef: ElementRef;
  @ViewChild('mobileNavTrigger') mobileNavTrigger: ElementRef;

  menu = '';
  baseUrl = '';
  itemCount = 0;
  username = '';
  isWholesaler = null;
  accountMenuLinks: IMenuLink[] = [];
  userHasOffersAvailable = false;
  logoImgClass: string = '';
  logoWebUrl: string = '';
  logoMobileUrl: string = '';
  contactUrl: string = '';
  consumerLabUrl: string = '';

  authorizationChangeSubscription: Subscription;

  get showHeaders(): boolean {
    return this.headerService.showFullHeader;
  }

  get webNavLogoUrl(): string {
    return this.setLogoStyleProperties('logoWebUrl');
  }

  get mobileNavLogoUrl(): string {
    return this.setLogoStyleProperties('logoMobileUrl');
  }

  get webImgClass(): string {
    return this.setLogoStyleProperties('logoImgClass');
  }

  setLogoStyleProperties(propertyName: string): string {
    this.logoImgClass = '';
    this.logoWebUrl = `${this.baseUrl}/-/media/project/lifeextension/images/logo/le-logo-svg.svg?rev=87127ddb108648aa9bf71c9ed237f8ef&h=75&w=200&la=en&hash=D8E7AC0CF5E35466F65C3FE531322CD8`;
    this.logoMobileUrl = `${this.baseUrl}/-/media/project/lifeextension/images/logo/le-logo-svg-no-tag.svg?rev=4efec91774554aeb9814ce052380e847&h=46&w=160&la=en&hash=38CF6189566FC120AD7F37702829E468`;
    if (this.authorizationService?.userCustomerType()?.toLowerCase() === 'w') {
      this.logoImgClass = 'web-logo';
      this.logoWebUrl = `https://www.lifeextension.com/-/media/project/lifeextension/images/logo/LE-White-200x87.svg`;
      this.logoMobileUrl = `${this.baseUrl}/-/media/project/lifeextension/images/logo/le_wholesale_logo_mobile_160x47.svg?rev=68c176136a2f42ff91b148b459a7000e&la=en&hash=DFD38D81AE31A6DD55121C255405DD1E`;
    }
    return this[propertyName];
  }

  constructor(
    private readonly config: CoreConfiguration,
    private readonly authorizationService: AuthorizationService,
    public readonly headerService: HeaderService,
    private readonly coveoService: CoveoService
  ) {
    this.baseUrl = config.environment.contentSite;
    this.contactUrl = this.baseUrl + '/quest-com';
    this.consumerLabUrl = this.baseUrl + '/lpages/consumerlab';
  }

  ngOnInit(): void {
    this.refreshMenu();
    this.subscribeToScrollEvent();
    this.subscribeToAuthorizationChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.mainMenu && this.mainMenu) || (changes.mainMenuWS && this.mainMenuWS)) {
      this.refreshMenu();
    }
  }

  ngAfterViewInit() {
    this.initCoveoSearchBox();
  }

  initCoveoSearchBox() {
    this.coveoService.initSearchBox(this.searchBoxElementRef.nativeElement, this.config.coveoConfiguration.searchPage);
  }

  ngOnDestroy(): void {
    if (this.authorizationChangeSubscription) {
      this.authorizationChangeSubscription.unsubscribe();
      this.authorizationChangeSubscription = null;
    }
  }

  getUserName() {
    this.username = this.authorizationService.userFullName();
  }

  onOffersCounterUpdated(availableOffersCount): void {
    this.userHasOffersAvailable = availableOffersCount >= 0;
  }

  subscribeToAuthorizationChange() {
    this.authorizationChangeSubscription = this.authorizationService.authorizationChange.subscribe(() => {
      this.refreshMenu();
    });
  }

  onLinkedClicked(): void {
    (this.mobileNavTrigger.nativeElement as any).click();
  }

  closeSideMenu(): void {
    window.closeAllSideMenuNavigation();
  }

  private refreshMenu() {
    this.getUserName();
    this.createAccountMenu();
    this.setSideMenu();
  }

  private setSideMenu() {
    const isWholesaler = !!this.authorizationService.isWholesaler();
    if (!this.mainMenu || !this.mainMenuWS || this.isWholesaler === isWholesaler) {
      return;
    }

    const elements = document.querySelectorAll('div.sidenav.is-ready');
    if (elements?.length) {
      elements.forEach((element) => {
        element.parentNode.removeChild(element);
      });
    }

    this.isWholesaler = isWholesaler;
    this.menu = this.isWholesaler ? this.mainMenuWS : this.mainMenu;
  }

  private createAccountMenu() {
    this.accountMenuLinks = [];
    if (this.config.environment.myOffers.enabled) {
      this.accountMenuLinks.push({ linkText: 'My Life Extension', route: this.config.webRoutes.myLifeExtension, external: true });
    }
    this.accountMenuLinks.push({ linkText: 'Account Options', route: this.config.webRoutes.accountHome });

    if (!this.authorizationService.isWholesaler() && !this.authorizationService.isAnonymousCustomer()) {
      this.accountMenuLinks.push({ linkText: 'LE Dollars', route: this.config.webRoutes.accountHome });
      this.accountMenuLinks.push({ linkText: 'AutoShip & Save', route: this.config.webRoutes.accountAutoShip });
    }

    this.accountMenuLinks.push({ linkText: 'Order History & Reorder', route: this.config.webRoutes.accountOrderHistory });
    this.accountMenuLinks.push({ linkText: 'Wish Lists', route: this.config.webRoutes.accountMyWishLists });
    this.accountMenuLinks.push({ linkText: 'Shipping Addresses', route: this.config.webRoutes.accountShippingAddress });
    this.accountMenuLinks.push({ linkText: 'Sign Out', route: this.config.webRoutes.logout });
  }

  private subscribeToScrollEvent() {
    var $window = $(window);
    var header = document.getElementById('mainHeader');
    const mobileMenuOffset = header.offsetHeight;

    $window.on('scroll', function () {
      if ($window.scrollTop() > mobileMenuOffset) {
        $('body').addClass('le-mobile-nav-set');
      } else {
        $('body').removeClass('le-mobile-nav-set');
      }
    });

  }
}
