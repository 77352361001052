import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CoreConfiguration } from 'src/app/core/core-configuration';

@Component({
  selector: 'lef-shipping-address-in-use',
  templateUrl: './shipping-address-in-use.component.html',
  styleUrls: ['./shipping-address-in-use.component.scss']
})
export class ShippingAddressInUseComponent implements OnInit {

  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter();

  constructor(
    public readonly config: CoreConfiguration,
    private readonly router: Router
  ) { }

  ngOnInit() {
  }

  goToAutoShipPage() {
    this.closeDialog.emit();
    this.router.navigateByUrl(this.config.webRoutes.accountAutoShip);
  }

}
