import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GenesysService } from '../../service/genesys.service';

@Component({
  selector: 'lef-genesys-survey',
  templateUrl: './genesys-survey.component.html',
  styleUrls: ['./genesys-survey.component.scss']
})
export class GenesysSurveyComponent implements OnInit {

  form: FormGroup;
  
  @Input() messageId: string;

  @Output() closeSurveyEvent: EventEmitter<void> = new EventEmitter();
   
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly genesysService: GenesysService) {
      this.initForm(); 
     }

  ngOnInit(): void { }

  private initForm(): void{
    this.form = this.formBuilder.group({
      messageId: ['', [Validators.required]],
      source: ['CART'],
      score: [0, [Validators.required]],
      comments: ['']
    });   
  }

  setServiceScore(value: number): void{
    this.form?.get('score')?.setValue(value);
  }

  onCloseSurvey(): void {
    this.closeSurveyEvent.emit();
  }

  sendChat(event: Event): void {
    event.preventDefault();

    this.form.get('messageId').setValue(this.messageId);
    
    if (this.form.valid) {
      this.genesysService.insertSurvey(this.form.value).subscribe({ 
        next: () => {
          this.onCloseSurvey();         
        },
        complete: () => {
          this.initForm();    
        }
       });
    }   
  }
}
