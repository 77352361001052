import { ICartMessage } from 'src/app/cart-common/model';

export interface IPaymentInfo {
  paymentMessages: ICartMessage[];
  creditCardMessages: ICartMessage[];
  giftCardMessages: ICartMessage[];
  rewardsDollarsAvailable: number;
  rewardsDollarsApplied: number;
  rewardsMaximumAllowed: number;
  rewardsPaymentAllowed: boolean;
  maximumAmountEligibleToPayWithRewards: number;
  giftCardPaymentAllowed: boolean;
  giftCards: IGiftCard[];
  giftCardPaymentApplied: number;
  isOnAccountAllowed: boolean;
  isPoAllowed: boolean;
  customerPo: string;
  isPayPalPaymentAllowed: boolean;
  isPayPalCheckoutFlowEnabled: boolean;
  isApplePayCheckoutFlowEnabled: boolean;
  isGooglePayCheckoutFlowEnabled: boolean;
  hasUserAvailableCredit: boolean;
  availableCredit: number;
  creditApplied: number;
}

export class PaymentInfo implements IPaymentInfo {
  isPayPalCheckoutFlowEnabled: boolean;
  isApplePayCheckoutFlowEnabled: boolean;
  isGooglePayCheckoutFlowEnabled: boolean;
  maximumAmountEligibleToPayWithRewards: number;
  paymentMessages: ICartMessage[];
  creditCardMessages: ICartMessage[];
  giftCardMessages: ICartMessage[];
  rewardsDollarsAvailable: number;
  rewardsDollarsApplied: number;
  rewardsMaximumAllowed: number;
  rewardsPaymentAllowed = false;
  giftCardPaymentAllowed = false;
  giftCards: IGiftCard[] = [];
  giftCardPaymentApplied: number;
  isOnAccountAllowed = false;
  isPoAllowed: boolean;
  customerPo: string;
  isPayPalPaymentAllowed = false;
  hasUserAvailableCredit: boolean;
  availableCredit: number;
  creditApplied: number;
}

export interface IGiftCard {
  number: string;
  amount: number;
  balance: number;
  isBeingRemoved: boolean;
}
