import { Component, Input } from '@angular/core';

@Component({
  selector: 'lef-auto-ship-ribbon-info',
  templateUrl: './auto-ship-ribbon-info.component.html',
  styleUrls: ['./auto-ship-ribbon-info.component.scss']
})
export class AutoShipRibbonInfoComponent {

  @Input() alias = '';
  @Input() showAlias: boolean;

  @Input() shippingDay: string;
  @Input() showShippingDay: boolean;

  @Input() shippingAddress: string;
  @Input() showShippingAddress: boolean;

  get aliasInfo(): string {
    return this.showAlias ? this.alias : '';
  }

  get shipmentInfo(): string {
    return this.shippingDay && this.showShippingDay ? `Shipment: ${this.shippingDay}` : '';
  }

  get shippingAddressInfo(): string {
    return this.shippingAddress && this.showShippingAddress ? `ShipTo: ${this.shippingAddress}` : '';
  }

  separator = ' | ';
}
