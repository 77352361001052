import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ILabTestItem } from 'src/app/cart-common/model';
import { AppValidators } from 'src/app/shared/form/form-validations';

@Component({
  selector: 'lef-lab-subject-consent',
  templateUrl: './lab-subject-consent.component.html',
  styleUrls: ['./lab-subject-consent.component.scss'],
})
export class LabSubjectConsentComponent implements OnInit {
  @Input() labTest: ILabTestItem;

  @Output() consentFormSubmitted: EventEmitter<ILabTestItem> = new EventEmitter<ILabTestItem>();

  consentForm: UntypedFormGroup;

  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.consentForm = this.formBuilder.group({
      guardian: [this.labTest.consentBy, [Validators.required, AppValidators.notNullOrWhitespace]],
    });
  }

  consentFormSubmit(): void {
    if (!this.consentForm.valid) {
      return;
    }

    this.labTest.consentBy = this.consentForm.value.guardian;
    this.consentFormSubmitted.next(this.labTest);
  }
}
