export class Constants {
  static CustomerNumber = 'CN';
  static CustomerType = 'CT';
  static CustomerRecurrence = 'CR';
  static RetentionStage = 'RS';
  static Email = 'EM';
  static FirstName = 'FN';
  static LastName = 'LN';
  static IsVipPremierCustomer = 'VIP';
  static TrackingHeaderName = 'cart-tracking';
  static TrackingId = 'SID';
  static TrackingStorageKey = 'TrakingCheckOutFunnel';
  static CustomerRegion = "CRG";
  static CustomerIP = "CIP";
  static CustomerFirstSaleDate = 'CFSD';
  static ExperianPersona = 'EP'; 
  static IsAutoShipCustomer = 'IASC';
  static IsPremierAutoRenew = 'IPAR';
  static IsSMS = 'SMS';
  static LifeCycleGroup = 'LCG';
  static WholesaleType = 'WT'; 
  static WholesaleTypeGroup = 'WTG';
  static WholesalerPriceGroup = 'WPG';
  static HashEmail = "HEM";
  static HashFirstName = "HFN";
  static HashLastName = "HLN";
  static HashPhoneNumber = "HPN";
  static HashZipCode= "HZC";
}
