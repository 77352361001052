import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Cart, ICartItem } from 'src/app/cart-common/model';
import { CartCommonDialogService } from 'src/app/cart-common/service/cart-common-dialog.service';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { BaseService } from 'src/app/shared/service/base-service';
import { TrackingService } from 'src/app/tracking/service/tracking.service';

@Injectable({
  providedIn: 'root',
})
export class CartService extends BaseService<Cart> {
  protected apiUrl: string;

  constructor(
    protected override readonly httpClient: HttpClient,
    private readonly config: CoreConfiguration,
    private readonly trackingService: TrackingService,
    private readonly cartCommonDialogService: CartCommonDialogService
  ) {
    super(httpClient);

    this.apiUrl = config.environment.api + config.apiRoutes.cart;
  }

  getCart(): Observable<Cart> {
    return this.getSingle(`${this.apiUrl}/GetCart`).pipe(tap((cart) => this.trackingService.setCart(cart)));
  }

  updateCart(cart: Cart): Observable<Cart> {
    return this.update(`${this.apiUrl}/UpdateCart`, cart).pipe(tap((result) => this.trackingService.detectChanges(result)));
  }

  updateAutoShip(item: ICartItem): Observable<Cart> {
    return this.update(`${this.apiUrl}/UpdateAutoShip`, item).pipe(tap((result) => this.trackingService.detectChanges(result)));
  }

  addItem(item: ICartItem): Observable<Cart> {
    return this.post(`${this.apiUrl}/AddItem`, item).pipe(tap((result) => this.trackingService.detectChanges(result)));
  }

  addItemWithConfirmationDialog(
    item: ICartItem,
    onClose: (success?: boolean, targetItems?: ICartItem[]) => void = null
  ): void {
    this.post(`${this.apiUrl}/AddItem`, item)
      .pipe(tap((result) => this.trackingService.detectChanges(result)))
      .subscribe(
        (cart) => {
          const items: ICartItem[] = item ? [item] : [];
          this.cartCommonDialogService.openAddToCartConfirmationDialog(items, cart, onClose);
        },
        () => {
          const items: ICartItem[] = [item];
          this.cartCommonDialogService.openAddToCartConfirmationDialog(items, null, onClose);
        });
  }

  addItems(items: ICartItem[]): Observable<Cart> {
    return this.post(`${this.apiUrl}/AddItems`, items).pipe(tap((result) => this.trackingService.detectChanges(result)));
  }

  addItemsWithConfirmationDialog(
    items: ICartItem[],
    onClose: (success?: boolean, targetItems?: ICartItem[]) => void = null
  ): void {
    this.post(`${this.apiUrl}/AddItems`, items)
      .pipe(tap((result) => this.trackingService.detectChanges(result)))
      .subscribe(
        (cart) => {
          this.cartCommonDialogService.openAddToCartConfirmationDialog(items, cart, onClose);
        },
        () => {
          this.cartCommonDialogService.openAddToCartConfirmationDialog(items, null, onClose);
        });
  }

  deleteItem(itemId: string): Observable<Cart> {
    return this.update(`${this.apiUrl}/DeleteItem?itemId=${itemId}`, null).pipe(
      tap((result) => this.trackingService.detectChanges(result))
    );
  }

  setShippingMethod(method: string): Observable<Cart> {
    return this.update(`${this.apiUrl}/SetShippingMethod?shippingMethod=${method}`, null);
  }

  addPremierItem(): Observable<Cart> {
    const isAutoShip = this.config.environment.enablePremierSubscription;
    return this.post(`${this.apiUrl}/AddPremierItem?isAutoShip=${isAutoShip}`, null).pipe(tap((result) => this.trackingService.detectChanges(result)));
  }

  checkProp65(): Observable<Cart> {
    return this.getSingle(`${this.apiUrl}/CheckProp65`);
  }

}
