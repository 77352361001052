import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'lef-horizontal-wrapper',
  template: `
    <div class="form-group row">
      <label *ngIf="to.label" [attr.for]="id" [class]="labelClass">
        {{ to.label + (to.required && to.hideRequiredMarker !== true ? '*' : '') }}
      </label>
      <div [class]="inputWrapperClass">
        <ng-template #fieldComponent></ng-template>
        <div *ngIf="showError" class="invalid-feedback d-block" role="alert">
          <lef-form-validation-message [field]="field"></lef-form-validation-message>
        </div>
      </div>
    </div>
  `,
})
export class HorizontalWrapperComponent extends FieldWrapper implements OnInit {
  labelClass = 'col-sm-6 col-form-label text-right';
  inputWrapperClass = 'col-sm-6';

  ngOnInit(): void {
    if (this.props.labelClass) {
      this.labelClass = this.props.labelClass;
    }
    if (this.props.inputWrapperClass) {
      this.inputWrapperClass = this.props.inputWrapperClass;
    }
  }
}
