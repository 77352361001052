import { Component, HostBinding, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AuthorizationService } from 'src/app/authorization';
import { AutoShipValuesService } from 'src/app/auto-ship/service';
import { ResponsiveService } from 'src/app/common/service/responsive.service';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { HtmlUtilityService } from 'src/app/shared/service/html-utilitity.service';
import { ItemComponent } from '../../model';
import { ItemConfigurationService } from '../../service/item-configuration.service';

@Component({
  selector: 'lef-cart-item-subscription',
  templateUrl: './cart-item-subscription.component.html',
  styleUrls: ['./cart-item-subscription.component.scss', '../cart-item-host/cart-item-host.component.scss'],
})
export class CartItemSubscriptionComponent extends ItemComponent implements OnInit {
  autoShipForm: UntypedFormGroup;

  get autoShipLabel(): string {
    return this.item && this.item.isAutoShip === true
      ? `<span class="cart-autoship-txt">AutoShip</span>`
      : `Subscribe to<span class="cart-autoship-txt"> AutoShip </span>and Save`;
  }

  get existingAutoShipText(): string {
    if (!this.cartItemConfig.showPurchaseOptions && this.isAutoShip()) {
      if (this.item.hasExistingAutoShip) {
        return `This item is already part of a subscription to your current Shipping Address.
                Please remove the item or change this order’s Shipping Address.`;
      }

      if (this.currentAddressHasAutoShip) {
        return 'You have an active subscription for this Shipping Address.';
      }
    }
    return null;
  }

  get autoShipFrequency(): string {
    if (this.item.autoShipFrequency) {
      const frequency = this.autoShipFrequencyValues.find((f) => f.value === this.item.autoShipFrequency);
      if (frequency) {
        return frequency.text;
      }
    }
    return '';
  }

  autoShipFrequencyValues: Array<{ value: number; text: string }> = [];
  autoShipStartDates: Array<{ value: string; text: string }> = [];
  autoShipFaqUrl = '';

  constructor(
    readonly config: CoreConfiguration,
    readonly service: ResponsiveService,
    readonly authorizationService: AuthorizationService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly dialogService: DialogService,
    private readonly autoShipValuesService: AutoShipValuesService,
    private readonly itemConfigService: ItemConfigurationService,
    private readonly htmlUtilityService: HtmlUtilityService
  ) {
    super(config, itemConfigService, service);

    this.autoShipFaqUrl = `${config.environment.contentSite}/lpages/vipautoship`;
  }

  @HostBinding('class') hostClass = 'cart-item';

  override ngOnInit() {
    this.generateForm();
    super.ngOnInit();
  }

  isAutoShip(): boolean {
    return this.item.isAutoShip === true;
  }

  showAutoShipInfo($event: MouseEvent, autoShipInfo: TemplateRef<any>): void {
    this.dialogService.open({
      title: 'AutoShip & Save',
      width: 600,
      maxHeight: '90%',
      maxWidth: '90%',
      content: autoShipInfo,
      htmlAttributes: { id: 'autoShipInfo' }
    });

    $event.preventDefault();
    $event.stopPropagation();
  }

  updateAutoShip(elementId: string = '') {
    if (elementId) {
      this.htmlUtilityService.setFocusPoint(elementId);
    }

    this.item.autoShipFrequency = this.autoShipForm.value.autoShipFrequency;
    this.item.autoShipStartDate = new Date(this.autoShipForm.value.autoShipStartDate);
    this.autoShipUpdated.emit(this.item);
  }

  private generateForm(): void {
    this.autoShipFrequencyValues = this.autoShipValuesService.autoShipFrequencyFullValues;
    this.autoShipStartDates = this.autoShipValuesService.getAutoShipStartDates();

    let setDate: string;
    let frequency: number;
    if (this.item.isAutoShip) {
      frequency = this.item.autoShipFrequency;
      setDate = new Date(this.item.autoShipStartDate).toDateString();
    } else {
      frequency = this.autoShipFrequencyValues[0].value;
      setDate = this.autoShipStartDates[this.autoShipValuesService.getAutoShipStartDateIndex()].value;
    }

    this.autoShipForm = this.formBuilder.group({
      isAutoShip: ['false', []],
      autoShipFrequency: [frequency, []],
      autoShipStartDate: [setDate, []],
    });

    this.autoShipForm.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => {
      this.updateAutoShip();
    });
  }
}
