import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { CoveoService } from 'src/app/coveo/service/coveo.service';
import { IAutoShipAddItem } from '../../model/auto-ship-add-item';
import { AutoShipService } from '../../service';

@Component({
  selector: 'lef-auto-ship-add-item',
  templateUrl: './auto-ship-add-item.component.html',
  styleUrls: ['./auto-ship-add-item.component.scss'],
})
export class AutoShipAddItemComponent implements OnInit, AfterViewInit, OnDestroy {
  analyticsEndPoint: any;

  constructor(
    private readonly renderer: Renderer2,
    private readonly coveoService: CoveoService,
    private readonly autoShipService: AutoShipService,
    private readonly config: CoreConfiguration
  ) {}

  @Input()
  autoShipNumber = 0;

  @Input()
  alias: string;

  @Input()
  shippingDay: string;

  @Input()
  shippingAddress: string;

  @Output()
  itemAdded: EventEmitter<string> = new EventEmitter();

  @Output()
  closeDialog: EventEmitter<any> = new EventEmitter();

  @ViewChild('autoShipSearchInterface') searchInterfaceElementRef: ElementRef;
  @ViewChild('autoShipSearchResultList') searchResultListElementRef: ElementRef;

  addToSubscriptionBtnClass = 'btnAutoShipAddToSubscription';
  stopClickListener: () => void;

  addItemModel: IAutoShipAddItem;

  ngOnInit() {
    this.analyticsEndPoint = this.config.coveoConfiguration.analyticsApiUrl;
    this.addItemModel = {
      messages: [],
      autoShipNumber: this.autoShipNumber,
      itemNumber: '',
      itemAdded: false,
    };
  }

  ngAfterViewInit() {
    this.injectResultTemplate();
    this.initSearchInterface();
    this.registerToClickEvent();
  }

  ngOnDestroy() {
    this.stopClickListener();
  }

  injectResultTemplate() {
    const script = document.createElement('script');
    script.type = 'text/underscore';
    script.id = 'ProductTemplate';
    script.classList.add('result-template');
    script.setAttribute('data-field-templatedisplayname', 'Product,Third Party Product');
    script.innerHTML = this.getResultTemplate();

    this.renderer.appendChild(this.searchResultListElementRef.nativeElement, script);
  }

  initSearchInterface() {
    this.coveoService.initSearchInterface(this.searchInterfaceElementRef.nativeElement);
  }

  registerToClickEvent() {
    this.stopClickListener = this.renderer.listen('document', 'click', ($event): void => {
      this.addItem($event.target);
    });
  }

  addItem(target: HTMLElement) {
    if (
      target &&
      target.className &&
      target.className.indexOf &&
      typeof target.className.indexOf === 'function' &&
      target.className.indexOf(this.addToSubscriptionBtnClass) >= 0
    ) {
      const itemNumber = target.getAttribute('item-number');

      if (!itemNumber) {
        return;
      }

      this.addItemModel.itemNumber = itemNumber;
      this.autoShipService.addItem(this.addItemModel).subscribe(
        (model) => {
          if (model.itemAdded) {
            this.itemAdded.emit(model.itemNumber);
            this.addItemModel.itemAdded = false;
          }

          this.addItemModel = model;
          this.addItemModel.itemNumber = '';
        },
        (error) => {
          this.closeDialog.emit();
        }
      );
    }
  }

  getResultTemplate() {
    return `
    <div id="<%= raw.sku %>" class="coveo-result-frame le-product">
        <% var productvariants = raw.productvariants ? JSON.parse(raw.productvariants) : null; %>
        <% if (productvariants) { %>
        <%   var currentVariant = productvariants.ProductVariants.find(function(variant){ %>
        <%     return variant.ItemNumber === raw.sku; %>
        <%   }); %>
        <%   var currentProduct = productvariants.RelatedProducts.find(function(related){ %>
        <%     return related.ItemNumber === raw.sku; %>
        <%   }); %>
        <%   if (currentProduct) { %>
        <%     var priceOption = currentProduct.ProductInfoModels.find(function(price){ %>
        <%       return price.WholesalerType === ""; %>
        <%     }); %>
        <%     if (priceOption) { %>
        <%       var priceBreaks =  priceOption.Prices; %>
        <%       if (priceBreaks) { %>
        <%           var basePrice = priceBreaks[0].BaseRetailPrice.toFixed(2); %>
        <%           var subscriptionPrice = priceBreaks[priceBreaks.length - 1].BaseRetailPrice.toFixed(2); %>
        <%           var hasSavings = basePrice > subscriptionPrice; %>
        <%           var percentSavings = hasSavings ? ((basePrice-subscriptionPrice)*100/basePrice).toFixed() : 0; %>
        <%           var showPrice = true; %>
        <%        } %>
        <%     } %>
        <%   } %>
        <% } %>

        <% var rating = parseFloat(raw.averageoverallrating).toFixed(1); %>
        <% var fullRating = rating; %>
        <% var reviewText = raw.fullreviews === "1" ? "Review" : "Reviews"; %>
        <% var starRating = 0; %>
        <% for(i=0; i < 5; i++) { %>
        <%   var starfill = rating > .8 ? 20 : rating > .5 ? 15 : rating > .2 ? 10 : rating > 0 ? 5 : 0; %>
        <%   starRating += starfill; %>
        <%   if (rating > 0) { rating = (rating - 1).toFixed(1); }  %>
        <% } %>

        <div class="row px-2 px-xl-0 py-4">
            <div class="col-4 col-md-2 text-center le-search-item-img">
                <img src="${this.config.environment.contentSite}<%= raw.productimageurl %>" />
            </div>

            <div class="col-8 d-sm-none">
                <div class="coveo-title">
                    <p class="font-lg text-primary"><%= raw.productfriendlyname %></p>
                </div>

                <div>
                    <% if (raw.productnamesuffiz120x) { %>
                    <%= raw.productnamesuffiz120x %> |
                    <% } %>
                    #<%= raw.sku %>
                </div>

                <% if (raw.fullreviews && raw.fullreviews !== "0") { %>
                <a class="productReviewLink" href="<%= clickUri + '#reviewsqa' %>">
                    <div class="productReviewSummary product-rating d-sm-flex align-items-sm-center my-3">
                        <div class="product-rating d-flex align-items-center mb-1">
                            <div class="star-ratings-css mr-2">
                                <div class="star-ratings-css-top related" style="width: <%= starRating %>%">
                                    <i class="lef-star"></i><i class="lef-star"></i><i class="lef-star"></i><i
                                      class="lef-star"></i><i class="lef-star"></i>
                                </div>
                                <div class="star-ratings-css-bottom">
                                    <i class="lef-star"></i><i class="lef-star"></i><i class="lef-star"></i><i
                                      class="lef-star"></i><i class="lef-star"></i>
                                </div>
                            </div>
                            <div class="star-rating-count text-green font-weight-bold">
                                <span class="averageOverallRating" id="averageOverallRating"><%= fullRating %></span>
                                <span class="fullReviews ml-2" id="fullReviews"><%= raw.fullreviews %>
                                    <%= reviewText %></span>
                            </div>
                        </div>
                    </div>
                </a>
                <% } %>
            </div>

            <div class="col-sm-8 col-md-10 mt-3 mt-sm-0">
                <div class="row">
                    <div class="col-md-8 d-none d-sm-block">
                        <div class="coveo-title">
                            <p class="font-lg text-primary"><%= raw.productfriendlyname %></p>
                        </div>

                        <div>
                            <% if (raw.productnamesuffiz120x) { %>
                            <%= raw.productnamesuffiz120x %> |
                            <% } %>
                            #<%= raw.sku %>
                        </div>

                        <% if (raw.fullreviews && raw.fullreviews !== "0") { %>
                        <a class="productReviewLink" href="<%= clickUri + '#reviewsqa' %>">
                            <div class="productReviewSummary product-rating d-sm-flex align-items-sm-center my-3">
                                <div class="product-rating d-flex align-items-center mb-1">
                                    <div class="star-ratings-css mr-2">
                                        <div class="star-ratings-css-top related" style="width: <%= starRating %>%">
                                            <i class="lef-star"></i><i class="lef-star"></i><i class="lef-star"></i><i
                                              class="lef-star"></i><i class="lef-star"></i>
                                        </div>
                                        <div class="star-ratings-css-bottom">
                                            <i class="lef-star"></i><i class="lef-star"></i><i class="lef-star"></i><i
                                              class="lef-star"></i><i class="lef-star"></i>
                                        </div>
                                    </div>
                                    <div class="star-rating-count text-green font-weight-bold">
                                        <span class="averageOverallRating"
                                              id="averageOverallRating"><%= fullRating %></span>
                                        <span class="fullReviews ml-2" id="fullReviews"><%= raw.fullreviews %>
                                            <%= reviewText %></span>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <% } %>

                        <div class="d-none d-md-block">
                            <div class="le-search-item-summary">
                                <hr />

                                <% if (raw.tieronesummary) { %>
                                <div><%= raw.tieronesummary %></div>
                                <hr />
                                <% } %>

                                <div class="text-red">
                                  Item price will be changed to the lowest price available at the time of shipment.
                                </div>
                                <hr />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 text-right">
                        <div class="product-price my-2">
                          <% if (showPrice) { %>
                            <div>
                                <p>Subscription Price <i class="lef-information-solid text-info"></i></p>
                            <div>
                            <div class="mb-2 text-primary salePricing <%= hasSavings ? '' : 'd-none' %>">
                                <s class="basePrice">$<%= basePrice %></s>
                            </div>
                            <div class="currentPriceWrapper font-weight-bold font-1x mb-2">
                                <span class="currentPrice <%= hasSavings ? 'text-red' : 'text-primary' %>">
                                    $<%= subscriptionPrice %>
                                </span>
                            </div>

                            <div class="text-red mb-2 savingsPrice <%= hasSavings ? '' : 'd-none' %>">
                                Save <span class="savingsAmount"><%= percentSavings %>%</span>
                            </div>
                          <% } %>
                        </div>

                        <button class="btn btn-action ${this.addToSubscriptionBtnClass}"
                                item-number="<%= raw.sku %>">
                            Add to AutoShip &amp; Save
                        </button>
                    </div>
                </div>
            </div>

            <div class="d-md-none w-100 px-3">

                <% if (raw.tieronesummary) { %>
                <hr />
                <div><%= raw.tieronesummary %></div>
                <% } %>

                <hr />
                <div class="text-red">
                  Items will be changed at the lowest price available at the time of shipment
                </div>
            </div>
        </div>
    </div>
`;
  }
}
