import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICartMessage } from 'src/app/cart-common/model';
import { HtmlUtilityService } from 'src/app/shared/service/html-utilitity.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { ApplicationSectionStep } from '../../model/application-section-step.enum';
import { IApplicationSection } from '../../model/application-section.model';
import { ResellerApplication } from '../../model/reseller-application';
import { ResellerApplicationService } from '../../service/reseller-application.service';

@Component({
  selector: 'lef-reseller-application-complete',
  templateUrl: './reseller-application-complete.component.html',
  styleUrls: ['./reseller-application-complete.component.scss'],
})
export class ResellerApplicationCompleteComponent implements OnInit, OnChanges, IApplicationSection {
  stepNumber = '4.';
  stepTitle = 'Complete Application';

  applicationForm: UntypedFormGroup = new UntypedFormGroup({});
  isDataAlreadyInitialized = false;
  model: ResellerApplication;
  messages: ICartMessage[] = [];

  @Input()
  applicationId: string;
  @Input()
  isActive = false;
  @Input()
  isEditable = false;
  @Input()
  hasContent = false;
  @Input()
  isApplicationValid = false;
  @Input()
  isApplicationApproved = false;

  @Output()
  applicationProcessContinue: EventEmitter<IApplicationSection> = new EventEmitter<IApplicationSection>();

  @Output()
  activateSection: EventEmitter<ApplicationSectionStep> = new EventEmitter<ApplicationSectionStep>();

  constructor(
    private readonly loadingService: LoadingService,
    private readonly resellerApplicationService: ResellerApplicationService,
    private readonly DOMElement: ElementRef,
    private readonly htmlUtilityService: HtmlUtilityService
  ) {}

  isValid(): boolean {
    return this.applicationForm.valid;
  }

  isDirty(): boolean {
    return this.applicationForm.dirty;
  }

  saveSection(): void {
    if (this.applicationForm.valid) {
      this.loadingService.show('Submitting Application');
      this.messages = [];
      const model = new ResellerApplication(this.applicationForm.value);
      this.resellerApplicationService.submitApplication(model).subscribe(
        (application) => {
          this.loadingService.hide();
          if (application.messages.length > 0) {
            this.messages = application.messages;
          } else {
            this.applicationProcessContinue.emit(this as IApplicationSection);
          }
        },
        () => {
          this.loadingService.hide();
        }
      );
    }
  }

  getCurrentStep(): ApplicationSectionStep {
    return ApplicationSectionStep.CompleteApplication;
  }

  onSubmit() {
    if (this.applicationForm.valid) {
      this.saveSection();
      return;
    }
    this.htmlUtilityService.focusFirstInvalidElement(this.DOMElement);
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isActive) {
      if (changes.isActive.currentValue) {
        this.initializeData();
      }
    }
  }

  activateCompleteApplicationSection() {
    this.activateSection.emit(ApplicationSectionStep.CompleteApplication);
  }

  initializeData() {
    this.loadingService.show();
    forkJoin([this.resellerApplicationService.getApplication()])
      .pipe(
        map(([application]) => {
          return { application };
        })
      )
      .subscribe((data) => {
        this.model = data.application;
        if (this.isEditable) {
          this.model.signature = '';
        }
        this.initForm();
        this.loadingService.hide();
        this.isDataAlreadyInitialized = true;
        this.messages = [];
      });
  }

  initForm(): void {
    this.applicationForm = new UntypedFormGroup({
      id: new UntypedFormControl(this.model.id),
      signature: new UntypedFormControl(this.model.signature, [Validators.required]),
    });
  }
}
