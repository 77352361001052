import { Pipe, PipeTransform } from '@angular/core';
import { isObservable, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Pipe({
  name: 'observed',
})
export class ObservedPipe implements PipeTransform {
  transform(value: any): Observable<any> {
    return isObservable(value) ? value : of(value);
  }
}
