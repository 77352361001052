import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { filter } from 'rxjs/operators';
import { AuthorizationService } from './authorization/service/authorization.service';
import { RenderingContentModel } from './common/model/rendering-content.model';
import { CommonDialogService } from './common/service/common-dialog.service';
import { HeaderService } from './common/service/header.service';
import { RegionService } from './common/service/region.service';
import { RenderingContentService } from './common/service/rendering-content.service';
import { CoreConfiguration } from './core/core-configuration';
import { CartCookieService } from './shared/service/cart-cookie-service.service';

@Component({
  selector: 'lef-shopping-cart',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [HttpClient, CookieService],
})
export class AppComponent implements OnInit, AfterViewInit {
  mainMenu = '';
  mainMenuWS = '';
  mainNavBar = '';
  footerContent = '';
  isRenderingNonHeadersOrFooterPage: boolean;

  get showFullHeader(): boolean {
    return !!this.headerService && this.headerService.showFullHeader;
  }

  constructor(
    public readonly headerService: HeaderService,
    private readonly regionService: RegionService,
    private readonly renderingService: RenderingContentService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly titleService: Title,
    private readonly commonDialogService: CommonDialogService,
    private readonly config: CoreConfiguration,
    private readonly authorizationService: AuthorizationService,
    private readonly cartCookieService: CartCookieService
  ) { }

  ngOnInit(): void {
    this.isRenderingNonHeadersOrFooterPage = this.checkNonHeadersOrFooter();
    this.initRenderingContent();
    this.initTitleService();
    this.verifyClientRegion();
    this.setLECustomerNumberCookie();
  }

  ngAfterViewInit(): void {
    this.updatePaymentScriptId();
  }

  initializeLazy(): void {
    // @ts-ignore
    const observer = lozad('.lozad', {
      rootMargin: '0px 0px 256px 0px',
    });
    observer.observe();
  }

  private updatePaymentScriptId() {
    const paymentScriptElement = document.querySelector('script[id="xo-pptm"]')
    if (paymentScriptElement) {
      paymentScriptElement.setAttribute('id', 'xo-pptm-1');
    }
  }

  private initRenderingContent() {
    if (!this.isRenderingNonHeadersOrFooterPage) {
      this.renderingService.getRenderingContent().subscribe({
        next: (renderingContent: Array<RenderingContentModel>) => {
          this.mainMenu = renderingContent.find((r) => r.contentName === 'MainMenu')?.htmlContent ?? "";
          this.mainMenuWS = renderingContent.find((r) => r.contentName === 'MainMenuWS')?.htmlContent ?? "";
          this.mainNavBar = renderingContent.find((r) => r.contentName === 'Highlights')?.htmlContent ?? "";
          this.footerContent = renderingContent.find((r) => r.contentName === 'Footer')?.htmlContent ?? "";
        },
      });
    }
  }

  private initTitleService() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd | any) => {
      const currentRoute = this.getChild(this.activatedRoute);
      currentRoute.data.subscribe((data) => {
        const titleSubfix = ' – Life Extension';
        let title = data.title || 'Shopping Cart';
        if (!(title as string).endsWith(titleSubfix)) {
          title = `${title}${titleSubfix}`;
        }
        this.titleService.setTitle(title);
      });
    });
  }

  private getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  private verifyClientRegion() {
    setTimeout(() => {
      this.regionService.verifyDistributorRegion().subscribe((verification) => {
        if (verification.showDialog) {
          this.commonDialogService.openDistributorRegionVerificationDialog(verification);
        }
      });
    }, 1500);
  }

  private checkNonHeadersOrFooter(): boolean {
    const rgxs = this.config.environment.nonHeadersOrFooterPaths;
    const path = window.location.pathname;
    return !!rgxs.find((r: RegExp) => r.test(path));
  }

  private setLECustomerNumberCookie(): void {
    let currentCustomerNumber = this.authorizationService.userCustomerNumber();
    let cookieName = this.config.cookies.lifeExtensionUser;
    if (this.cartCookieService.getCookieFromName(cookieName) !== currentCustomerNumber) {
      this.cartCookieService.setContentSiteCookie(cookieName, currentCustomerNumber, 0, 0, 30, null, true, 'None');
    }
  }


}
