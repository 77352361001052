import { Injectable } from '@angular/core';
import * as braintree from 'braintree-web';
import { Subject } from 'rxjs';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { PaymentGateway } from '../model/payment-gateway';
import { PaymentOptionService } from './payment-option.service';
declare global {
  interface Window {
    ApplePaySession: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ApplePayService {
  public readonly applePayAvailabilityChange: Subject<boolean> = new Subject();
  public readonly onButtonsReady: Subject<boolean> = new Subject<boolean>();
  public IsStatusOnline = false;
  private auth: string;
  private signature: string;
  private requiredIntent = 'authorize';
  applePayInstance: any;
  deviceData = '';

  private isApplePayServiceEnabled: boolean;
  get isApplePaySessionActive(): boolean {
    return this.isApplePayServiceEnabled && this.applePayInstance;
  }

  constructor(private readonly paymentOptionService: PaymentOptionService, private readonly config: CoreConfiguration) {
    this.isApplePayServiceEnabled =
      window.ApplePaySession && window.ApplePaySession.supportsVersion(3) && window.ApplePaySession.canMakePayments();
  }

  refreshApplePayInstanceForAuthorization(gateway: PaymentGateway) {
    this.refreshApplePayInstance('authorize', gateway);
  }

  refreshApplePayInstanceForCapture(gateway: PaymentGateway) {
    this.refreshApplePayInstance('capture', gateway);
  }

  private refreshApplePayInstance(intent: string, gateway: PaymentGateway) {
    if (!this.isApplePayServiceEnabled) {
      return;
    }
    if (!!gateway) {
      this.checkIfRequireRefresh(gateway, intent);
    } else {
      this.paymentOptionService.paymentOptions(false, false).subscribe({
        next: (result) => {
          this.checkIfRequireRefresh(result.gateway, intent);
        },
        error: (error) => {},
      });
    }
  }

  private setOnlineStatus(status: boolean) {
    this.IsStatusOnline = status;
    this.applePayAvailabilityChange.next(status);
  }

  private checkIfRequireRefresh(gateway: PaymentGateway, intent: string) {
    this.isApplePayServiceEnabled = this.isApplePayServiceEnabled && gateway.isApplePayEnabled;
    const refreshRequired = this.isApplePayServiceEnabled && (gateway.signature !== this.signature || intent !== this.requiredIntent);
    if (refreshRequired) {
      this.requiredIntent = intent;
      this.auth = gateway.auth;
      this.signature = gateway.signature;
      this.setOnlineStatus(false);
      this.initApplePayInstance();
    }
  }

  initApplePayInstance() {
    braintree.client
      .create({
        authorization: this.auth,
      })
      .then((clientInstance) => {
        braintree.dataCollector
          .create({
            client: clientInstance,
          })
          .then((dataCollectorInstance) => {
            this.deviceData = dataCollectorInstance.deviceData;
          })
          .catch((err) => {
            console.log(err)
          });

        return braintree.applePay.create({
          client: clientInstance,
        });
      })
      .then((applePayInstance) => {
        this.applePayInstance = applePayInstance || {};
        this.setOnlineStatus(true);
      })
      .catch((err) => {
        console.error('ApplePayService', err);
        this.setOnlineStatus(false);
      });
  }
}
