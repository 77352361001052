import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { BaseService } from 'src/app/shared/service/base-service';
import { IFavorite, IFavoriteItem } from './../model/favorite.model';
import { IWishList } from './../model/wish.model';

@Injectable({
  providedIn: 'root',
})
export class FavoriteService extends BaseService<IFavorite> {
  protected apiUrl: string;

  constructor(protected override readonly httpClient: HttpClient, private readonly config: CoreConfiguration) {
    super(httpClient);
    this.apiUrl = config.environment.api + config.apiRoutes.favorite;
  }

  getFavoriteItemList(): Observable<IFavorite> {
    return this.getSingle(`${this.apiUrl}/GetFavoriteItemList`);
  }

  getFavoriteWishListList(): Observable<IWishList[]> {
    return this.httpGet(`${this.apiUrl}/GetWishList`);
  }

  getFavoriteItemListAll(): Observable<any> {
    return this.httpGet(`${this.apiUrl}/GetFavoriteItemListAll`);
  }

  createWishList(wishListName: string): Observable<any> {
    return this.httpClient.post<any>(`${this.apiUrl}/CreateWishList?newWishListName=${wishListName}`, {});
  }

  renameWishList(wishListId: string, newWishListName: string, wishListItem: IWishList): Observable<any> {
    return this.httpClient.put(`${this.apiUrl}/RenameWishList?wishListId=${wishListId}&newWishListName=${newWishListName}`, wishListItem, {
      headers: this.getHeaders.set('Content-Type', 'application/json'),
    }) as Observable<any>;
  }

  removeWishList(wishListItem: IWishList): Observable<any> {
    return this.httpClient.post<any>(`${this.apiUrl}/RemoveWishList`, wishListItem) as Observable<any>;
  }

  moveItemToWishList(fromWishListId: string, toWishListId: string, item: string): Observable<any> {
    return this.httpClient.put(
      `${this.apiUrl}/MoveItemToWishList?fromWishListId=${fromWishListId}&toWishListId=${toWishListId}&item=${item}`,
      {}
    ) as Observable<any>;
  }

  addItemToFavorites(itemNumber: string, quantity: number, wishListName: string): Observable<any> {
    return this.httpClient.post<any>(
      `${this.apiUrl}/AddItemToFavorites?itemNumber=${itemNumber}&quantity=${quantity}&wishListName=${wishListName}`,
      {}
    );
  }

  removeItemFavorite(lineId: string, favoriteItem: IFavoriteItem): Observable<any> {
    return this.httpClient.post<any>(`${this.apiUrl}/RemoveItemFavorite?lineId=${lineId}`, favoriteItem) as Observable<any>;
  }
}
