import { Injectable } from '@angular/core';
import * as braintree from 'braintree-web';
import { Subject } from 'rxjs';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { PaymentGateway } from '../model/payment-gateway';
import { PaymentOptionService } from './payment-option.service';

@Injectable({
  providedIn: 'root',
})
export class PayPalCheckoutService {
  public readonly payPalAvailabilityChange: Subject<boolean> = new Subject();
  public readonly onButtonsReady: Subject<boolean> = new Subject<boolean>();
  public IsStatusOnline = false;
  private auth: string;
  private signature: string;
  private requiredIntent = 'authorize';
  private isPayPalEnabled: boolean;
  paypalCheckoutInstance: any;
  deviceData = '';
  runningOnIE11 = /msie\s|trident\//i.test(window.navigator.userAgent);

  constructor(private readonly paymentOptionService: PaymentOptionService, private readonly config: CoreConfiguration) {
    this.isPayPalEnabled = !this.runningOnIE11;
  }

  isPayPalServiceActive() {
    return this.isPayPalEnabled;
  }

  refreshPayPalInstanceForAuthorization(gateway: PaymentGateway) {
    this.refreshPayPalCheckOutInstance('authorize', gateway);
  }

  refreshPayPalInstanceForCapture(gateway: PaymentGateway) {
    this.refreshPayPalCheckOutInstance('capture', gateway);
  }

  private refreshPayPalCheckOutInstance(intent: string, gateway: PaymentGateway) {
    if (this.runningOnIE11) {
      return;
    }
    if (!!gateway) {
      this.checkIfRequireRefresh(gateway, intent);
    } else {
      this.paymentOptionService.paymentOptions(false, false).subscribe(
        (result) => {
          this.checkIfRequireRefresh(result.gateway, intent);
        },
        (error) => {}
      );
    }
  }

  private setOnlineStatus(status: boolean) {
    this.IsStatusOnline = status;
    this.payPalAvailabilityChange.next(status);
  }

  private checkIfRequireRefresh(gateway: PaymentGateway, intent: string) {
    this.isPayPalEnabled = gateway.isPayPalEnabled;
    const refreshRequired = this.isPayPalEnabled && (gateway.signature !== this.signature || intent !== this.requiredIntent);
    if (refreshRequired) {
      this.requiredIntent = intent;
      this.auth = gateway.auth;
      this.signature = gateway.signature;
      this.setOnlineStatus(false);
      this.initPaypalCheckoutComponent();
    }
  }

  initPaypalCheckoutComponent() {
    braintree.client
      .create({
        authorization: this.auth,
      })
      .then((clientInstance) => {
        // Create a PayPal Checkout component.
        braintree.dataCollector
          .create({
            client: clientInstance,
          })
          .then((dataCollectorInstance) => {
            this.deviceData = dataCollectorInstance.deviceData;
          })
          .catch((err) => {
            console.log(err);
          });

        return braintree.paypalCheckout.create({
          client: clientInstance,
          autoSetDataUserIdToken: true,
        });
      })
      .then((paypalCheckoutInstance) => {
        return paypalCheckoutInstance.loadPayPalSDK({
          components: 'buttons',
          'disable-funding': 'card',
          'client-id': this.config.environment.payPal.clientId,
          vault: this.requiredIntent !== 'authorize',
          intent: this.requiredIntent,
        });
      })
      .then((paypalCheckoutInstance) => {
        this.paypalCheckoutInstance = paypalCheckoutInstance;
        this.setOnlineStatus(true);
      })
      .catch((err) => {
        // Handle component creation error
        console.error('PayPalCheckoutService', err);
        this.setOnlineStatus(false);
      });
  }
}
