import { ICartMessage } from 'src/app/cart-common/model/cart-message';

export class Information {
  id: string;
  name: string;
  address: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  emailAddress: string;
  phone: string;
  messages?: ICartMessage[];
  refreshGlobalMessages: boolean;
  success: boolean;
}

export class CurrentCustomer {
  email?: string;
  phoneNumber?: string;
}

export class CompanyRegistration {
  isValid: boolean;
  id: string;
  applicationId: string;
  hasOfficesOutsideUS: string;
  exportOutsideUS: string;
  companyInformationSameAsPersonal: boolean;
  companyInformation: Information;
  contactPerson: string;
  title: string;
  emailAddress: string;
  website: string;
  phone: string;
  stateOnIncorporation: string;
  corporateContactSameAsContact: boolean;
  corporateOfficeInformation: Information;
  ownerInformationSameAsPersonal: boolean;
  ownerContactInformation: Information;
  buyerContactSameAsOwner: boolean;
  buyerContactInformation: Information;
  messages?: ICartMessage[];
  refreshGlobalMessages: boolean;
  success: boolean;
  currentCustomerInfo?: CurrentCustomer;
  public constructor(init?: Partial<CompanyRegistration>) {
    this.hasOfficesOutsideUS = 'N';
    this.companyInformation = new Information();
    this.corporateOfficeInformation = new Information();
    this.buyerContactInformation = new Information();
    this.ownerContactInformation = new Information();
    Object.assign(this, init);
  }
}
