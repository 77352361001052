import { Component, EventEmitter, Input, Output, Renderer2, TemplateRef } from '@angular/core';
import { AuthorizationService } from 'src/app/authorization';
import { ICart } from 'src/app/cart-common/model';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { CartService } from '../../service';

@Component({
  selector: 'lef-cart-premier-offer',
  templateUrl: './cart-premier-offer.component.html',
  styleUrls: ['./cart-premier-offer.component.scss'],
})
export class CartPremierOfferComponent {
  @Input() cartTotal = 0;
  @Input() qualifyingAmount = 50;
  @Input() isRenewalCandidate = false;
  @Input() isVipPremierCustomer = false;

  @Output() premierItemAdded: EventEmitter<ICart> = new EventEmitter<ICart>();

  get progress(): number {
    const progress = this.cartTotal / this.qualifyingAmount;
    return progress > 1 ? 100 : progress * 100;
  }

  get amountLeft(): number {
    const progress = this.qualifyingAmount - this.cartTotal;
    return progress > 0 ? progress : 0;
  }

  get adjustedTotal(): number {
    return this.cartTotal < this.qualifyingAmount ? this.cartTotal : this.qualifyingAmount;
  }

  get qualifies(): boolean {
    return this.cartTotal > this.qualifyingAmount;
  }

   get isPremierExpired(): boolean {
    return this.authService?.userCustomerType()?.toLowerCase() === 'ep';
  }

  faqUrl: string;

  constructor(
    private readonly cartService: CartService,
    private readonly dialogService: DialogService,
    private readonly authService: AuthorizationService,
    private readonly renderer: Renderer2,
    private readonly config: CoreConfiguration
  ) {
    this.faqUrl = `${config.environment.contentSite}/about/premier`;
  }

  showPremierInfo(premierInfo: TemplateRef<any>): void {
    const infoDialogRef = this.dialogService.open({
      title: ' ',
      width: 600,
      maxHeight: '90%',
      maxWidth: '90%',
      content: premierInfo,
    });
  }

  addPremier(): void {
    this.cartService.addPremierItem().subscribe((result: ICart) => {
      this.premierItemAdded.emit(result);
    });
  }
}
