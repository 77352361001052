<div class="w-100 ">
    <div class="wra-complete-appplication w-100">
        <div class="d-flex justify-content-between align-items-center d-md-block">
            <div class="d-flex justify-content-between">
                <p class="wra-step-wrap m-0">
                    <span class="wra-step-number">{{stepNumber}}</span><span class="wra-step-title">{{stepTitle}}</span>
                </p>
                <div>
                    <ng-container *ngIf="hasContent && !isActive">
                        <div *ngIf="!isEditable">
                            <button class="btn btn-link" type="button"
                                    (click)="activateCompleteApplicationSection()">Review</button>
                        </div>
                        <div *ngIf="isEditable">
                            <button class="btn btn-link" type="button"
                                    (click)="activateCompleteApplicationSection()">Review/Edit</button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isActive" class="w-100 mt-2">
        <form *ngIf="isDataAlreadyInitialized" [formGroup]="applicationForm" (ngSubmit)="onSubmit()">
            <input id="id" type="hidden" name="id" formControlName="id" />
            <div class="w-100 bg-light p-3 form-group">
                <div class="d-flex justify-content-end">
                    <p class="font-sm required-message">* Indicated required fields</p>
                </div>
                <div class="bg-white p-3 border">
                    <kendo-pdfviewer style="height: 374px;" [zoom]="1" [tools]="['pager', 'zoom', 'download', 'print']"
                                     [url]="'assets/documents/Wholesaler/WholesalerTermsConditions.pdf'">
                    </kendo-pdfviewer>
                </div>
                <div class="mb-3">
                    <div class="d-flex justify-content-end">
                        <a href="https://www.lifeextension.com/Vitamins-Supplements/WholesalerTermsConditions20201223.pdf"
                           target="_blank" class="btn btn-link">Download &
                            Print</a>
                    </div>
                </div>
                <div *ngIf="!model.isCaliforniaCustomer" class="mb-3">
                    <p> <strong>AGREED BY WHOLESALER:</strong> By signing this document, the undersigned applicant
                        certifies that:
                        (i) I have authority to complete and execute this Domestic Wholesaler Application and Agreement;
                        (ii) I will not resell Products on Amazon; (iii) I will comply with California Proposition 65;
                        (iv) I have read the Domestic Wholesaler Terms and Conditions above and agree to abide by
                        them as a VSW Wholesaler; and (v) I understand the Terms and Conditions may be revised from time
                        to time and that by placing an order for Products, I accept the Terms and Conditions in place at
                        the time of my order. If I am health care provider, I acknowledge that I am solely responsible
                        for knowing and complying with the laws governing my practice and the products and services that
                        I may offer to my patients.</p>
                </div>
                <div *ngIf="model.isCaliforniaCustomer" class="mb-3">
                    <p> <strong>AGREED BY WHOLESALER:</strong> By signing this document, the undersigned applicant
                        certifies that:
                        (i) I have authority to complete and execute this Domestic Wholesaler Application and Agreement:
                        (ii) I will not resell Products on Amazon; (iii) I will comply with California Proposition 65;
                        (iv) I have read the <a href="https://www.lifeextension.com/legal/california-privacy-notice"
                           target="_blank">California Privacy
                            Notice</a> and agree to abide by the Notice;
                        (v) I have read the Terms and Conditions above and agree to abide by them as a VSW Wholesaler;
                        and (vi) I understand the Terms and Conditions may be revised from time to time and that by
                        placing
                        an order for Products, I accept the Terms and Conditions in place at the time of my order. If I
                        am a health care
                        provider, I acknowledge that I am solely responsible for knowing and complying with the laws
                        governing my
                        practice and the products and services that I may offer to my patients.</p>
                </div>
                <div>
                    <lef-cart-messages [messages]="messages"></lef-cart-messages>
                </div>
                <ng-container *ngIf="isEditable">
                    <ng-container *ngIf="isApplicationValid">
                        <div class="rsc-sig-line">
                            <div>
                                <label for="signature" class="m-0">E-Sign Document*:</label>
                            </div>
                            <div class="flex-grow mr-2">
                                <input id="signature" class="form-control" type="text" name="signature"
                                       formControlName="signature" />
                            </div>
                            <div>
                                <button class="btn btn-warning px-4">Submit</button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!isApplicationValid">
                        <div class="alert alert-danger" role="alert">
                            <span>Your application has some invalid information.
                                You must fix that to be able to submit the application.</span>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!isEditable">
                    <div class="w-75 d-flex">
                        <div class="mr-3">
                            <label for="signature">E-Sign Document*:</label>
                        </div>
                        <div class="user-signature mr-3">
                            {{model.signature}}
                        </div>
                        <div>
                            Updated {{model.submitDate}}
                        </div>
                    </div>
                    <p *ngIf="!isApplicationApproved">Your application was submitted and will be review. Application
                        review can take up to 5 business days. Please visit <a routerLink="/account">Account Options</a>
                        to see the status of your application.</p>
                </ng-container>
            </div>
        </form>
    </div>
</div>