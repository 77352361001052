<!-- tracking -->
<lef-tracking></lef-tracking>
<!-- captcha placeholder -->
<lef-captcha-placeholder></lef-captcha-placeholder>
<!-- header -->
<header *ngIf="!isRenderingNonHeadersOrFooterPage" class="header-sticky">
  <lef-header [mainNavBar]="mainNavBar" [mainMenu]="mainMenu" [mainMenuWS]="mainMenuWS"></lef-header>
  <lef-rendering-content></lef-rendering-content>
</header>

<main role="main" class="main-print" id="content">
  <div class="container main-container py-2"
       [ngClass]="{'main-flow': showFullHeader, 'main-checkout-flow': !showFullHeader}">
    <img class="logo-print d-none d-print-block mb-2"
         src="https://qa3.lifeextension.com/-/media/project/lifeextension/images/logo/le-logo.png"
         alt="Life Extension: The Science of a Healthier Life – Homepage">
    <div>
      <lef-global-cart-messages class="d-print-none"></lef-global-cart-messages>
    </div>

    <router-outlet></router-outlet>
  </div>

</main>

<lef-genesys-chat></lef-genesys-chat>

<!-- Footer -->
<footer id="footer" *ngIf="!isRenderingNonHeadersOrFooterPage" class="cart-print-ftr">
  <lef-rendering-content [htmlContent]="footerContent" (contentInitialized)="initializeLazy()"></lef-rendering-content>
  <lef-application-version-info></lef-application-version-info>
</footer>
<div class="bg-overlay"></div>

<!-- Cart Dialogs Container -->
<div kendoDialogContainer></div>
<div kendoWindowContainer></div>