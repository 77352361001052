import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'lef-collapse-box',
  templateUrl: './collapse-box.component.html',
  styleUrls: ['./collapse-box.component.scss'],
  animations: [
    trigger('openClose', [
      transition(':enter', [style({ height: '0', opacity: 0 }), animate('400ms', style({ height: '100%', opacity: 1 }))]),
      transition(':leave', [style({ height: '100%', opacity: 1 }), animate('200ms', style({ height: '0', opacity: 0 }))]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class CollapseBoxComponent implements OnInit {
  @Input() expanded = true;
  @Input() enabled = true;
  @Input() boxClass = '';
  @Input() headerClass = '';
  @Input() titleClass = '';
  @Input() bodyClass = '';
  @Input() showIcon = true;
  @Input() expandedIcon = 'lef-chevron-up';
  @Input() collapsedIcon = 'lef-chevron-down';

  constructor() {}

  ngOnInit(): void {}

  toggleExpanded(): void {
    if (!this.enabled) {
      return;
    }
    this.expanded = !this.expanded;
  }
}
