import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IActionToken } from '../model/action-token';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class ActionService {
  private readonly actionToken: string = 'currentIntent';

  constructor(private readonly commonService: CommonService) {}

  public GetActionToken(actionName: string): Observable<IActionToken> {
    return this.commonService.createActionToken(actionName);
  }

  public SetCurrentIntent(token: string): void {
    localStorage.setItem(this.actionToken, token);
  }

  public GetCurrentIntent(): string {
    return localStorage.getItem(this.actionToken);
  }
}
