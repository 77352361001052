import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ResellerApplicationComponent } from './component/reseller-application/reseller-application.component';
import { ResellerAppGuardGuard } from './guard/reseller-app-guard.guard';

const routes: Routes = [
  { path: '', component: ResellerApplicationComponent, canActivate: [ResellerAppGuardGuard], runGuardsAndResolvers: 'always' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ResellerRoutingModule {}
