import { Injectable } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { ShippingAddressInUseComponent } from '../component/shipping-address-in-use/shipping-address-in-use.component';
import { ShippingAddressComponent } from '../component/shipping-address/shipping-address.component';
import { ShippingInformationComponent } from '../component/shipping-information/shipping-information.component';
import { ShippingMethodSelectionComponent } from '../component/shipping-method-selection/shipping-method-selection.component';
import { IShippingAddress, IShippingMethod } from '../model';

@Injectable({
  providedIn: 'root',
})
export class ShippingDialogService {

  constructor(private readonly dialogService: DialogService) { }

  openShippingAddressDialog(
    shipToNumber: number,
    onSelected: (shippingAddress: IShippingAddress) => void = null,
    onClose: () => void = null
  ): DialogRef {
    const dialogRef: DialogRef = this.dialogService.open({
      title: 'Shipping Address',
      content: ShippingAddressComponent,
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
    });

    const componentRef: ShippingAddressComponent = dialogRef.content.instance;
    componentRef.shipToNumber = shipToNumber;

    componentRef.closeDialog.subscribe((shippingAddress: IShippingAddress) => {
      if (onSelected) {
        onSelected(shippingAddress);
      }
      dialogRef.close();
    });

    dialogRef.result.subscribe((result: any) => {
      if (onClose) {
        onClose();
      }
    });

    return dialogRef;
  }

  openShippingMethodSelectionDialog(
    selectedMethod: string,
    shippingMethods: IShippingMethod[],
    showPremierDiscount: boolean,
    onSelected: (shippingMethod: string) => void = null,
    onClose: () => void = null
  ): DialogRef {

    const dialogRef = this.dialogService.open({
      title: 'Shipping Method',
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
      content: ShippingMethodSelectionComponent,
    });

    const shippingSelection: ShippingMethodSelectionComponent = dialogRef.content.instance;
    shippingSelection.selectedMethod = selectedMethod;
    shippingSelection.shippingMethods = shippingMethods;
    shippingSelection.showPremierDiscount = showPremierDiscount;
    shippingSelection.methodSelected.subscribe((shippingMethod: string) => {
      if (onSelected) {
        onSelected(shippingMethod);
      }
      dialogRef.close();
    });

    dialogRef.result.subscribe((result) => {
      if (onClose) {
        onClose();
      }
    });

    return dialogRef;
  }

  openShippingInformationDialog(onClose: () => void = null): DialogRef {
    const dialogRef = this.dialogService.open({
      title: 'Shipping Information',
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
      content: ShippingInformationComponent,
    });

    dialogRef.result.subscribe((result) => {
      if (onClose) {
        onClose();
      }
    });

    return dialogRef;
  }

  openAddressInUseErrorDialog(onClose: () => void = null): DialogRef {
    const dialogRef = this.dialogService.open({
      title: 'We\'re Sorry',
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
      content: ShippingAddressInUseComponent,
    });

    const componentRef: ShippingAddressInUseComponent = dialogRef.content.instance;
    componentRef.closeDialog.subscribe(() => {
      dialogRef.close();
    });

    dialogRef.result.subscribe((result) => {
      if (onClose) {
        onClose();
      }
    });

    return dialogRef;
  }

}
