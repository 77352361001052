import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/service/common.service';
import { CommonMessage, ICommonMessage } from '../../model/common-message.model';

@Component({
  selector: 'lef-global-cart-messages',
  templateUrl: './global-cart-messages.component.html',
  styleUrls: ['./global-cart-messages.component.scss'],
})
export class GlobalCartMessagesComponent implements OnInit, OnDestroy {
  model: ICommonMessage = new CommonMessage();
  currentRoute: string;
  globalMessagesChangeSubscription: Subscription;

  constructor(private readonly commonService: CommonService, private readonly router: Router) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd | any) => {
      this.loadGlobalCommonMessages();
      this.currentRoute = event.url;
    });
  }

  ngOnInit(): void {
    this.subscribeToRefreshMessage();
  }

  subscribeToRefreshMessage() {
    this.globalMessagesChangeSubscription = this.commonService.globalMessagesChange.subscribe(() => {
      this.loadGlobalCommonMessages();
    });
  }

  ngOnDestroy(): void {
    if (this.globalMessagesChangeSubscription) {
      this.globalMessagesChangeSubscription.unsubscribe();
      this.globalMessagesChangeSubscription = null;
    }
  }

  loadGlobalCommonMessages() {
    setTimeout(() => {
      this.commonService.getGlobalCommonMessage(this.router.url, false).subscribe({
        next: (result: CommonMessage) => {
          this.model = result;
        },
        error: (error) => {},
        complete: () => {},
      });
    }, 1500);
  }
}
