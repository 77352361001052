import { Injectable } from '@angular/core';
import { ICartItem } from 'src/app/cart-common/model';
import { CartItemsChanged } from '../model/cart-items-changed.model';

@Injectable({
  providedIn: 'root',
})
export class CartExplorerService {
  constructor() {}

  verifyItemsOnCartChanges(previousItems: ICartItem[], currentItems: ICartItem[]): CartItemsChanged {
    const changes = new CartItemsChanged();
    changes.itemsAdded = this.extractItemsAdded(previousItems, currentItems);
    changes.itemsRemoved = this.extractItemsRemoved(previousItems, currentItems);
    return changes;
  }

  extractItemsRemoved(previousItems: ICartItem[], currentItems: ICartItem[]): ICartItem[] {
    if (previousItems.length === 0) {
      return [];
    }
    const itemsRemoved: ICartItem[] = [];
    previousItems.forEach((item) => {
      const currentItem = currentItems.find((p) => p.itemNumber === item.itemNumber);
      if (!!currentItem) {
        // Lets Ignore Qty changes (We don't want that events on GTM)
        // if (currentItem.quantity < item.quantity) {
        //   const itemRemoved: ICartItem = JSON.parse(JSON.stringify(item));
        //   itemRemoved.quantity = item.quantity - currentItem.quantity;
        //   itemsRemoved.push(itemRemoved);
        // }
      } else {
        itemsRemoved.push(item);
      }
    });
    return itemsRemoved;
  }

  extractItemsAdded(previousItems: ICartItem[], currentItems: ICartItem[]): ICartItem[] {
    if (currentItems.length === 0) {
      return [];
    }
    const itemsAdded: ICartItem[] = [];
    currentItems.forEach((item) => {
      const previousItem = previousItems.find((p) => p.itemNumber === item.itemNumber);
      if (!!previousItem) {
        // Lets Ignore Qty changes (We don't want that events on GTM)
        // if (previousItem.quantity < item.quantity) {
        //   const itemAdded: ICartItem = JSON.parse(JSON.stringify(item));
        //   itemAdded.quantity = item.quantity - previousItem.quantity;
        //   itemsAdded.push(itemAdded);
        // }
      } else {
        itemsAdded.push(item);
      }
    });
    return itemsAdded;
  }
}
