<div class="auto-ship-shipping-address-selection">
  <div class="selection-header">
    <lef-auto-ship-ribbon-info [shippingDay]='shippingDay' [showShippingDay]='true'>
    </lef-auto-ship-ribbon-info>
  </div>
  <div *ngIf="shippingMethods" class="selection-body">
    <div *ngFor="let shippingMethod of shippingMethods" class="selection-record py-2 px-4">
      <div class="d-flex justify-content-between">
        <input kendoRadioButton class="k-radio form-check-input" type="radio" [value]="shippingMethod.shippingMethod"
               [(ngModel)]="selectedMethod" />
        <div>
          <div>
            {{shippingMethod.description}}
          </div>
        </div>
      </div>
    </div>
    <div class="text-right">
      <button class="btn btn-action" (click)="onMethodSelected()">
        Select
      </button>
    </div>
  </div>
</div>