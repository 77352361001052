import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

// Use in this way: []
// [childAttributes]="['selectors:attr=value', 'selectors:attr=value']
// [childAttributes]="['input:aria-labelledby=Test', '#myId:tabindex=-1'"

@Directive({
  selector: '[lefSetChildAttributes]',
})
export class SetChildAttributesDirective implements AfterViewInit {
  @Input() childAttributes?: string[];

  nativeElement
  constructor(private readonly elementRef: ElementRef) {
    
  }

  ngAfterViewInit() {
    this.getElement();
    this.applyAttributeChanges();
  }

  private getElement(){
    this.nativeElement = (this.elementRef as any)?.wrapper ? 
    (this.elementRef as any)?.wrapper?.nativeElement : 
    (this.elementRef as any)?.nativeElement;
  }

  private applyAttributeChanges(): void {
    setTimeout(() => {
      if(!this.nativeElement) return;

      this.childAttributes.forEach(attribute => {
        this.applyAttribute(attribute)
      });
    });
  }

  private applyAttribute(attribute: string): void {
    if(!attribute?.indexOf(':')) return;

    let data = attribute.split(":", 2); // Extract Selector
    if(data.length < 2) return;

    const selector = data[0];
    data = data[1].split("=", 2);  // Extract & Separate KVP
    const attributeName = data[0];
    const attributeValue = data.length == 1 ? "" : data[1];
    
    const elements = this.nativeElement.querySelectorAll(selector)
    if(!elements?.length) return;

    for (const element of elements) {
      if (attributeValue == "null") {
        element.removeAttribute(attributeName);
      } else {
        element.setAttribute(attributeName, attributeValue);
      }
    }
  }
}
