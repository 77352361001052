import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { AuthorizationService } from 'src/app/authorization';
import { ILabSubject } from 'src/app/blood-lab/model';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { DefaultCartItemConfig, ICartItem, ICartItemConfig, IItemComponent } from '../../model';
import { CartItemPremierComponent } from '../cart-item-premier/cart-item-premier.component';
import { CartItemSubscriptionComponent } from '../cart-item-subscription/cart-item-subscription.component';
import { CartItemComponent } from '../cart-item/cart-item.component';

interface IComponentType {
  type: string;
  name: string;
  component: any;
}

@Component({
  selector: 'lef-cart-item-host',
  templateUrl: './cart-item-host.component.html',
  styleUrls: ['./cart-item-host.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CartItemHostComponent implements OnInit, IItemComponent {
  @Input() cartItemConfig: ICartItemConfig = {};
  @Input() item: ICartItem;
  @Input() labSubjects: ILabSubject[] = [];
  @Input() currentAddressHasAutoShip = false;
  @Input() wrapperClass: string;

  @Output() updateCart: EventEmitter<any> = new EventEmitter();
  @Output() removeItem: EventEmitter<ICartItem> = new EventEmitter();
  @Output() autoShipUpdated: EventEmitter<ICartItem> = new EventEmitter();
  @Output() extenderUpdated: EventEmitter<ICartItem> = new EventEmitter();
  @Output() addToWishListItem: EventEmitter<ICartItem> = new EventEmitter();

  itemConfig: ICartItemConfig = new DefaultCartItemConfig();
  defaultComponentType = 'NoSub';
  isWholesalerCustomer: boolean;

  itemComponents: IComponentType[] = [
    { type: 'P', name: 'Product', component: CartItemSubscriptionComponent },
    { type: 'M', name: 'MemberShip', component: CartItemPremierComponent },

    { type: 'NoSub', name: 'NoSubscription', component: CartItemComponent },
    { type: 'LA', name: 'BloodTest', component: CartItemComponent },
    { type: 'X', name: 'MemberShipExtension', component: CartItemComponent },
    { type: 'S', name: 'Subscription', component: CartItemComponent },
    { type: 'GC', name: 'GiftCard', component: CartItemComponent },
    { type: 'PC', name: 'Catalog', component: CartItemComponent },
  ];

  constructor(
    private readonly authService: AuthorizationService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private readonly config: CoreConfiguration
  ) { }

  ngOnInit(): void {
    Object.keys(this.cartItemConfig).forEach((k: string) => {
      if (this.cartItemConfig[k] != undefined) {
        this.itemConfig[k] = this.cartItemConfig[k];
      }
    });

    this.isWholesalerCustomer = this.authService.isWholesaler();

    // Load Component By Type
    const componentType = this.getComponentType();
    const componentDefinition = this.getComponentDefinitionByType(componentType);
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentDefinition.component);

    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(componentFactory);
    const instance = componentRef.instance as IItemComponent;
    instance.cartItemConfig = this.itemConfig;
    instance.item = this.item;
    instance.wrapperClass = this.wrapperClass;

    if (componentType === 'LA') {
      instance.labSubjects = this.labSubjects;
    }

    if (componentType === 'P') {
      instance.currentAddressHasAutoShip = this.currentAddressHasAutoShip;
    }

    instance.updateCart.subscribe((cart) => this.updateCart.emit(cart));
    instance.removeItem.subscribe((item) => this.removeItem.emit(item));
    instance.autoShipUpdated.subscribe((item) => this.autoShipUpdated.emit(item));
    instance.extenderUpdated.subscribe((item) => this.extenderUpdated.emit(item));
    instance.addToWishListItem.subscribe((item) => this.addToWishListItem.emit(item));

    this.cdr.detectChanges();
  }

  getComponentType(): string {
    let componentType = this.item && this.item.itemType ? this.item.itemType : this.defaultComponentType;

    // Restrict Subscription for Wholesalers on the Cart Page
    if (componentType === 'P' && this.isWholesalerCustomer && window.location.pathname === this.config.webRoutes.root) {
      componentType = this.defaultComponentType;
    }

    // Restrict VIP
    if (componentType === 'P' && !this.item?.isVipEligible) {
      componentType = this.defaultComponentType;
    }

    return componentType;
  }

  getComponentDefinitionByType(type: string): IComponentType {
    let compType = this.itemComponents.find((i) => i.type === type);
    if (!compType) {
      compType = this.itemComponents.find((i) => i.type === this.defaultComponentType);
    }
    return compType;
  }
}
