import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'lef-icon-checkbox',
  template: `
    <div
      [ngClass]="{
        'form-check': to.formCheck.indexOf('custom') === -1,
        'form-check-inline': to.formCheck === 'inline',
        'custom-control': to.formCheck.indexOf('custom') === 0,
        'custom-checkbox': to.formCheck === 'custom' || to.formCheck === 'custom-inline',
        'custom-control-inline': to.formCheck === 'custom-inline',
        'custom-switch': to.formCheck === 'custom-switch'
      }"
    >
      <div>
        <input
          #checkBox
          *ngIf="!(to.disabled && to.iconClass); else disabledIcon"
          type="checkbox"
          kendoCheckBox
          [class.k-state-invalid]="showError"
          [indeterminate]="to.indeterminate && formControl.value == null"
          [formControl]="formControl"
          [formlyAttributes]="field"
        />
      </div>
      <div>
        <ng-template #disabledIcon>
          <i [ngClass]="to.iconClass"></i>
        </ng-template>
        <label class="k-checkbox-label clickable" [for]="id">
          <span [innerHtml]="to.label"></span>
          <span *ngIf="to.required && to.hideRequiredMarker !== true">*</span>
        </label>
      </div>
    </div>
  `,
})
export class IconCheckboxComponent extends FieldType<FieldTypeConfig> implements OnInit {
  iconClass = '';

  override defaultOptions = {
    templateOptions: {
      indeterminate: true,
      hideLabel: true,
      formCheck: 'custom', // 'custom' | 'custom-inline' | 'custom-switch' | 'stacked' | 'inline'
    },
  };

  ngOnInit(): void {
    if (this.props.options) {
      const options: any[] = this.props.options as any[];
      if (options && options.length > 0) {
        if (options[0].iconClass) {
          this.iconClass = options[0].iconClass;
        }
      }
    }
  }
}
