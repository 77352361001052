<div *ngIf="delivery" class="auto-sched-delivery-tabs">
  <kendo-tabstrip [keepTabContent]="true">
    <kendo-tabstrip-tab *ngFor="let shipment of delivery.shipments let i=index" [title]="shipment.date"
                        [selected]="i == 0">
      <ng-template kendoTabContent>
        <div *ngFor="let item of shipment.items" class="auto-sched-delivery-item">
          <p class="text-primary font-md font-weight-medium">
            {{item.friendlyName}}
          </p>
          <div class="d-flex justify-content-between">
            <div>
              <p>
                {{item.subtitle}}
              </p>
              <p>
                <strong>Quantity:</strong> {{item.quantity}}
              </p>
            </div>
            <div class="text-right">
              <p>
                <strong>Extended Price:</strong>
              </p>
              <p>
                {{(item.itemPrice*item.quantity)|currency}}
              </p>
            </div>
          </div>
        </div>
        <div class="auto-sched-delivery-item">
          <div [ngClass]="delivery.requiresTaxes ? 'd-flex justify-content-between' : 'd-flex justify-content-end'">
            <div *ngIf="delivery.requiresTaxes" class="text-red">
              **Taxes not displayed on autoship totals
            </div>
            <div>
              <strong>Total:</strong> {{shipment.total | currency}}
            </div>
          </div>
        </div>
        <p class="mt-3">
          If any of your items are on sale when your order is processed, you will receive sale pricing. Note that prices
          displayed are a quote; when your order
          is processed, larger discounts may be applied.
        </p>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>