import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CommonMessage } from 'src/app/common/model/common-message.model';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { BaseService } from 'src/app/shared/service/base-service';
import { IActionToken } from '../model/action-token';
import { IListItem } from '../model/list-item';

@Injectable({
  providedIn: 'root',
})
export class CommonService extends BaseService<object> {
  protected apiUrl: string;

  globalMessagesChange: Subject<any> = new Subject();

  constructor(protected override readonly httpClient: HttpClient, private readonly config: CoreConfiguration) {
    super(httpClient);

    this.apiUrl = config.environment.api + config.apiRoutes.common;
  }

  getStateList(country: string): Observable<IListItem[]> {
    return this.httpClient.get<IListItem[]>(`${this.apiUrl}/getStateList?country=${country}`).pipe(catchError(this.handleError));
  }

  getCountryList(): Observable<IListItem[]> {
    return this.httpClient.get<IListItem[]>(`${this.apiUrl}/getCountryList`).pipe(catchError(this.handleError));
  }

  getAboutUsList(): Observable<IListItem[]> {
    return this.httpClient.get<IListItem[]>(`${this.apiUrl}/getAboutUsList`).pipe(catchError(this.handleError));
  }

  getSalutationList(): Observable<IListItem[]> {
    return this.httpClient.get<IListItem[]>(`${this.apiUrl}/getSalutationList`).pipe(catchError(this.handleError));
  }

  getProp65SaleOptionsList(): Observable<IListItem[]> {
    return this.httpClient.get<IListItem[]>(`${this.apiUrl}/getProp65SaleOptionsList`).pipe(catchError(this.handleError));
  }

  getGlobalCommonMessage(currentUrl: string, refreshGlobalMessages: boolean): Observable<CommonMessage> {
    return this.httpClient.get<CommonMessage>(
      `${this.apiUrl}/GetGlobalCommonMessage?currentUrl=${currentUrl}&refreshGlobalMessages=${refreshGlobalMessages}`
    );
  }

  refreshGlobalMessages(): void {
    this.globalMessagesChange.next(null);
  }

  getErrorExclution(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.apiUrl}/GetErrorExclution`);
  }

  createActionToken(actionName: string): Observable<IActionToken> {
    return this.httpClient.get<{ allowance: string; allowedFor: number }>(`${this.apiUrl}/GetAllowance?value=${actionName}`).pipe(
      map((result) => {
        return { token: btoa(result.allowance), expiration: result.allowedFor };
      })
    );
  }
}
