export enum PaymentType {
  CreditCard,
  Cash,
  Check,
  GiftCertificate,
  Cod,
  TravelersCheck,
  OnAccount,
  AppliedCredit,
  CheckRequest,
  eCheck,
  PayPal,
  Rewards,
  ApplePay,
  GooglePay,
  None,
}
