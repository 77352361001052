<div class="d-flex cart-item-wrapper" [ngClass]='wrapperClass'>
  <div *ngIf="cartItemConfig.showImage && screenSize > screenSizes.MD" class="cart-item-image-wrapper">
    <a [attr.href]="itemUrl" aria-hidden="true" tabindex="-1">
      <img class="cart-item-image" alt="" [defaultImage]="defaultImage" [errorImage]="defaultImage"
           [lazyLoad]="itemImageUrl" aria-hidden="true" />
    </a>
  </div>

  <div class="cart-item-content-wrapper">

    <div class="cart-item-content">
      <div class="d-flex co-item-wrap">
        <div *ngIf="cartItemConfig.showImage && screenSize < screenSizes.LG" class="cart-item-image-wrapper">
          <a [attr.href]="itemUrl" aria-hidden="true" tabindex="-1">
            <img class="cart-item-image-sm" alt="" [defaultImage]="defaultImage" [errorImage]="defaultImage"
                 [lazyLoad]="itemImageUrl" aria-hidden="true" />
          </a>
        </div>
        <div class="w-100">
          <p class="cart-item-title"><a [attr.href]="itemUrl">{{item.itemDescription}}</a></p>
          <p class="cart-item-number">
            <span *ngIf="item.nameSuffix">{{item.nameSuffix}} | </span>
            <span>#{{item.itemNumber}} | <b>{{item.yourPrice | currency}}</b></span>
          </p>
          <p class="cart-item-component" *ngFor="let component of item.components">{{component.longDescription}}</p>

          <div class="cart-item-actions-wrap">
            <div class="d-md-flex align-items-baseline flex-wrap">
              <div class="cart-item-qty">
                <lef-quantity-input [label]="'Quantity:'" [quantity]="item.quantity" [componentId]="+item.itemNumber"
                                    [canChangeQuantity]="cartItemConfig.isEditable && item.canChangeQuantity"
                                    (quantityUpdate)="onQuantityUpdate($event)">
                </lef-quantity-input>
              </div>

              <ul *ngIf="cartItemConfig.isEditable" class="cart-item-actions mt-3 ml-lg-2">
                <li>
                  <button (click)="remove()" class="btn btn-link ml-lg-3">Remove</button>
                </li>
                <li *ngIf="cartItemConfig.showActions && item.itemIsInFavorites">Added to Wish List</li>
                <li
                    *ngIf="cartItemConfig.showActions && !item.itemIsInFavorites && item.itemType !=='M' && item.itemType !=='X'">
                  <button (click)="addToWishList()" class="btn btn-link"
                          [attr.role]="authorizationService.isLoggedIn() ? null: 'link'">Add to Wish List</button>
                </li>
              </ul>
            </div>
            <div>
              <div class="text-red text-right ml-lg-1" [innerHTML]="item.discountMessage"></div>
              <lef-cart-item-price *ngIf="!cartItemConfig.showPurchaseOptions && !isAutoShip()"
                                   [extendedPrice]="item.extendedPrice"
                                   [totalBaseStandardPrice]="item.totalBaseStandardPrice"
                                   [percentSavings]="item.percentSavings" [isSuperSale]="item.isSuperSale"
                                   [isExcludedFromPromotion]="item.isExcludedFromPromotion"
                                   [excludedFromPromotionMessage]="item.excludedFromPromotionMessage">
              </lef-cart-item-price>
            </div>
          </div>
        </div>
      </div>
      <div class="co-alert-messages" role="alert">
        <lef-cart-messages [messages]="item.itemMessages"></lef-cart-messages>
      </div>
    </div>

    <div class="cart-item-purchase-block">
      <div *ngIf="cartItemConfig.showPurchaseOptions" class="cart-item-one-purchase-wrapper"
           [ngClass]="{'cart-item-active-purchase-type' : !isAutoShip()}">
        <div class="cart-purchase-opt">
          <div class="cart-item-one-purchase">
            <label class="cart-radio-opt mb-0">
              <input *ngIf="cartItemConfig.showPurchaseOptions" type="radio" [id]="purchaseOptionNonAutoShipId"
                     [attr.name]="'isAutoShip-' + item.itemNumber" [value]="false" [(ngModel)]="item.isAutoShip"
                     (ngModelChange)="updateAutoShip(purchaseOptionNonAutoShipId)" kendoRadioButton />
              <span class="cart-radio-opt-copy">One Time Purchase</span>
            </label>
          </div>
          <div>
            <lef-cart-item-price *ngIf="!isAutoShip()" [extendedPrice]="item.extendedPrice"
                                 [totalBaseStandardPrice]="item.totalBaseStandardPrice"
                                 [percentSavings]="item.percentSavings" [isSuperSale]="item.isSuperSale"
                                 [isExcludedFromPromotion]="item.isExcludedFromPromotion"
                                 [excludedFromPromotionMessage]="item.excludedFromPromotionMessage">
            </lef-cart-item-price>
          </div>
        </div>
        <div *ngIf="!isAutoShip()" class="cart-item-message">
          <lef-cart-item-messages [messages]="item.messages"></lef-cart-item-messages>
        </div>
      </div>

      <div *ngIf="item.isAutoShipAvailable && (cartItemConfig.showPurchaseOptions || isAutoShip())"
           class="cart-item-subscription-wrapper d-block"
           [ngClass]="{'cart-item-active-purchase-type co-auto-ship' : isAutoShip()}">

        <div class="cart-purchase-opt">

          <div class="cart-item-subscription">
            <div *ngIf="existingAutoShipText" class="text-red">
              {{existingAutoShipText}}
            </div>

            <div class="d-flex">
              <div class="subscribe-container">
                <label class="cart-radio-opt mb-0">
                  <input class="auto-ship-radio" *ngIf="cartItemConfig.showPurchaseOptions" type="radio"
                         [id]="purchaseOptionAutoShipId" [attr.name]="'isAutoShip-' + item.itemNumber" [value]="true"
                         [(ngModel)]="item.isAutoShip" (ngModelChange)="updateAutoShip(purchaseOptionAutoShipId)"
                         kendoRadioButton />
                  <div class="cart-radio-opt-copy" *ngIf="isAutoShip()">
                    <span> Subscribe to <span class="premier">AutoShip &amp;
                        Save</span> for <strong>FREE DELIVERY</strong></span>
                    <b *ngIf="!cartItemConfig.isEditable"><span *ngIf="item.autoShipFrequency && autoShipFrequency">
                        {{autoShipFrequency}}
                      </span></b>
                  </div>
                  <div class="cart-radio-opt-copy" *ngIf="!isAutoShip()">
                    Subscribe to <span class="text-primary font-weight-bold"> AutoShip &amp; Save </span>
                    <span *ngIf="item.maximumSavings > 0"> and Save ({{item.maximumSavings | currency}})</span>
                  </div>
                </label>
                <button class="ml-3 btn-link-txt" aria-haspopup="dialog" aria-controls="autoShipInfo"
                        (click)="showAutoShipInfo($event, autoShipInfo)"> <span>Learn
                    More</span> <i class="lef-information-solid text-info ml-1"></i></button>
              </div>
              <div class="cart-item-price-info pl-3 pl-xl-0">
                <lef-cart-item-price *ngIf="isAutoShip()" [extendedPrice]="item.extendedPrice"
                                     [totalBaseStandardPrice]="item.totalBaseStandardPrice"
                                     [percentSavings]="item.percentSavings" [isSuperSale]="item.isSuperSale"
                                     [isExcludedFromPromotion]="item.isExcludedFromPromotion"
                                     [excludedFromPromotionMessage]="item.excludedFromPromotionMessage">
                </lef-cart-item-price>
                <div *ngIf="!cartItemConfig.isEditable" class="text-right mt-3 mr-2">
                  <button class="btn btn-link" routerLink="/account/autoship">Manage AutoShip</button>
                </div>
              </div>
            </div>




            <form [formGroup]="autoShipForm" novalidate class="k-form form-ui autoShip-form" *ngIf="isAutoShip()">
              <div class="cart-item-smf">
                <button type="button" class="link-underline clickable btn-link-txt p-0 my-2"
                        (click)="showAutoShipInfo($event, autoShipInfo)">
                  <span>Ship my first order immediately</span> <i class="lef-information-solid text-info ml-1"></i>
                </button>
                <div class="auto-ship-selection">
                  <div class="d-flex align-items-center justify-content-start mb-2 mt-md-2">
                    <div class="mr-1 mr-xl-0">Then delivery every</div>
                    <div class="as-del-every">
                      <span *ngIf="!cartItemConfig.isEditable" class="px-1 font-weight-bold">
                        {{item.autoShipFrequency}} month(s)
                      </span>
                      <div *ngIf="cartItemConfig.isEditable" class="mx-lg-2">
                        <!-- <kendo-dropdownlist [data]="autoShipFrequencyValues" [textField]="'text'" [valueField]="'value'"
                                          [valuePrimitive]="true" name="autoShipFrequency"
                                          [popupSettings]="{width: 150}" formControlName="autoShipFrequency">
                      </kendo-dropdownlist> -->
                        <select formControlName="autoShipFrequency" name="autoShipFrequency">
                          <ng-container *ngFor="let opt of autoShipFrequencyValues">
                            <option [ngValue]="opt.value">{{ opt.text }}</option>
                          </ng-container>
                        </select>
                      </div>
                    </div>
                  </div>


                  <div class="d-flex align-items-center">
                    <span class="ml-lg-1 mr-1">beginning on</span>
                    <div>
                      <span *ngIf="!cartItemConfig.isEditable" class="pl-1 font-weight-bold">
                        {{item.autoShipStartDate | date}}
                      </span>
                      <div *ngIf="cartItemConfig.isEditable">
                        <!-- <kendo-dropdownlist [data]="autoShipStartDates" [textField]="'text'" [valueField]="'value'"
                                          [valuePrimitive]="true" name="autoShipStartDate"
                                          [popupSettings]="{width: 150}" formControlName="autoShipStartDate">
                      </kendo-dropdownlist> -->
                        <select formControlName="autoShipStartDate" name="autoShipStartDate">
                          <ng-container *ngFor="let opt of autoShipStartDates">
                            <option [ngValue]="opt.value">{{ opt.text }}</option>
                          </ng-container>
                        </select>
                      </div>
                    </div>
                  </div>

                </div>

                <div *ngIf="item.discountMessage" class="text-red mt-2">
                  <strong>{{item.marketingCode}}</strong> coupon applied to today's shipment only. Future shipments will
                  be charged at the lowest available price at the time of shipping.
                </div>
                <div *ngIf="item.isOnSale && !item.discountMessage" class="text-red mt-2">
                  This item is currently on sale. Future shipments will be charged at the lowest price available at that
                  time.
                </div>
              </div>
            </form>
          </div>


        </div>
        <div *ngIf="isAutoShip()" class="cart-autoship-message">
          <lef-cart-item-messages [messages]="item.messages"></lef-cart-item-messages>
        </div>
      </div>

    </div>

    <div *ngIf="cartItemConfig.showExtender" class="cart-item-extender">
      <div>
        <ng-container [ngSwitch]="item.itemType">
          <lef-cart-item-blood-lab [labSubjects]="" *ngSwitchCase="'LA'">output</lef-cart-item-blood-lab>
        </ng-container>
      </div>
      <div class="cart-item-message">
        <lef-cart-item-messages [messages]="item.messages"></lef-cart-item-messages>
      </div>
    </div>

  </div>

</div>

<ng-template #autoShipInfo>
  <div class="autoship-info">
    <div class="text-center mb-3">
      <p class="text-info font-weight-bold">Free Shipping & Guaranteed Lowest Price</p>
    </div>
    <p class="mb-3">
      Never run out of what you need to stay well! With our FREE AutoShip & Save program, you’ll always save off the
      regular price.
    </p>
    <div class="d-md-flex align-items-md-center mb-3">
      <img src="../../../../assets/images/free-ship-ban.gif" width="225" height="96" alt="Free Shipping" />
      <p>
        You choose the delivery schedule, then modify or cancel any time—no strings attached!
      </p>
    </div>
    <div class="d-md-flex align-items-md-center mb-3">
      <img src="../../../../assets/images/guran-low-ban.gif" width="225" height="96" alt="Guranteed Lowest Prices" />
      <p>
        And shipping to anywhere in the United States (including Alaska and Hawaii) is always free, no matter how big or
        small the order.
      </p>
    </div>
    <p>
      Our AutoShip program is FREE. There are no additional charges, you pay only for the supplements you order.
    </p>
    <div class="text-right">
      <a [href]="autoShipFaqUrl" target="_blank" class="font-md text-primary">
        <strong>AutoShip Program FAQs</strong> <i class="lef-chevron-circle-right text-orange font-lg ml-1"></i>
      </a>
    </div>
  </div>
</ng-template>