<div class="mb-3">
  <label id="labTestSubjectSelect">Lab Test Subject:</label>
  <div class="mb-3">
    <!-- <kendo-dropdownlist *ngIf="isEditable" [defaultItem]="{name: 'Select a subject...', subjectNumber: null}"
                        [data]="labSubjects" textField="name" valueField="subjectNumber" [(ngModel)]="labSubject"
                        [popupSettings]="{ width: 'auto' }" (selectionChange)="checkSubject($event)">
    </kendo-dropdownlist> -->
    <select *ngIf="isEditable && !isSystemControlled" aria-labelledby="labTestSubjectSelect" [(ngModel)]="labSubject"
            (ngModelChange)="checkSubject($event)">
      <ng-container *ngFor="let opt of labSubjects">
        <option [ngValue]="opt">{{ opt.name }}</option>
      </ng-container>
    </select>

    <label *ngIf="!isEditable || isSystemControlled">{{labSubject.name}}</label>
    <button *ngIf="!isSystemControlled && isEditable && labSubject.subjectNumber > 0" class="btn btn-link ml-2"
            (click)="editSubject(labSubject.subjectNumber)">
      Edit
    </button>
  </div>
  <div *ngIf="showNonLongerMailAvailableMsg" [class]="'alert alert-info'" role="alert">
    <div>
      <i [class]="'lef-information-solid'"></i>
      <span>We no longer send Lab Test Results via Mail.</span>
    </div>
  </div>
  <div *ngIf="requireEmailAddress" [class]="'alert alert-warning'" role="alert">
    <div class="d-flex align-items-md-center">
      <div class="mr-1">
        <i [class]="'lef-warning text-red'"></i>
      </div>
      <div>
        <p class="text-copy">We no longer send Lab Test Results via Mail. An Email will be required to receive lab
          results.</p>
        <form class="" [formGroup]="emailRequiredForm" (ngSubmit)="updateSubjectEmail(true)">
          <input id="subjectNumber" type="hidden" name="subjectNumber" formControlName="subjectNumber">
          <input id="customerNumber" type="hidden" name="customerNumber" formControlName="customerNumber">
          <div class="row align-items-center">
            <div class="col-sm-6 d-flex align-items-center">
              <div class="">
                <input type="text" class="form-control" id="email" formControlName="email" placeholder="Email*"
                       (focusout)="emailResultsFocusOut()">
              </div>

              <button *ngIf="enableEmailSubmitButton" [disabled]="!emailRequiredForm.valid" type="submit"
                      class="btn btn-link ml-1">Submit</button>
            </div>
            <div class="col-md-6">
              <div class="text-lg-right pt-2">
                <input class="k-checkbox" type="checkbox" id="sameAsOnAccount" formControlName="sameAsOnAccount"
                       (click)="updateSubjectEmail(false)">
                <label class="form-check-label font-md" for="sameAsOnAccount">
                  Same as my account email
                </label>
              </div>
            </div>
          </div>

        </form>
      </div>

    </div>
  </div>
  <div *ngIf="labSubject.isMinor && !isSystemControlled && !labTest.isMinorFromCalifornia" class="bg-light-blue p-2">
    <div class="font-weight-bold">Lab test subject is a minor. Consent of a parent or legal guardian is required.</div>
    <div class="d-flex align-items-baseline">
      <input id="consent" role="button" aria-haspopup="dialog" type="checkbox" [(ngModel)]="labTest.hasConsent"
             [disabled]="consentDisabled" (click)="giveConsent($event)" />
      <div class="ml-2">
        <label for="consent" [ngClass]="{'clickable': !consentDisabled}">
          Check here if you qualify to provide consent, then read and acknowledge the Consent.
        </label>

        <div *ngIf="labTest.consentBy">
          <ng-container *ngIf="!isEditable"> Consent Provided By: </ng-container>{{labTest.consentBy}}<ng-container
                        *ngIf="isEditable"> - </ng-container>
          <button *ngIf="isEditable" aria-haspopup="dialog" class="btn btn-link" (click)="showConsent()">
            View
          </button>
        </div>
        <div *ngIf="!labTest.consentBy" class="text-red">
          <i class="lef-warning"></i> Minor consent is required for: {{itemNumber}}
        </div>
      </div>
    </div>
  </div>
</div>