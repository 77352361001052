export interface IRequestResetPassword {
  email: string;
  isForgotPassword: boolean;
}

export class RequestResetPasswordModel implements IRequestResetPassword {
  email: string;
  isForgotPassword: boolean;
  constructor() {
    this.email = '';
    this.isForgotPassword = true;
  }
}
