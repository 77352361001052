import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { LoginModel } from 'src/app/login/model/login-model.model';
import { ApplicationSectionStep } from '../../model/application-section-step.enum';
import { IApplicationSection } from '../../model/application-section.model';

@Component({
  selector: 'lef-reseller-customer',
  templateUrl: './reseller-customer.component.html',
  styleUrls: ['./reseller-customer.component.scss'],
})
export class ResellerCustomerComponent implements OnInit, IApplicationSection {
  email = '';
  loginModel = new LoginModel();
  stepNumber = '1.';
  stepTitle = 'Register / Sign In';
  isStepValid = false;

  showChangeButton = false;
  showSignInButton = false;

  resetSubject: Subject<void> = new Subject<void>();

  isLoading = true;

  @Input()
  disableChangeButton: boolean;

  @Output()
  stepValidationChange: EventEmitter<IApplicationSection> = new EventEmitter<IApplicationSection>();

  isValid(): boolean {
    return this.isStepValid;
  }

  isDirty(): boolean {
    return false;
  }

  saveSection(): void {}

  getCurrentStep(): ApplicationSectionStep {
    return ApplicationSectionStep.Customer;
  }

  ngOnInit(): void {}

  signIn() {
    this.resetSubject.next();
  }

  changeCredentials() {
    this.resetSubject.next();
    this.isStepValid = false;
    this.stepValidationChange.emit(this as IApplicationSection);
  }

  onLoginUpdate(model: LoginModel) {
    const isFullLogin = model.loginStatus === 'FullLogin';
    const hasEmail = !!model.email;
    this.isStepValid = isFullLogin;

    this.showChangeButton = this.isStepValid;
    // this.showSignInButton = !this.isStepValid && hasEmail;
    this.isLoading = false;
    this.stepValidationChange.emit(this as IApplicationSection);
  }
}
