import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { BaseService } from 'src/app/shared/service/base-service';
import { FloatingCart } from '../model/floating-cart.model';

@Injectable({
  providedIn: 'root',
})
export class FloatingCartService extends BaseService<FloatingCart> {
  protected apiUrl: string;
  public itemsCountChange: Subject<any> = new Subject();

  constructor(protected override readonly httpClient: HttpClient, private readonly config: CoreConfiguration) {
    super(httpClient);
    this.apiUrl = config.environment.api + config.apiRoutes.cart;
  }

  getFloatingCart(): Observable<FloatingCart> {
    return this.httpGet(`${this.apiUrl}/GetFloatingCart`);
  }

  refreshItemsCount(): void {
    this.itemsCountChange.next(null);
  }
}
