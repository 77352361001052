import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { CommonDialogService } from 'src/app/common/service/common-dialog.service';
import { IShippingAddress } from '../../model/shipping-address';
import { ShippingDialogService } from '../../service/shipping-dialog.service';
import { ShippingService } from '../../service/shipping.service';

@Component({
  selector: 'lef-shipping-address-selection',
  templateUrl: './shipping-address-selection.component.html',
  styleUrls: ['./shipping-address-selection.component.scss'],
})
export class ShippingAddressSelectionComponent implements OnInit {
  @Input() shippingAddresses: IShippingAddress[] = [];
  @Input() componentClass: string;
  @Input() searchEnable = false;
  @Input() continentalOnly = false;

  @Output() selectionVerified: EventEmitter<number> = new EventEmitter();
  @Output() updateCart: EventEmitter<any> = new EventEmitter();

  selectedAddress: IShippingAddress;

  searching = false;
  searchText = '';
  searchMessage =
    'Your Account has over 20 shipping destinations. ' +
    'You may search for the shipping destination of your choice ' +
    'by customer number, nickname or last name below.';

  paginationPageSize = 5;
  paginationSkip = 0;
  paginationShowPageSize = false;
  paginationShowInfo = false;
  paginationShowPrevNext = true;
  paginationType = 'numeric';
  paginationTotalPages: number;
  pagedShippingAddresses: IShippingAddress[];

  constructor(
    private readonly shippingService: ShippingService,
    private readonly shippingDialogService: ShippingDialogService,
    private readonly commonDialogService: CommonDialogService
  ) {}
  ngOnInit(): void {
    this.search();
  }

  onAddressSelected(): void {
    for (const shippingAddress of this.shippingAddresses) {
      shippingAddress.isSelected = false;
    }

    this.selectedAddress.isSelected = true;
  }

  onSelectionVerified(): void {
    if (this.selectedAddress?.isServiceByDistributor) {
      this.showDistributorDialog(this.selectedAddress);
      return;
    }

    this.selectionVerified.emit(this.selectedAddress?.shipToNumber);
  }

  onEditAddress(shippingAddress: IShippingAddress) {
    this.openShippingAddressDialog(shippingAddress.shipToNumber);
  }

  onAddAddress(): void {
    this.openShippingAddressDialog(0);
  }

  private initShippingAddresses(): void {
    this.selectedAddress = this.shippingAddresses.find((shippingAddress: IShippingAddress) => shippingAddress.isSelected);
    if (!this.selectedAddress) {
      this.selectedAddress = this.shippingAddresses[0];
    }

    this.paginationTotalPages = this.shippingAddresses.length;
    this.pageShippingAddresses();
  }

  search(): void {
    this.searching = true;
    this.shippingService.getShippingAddressList(this.searchText, false, this.continentalOnly).subscribe((addressList) => {
      this.shippingAddresses = addressList;
      this.initShippingAddresses();
      this.searching = false;
    });
  }

  openShippingAddressDialog = (shipToNumber: number) => {
    this.shippingDialogService.openShippingAddressDialog(shipToNumber, this.onShippingAddressUpdated);
  };

  onShippingAddressUpdated = (shippingAddress: IShippingAddress) => {
    const existingAddress = this.shippingAddresses.find((a) => a.shipToNumber === shippingAddress.shipToNumber);
    if (existingAddress) {
      Object.assign(existingAddress, shippingAddress);
      this.selectedAddress = existingAddress;
    } else {
      this.shippingAddresses.push(shippingAddress);
      this.selectedAddress = shippingAddress;
      this.pageShippingAddresses();
    }
  };

  onPageChange(e: PageChangeEvent): void {
    this.paginationSkip = e.skip;
    this.paginationPageSize = e.take;
    this.pageShippingAddresses();
  }

  private pageShippingAddresses(): void {
    this.pagedShippingAddresses = this.shippingAddresses.slice(this.paginationSkip, this.paginationSkip + this.paginationPageSize);
  }

  private showDistributorDialog(shippingAddress: IShippingAddress) {
    this.commonDialogService.openDistributorCountryVerificationDialog({
      showDialog: shippingAddress.isServiceByDistributor,
      message: shippingAddress.distributorRegionMessage,
      distributor: shippingAddress.distributorName,
      distributorSiteUrl: shippingAddress.distributorSiteUrl,
      region: shippingAddress.distributorRegion,
    });
  }
}
