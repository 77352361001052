import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ICartBaseModel } from 'src/app/cart-common/model';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { BaseService } from 'src/app/shared/service/base-service';
import { IShippingAddress } from '../model';

@Injectable({
  providedIn: 'root',
})
export class ShippingService extends BaseService<IShippingAddress> {
  protected apiUrl: string;

  constructor(protected override readonly httpClient: HttpClient, private readonly config: CoreConfiguration) {
    super(httpClient);

    this.apiUrl = config.environment.api + config.apiRoutes.shipping;
  }

  isAddressSearchEnable(): Observable<boolean> {
    return this.httpGet<boolean>(`${this.apiUrl}/IsAddressSearchEnable`);
  }

  getShippingAddress(shipToNumber: number): Observable<IShippingAddress> {
    return this.getSingle(`${this.apiUrl}/GetShippingAddress?shipToNumber=${shipToNumber}`);
  }

  getGuestShippingDestination(): Observable<IShippingAddress> {
    return this.getSingle(`${this.apiUrl}/GetGuestShippingDestination`);
  }

  validateAddress(shippingAddress: IShippingAddress): Observable<IShippingAddress> {
    return this.post(`${this.apiUrl}/ValidateAddress`, shippingAddress);
  }
  saveShippingAddress(shippingAddress: IShippingAddress): Observable<IShippingAddress> {
    return this.update(`${this.apiUrl}/SaveShippingAddress`, shippingAddress);
  }

  deleteShippingAddress(shipTo: number): Observable<ICartBaseModel> {
    return this.httpClient
      .delete<ICartBaseModel>(`${this.apiUrl}/DeleteShippingAddress?shipTo=${shipTo}`)
      .pipe(catchError(this.handleError));
  }

  saveGuestShippingDestination(shippingAddress: IShippingAddress): Observable<IShippingAddress> {
    return this.post(`${this.apiUrl}/SaveGuestShippingDestination`, shippingAddress);
  }

  getShippingAddressList(search: string = '', getWholeList: boolean = false, continental: boolean = false): Observable<IShippingAddress[]> {
    return this.getMany(`${this.apiUrl}/GetShippingAddressList?search=${search}&getWholeList=${getWholeList}&continental=${continental}`);
  }  
}
