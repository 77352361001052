import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PremierService {
  private readonly currencyPipe = new CurrencyPipe('en-US');

  constructor() {}

  getRateText(rate: number): string {
    return rate > 0 ? this.currencyPipe.transform(rate) : 'FREE';
  }

  getDiscountText(rate: number): string {
    return rate > 0 ? 'Discount' : 'FREE';
  }
}
