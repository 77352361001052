import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DialogCloseResult, DialogRef } from '@progress/kendo-angular-dialog';
import { AuthorizationService } from 'src/app/authorization';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { Acknowledgment } from '../../model/acknowledgment.model';
import { ApplicationSectionStep } from '../../model/application-section-step.enum';
import { IApplicationSection } from '../../model/application-section.model';
import { ResellerSectionStatus } from '../../model/reseller-section-status';
import { ResellerApplicationService } from '../../service/reseller-application.service';
import { ResellerAcknowledgmentComponent } from '../reseller-acknowledgment/reseller-acknowledgment.component';

@Component({
  selector: 'lef-reseller-application',
  templateUrl: './reseller-application.component.html',
  styleUrls: ['./reseller-application.component.scss'],
})
export class ResellerApplicationComponent implements OnInit, AfterViewInit {
  applicationId: string;
  companySectionStatus = new ResellerSectionStatus();
  businessSectionStatus = new ResellerSectionStatus();
  completeApplicationStatus = new ResellerSectionStatus();
  enableSaveApplicationSection: boolean;
  enableSidebar: boolean;
  isWaitingFeedback: boolean;
  lastTicketResponse: string;
  isEditable: boolean;
  enableResetApplication: boolean;
  workflowState: string;
  private acknowledgment: Acknowledgment;
  private acknowledgmentKey: string = 'acknowledgment';
  private currentAcknowledgmentVersion: string = '1';
  private acknowledgmentDialog: DialogRef;

  get disableChangeButton(): boolean {
    return this.isWaitingFeedback || this.workflowState === 'Approved' || this.workflowState === 'Declined';
  }

  constructor(private readonly loadingService: LoadingService,
    private readonly resellerApplicationService: ResellerApplicationService,
    private readonly dialogService: DialogService,
    private readonly authorizationService: AuthorizationService,
    private readonly config: CoreConfiguration) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    if (!this.authorizationService.isLoggedIn() && this.config.environment.reselleracknowledgementEnabled) {
      this.showAcknowledgmentInfo();
    }
  }

  saveActiveSection() {
    this.resellerApplicationService.customerRequestSaveSection.next(true);
  }

  onStepValidationChange(section: IApplicationSection) {
    switch (section.getCurrentStep()) {
      case ApplicationSectionStep.Customer:
        this.onCustomerInformationChange(section);
        break;
    }
  }

  onApplicationProcessContinue(section: IApplicationSection) {
    switch (section.getCurrentStep()) {
      case ApplicationSectionStep.CompanyRegistration:
        this.onActivateSection(ApplicationSectionStep.BusinessInformation);
        break;
      case ApplicationSectionStep.BusinessInformation:
        this.onActivateSection(ApplicationSectionStep.CompleteApplication);
        break;
      case ApplicationSectionStep.CompleteApplication:
        this.onActivateSection(ApplicationSectionStep.CompleteApplication);
        break;
    }
  }

  onCustomerInformationChange(section: IApplicationSection) {
    if (section.isValid()) {
      this.refreshApplication(null);
      this.getAcknowledgment();
    }
  }

  restartApplicationProcess() {
    this.loadingService.show();
    this.resellerApplicationService.moveApplicationToArchive(this.applicationId).subscribe(
      (application) => {
        this.loadingService.hide();
        this.refreshApplication(null);
      },
      (error) => {
        this.loadingService.hide();
      }
    );
  }

  onActivateSection(step: ApplicationSectionStep) {
    this.businessSectionStatus.isActive = false;
    this.companySectionStatus.isActive = false;
    this.completeApplicationStatus.isActive = false;
    this.isWaitingFeedback = false;
    this.lastTicketResponse = '';
    this.isEditable = false;
    this.refreshApplication(step);
  }

  refreshApplication(step: ApplicationSectionStep) {
    this.loadingService.show();
    this.resellerApplicationService.getApplication().subscribe(
      (application) => {
        this.applicationId = application.id;
        this.isWaitingFeedback = application.isWaitingFeedback;
        this.isEditable = application.isEditable;
        this.enableResetApplication = application.enableResetApplication;
        this.workflowState = application.workflowState;
        this.lastTicketResponse = application.lastTicketResponse;
        this.companySectionStatus.isEditable = application.isEditable;
        this.companySectionStatus.hasContent = application.companyRegistrationReceived;
        this.companySectionStatus.isContentValid = application.companyRegistrationValid;

        this.businessSectionStatus.isEditable = application.isEditable;
        this.businessSectionStatus.hasContent = application.businessInformationReceived;
        this.businessSectionStatus.isContentValid = application.businessInformationValid;

        this.completeApplicationStatus.isEditable = application.isEditable;
        this.completeApplicationStatus.isContentValid = application.businessInformationValid && application.companyRegistrationValid;
        this.completeApplicationStatus.hasContent = application.businessInformationReceived && application.companyRegistrationReceived;

        if (step == null) {
          if (application.businessInformationReceived) {
            if (application.businessInformationValid) {
              this.completeApplicationStatus.isActive = true;
            } else {
              this.businessSectionStatus.isActive = true;
            }
          } else if (application.companyRegistrationReceived) {
            if (application.companyRegistrationValid) {
              this.businessSectionStatus.isActive = true;
            } else {
              this.companySectionStatus.isActive = true;
            }
          } else {
            this.companySectionStatus.isActive = true;
          }
        } else {
          switch (step) {
            case ApplicationSectionStep.CompanyRegistration:
              this.companySectionStatus.isActive = true;
              break;
            case ApplicationSectionStep.BusinessInformation:
              this.businessSectionStatus.isActive = true;
              break;
            case ApplicationSectionStep.CompleteApplication:
              this.completeApplicationStatus.isActive = true;
              break;
          }
        }
        this.enableSaveApplicationSection = this.isEditable && (this.companySectionStatus.isActive || this.businessSectionStatus.isActive);
        this.enableSidebar =
          this.companySectionStatus.isActive || this.businessSectionStatus.isActive || this.completeApplicationStatus.isActive;
        /*
        if (this.companySectionStatus.isActive) {
          this.businessSectionStatus.hasContent = this.businessSectionStatus.hasContent && !this.businessSectionStatus.isEditable;
          this.completeApplicationStatus.hasContent =
            this.completeApplicationStatus.hasContent && !this.completeApplicationStatus.isEditable;
        } else if (this.businessSectionStatus.isActive) {
          this.companySectionStatus.hasContent = this.companySectionStatus.hasContent && !this.companySectionStatus.isEditable;
          this.completeApplicationStatus.hasContent =
            this.completeApplicationStatus.hasContent && !this.completeApplicationStatus.isEditable;
        }
        */
        this.loadingService.hide();
      },
      (error) => {
        this.loadingService.hide();
      }
    );
  }

  private getAcknowledgment(): void {
    if (!this.acknowledgment && this.config.environment.reselleracknowledgementEnabled) {
      this.loadingService.show();
      this.resellerApplicationService.getAcknowledgment().subscribe(
        (acknowledgment) => {
          this.acknowledgment = acknowledgment;
          if (this.acknowledgment?.acknowledgmentDate && this.acknowledgment?.acknowledgmentVersion === this.currentAcknowledgmentVersion) {
            window.localStorage.removeItem(this.acknowledgmentKey);
            return;
          }

          const acknowledgmentStorage: Acknowledgment = JSON.parse(window.localStorage.getItem(this.acknowledgmentKey));
          if (acknowledgmentStorage?.acknowledgmentVersion === this.currentAcknowledgmentVersion && acknowledgmentStorage?.acknowledgmentDate) {
            this.submitAcknowledgment(acknowledgmentStorage);
            window.localStorage.removeItem(this.acknowledgmentKey);
            return;
          }

          this.showAcknowledgmentInfo();
          this.loadingService.hide();
        },
        (error) => {
          this.loadingService.hide();
        }
      );
    }
  }

  private showAcknowledgmentInfo(): void {
    window.localStorage.removeItem(this.acknowledgmentKey);
    this.acknowledgmentDialog = this.dialogService.openClickListenerDisbled({
      title: 'Wholesaler Application Supplemental Privacy Statement & Consent',
      width: '80%',
      maxHeight: '100%',
      maxWidth: '100%',
      content: ResellerAcknowledgmentComponent,
      preventAction: (ev) => {
        return ev instanceof DialogCloseResult;
      }
    });

    const acknowledgmentComponent: ResellerAcknowledgmentComponent = this.acknowledgmentDialog.content.instance;

    acknowledgmentComponent.okayClicked.subscribe(() => {
      this.aproveAcknowledgment();
    });
  }

  private submitAcknowledgment(acknowledgment: Acknowledgment): void {
    this.loadingService.show();
    this.resellerApplicationService.saveAcknowledgment(acknowledgment).subscribe(
      () => {
        this.loadingService.hide();
      },
      (error) => {
        this.loadingService.hide();
      }
    )
  }

  private aproveAcknowledgment(): void {
    const acknowledgment = new Acknowledgment(this.currentAcknowledgmentVersion, new Date());
    if (this.authorizationService.isLoggedIn()) {
      this.submitAcknowledgment(acknowledgment);
    }
    else {
      window.localStorage.setItem(this.acknowledgmentKey, JSON.stringify(acknowledgment));
    }
    this.acknowledgmentDialog.close();
  }
}
