<div *ngIf="embeddedView" class="order-item-wrapper">
    <div class="row py-2 px-4">
        <div class="col-md-8">
            <div class="d-flex order-item-details-wrap border-sm-right">
                <div class="order-item-image">
                    <img alt="Product Image" [defaultImage]="defaultImage" [errorImage]="defaultImage"
                         [lazyLoad]="itemImageUrl" />
                </div>
                <div class="order-item-details">

                    <p class="order-hist-item-title pb-2">{{item.webFriendlyName}}</p>
                    <div class="d-lg-flex justify-content-md-between mb-1">
                        <p class="mb-0"><span *ngIf="item.nameSuffix">{{item.nameSuffix}}</span>
                            <span class="d-none d-md-inline"> | #{{item.itemNumber}}</span>
                            <span *ngIf="item.upcCode && isWholesaler"> | UPC: {{item.upcCode}}</span>
                        </p>
                        <p *ngIf="item.lineStatus" class="mb-0"><strong class="text-red">Status:
                                {{item.lineStatus}}</strong></p>
                    </div>
                    <div class="d-md-flex justify-content-md-between mb-1">
                        <div class="item-details">
                            <p class="mb-md-0 d-none d-md-block">Quantity: <span class="text-primary">
                                    {{item.quantity}}</span></p>

                            <p class="mb-md-0 d-block d-md-none">|
                                <span>#{{item.itemNumber}}</span>|&nbsp;&nbsp;Qty: <span class="text-primary">
                                    {{item.quantity}}</span>
                            </p>
                        </div>
                        <div class="item-details d-flex">
                            <p *ngIf="item.shippedDate"> <span>|</span>Shipped:
                                {{item.shippedDate}}</p>
                            <p *ngIf="item.returnedQuantity"><span>|</span>Returned Qty: {{item.returnedQuantity}}
                            </p>
                        </div>

                        <div class="item-details d-flex flex-column align-items-start align-items-md-end">
                            <p *ngIf="isWholesaler && item?.minimunAdvertisedPrice"
                               class="mb-0 font-weight-bold map-text">MAP:
                                <span class="font-weight-bold d-inline">{{item.minimunAdvertisedPrice}}</span>
                            </p>
                            <p class="mb-0 font-weight-bold" *ngIf="item.total">Total: <span
                                      class="font-weight-bold font-md-lg text-primary m-0 d-inline">{{item.total}}</span>
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 text-right order-item-rt d-print-none">
            <div>
                <button *ngIf="!item.isInCart" class="btn btn-link" type="button" (click)="addItem()">Add to
                    Cart</button>
                <button *ngIf="item.isInCart" class="btn btn-link" type="button" disabled>Added to Cart</button>
            </div>
            <div>
                <button *ngIf="!item.isInVip && item.isVipEligible && !item.isInCart" class="btn btn-link pb-0"
                        type="button" (click)="addToSubscription()">
                    Add to AutoShip &amp; Save
                </button>
                <p *ngIf="!item.isInVip && item.isVipEligible && !item.isInCart" class="font-md">
                    (Get lowest price &amp; free shipping)
                </p>

                <button *ngIf="item.isInVip" class="btn btn-link pb-0" type="button" (click)="goToAutoShipPage()">
                    Manage Subscriptions
                </button>
            </div>
            <div>
                <a href="{{writeReviewUrl}}?item={{item.itemNumber}}" class="btn btn-link" target="_blank">
                    Write a review
                </a>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!embeddedView" class="order-search-item-wrapper bg-light p-3 mb-4">
    <div class="row">
        <div class="col-md-7">
            <div class="d-flex">
                <div class="mr-3">
                    <img class="cart-item-image" alt="Product Image" [defaultImage]="defaultImage"
                         [errorImage]="defaultImage" [lazyLoad]="itemImageUrl" />
                </div>
                <div>
                    <p class="text-primary m-0"><strong>{{item.webFriendlyName}}</strong></p>
                    <p><span *ngIf="item.nameSuffix">{{item.nameSuffix}} |
                        </span> <span>#{{item.itemNumber}}</span></p>
                </div>
            </div>
        </div>
        <div class="col-md-5 text-right">
            <ul class="order-search-item-info">
                <li>Order #{{item.orderNumber}}</li>
                <li>Order Date {{item.orderDate}}</li>
                <li><button type="button" class="btn btn-link" (click)="goToDetails()">Order
                        Details</button></li>
            </ul>
            <button class="btn btn-warning" type="button" (click)="addItem()">Add to Cart</button>
        </div>
    </div>
</div>