import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { BaseService } from 'src/app/shared/service/base-service';
import { RenderingContentModel } from '../model/rendering-content.model';

@Injectable({
  providedIn: 'root',
})
export class RenderingContentService extends BaseService<RenderingContentModel> {
  protected apiUrl: string;

  constructor(protected override readonly httpClient: HttpClient, private readonly config: CoreConfiguration) {
    super(httpClient);

    this.apiUrl = config.environment.api + config.apiRoutes.renderingContent;
  }

  getRenderingContent(): Observable<Array<RenderingContentModel>> {
    const now = new Date();
    now.setDate(now.getDate() + 7);
    return this.getManyCached(`${this.apiUrl}/All`, 604800, now);
  }
}
