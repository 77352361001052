<ul class="contact-info" [ngClass]="{
      'text-left': align==='left',
      'text-center': align==='center',
      'text-right': align==='right'}">
  <li>24/7 Customer Service</li>
  <li>
    <a href="tel:{{config.environment.customerServicePhone}}" class="text-dark">
      <strong>
        {{config.environment.customerServicePhone}}
      </strong>
    </a>
  </li>
  <li>
    <button class="btn btn-link" (click)="sendFeedBack()">
      Email Us <i class="lef-chevron-circle-right text-orange"></i>
    </button>
  </li>
</ul>