export interface IErrorOptionalConfig {
  errorPath?: string;
}

export interface IErrorConfig {
  enableApiLogging: boolean;
  baseUrl: string;
  errorPath: string;
}

const ErrorConfigDefault: IErrorConfig = {
  enableApiLogging: true,
  baseUrl: '',
  errorPath: '/error',
};

export class ErrorRootConfigModel {
  public enableApiLogging: boolean;
  public apiServer: string;
  public optionalConfig?: IErrorOptionalConfig;
}

export class ErrorConfigModel {
  private configuration: IErrorConfig;

  constructor(enableApiLogging: boolean, server: string, errorConfig?: IErrorOptionalConfig) {
    errorConfig = errorConfig || {};
    // Only take values when set.
    Object.keys(errorConfig).forEach((k: string) => {
      if (errorConfig[k]) {
        ErrorConfigDefault[k] = errorConfig[k];
      }
    });
    this.configuration = ErrorConfigDefault;
    this.configuration.baseUrl = server;
    this.configuration.enableApiLogging = enableApiLogging;
  }

  public getConfig(): IErrorConfig {
    return this.configuration;
  }
}
