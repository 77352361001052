import { ElementRef, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HtmlUtilityService {
  localStorageKey = 'Cart_FocusElement';

  constructor() { }

  public restoreFocusPoint(): void {
    setTimeout(() => {
      this.tryFocusOnElement();
    });
  }

  public setFocusPoint(id: string): void {
    localStorage.setItem(this.localStorageKey, id);
  }

  private tryFocusOnElement() {
    const id = localStorage.getItem(this.localStorageKey);
    if (!id) {
      return;
    }

    const element = document.getElementById(id);
    if (element) {
      element.focus();
    }

    localStorage.removeItem(this.localStorageKey);
  }

  public focusFirstInvalidElement(DOMElement: ElementRef) {
    const firstInvalidControl: HTMLElement = DOMElement.nativeElement.querySelectorAll('input.ng-invalid, select.ng-invalid, textarea.ng-invalid')[0];
    firstInvalidControl.focus();
  }
}
