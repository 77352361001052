import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { ICart } from 'src/app/cart-common/model';
import { IListItem } from 'src/app/shared/model/list-item';
import { ILabSubject } from '../../model';
import { LabTestService } from '../../service/lab-test.service';

@Component({
  selector: 'lef-blood-lab-subject',
  templateUrl: './blood-lab-subject.component.html',
  styleUrls: ['./blood-lab-subject.component.scss'],
})
export class BloodLabSubjectComponent implements OnInit {
  @Input() customerNumber: number;
  @Input() subjectNumber: number;
  @Input() isForCart: boolean;
  @Input() itemId: string;

  @Output() subjectSaved: EventEmitter<ILabSubject> = new EventEmitter<ILabSubject>();
  @Output() cartSubjectSaved: EventEmitter<ICart> = new EventEmitter<ICart>();
  @Output() infoLabelClicked: EventEmitter<TemplateRef<any>> = new EventEmitter<TemplateRef<any>>();

  @ViewChild('requisitionDetails') requisitionDetails: TemplateRef<any>;

  labSubjectForm: UntypedFormGroup = new UntypedFormGroup({});
  labSubjectFormFields: FormlyFieldConfig[] = [];
  formLoading = false;

  labSubject: ILabSubject = {};
  genders: IListItem[] = [
    { value: 'F', description: 'Female' },
    { value: 'M', description: 'Male' },
  ];

  private currentOptions$: BehaviorSubject<IListItem[]> = new BehaviorSubject([]);
  protected requisitionOptions$: Observable<IListItem[]> = this.currentOptions$.pipe(map((items: IListItem[]) => items));

  constructor(private readonly labTestService: LabTestService) { }

  ngOnInit(): void {
    this.init();
  }

  saveLabSubject(): void {
    if (!this.labSubjectForm.valid || !this.labSubjectForm.dirty || !this.labSubject) {
      return;
    }

    if (this.isForCart) {
      this.saveCheckoutSubject();
      return;
    }

    const labSubjectResult$ = this.labSubject.isNew
      ? this.labTestService.createLabSubject(this.labSubject)
      : this.labTestService.updateLabSubject(this.labSubject);

    this.formLoading = true;
    labSubjectResult$.subscribe({
      next: (result: ILabSubject) => {
        this.subjectSaved.emit(result);
      },
      error: (error) => {
        this.formLoading = false;
      },
      complete: () => {
        this.formLoading = false;
      },
    });
  }

  private saveCheckoutSubject(): void {
    this.labSubject.itemId = this.itemId;
    const labSubjectResult$ = this.labSubject.isNew
      ? this.labTestService.createLabItemSubject(this.labSubject)
      : this.labTestService.updateLabItemSubject(this.labSubject);

    this.formLoading = true;
    labSubjectResult$.subscribe({
      next: (result: ICart) => {
        this.cartSubjectSaved.emit(result);
      },
      error: (error) => {
        this.formLoading = false;
      },
      complete: () => {
        this.formLoading = false;
      },
    });
  }

  private init(): void {
    this.formLoading = true;

    if (!this.customerNumber || !this.subjectNumber) {
      this.labTestService.getNewLabSubject().subscribe({
        next: (result: ILabSubject) => {
          this.labSubject = result;
          this.setRequisitionOptions();

          this.initForm();
        },
        error: (error) => {
          this.formLoading = false;
        },
        complete: () => {
          this.formLoading = false;
        },
      });
    } else {
      this.labTestService.getLabSubject(this.subjectNumber).subscribe({
        next: (result: ILabSubject) => {
          this.setLabSubject(result);
          this.initForm();
        },
        error: (error) => {
          this.formLoading = false;
        },
        complete: () => {
          this.formLoading = false;
        },
      });
    }
  }

  private setLabSubject(labSubject: ILabSubject): void {
    if (labSubject.dateOfBirth) {
      labSubject.dateOfBirth = new Date(labSubject.dateOfBirth);
    }
    this.labSubject = labSubject;
    this.setRequisitionOptions();
  }

  private setRequisitionOptions(): void {
    const requisitionOptions = [];
    if (!this.labSubject.labKitOnly) {
      requisitionOptions.push({ value: 'F', description: 'LabCorp Requisition Form' });
    }
    if (!this.labSubject.formOnly) {
      requisitionOptions.push({ value: 'K', description: 'Lab Kit' });
    }
    this.currentOptions$.next(requisitionOptions);
  }

  private initForm(): void {
    this.labSubjectForm = new UntypedFormGroup({});
    const now = new Date();
    const nowFormatted = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    now.setFullYear(now.getFullYear() - 120);
    const thenFormatted = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    this.labSubjectFormFields = [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12 col-md-6',
            type: 'kTextBox',
            key: 'firstName',
            templateOptions: {
              placeholder: 'First Name*',
              required: true,
              setFocus: true,
              maxLength: 20,
            },
          },
          {
            className: 'col-12 col-md-6',
            type: 'kTextBox',
            key: 'lastName',
            templateOptions: {
              placeholder: 'Last Name*',
              required: true,
              maxLength: 20,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col',
            type: 'kTextBox',
            key: 'address',
            templateOptions: {
              placeholder: 'Street Address*',
              required: true,
              maxLength: 40,
            },
            modelOptions: {
              updateOn: 'blur',
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-6 col-md-3',
            type: 'kTextBox',
            key: 'postalCode',
            templateOptions: {
              placeholder: 'Postal Code*',
              required: true,
              pattern: /^\d{5}(-\d{4})?$/,
            },
            modelOptions: {
              updateOn: 'blur',
            },
            validation: {
              messages: {
                required: 'This field is required.',
                pattern: 'Invalid Postal Code.',
                role: 'alert'
              },
            },
            asyncValidators: {
              validPostalCode: {
                expression: (control: UntypedFormControl) => {
                  return new Promise((resolve, reject) => {
                    if (this.labSubject.postalCode !== control.value) {
                      this.formLoading = true;
                      this.labSubject.postalCode = control.value;
                      this.labTestService
                        .validatePostalCode(this.labSubject)
                        .pipe(
                          tap((result: ILabSubject) => {
                            this.setLabSubject(result);
                          }),
                          map((result: ILabSubject) => resolve(result.messages.length < 1)),
                          finalize(() => (this.formLoading = false))
                        )
                        .subscribe();
                    } else {
                      resolve(true);
                    }
                  });
                },
                message: 'Invalid Postal Code.',
              },
            },
          },
          {
            className: 'col-6 col-md-3',
            type: 'kDropdown',
            key: 'gender',
            templateOptions: {
              options: this.genders,
              valueProp: 'value',
              labelProp: 'description',
              required: true,
              valuePrimitive: true,
              placeholder: 'Gender',
              placeholderValue: '',
            },
          },
          {
            className: 'col-12 col-md-6',
            type: 'date',
            key: 'dateOfBirth',
            wrappers: ['form-field-horizontal'],
            modelOptions: {
              updateOn: 'blur',
            },
            templateOptions: {
              label: 'Birthday:',
              placeholder: 'MM/DD/YYYY',
              required: true,
              labelClass: 'col-4 col-form-label',
              inputWrapperClass: 'col-8',
              minDate: thenFormatted,
              maxDate: nowFormatted,
            },
            validation: {
              messages: {
                minError: (error, field: FormlyFieldConfig) => 'Invalid Date of Birth',
                maxError: (error, field: FormlyFieldConfig) => 'Invalid Date of Birth',
              },
            },
            asyncValidators: {
              validDob: {
                expression: (control: UntypedFormControl) => {
                  return new Promise((resolve, reject) => {
                    if (this.labSubject.dateOfBirth !== control.value) {
                      this.formLoading = true;
                      this.labSubject.dateOfBirth = control.value;
                      this.labTestService
                        .validateDob(this.labSubject)
                        .pipe(
                          tap((result: ILabSubject) => {
                            this.setLabSubject(result);
                          }),
                          map((result: ILabSubject) => resolve(result.messages.length < 1)),
                          finalize(() => (this.formLoading = false))
                        )
                        .subscribe();
                    } else {
                      resolve(true);
                    }
                  });
                },
                message: 'Invalid Date of Birth',
              },
            },
          },
          {
            className: 'col-6 col-md-3',
            type: 'kTextBox',
            key: 'phoneNumber',
            templateOptions: {
              placeholder: 'Phone',
            },
          }
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12 col-md-6',
            type: 'iconLabel',
            templateOptions: {
              label: 'Lab Test Requisition Options:',
              onClick: ($event) => {
                this.infoLabelClicked.emit(this.requisitionDetails);
              },
              options: [
                {
                  labelClass: 'font-md font-weight-bold text-primary',
                  iconClass: 'lef-information-solid text-info font-root',
                  ariaHasPopup: 'dialog',
                  role: 'button',
                },
              ],
            },
          },
          {
            className: 'col-12 col-md-6',
            type: 'kDropdown',
            key: 'requisitionOption',
            templateOptions: {
              options: this.requisitionOptions$,
              valueProp: 'value',
              labelProp: 'description',
              required: true,
              valuePrimitive: true,
              async: true,
              placeholder: 'Select',
              placeholderValue: '',
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row align-items-baseline',
        fieldGroup: [
          {
            className: 'ml-3',
            template: `<div class="font-md font-weight-bold text-primary">Requisition<div>`,
          },
          {
            className: 'ml-3',
            type: 'checkbox',
            key: 'emailReq',
            templateOptions: {
              label: 'Email',
            },
            expressionProperties: {
              'templateOptions.disabled': 'model.requisitionOption !== "F" || model.isMinor',
            },
            hooks: {
              onInit: (field) => {
                return field.formControl.valueChanges.pipe(
                  tap((isEmail: boolean) => {
                    if (!isEmail && !this.labSubjectForm.value.faxReq) {
                      this.labSubjectForm.get('mailReq').setValue(true);
                    }
                  })
                );
              },
            },
          },
          {
            className: 'ml-3',
            type: 'checkbox',
            key: 'mailReq',
            templateOptions: {
              label: 'Mail',
            },
            expressionProperties: {
              'templateOptions.disabled': 'model.requisitionOption !== "F" || model.isMinor || (!model.emailReq && !model.faxReq)',
            },
          },
          {
            className: 'ml-3',
            type: 'checkbox',
            key: 'faxReq',
            templateOptions: {
              label: 'Fax',
            },
            expressionProperties: {
              'templateOptions.disabled': 'model.requisitionOption !== "F" || model.isMinor',
            },
            hooks: {
              onInit: (field) => {
                return field.formControl.valueChanges.pipe(
                  tap((isFax: boolean) => {
                    if (!isFax && !this.labSubjectForm.value.emailReq) {
                      this.labSubjectForm.get('mailReq').setValue(true);
                    }
                  })
                );
              },
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12 col-md-6',
            type: 'kTextBox',
            key: 'emailAddressReq',
            templateOptions: {
              placeholder: 'Email',
              type: 'email',
            },
            validators: {
              validation: ['email-address', Validators.email],
            },
            expressionProperties: {
              'templateOptions.required': 'model.emailReq',
              'templateOptions.disabled': '!model.emailReq || model.requisitionOption !== "F" || model.isMinor',
            },
          },
          {
            className: 'col-12 col-md-6',
            type: 'kTextBox',
            key: 'altEmailAddressReq',
            templateOptions: {
              placeholder: 'Alternate Email',
              type: 'email',
            },
            validators: {
              validation: ['email-address', Validators.email],
            },
            expressionProperties: {
              'templateOptions.disabled': '!model.emailReq || model.requisitionOption !== "F" || model.isMinor',
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12 col-md-6',
            type: 'kTextBox',
            key: 'faxNumberReq',
            templateOptions: {
              placeholder: 'Fax Number',
              type: 'tel',
            },
            expressionProperties: {
              'templateOptions.required': 'model.faxReq',
              'templateOptions.disabled': '!model.faxReq || model.requisitionOption !== "F" || model.isMinor',
            },
          },
          {
            className: 'col-12 col-md-6',
            type: 'kTextBox',
            key: 'altFaxNumberReq',
            templateOptions: {
              placeholder: 'Alternate Fax Number',
              type: 'tel',
            },
            expressionProperties: {
              'templateOptions.disabled': '!model.faxReq || model.requisitionOption !== "F" || model.isMinor',
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row align-items-baseline',
        fieldGroup: [
          {
            className: 'ml-3',
            template: `<div class="font-md font-weight-bold text-primary" id="labtestresultsWrap">Lab Test Results<div>`,
          },
          {
            className: 'ml-3',
            type: 'checkbox',
            key: 'emailResult',
            templateOptions: {
              label: 'Email',
              placeholder: 'Email*',
            },
            expressionProperties: {
              'templateOptions.disabled': '!model.onlineResult && !model.faxResult',
            },
          },
          {
            className: 'ml-3',
            type: 'checkbox',
            key: 'onlineResult',
            templateOptions: {
              label: 'Online Results',
            },
            hooks: {
              onInit: (field) => {
                return field.formControl.valueChanges.pipe(
                  tap((onlineResult: boolean) => {
                    if (!onlineResult && !this.labSubjectForm.value.faxResult) {
                      this.labSubjectForm.get('emailResult').setValue(true);
                    }
                  })
                );
              },
            },
          },
          {
            className: 'ml-3',
            type: 'checkbox',
            key: 'faxResult',
            templateOptions: {
              label: 'Fax',
            },
            hooks: {
              onInit: (field) => {
                return field.formControl.valueChanges.pipe(
                  tap((faxResult: boolean) => {
                    if (!this.labSubjectForm.value.onlineResult && !faxResult) {
                      this.labSubjectForm.get('emailResult').setValue(true);
                    }
                  })
                );
              },
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12 col-md-6',
            type: 'kTextBox',
            key: 'emailAddress',
            templateOptions: {
              placeholder: 'Email',
              type: 'email',
            },
            validators: {
              validation: ['email-address', Validators.email],
            },
            expressionProperties: {
              'templateOptions.required': 'model.emailResult',
              'templateOptions.disabled': '!model.emailResult',
            },
          },
          {
            className: 'col-12 col-md-6',
            type: 'kTextBox',
            key: 'emailAddressAlt',
            templateOptions: {
              placeholder: 'Alternate Email',
              type: 'email',
            },
            validators: {
              validation: ['email-address', Validators.email],
            },
            expressionProperties: {
              'templateOptions.disabled': '!model.emailResult',
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12 col-md-6',
            type: 'kTextBox',
            key: 'faxNumber',
            templateOptions: {
              placeholder: 'Fax Number',
              type: 'tel',
            },
            expressionProperties: {
              'templateOptions.required': 'model.faxResult',
              'templateOptions.disabled': '!model.faxResult',
            },
          },
          {
            className: 'col-12 col-md-6',
            type: 'kTextBox',
            key: 'faxNumberAlt',
            templateOptions: {
              placeholder: 'Alternate Fax Number',
              type: 'tel',
            },
            expressionProperties: {
              'templateOptions.disabled': '!model.faxResult',
            },
          },
        ],
      },
    ];
  }
}
