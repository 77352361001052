import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'lef-cart-item-messages',
  templateUrl: './cart-item-messages.component.html',
  styleUrls: ['./cart-item-messages.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CartItemMessagesComponent {
  @Input()
  messages: string[] = [];
}
