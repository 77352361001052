import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { PaymentOptionCollection, WalletPayment } from '../../model';
import { IApplePayPaymentProcessor } from '../../model/payment-apple-pay-processor';
import { ApplePayPaymentProcessorFactory } from '../../model/payment-apple-pay-processor-factory';
import { IPaymentGateway, PaymentGateway } from '../../model/payment-gateway';
import { IPaymentOption } from '../../model/payment-option';
import { ApplePayService } from '../../service/apple-pay.service';
import { PaymentOptionService } from '../../service/payment-option.service';

@Component({
  selector: 'lef-payment-apple-pay',
  templateUrl: './payment-apple-pay.component.html',
  styleUrls: ['./payment-apple-pay.component.scss'],
})
export class PaymentApplePayComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() gateway: IPaymentGateway = new PaymentGateway();
  @Input() isNetworkRequestInProgress = false;
  @Input() isCheckOut: boolean;
  @Input() isReturn: boolean;
  @Input() instantSave: boolean;
  @Input() usingCheckoutFlow: boolean;
  @Input() amount: number;
  @Input() customButtonId: string;
  @Input() layout: string;
  @Input() paymentOptionSelected: IPaymentOption;

  @Output() paymentOptionsChanged: EventEmitter<PaymentOptionCollection> = new EventEmitter();
  @Output() executingNetworkRequest: EventEmitter<boolean> = new EventEmitter();
  @Output() checkOutPaymentSelected: EventEmitter<WalletPayment> = new EventEmitter();

  @ViewChild('applePayBtn', { read: ElementRef, static: false }) private applePayBtn: ElementRef;

  paymentProcessor: IApplePayPaymentProcessor;
  savePayment = false;
  previousPayPalComponentArguments: string[];
  payPalAvailabilitySubscription: Subscription;
  errorMessage: string;

  constructor(private readonly paymentOptionService: PaymentOptionService, private readonly applePayService: ApplePayService) {}

  ngAfterViewInit() {
    this.refreshApplePayComponents();
  }

  ngOnInit(): void {
    this.initApplePayPaymentProcessor();
    this.payPalAvailabilitySubscription = this.applePayService.applePayAvailabilityChange.subscribe((available) => {
      this.refreshApplePayComponents();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!this.applePayBtn) {
      if (changes.gateway) {
        this.initApplePayPaymentProcessor();
      }
      if (changes.gateway || changes.amount) {
        this.refreshApplePayComponents();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.payPalAvailabilitySubscription) {
      this.payPalAvailabilitySubscription.unsubscribe();
      this.payPalAvailabilitySubscription = null;
    }
  }

  onExecutingNetworkRequest(inProgress: boolean) {
    this.executingNetworkRequest.emit(inProgress);
  }

  initApplePayPaymentProcessor() {
    if (this.paymentProcessor == null || this.paymentProcessor.gateway?.name !== this.gateway?.name) {
      this.paymentProcessor = new ApplePayPaymentProcessorFactory().GetProcessor(this.gateway);
      this.paymentProcessor.isProcessingPayment.subscribe({
        next: (result) => this.onExecutingNetworkRequest(result),
      });
      this.paymentProcessor.onPaymentDataReceived.subscribe({
        next: (result) => {
          result.savePayment = this.savePayment;
          this.validateApplePayPaymentMethod(result);
        },
      });
      this.paymentProcessor.onCheckOutPaymentSelected.subscribe({
        next: (payment) => {
          this.onExecutingNetworkRequest(true);
          if (!!this.paymentOptionSelected) {
            payment.id = this.paymentOptionSelected.id;
          }
          payment.isCheckOut = true;
          payment.instantSave = this.instantSave;
          payment.deviceData = this.applePayService?.deviceData;
          this.paymentOptionService.updateWalletPaymentOption(payment, this.isReturn, this.isCheckOut).subscribe(
            (result) => {
              this.onExecutingNetworkRequest(false);
              this.checkOutPaymentSelected.emit(payment);
            },
            (error) => {
              this.onExecutingNetworkRequest(false);
            }
          );
        },
      });
      this.paymentProcessor.onError.subscribe((message) => (this.errorMessage = message));
    }
  }

  refreshApplePayComponents() {
    if (!!this.paymentProcessor) {
      let amount = this.amount == 0 ? 1 : this.amount;
      if (this.applePayService.IsStatusOnline) {
        this.paymentProcessor.refresh(
          this.applePayService.applePayInstance,
          this.usingCheckoutFlow,
          amount,
          this.applePayBtn.nativeElement as HTMLDivElement
        );
      } else {
        //this.paymentProcessor.clearResources();
      }
    }
  }

  validateApplePayPaymentMethod(payment: WalletPayment) {
    this.onExecutingNetworkRequest(true);
    payment.isCheckOut = true;
    payment.instantSave = this.instantSave;
    this.paymentOptionService.addWalletPaymentOption(payment, this.isReturn).subscribe(
      (result) => {
        this.onExecutingNetworkRequest(false);
        this.paymentOptionsChanged.emit(result);
      },
      (error) => {
        this.onExecutingNetworkRequest(false);
      }
    );
  }

  authorizePayment(): void {}
}
