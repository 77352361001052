<div class="text-right">*Indicates required fields.</div>
<p>
  By Typing my full name in the space below, I represent that I am the Parent or Legal Guardian of the Laboratory Test
  Subject selected.
</p>
<p>
  I acknowledge and agree that this Parental Consent to Laboratory Testing is being provided to, and will be relied upon
  by, Forever Health National Diagnostics, Inc., the service provider receiving the order of the Laboratory Test, and
  its representatives, consultants (including Medivo Services, LLC), agents and their authorizing physicians and
  laboratories, to order, authorize and deliver the Laboratory Test for the Test Recipient, and, accordingly I represent
  and warrant that:
</p>
<ul class="standard-list">
  <li>The Test Subject is a minor and resides in the State named in the order;</li>
  <li>
    I am the parent or legal guardian of the Test Subject and I have the requisite authority to provide the consent
    herein;
  </li>
  <li>I agree to have the Laboratory Test performed for and on the Test Subject;</li>
  <li>I am delivering this consent voluntarily.</li>
</ul>
<form class="k-form" [formGroup]="consentForm" (ngSubmit)="consentFormSubmit()">
  <div class="d-flex justify-content-between consent-field">
    <kendo-textbox lefSetChildAttributes [childAttributes]="['input:class=p-0 w-100 border-none']" type="text"
                   class="form-control mr-3" placeholder="Parent / Legal Guardian Full Name*"
                   formControlName="guardian"></kendo-textbox>
    <button type="submit" class="btn btn-action" type="submit">Submit</button>
  </div>
</form>