import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { BaseService } from 'src/app/shared/service/base-service';
import { OffersNotification } from '../model/offers-notification.model';

@Injectable({
  providedIn: 'root',
})
export class OffersNotificationService extends BaseService<OffersNotification> {
  protected apiUrl: string;
  protected offersVerificationUrl: string;
  constructor(protected override readonly httpClient: HttpClient, private readonly config: CoreConfiguration) {
    super(httpClient);
    this.offersVerificationUrl = config.environment.myOffers.offersVerificationUrl;
  }

  get(customerNumber: string): Observable<OffersNotification> {
    return this.httpClient.get<OffersNotification>(`${this.offersVerificationUrl}?cid=${customerNumber}`);
  }
}
