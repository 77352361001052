import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { BaseService } from 'src/app/shared/service/base-service';
import { Acknowledgment } from '../model/acknowledgment.model';
import { BusinessInformation } from '../model/business-information.model';
import { CompanyRegistration } from '../model/company-registration.model';
import { ResellerApplication } from '../model/reseller-application';
import { ResellerBusinessConfiguration } from '../model/reseller-business-configuration';

@Injectable({
  providedIn: 'root',
})
export class ResellerApplicationService extends BaseService<CompanyRegistration> {
  protected apiUrl: string;
  customerRequestSaveSection: Subject<boolean> = new Subject<boolean>();

  constructor(protected override readonly httpClient: HttpClient, private readonly config: CoreConfiguration) {
    super(httpClient);
    this.apiUrl = config.environment.api + config.apiRoutes.resellerApplication;
  }

  getApplication(): Observable<ResellerApplication> {
    return this.httpGet(`${this.apiUrl}/GetApplication`);
  }

  submitApplication(application: ResellerApplication): Observable<ResellerApplication> {
    return this.httpPost(`${this.apiUrl}/SubmitApplication`, application);
  }

  getBusinessConfigurations(): Observable<ResellerBusinessConfiguration> {
    return this.httpGet(`${this.apiUrl}/BusinessConfigurations`);
  }

  getCurrentCompanyRegistration(applicationId: string): Observable<CompanyRegistration> {
    return this.httpGet(`${this.apiUrl}/getCompanyRegistration?applicationId=${applicationId}`);
  }

  saveCurrentCompanyRegistration(model: CompanyRegistration): Observable<CompanyRegistration> {
    return this.httpPost(`${this.apiUrl}/saveCompanyRegistration`, model);
  }

  getBusinessInformation(applicationId: string): Observable<BusinessInformation> {
    return this.httpGet(`${this.apiUrl}/getBusinessInformation?applicationId=${applicationId}`);
  }

  saveBusinessInformation(model: BusinessInformation): Observable<BusinessInformation> {
    return this.httpPost(`${this.apiUrl}/saveBusinessInformation`, model);
  }

  moveApplicationToArchive(applicationId: string): Observable<ResellerApplication> {
    return this.httpPost(`${this.apiUrl}/moveApplicationToArchive`, { id: applicationId });
  }

  getAcknowledgment(): Observable<any> {
    return this.httpGet(`${this.apiUrl}/getAcknowledgment`);
  }

  saveAcknowledgment(acknowledgment: Acknowledgment): Observable<any> {
    return this.httpPost(`${this.apiUrl}/saveAcknowledgment`, acknowledgment);
  }

  getBusinessSubTypeList(code: string): Observable<any> {
    return this.httpGet(`${this.apiUrl}/getBusinessSubTypeList?code=${code}`);
  }
}
