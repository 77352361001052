import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { BaseService } from 'src/app/shared/service/base-service';
import { LoginModel } from '../model/login-model.model';
import { RequestResetPasswordModel } from '../model/password-request-reset.model';
import { AccountVerificationModel } from '../model/verification-code.model';

@Injectable({
  providedIn: 'root',
})
export class LoginService extends BaseService<LoginModel> {
  protected apiUrl: string;

  constructor(protected override readonly httpClient: HttpClient, private readonly config: CoreConfiguration) {
    super(httpClient);

    this.apiUrl = config.environment.api + config.apiRoutes.login;
  }

  getCurrentUser(isCheckoutFlow: boolean): Observable<LoginModel> {
    return this.getSingle(`${this.apiUrl}/getCurrentUser?isCheckoutFlow=${isCheckoutFlow}`);
  }

  login(model: LoginModel): Observable<LoginModel> {
    return this.post(`${this.apiUrl}/login`, model);
  }

  logout(): Observable<LoginModel> {
    return this.getSingle(`${this.apiUrl}/logout`);
  }

  requestResetPassword(requestResetPasswordModel: RequestResetPasswordModel): Observable<RequestResetPasswordModel> {
    return this.httpClient.post<RequestResetPasswordModel>(`${this.apiUrl}/requestResetPassword`, requestResetPasswordModel);
  }

  resetPassword(model: LoginModel): Observable<LoginModel> {
    return this.post(`${this.apiUrl}/resetPassword`, model);
  }

  validateRecoveryId(model: LoginModel): Observable<LoginModel> {
    return this.post(`${this.apiUrl}/validatePasswordRecoveryId`, model);
  }

  validateEmail(model: LoginModel): Observable<LoginModel> {
    return this.post(`${this.apiUrl}/validateEmail`, model);
  }

  validateImpersonationSession(sessionId: string): Observable<LoginModel> {
    return this.getSingle(`${this.apiUrl}/validateImpersonationSession?sessionId=${sessionId}`);
  }

  impersonateCustomer(model: LoginModel): Observable<LoginModel> {
    return this.post(`${this.apiUrl}/impersonateCustomer`, model);
  }

  verifyAccount(model: AccountVerificationModel): Observable<AccountVerificationModel> {
    return this.httpClient.post<AccountVerificationModel>(`${this.apiUrl}/verifyAccount`, model);
  }

  requestVerificationCode(): Observable<LoginModel> {
    return this.getSingle(`${this.apiUrl}/requestVerificationCode`);
  }

  guestCheckOut(model: LoginModel): Observable<LoginModel> {
    return this.post(`${this.apiUrl}/guestCheckOut`, model);
  }

  guestCheckOutConfirmEmail(model: LoginModel): Observable<LoginModel> {
    return this.post(`${this.apiUrl}/guestCheckOutConfirmEmail`, model);
  }
}
