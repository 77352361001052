import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AuthorizationService } from 'src/app/authorization';
import { FloatingCartService } from 'src/app/common/service/floating-cart.service';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { CartNotificationService } from 'src/app/shared/service/cart-notification.service';
import { LoginModel } from '../../model/login-model.model';
import { LoginService } from '../../service/login.service';

@Component({
  selector: 'lef-user-impersonation',
  templateUrl: './user-impersonation.component.html',
  styleUrls: ['./user-impersonation.component.scss'],
})
export class UserImpersonationComponent implements OnInit {
  title = 'Customer Impersonation';
  impForm: UntypedFormGroup;
  impFormFields: FormlyFieldConfig[];
  showForm = false;
  sessionId: string;
  email: string;
  redirectRoute: string;

  constructor(
    private readonly loginService: LoginService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly notificationService: CartNotificationService,
    private readonly config: CoreConfiguration,
    private readonly authorizationService: AuthorizationService,
    private readonly floatingCartService: FloatingCartService
  ) { }

  ngOnInit() {
    this.initForm();
    this.getQueryStringParameters();

    if (this.sessionId) {
      this.validateImpersonationSessionId();
    } else {
      this.navigateTo(this.config.webRoutes.login);
    }
  }

  getQueryStringParameters() {
    this.sessionId = this.route.snapshot.queryParamMap.get('session');
    this.email = this.route.snapshot.queryParamMap.get('an8');

    const redirectRoute = this.route.snapshot.queryParamMap.get('redirectTo');
    this.redirectRoute = redirectRoute || this.config.webRoutes.orderByNumber;
  }

  initForm() {
    this.impForm = new UntypedFormGroup({});
    this.impFormFields = [
      {
        key: 'email',
        type: 'focusInput',
        templateOptions: {
          label: 'Email or Customer Number:',
          placeholder: ''
        },
        validators: {
          validation: [Validators.required],
        },
      },
    ];
  }

  validateImpersonationSessionId() {
    if (!this.sessionId) {
      this.navigateTo(this.config.webRoutes.login);
    }

    this.loginService.validateImpersonationSession(this.sessionId).subscribe(
      (response: LoginModel) => {
        if (response && response.success) {
          if (this.email) {
            this.impersonateCustomer(this.email);
          } else {
            this.showForm = true;
          }
        } else if (response && response.errorMessage) {
          this.notificationService.showError(response.errorMessage);
        } else {
          this.navigateTo(this.config.webRoutes.login);
        }
      },
      (error) => {
        this.navigateTo(this.config.webRoutes.login);
      }
    );
  }

  impersonateCustomer(email: string) {
    if (!email) {
      return;
    }

    const model: LoginModel = {
      sessionId: this.sessionId,
      email,
    };

    this.loginService.impersonateCustomer(model).subscribe(
      (response: LoginModel) => {
        if (response && response.success) {
          this.authorizationService.authorizationChange.next(null);
          this.floatingCartService.refreshItemsCount();
          this.navigateTo(this.redirectRoute);
        } else if (response && response.errorMessage) {
          this.notificationService.showError(response.errorMessage);
        } else {
          this.navigateTo(this.config.webRoutes.login);
        }
      },
      (error) => {
        this.navigateTo(this.config.webRoutes.login);
      }
    );
  }

  submitForm() {
    if (!this.impForm.valid) {
      return;
    }

    this.redirectRoute = this.config.webRoutes.root;
    const email = this.impForm.get('email').value;
    this.impersonateCustomer(email);
  }

  navigateTo(route: string) {
    if (!route) {
      return;
    }

    this.router.navigateByUrl(route);
  }
}
