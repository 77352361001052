<div class="auto-ship-address-selection" (keyup.enter)="verifyAddress()">
  <div class="autoship-modal-header">
    <lef-auto-ship-ribbon-info [shippingDay]='shippingDay' [showShippingDay]='true'>
    </lef-auto-ship-ribbon-info>
  </div>
  <div>
    <p>Our AutoShip &amp; Save service allows up to 1 shipment each month per shipping address.<br />Item can be
      reassigned to
      a different shipping address.</p>
  </div>
  <div *ngIf="shippingAddresses" class="selection-body">
    <div *ngFor="let shippingAddress of pagedShippingAddresses" class="border-bottom-dashed">
      <div class="selection-record">
        <div class="d-flex">
          <input kendoRadioButton name="address" class="k-radio form-check-input" type="radio" [value]="shippingAddress"
                 [(ngModel)]="selectedAddress" />
          <div>
            <div>{{shippingAddress.alias}}</div>
            <div>{{shippingAddress.address}} {{shippingAddress.city}}, {{shippingAddress.state}}
              {{shippingAddress.postalCode}}
            </div>
          </div>
        </div>
        <button class="btn btn-link" (click)="editAddress(shippingAddress)">
          {{ shippingAddress.isEditableOnWeb? "Edit": "View"}}
        </button>
      </div>

      <div *ngIf="showHasDeliveryItemsMessage && shippingAddress.hasDeliveryItems && shippingAddress == selectedAddress && selectedAddress !== originalAddress"
           class="address-error-message">
        <p class="text-red">
          You already have an active subscription for this address.
          Transferred items will ship on {{ getShippingDay(shippingAddress?.dayOfMonth) }}.
        </p>
      </div>

    </div>
  </div>
  <div class="px-1 mb-2">
    <div class="mb-2">
      <button class="btn btn-link" (click)="addAddress()">Add New Address</button>
    </div>
    <div *ngIf="this.shippingAddresses.length > paginationPageSize">
      <kendo-datapager [style.width.%]="100" [pageSize]="paginationPageSize" [skip]="paginationSkip"
                       [total]="paginationTotalPages" [pageSizeValues]="paginationShowPageSize"
                       [previousNext]="paginationShowPrevNext" [type]="paginationType" [info]="paginationShowInfo"
                       (pageChange)="onPageChange($event)">
      </kendo-datapager>
    </div>
  </div>
  <div *ngIf="autoShipItemId" class="auto-ship-address-selection-ftr">
    <div>
      <label>
        <input kendoradiobutton name="targetItems" class="k-radio form-check-input" type="radio" [value]="false"
               [(ngModel)]="updateAllDeliveryItems" />
        Apply to this Item only
      </label>
    </div>
    <div>
      <label>
        <input kendoradiobutton name="targetItems" class="k-radio form-check-input" type="radio" [value]="true"
               [(ngModel)]="updateAllDeliveryItems" />
        Apply to all items in this AutoShip &amp; Save
      </label>
    </div>
  </div>
  <div class="text-right">
    <button class="btn btn-action" (click)="verifyAddress()">
      Use This Address
    </button>
  </div>
</div>