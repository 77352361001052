import { Injectable } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { DistributorCountryComponent } from '../component/distributor-country/distributor-country.component';
import { DistributorLocationNoticeComponent } from '../component/distributor-location-notice/distributor-location-notice.component';
import { RegionVerification } from '../model/region-verification';

@Injectable({
  providedIn: 'root',
})
export class CommonDialogService {
  constructor(private readonly dialogService: DialogService) {}

  openDistributorRegionVerificationDialog(distVerification: RegionVerification): DialogRef {
    const dialogRef: DialogRef = this.dialogService.open({
      title: '',
      content: DistributorLocationNoticeComponent,
      height: 430,
      width: 500,
      maxHeight: '90%',
      maxWidth: '90%',
    });

    const componentRef: DistributorLocationNoticeComponent = dialogRef.content.instance;
    componentRef.verification = distVerification;
    componentRef.closeDialog.subscribe(() => {
      dialogRef.close();
    });

    return dialogRef;
  }

  openDistributorCountryVerificationDialog(distVerification: RegionVerification): DialogRef {
    const dialogRef: DialogRef = this.dialogService.open({
      title: 'Invalid Country',
      content: DistributorCountryComponent,
      minHeight: 200,
      width: 500,
      maxHeight: '90%',
      maxWidth: '90%',
    });

    const componentRef: DistributorCountryComponent = dialogRef.content.instance;
    componentRef.verification = distVerification;
    componentRef.closeDialog.subscribe(() => {
      dialogRef.close();
    });

    return dialogRef;
  }
}
