import { Type } from '@angular/core';

const boolType = typeof true;
const numberType = typeof 0;
const stringType = typeof '';
const typeType = 'function';

export class ValueTypeHelper {
  static isBoolean(value: any): value is boolean {
    return typeof value === boolType;
  }

  static isEmptyValue(value: any): boolean {
    return value == null || value.length === 0;
  }

  static isNumber(value: any): value is number {
    return typeof value === numberType;
  }

  static isString(value: any): value is string {
    return typeof value === stringType;
  }

  static isType(value: any): value is Type<any> {
    return typeof value === typeType;
  }

  static isObject(value: any) {
    return value != null && typeof value === 'object';
  }

  static defaultBoolean(value: any, defaultValue: boolean): boolean {
    return this.isBoolean(value) ? value : defaultValue;
  }

  static defaultNumber(value: any, defaultValue: number): number {
    return this.isNumber(value) ? value : defaultValue;
  }

  static defaultString(value: any, defaultValue: string): string {
    return this.isString(value) ? value : defaultValue;
  }
}
