import { Component, OnInit, Renderer2 } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ICart } from 'src/app/cart-common/model/cart';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { Constants } from '../../model/constants';
import { TrackingService } from '../../service/tracking.service';

declare global {
  interface Window {
    cnxtag: any;
  }
}

@Component({
  selector: 'lef-connexity-conversion',
  templateUrl: './connexity-conversion.component.html',
  styleUrls: ['./connexity-conversion.component.scss'],
})
export class ConnexityConversionComponent implements OnInit {
  private configuration: any;
  constructor(
    private readonly renderer: Renderer2,
    private readonly trackingService: TrackingService,
    private readonly cookieService: CookieService,
    private readonly config: CoreConfiguration
  ) {
    this.configuration = config.environment.connexityConversionTracking;
  }

  ngOnInit(): void {
    if (this.configuration.enabled && this.trackingService.checkOneTrustActiveGroup(this.config.oneTrustGroups.targeting)) {
      this.trackingService.orderPlaced.subscribe({ next: (cart) => this.onOrderPlaced(cart) });
    }
  }

  onOrderPlaced(cart: ICart) {
    if (!this.cookieService.check(this.configuration.cookieName)) {
      return;
    }
    this.triggerBixRatePixel(cart);
    this.triggerCnxTagEvent(cart);
  }

  triggerCnxTagEvent(cart: ICart) {
    if (!!window.cnxtag && typeof window.cnxtag === 'function') {
      const customer = this.trackingService.getCurrentCustomerData();
      const unitsOrdered = cart.items
        .filter((i) => i.itemNumber !== 'FREIGHT')
        .map((i) => i.quantity)
        .reduce((a, b) => a + b);
      window.cnxtag('event', 'conversion', {
        mid: this.configuration.memberId,
        cust_type: customer.customerRecurrence === 'New' ? '1' : '0',
        order_value: cart.totalOrder,
        currency: 'USD',
        order_id: cart.orderNumber,
        units_ordered: unitsOrdered,
      });
    }
  }

  triggerBixRatePixel(cart: ICart) {
    const unitsOrdered = cart.items
      .filter((i) => i.itemNumber !== 'FREIGHT')
      .map((i) => i.quantity)
      .reduce((a, b) => a + b);
    const baseUrl = 'https://www.bizrate.com/roi/index__';
    const params =
      'cust_type--' +
      encodeURIComponent(this.trackingService.storage.get(Constants.CustomerType)) +
      ',mid--' +
      encodeURIComponent(this.configuration.memberId) +
      ',order_id--' +
      encodeURIComponent(cart.orderNumber) +
      ',order_value--' +
      encodeURIComponent(cart.totalOrder) +
      ',units_ordered--' +
      encodeURIComponent(unitsOrdered) +
      ',tmpl_id--1.html';
    this.addPixelImage(baseUrl + params);
  }

  addPixelImage(imageUrl: string) {
    new Promise((resolve, reject) => {
      const imgPixel = document.createElement('img');
      imgPixel.id = 'standard-roi-tracking';
      imgPixel.src = imageUrl;
      imgPixel.width = 1;
      imgPixel.height = 1;
      imgPixel.className = `d-none lef-img-pixel`;
      imgPixel.onload = () => resolve(imgPixel);
      imgPixel.onerror = reject;
      this.renderer.appendChild(document.body, imgPixel);
    }).then(
      (image: HTMLImageElement | any) => {
        document.body.removeChild(image);
      },
      (error) => {
        document.body.removeChild(error.target);
      }
    );
  }
}
