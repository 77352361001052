import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintService } from 'src/app/common/service';
import { IShippingLabel } from '../../model';
import { ReturnsService } from '../../service/returns.service';

@Component({
  selector: 'lef-shipping-label',
  templateUrl: './shipping-label.component.html',
  styleUrls: ['./shipping-label.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ShippingLabelComponent implements OnInit, OnDestroy {
  @Input() trackingNumber: string;
  @Input() orderNumber: string = '';
  @Input() autoPrint = false;

  @ViewChild('printable', { read: ElementRef }) printable: ElementRef;

  shippingLabel: IShippingLabel;

  get hasLabel(): boolean {
    return this.shippingLabel && this.shippingLabel.imageExists;
  }

  constructor(
    private readonly renderer: Renderer2,
    private readonly router: ActivatedRoute,
    private readonly returnsService: ReturnsService,
    private readonly printService: PrintService
  ) { }

  ngOnInit(): void {
    if (this.trackingNumber) {
      this.loadShippingLabel();
    } else {
      this.router.queryParams.subscribe((params) => {
        const footer = document.getElementsByTagName('footer')[0];
        this.renderer.addClass(footer, 'd-print-none');
        if (params.trackingnumber) {
          this.trackingNumber = params.trackingnumber;
        }
        if (params.ordernumber && !isNaN(params.ordernumber)) {
          this.orderNumber = params.ordernumber;
        }
        this.loadShippingLabel();
      });
    }
  }

  ngOnDestroy(): void {
    const footer = document.getElementsByTagName('footer')[0];
    this.renderer.removeClass(footer, 'd-print-none');
  }

  private loadShippingLabel() {
    if (!this.trackingNumber) {
      return;
    }

    this.returnsService.getPrepaidShippingLabel(this.trackingNumber, this.orderNumber).subscribe((shippingLabel: IShippingLabel) => {
      this.shippingLabel = shippingLabel;
      if (this.autoPrint) {
        setTimeout(() => {
          this.printLabel();
        });
      }
    });
  }

  printLabel() {
    if (this.hasLabel) {
      this.printService.printElement(this.printable.nativeElement as HTMLElement);
    }
  }

  simplePrint() {
    window.print();
  }
}
