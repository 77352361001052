import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { AuthorizationService } from 'src/app/authorization';
import { ICartItem } from 'src/app/cart-common/model/cart-item';
import { CartService } from 'src/app/cart/service/cart.service';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { IOrderItem } from 'src/app/order-by-item/model/order-item';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { OrderItem } from '../../model/order-item.model';

@Component({
  selector: 'lef-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderItemComponent implements OnInit {
  @Input() item: OrderItem = {};
  @Input() embeddedView: boolean;
  @Output() goToOrderDetails: EventEmitter<string> = new EventEmitter();

  private alertDialogRef: DialogRef;
  defaultImage = 'assets/images/ph-sm.png';

  constructor(
    private readonly config: CoreConfiguration,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly cartService: CartService,
    private readonly authorizationService: AuthorizationService
  ) { }

  get itemImageUrl(): string {
    return this.item.itemNumber ? this.config.environment.itemUrlTemplates.itemImageUrl.replace('{itemNumber}', this.item.itemNumber) : '';
  }

  get writeReviewUrl(): string {
    return this.config.environment.writeReviewUrl;
  }

  get isWholesaler(): boolean {
    return this.authorizationService?.userCustomerType()?.toLowerCase() === 'w'
  }

  ngOnInit(): void { }

  addItem() {
    const items: IOrderItem[] = [];
    items.push({ itemNumber: this.item.itemNumber, quantity: 1 });
    this.addToCart(items);
  }

  goToAutoShipPage() {
    this.router.navigateByUrl(this.config.webRoutes.accountAutoShip);
  }

  addToSubscription() {
    this.loadingService.show();
    const item: ICartItem = {
      isAutoShip: true,
      autoShipFrequency: 1,
      itemNumber: this.item.itemNumber,
      quantity: 1,
    };
    this.cartService.addItemWithConfirmationDialog(item, this.onItemsAdded.bind(this));
  }

  addToCart(items: IOrderItem[]) {
    this.loadingService.show();
    this.cartService.addItemsWithConfirmationDialog(items, this.onItemsAdded.bind(this));
  }

  onItemsAdded(success: boolean, targetItems: ICartItem[]) {
    const targetItem = targetItems.find(item => item.itemNumber === this.item.itemNumber);
    if (targetItem?.itemIsInCart) {
      this.item.isInCart = true;
    }
  }

  goToDetails() {
    this.goToOrderDetails.emit(this.item.orderNumber);
  }
}
