import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CartCookieService } from 'src/app/shared/service/cart-cookie-service.service';
import { AuthorizationConfig, AuthorizationConfigModel } from '../model';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  readonly configuration: AuthorizationConfig;

  private readonly authorizationUrl: string;

  public readonly authorizationChange: Subject<any> = new Subject();

  constructor(authConfig: AuthorizationConfigModel, private httpClient: HttpClient, private cookieService: CartCookieService) {
    this.configuration = authConfig.getConfig();
    this.authorizationUrl = this.configuration.serverUrl + this.configuration.authorizationPath;
  }

  public isLoggedIn(): boolean {
    const cookieObject = this.cookieService.getCookieObject();
    return cookieObject[this.configuration.cookieLoginStatus] === 'F';
  }

  public isSoftLoggedIn(): boolean {
    const cookieObject = this.cookieService.getCookieObject();
    return cookieObject[this.configuration.cookieLoginStatus] === 'S';
  }

  public userFullName(): string {
    const cookieObject = this.cookieService.getCookieObject();
    const fullName: string = cookieObject[this.configuration.cookieNameToken];
    return (!!fullName ? fullName.split('+').join(' ').split('*').join('&') : '').trim();
  }

  public userCustomerNumber(): string {
    const cookieObject = this.cookieService.getCookieObject();
    return cookieObject[this.configuration.cookieCustomerNumber];
  }

  public userCustomerType(): string {
    const cookieObject = this.cookieService.getCookieObject();
    return cookieObject[this.configuration.cookieCustomerType];
  }

  public isWholesaler(): boolean {
    const cookieObject = this.cookieService.getCookieObject();
    const customerType: string = cookieObject[this.configuration.cookieCustomerType];

    return customerType && customerType.toLowerCase().trim() === 'w';
  }

  public isAnonymousCustomer(): boolean {
    const cookieObject = this.cookieService.getCookieObject();
    const customerType: string = cookieObject[this.configuration.cookieCustomerType];

    return customerType && customerType.trim() === 'AN';
  }

  public isEmployee(): boolean {
    const cookieObject = this.cookieService.getCookieObject();
    const customerType: string = cookieObject[this.configuration.cookieCustomerType];

    return customerType && customerType.trim() === 'E';
  }
}
