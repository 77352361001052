<div class="autoShip-delivery">
  <div (click)="toggleVisibility()"
       [ngClass]="{'autoShip-delivery-header':isActiveView, 'autoShip-delivery-header-inactive': !isActiveView}">
    <div class="row">
      <div class="col-6">
        <lef-auto-ship-ribbon-info [alias]="delivery.alias" [showAlias]="true" [shippingDay]="shippingDay"
                                   [showShippingDay]="isBodyHidden" [shippingAddress]='shippingAddress'
                                   [showShippingAddress]="isBodyHidden">
        </lef-auto-ship-ribbon-info>
      </div>
      <div class="col-6 text-right" *ngIf="!delivery.isSubscription">
        {{ itemsCount }} Item(s)
        <span>
          <i *ngIf="!isBodyHidden" class="lef-chevron-up text-info"></i>
          <i *ngIf="isBodyHidden" class="lef-chevron-down text-info"></i>
        </span>
      </div>
    </div>
  </div>
  <div class="autoShip-delivery-body" [style.display]="isBodyHidden ? 'none' : 'block'">
    <div *ngIf="!delivery.isSubscription" class="p-3 mb-3"
         [ngClass]="{'bg-light-blue':isActiveView, 'bg-light-grey': !isActiveView}">
      <div class="row">
        <div class="col-md-8 border-md-right">
          <div class="auto-ship-saved-info-wrap">
            <div class="auto-ship-saved-info">
              <ul>
                <li class="text-primary font-md"><strong>Shipping Date:</strong></li>
                <li>{{ shippingDay }}</li>
              </ul>
              <div *ngIf="isActiveView"><button class="btn btn-link" aria-haspopup="dialog"
                        (click)="changeShippingDate()">Change</button>
              </div>
            </div>
            <div class="auto-ship-saved-info">
              <ul>
                <li class="text-primary font-md"><strong>Shipping Address:</strong></li>
                <li>{{ shippingAddress }}</li>
              </ul>
              <div *ngIf="isActiveView"><button class="btn btn-link" aria-haspopup="dialog"
                        (click)="changeShippingAddress()">Change</button>
              </div>
            </div>
            <div class="auto-ship-saved-info" *ngIf="isActiveView">
              <ul>
                <li class="text-primary font-md"><strong>Shipping Method:</strong></li>
                <li>
                  <p class="m-0">{{shippingMethod?.description ? shippingMethod?.description : ''}}</p>
                  <p>
                    <span *ngIf="shippingMethod?.rate == 0">FREE</span>
                  </p>
                </li>
              </ul>
              <div>
                <button class="btn btn-link" aria-haspopup="dialog" (click)="changeShippingMethod()">Change</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 auto-ship-delivery-actions" *ngIf="isActiveView">
          <div><button class="btn btn-action" (click)="addItems()">+ Add Item(s)</button></div>
          <div><button class="btn btn-link p-0" id="changePaymentAllBtn" aria-controls="changePaymentAllPanel"
                    (click)="changePaymentMethod()">
              Change Payment Method for All Items
            </button></div>
        </div>
      </div>
    </div>
    <div *ngIf="!delivery.isSubscription" id="changePaymentAllPanel" aria-labelledby="changePaymentAllBtn"
         [attr.aria-expanded]="showPaymentOptions" [ngClass]="{'auto-ship-payment-wrap' : showPaymentOptions }">
      <lef-payment-method-selector [ngClass]="{'d-none' : !showPaymentOptions }" [hidePaymentMethodActions]="true"
                                   [labelClass]="'pr-3'" (paymentOptionSelected)="onPaymentSelected($event)">
      </lef-payment-method-selector>
    </div>
    <div>
      <lef-cart-messages [messages]="delivery.messages"></lef-cart-messages>
    </div>
    <div *ngFor="let item of delivery.items" class="auto-ship-item-wrap">
      <lef-auto-ship-subscription-item *ngIf="delivery.isSubscription else autoshipItemComponent" [item]="item"
                                       [delivery]="delivery" [shippingDay]="shippingDay"
                                       (updateAutoShip)="doUpdateAutoShip()">
      </lef-auto-ship-subscription-item>
      <ng-template #autoshipItemComponent>
        <lef-auto-ship-item [item]="item" [delivery]="delivery" [shippingDay]="shippingDay"
                            (updateAutoShip)="doUpdateAutoShip()">
        </lef-auto-ship-item>
      </ng-template>
    </div>
  </div>
</div>