import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppValidators } from 'src/app/shared/form/form-validations';
import { Requisition } from '../../model/requisition.model';

@Component({
  selector: 'lef-minor-consent-requisition',
  templateUrl: './minor-consent-requisition.component.html',
  styleUrls: ['./minor-consent-requisition.component.scss'],
})
export class MinorConsentRequisitionComponent implements OnInit {
  @Input() requisition: Requisition;
  @Output() consentByChanged: EventEmitter<Requisition> = new EventEmitter<Requisition>();

  consentForm: UntypedFormGroup;

  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.consentForm = this.formBuilder.group({
      guardian: [this.requisition.consentBy, [Validators.required, AppValidators.notNullOrWhitespace]],
    });
  }

  onConsentByChange(consentBy) {
    this.requisition.consentBy = consentBy;
    this.consentByChanged.emit(this.requisition);
  }
}
