import { Component, OnInit } from '@angular/core';
import { CoreConfiguration } from 'src/app/core/core-configuration';

@Component({
  selector: 'lef-application-version-info',
  templateUrl: './application-version-info.component.html',
  styleUrls: ['./application-version-info.component.scss'],
})
export class ApplicationVersionInfoComponent implements OnInit {
  showApplicationVersion = false;

  constructor(private readonly config: CoreConfiguration) {}

  get appVersion(): string {
    return this.config.runtimeAppVersion;
  }

  ngOnInit(): void {
    this.showApplicationVersion = this.config.environment.showApplicationVersion;
  }
}
