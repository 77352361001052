import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { CommonDialogService } from 'src/app/common/service/common-dialog.service';
import { ShippingDialogService } from 'src/app/shipping/service/shipping-dialog.service';
import { IAutoShipAddress } from '../../model/auto-ship-address';
import { IAutoShipChange } from '../../model/auto-ship-change';
import { AutoShipValuesService } from '../../service';
import { AutoShipService } from '../../service/auto-ship.service';

@Component({
  selector: 'lef-auto-ship-address-selection',
  templateUrl: './auto-ship-address-selection.component.html',
  styleUrls: ['./auto-ship-address-selection.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AutoShipAddressSelectionComponent implements OnInit {

  @Input()
  autoShipNumber = 0;

  @Input()
  autoShipItemId: string = null;

  @Input()
  shippingDay: string;

  @Input()
  shippingAddresses: IAutoShipAddress[] = [];

  @Output()
  addressChanged: EventEmitter<any> = new EventEmitter();

  @Output()
  isUndeliverableAddress: EventEmitter<boolean> = new EventEmitter();

  selectedAddress: IAutoShipAddress;
  originalAddress: IAutoShipAddress = {};

  showHasDeliveryItemsMessage = false;
  updateAllDeliveryItems = false;

  paginationPageSize = 5;
  paginationSkip = 0;
  paginationShowPageSize = false;
  paginationShowInfo = false;
  paginationShowPrevNext = true;
  paginationType = 'numeric';
  paginationTotalPages: number;
  pagedShippingAddresses: IAutoShipAddress[];

  constructor(
    private readonly valuesService: AutoShipValuesService,
    private readonly autoShipService: AutoShipService,
    private readonly dialogService: ShippingDialogService,
    private readonly commonDialogService: CommonDialogService
  ) { }

  ngOnInit() {
    this.initShippingAddresses();
  }

  selectAddress(): void {
    for (const shippingAddress of this.shippingAddresses) {
      shippingAddress.isSelected = false;
    }

    this.selectedAddress.isSelected = true;

    if (!this.showHasDeliveryItemsMessage) {
      this.showHasDeliveryItemsMessage = true;
    }
  }

  editAddress(shippingAddress: IAutoShipAddress) {
    this.openShippingAddressDialog(shippingAddress.shipToNumber);
  }

  addAddress(): void {
    this.openShippingAddressDialog(0);
  }

  verifyAddress(): void {
    if (this.selectedAddress.isServiceByDistributor) {
      this.showDistributorDialog(this.selectedAddress);
      return;
    }

    if (this.selectedAddress?.isUndeliverableAddress) {
      this.isUndeliverableAddress.emit(true);
      return;
    }

    this.changeAddress();
  } 

  onPageChange(e: PageChangeEvent): void {
    this.paginationSkip = e.skip;
    this.paginationPageSize = e.take;
    this.pageShippingAddresses();
  }

  getShippingDay(dayOfMonth: number): string {
    return dayOfMonth ? this.valuesService.getShippingDayOfMonth(dayOfMonth, true) : '';
  }

  private openShippingAddressDialog = (shipToNumber: number) => {
    this.dialogService.openShippingAddressDialog(
      shipToNumber,
      this.onAddressUpdated
    );
  }

  private onAddressUpdated = (shippingAddress: IAutoShipAddress) => {
    const address = this.shippingAddresses.find(a => a.shipToNumber === shippingAddress.shipToNumber);
    if (address) {
      Object.assign(address, shippingAddress);
    } else {
      this.shippingAddresses.push(shippingAddress);
      this.selectedAddress = shippingAddress;

      this.selectAddress();
      this.refreshShippingAddresses(shippingAddress);
    }
  }

  private changeAddress() {
    if(this.selectedAddress === this.originalAddress){
      this.addressChanged.emit();
      return;
    }

    const addressChange: IAutoShipChange = {
      autoShipNumber: this.autoShipNumber,
      autoShipItemId: this.updateAllDeliveryItems ? null : this.autoShipItemId,
      shipToNumber: this.selectedAddress.shipToNumber,
    };

    this.autoShipService.changeAddress(addressChange).subscribe(
      (autoShip) => {
        this.autoShipService.autoShipChange.next(autoShip);
        this.addressChanged.emit();
      }
    );
  }

  private initShippingAddresses() {
    const originalAddress = this.shippingAddresses.find(a => a.isSelected);
    if (originalAddress) {
      this.originalAddress = originalAddress;
    }

    this.refreshShippingAddresses(originalAddress);
  }

  private refreshShippingAddresses(selectedAddress: IAutoShipAddress): void {
    this.selectedAddress = selectedAddress;
    if (!this.selectedAddress) {
      this.selectedAddress = this.shippingAddresses[0];
    }

    this.paginationTotalPages = this.shippingAddresses.length;
    this.pageShippingAddresses();
  }

  private pageShippingAddresses(): void {
    this.pagedShippingAddresses = this.shippingAddresses
      .slice(this.paginationSkip, this.paginationSkip + this.paginationPageSize);
  }

  private showDistributorDialog(shippingAddress: IAutoShipAddress) {
    this.commonDialogService.openDistributorCountryVerificationDialog({
      showDialog: shippingAddress.isServiceByDistributor,
      message: shippingAddress.distributorRegionMessage,
      distributor: shippingAddress.distributorName,
      distributorSiteUrl: shippingAddress.distributorSiteUrl,
      region: shippingAddress.distributorRegion,
    });
  }

}
