import { HttpClient } from '@angular/common/http';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { CommonService } from 'src/app/shared/service/common.service';
import { ErrorsHandler } from '../handler/errors.handler';
import { ErrorConfigModel } from '../model';

export function errorsHandlerFactory(
  http: HttpClient,
  errorConfig: ErrorConfigModel,
  config: CoreConfiguration,
  commonService: CommonService) {
  return new ErrorsHandler(errorConfig, http, config, commonService);
}
