import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ICartMessage } from 'src/app/cart-common/model';
import { CreditCardValidationResult } from '../../../payment/model/payment-credit-card';
import { EmptyCreditCardProcessor, ICreditCardProcessor } from '../../model/payment-credit-card-processor';
import { CreditCardProcessorFactory } from '../../model/payment-credit-card-processor-factory';
import { IPaymentGateway, PaymentGateway } from '../../model/payment-gateway';
import { PaymentOptionCollection } from '../../model/payment-option-collection';
import { PaymentOptionService } from '../../service/payment-option.service';

@Component({
  selector: 'lef-payment-credit-card',
  templateUrl: './payment-credit-card.component.html',
  styleUrls: ['./payment-credit-card.component.scss'],
})
export class PaymentCreditCardComponent implements OnInit, OnChanges {
  @Input() gateway: IPaymentGateway = new PaymentGateway();
  @Input() isNetworkRequestInProgress = true;
  @Input() isCheckOut: boolean;
  @Input() isReturn: boolean;
  @Input() instantSave: boolean;
  @Input() paymentOptionId: string;
  @Input() notEmbeddedComponent: boolean;

  @Output() paymentOptionsChanged: EventEmitter<PaymentOptionCollection> = new EventEmitter();
  @Output() executingNetworkRequest: EventEmitter<boolean> = new EventEmitter();

  creditCardMessages: ICartMessage[];
  creditCardProcessor: ICreditCardProcessor = new EmptyCreditCardProcessor();
  billingType = 'local';
  postalCode = '';
  
  get useInstantVerification(): boolean {
    return !this.isCheckOut || this.gateway.usingSecondaryGateway;
  }

  constructor(private readonly paymentOptionService: PaymentOptionService) {}

  ngOnInit(): void {
    if (this.notEmbeddedComponent) {
      this.initCreditCardProcessor();
    }

    // External Verification Subscription
    if(!this.useInstantVerification) {
      this.paymentOptionService.requestCreditCardVerification.subscribe(
        () => { 
          this.verifyCreditCard(); 
        }
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.gateway) {
      if (!changes.gateway.previousValue || changes.gateway.currentValue.name !== changes.gateway.previousValue.name) {
        this.initCreditCardProcessor();
      }
    }
  }

  onExecutingNetworkRequest(inProgress: boolean) {
    this.executingNetworkRequest.emit(inProgress);
  }

  initCreditCardProcessor() {
    if (this.gateway.name !== '') {
      this.onExecutingNetworkRequest(true);
      this.creditCardProcessor = new CreditCardProcessorFactory().GetGateway(this.gateway);
      this.creditCardProcessor.onCreditCardValidation.subscribe({
        next: (result) => this.onCreditCardValidation(result),
      });
      this.creditCardProcessor.onInitialized.subscribe({
        next: (initialized) => {
          this.onExecutingNetworkRequest(!initialized);
        },
      });
      this.creditCardProcessor.initCreditCardFormFields();
    }
  }

  verifyCreditCard() {
    if (!this.isNetworkRequestInProgress) {
      this.onExecutingNetworkRequest(true);
      this.creditCardMessages = [];
      this.creditCardProcessor.validateCreditCard(this.postalCode, this.billingType, this.gateway.usePostalCodeInCreditCardValidation);
    }
  }

  onCreditCardValidation(verification: CreditCardValidationResult) {
    if (verification.isSuccess) {
      this.creditCardVerification(verification);
    } else {
      if (verification.response !== '') {
        this.creditCardMessages = [{ message: verification.response, type: 'Error' } as ICartMessage];
      }
      this.onExecutingNetworkRequest(false);
    }
  }

  creditCardVerification(verification: CreditCardValidationResult) {
    this.onExecutingNetworkRequest(true);
    verification.creditCard.isCheckOut = this.isCheckOut;
    verification.creditCard.instantSave = this.instantSave;
    if (!!this.paymentOptionId) {
      verification.creditCard.paymentOptionId = this.paymentOptionId;
    }

    // External Verification
    if(!this.useInstantVerification) {
      this.paymentOptionService.onCreditCardVerification.next(verification.creditCard)
      this.onExecutingNetworkRequest(false);
      return;
    }

    // Instant Verification
    this.paymentOptionService.addCreditCardPaymentOption(verification.creditCard, this.isReturn).subscribe(
      (result) => {
        this.creditCardMessages = result.messages;
        if (result.messages.length === 0) {
          this.paymentOptionsChanged.emit(result);
        }
        this.onExecutingNetworkRequest(false);
      },
      (error) => {
        this.onExecutingNetworkRequest(false);
      }
    );
  }
}
