import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Base class is not injectable since we need to extend it for use.
export abstract class BaseService<T> {
  protected getHeaders = new HttpHeaders({
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
  });

  protected getPdfHeaders = new HttpHeaders({
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Accept: 'application/pdf',
  });

  protected abstract apiUrl: string;

  constructor(protected readonly httpClient: HttpClient) {}

  protected httpGet<TT>(route: string): Observable<TT> {
    return (this.httpClient.get<TT>(route, { headers: this.getHeaders }) as Observable<TT>).pipe(catchError(this.handleError));
  }

  protected httpPost<TT>(route: string, obj: any) {
    return this.httpClient.post<TT>(route, obj).pipe(catchError(this.handleError));
  }

  protected getPdf(route: string): any {
    return this.httpClient.get(route, { headers: this.getPdfHeaders, responseType: 'blob' as 'json' }).pipe(catchError(this.handleError));
  }

  protected getMany(route: string): Observable<T[]> {
    return (this.httpClient.get<T[]>(route, { headers: this.getHeaders }) as Observable<T[]>).pipe(catchError(this.handleError));
  }

  protected getManyCached(route: string, maxAge: number, expires: Date): Observable<T[]> {
    const cacheHeaders = this.GetCacheHeaders(maxAge, expires);
    return (this.httpClient.get<T[]>(route, { headers: cacheHeaders }) as Observable<T[]>).pipe(catchError(this.handleError));
  }

  protected getManyWithParams(route: string, params: any): Observable<T[]> {
    return (this.httpClient.get<T[]>(route, { headers: this.getHeaders, params }) as Observable<T[]>).pipe(catchError(this.handleError));
  }

  protected getSingle(route: string): Observable<T> {
    return (this.httpClient.get<T>(route, { headers: this.getHeaders }) as Observable<T>).pipe(catchError(this.handleError));
  }

  protected getSingleWithParams(route: string, params: any): Observable<T> {
    return (this.httpClient.get<T>(route, { headers: this.getHeaders, params }) as Observable<T>).pipe(catchError(this.handleError));
  }

  protected add(route: string, obj: T) {
    return this.httpClient.post<T>(route, obj).pipe(catchError(this.handleError));
  }

  protected post(route: string, obj: any) {
    return this.httpClient.post<T>(route, obj).pipe(catchError(this.handleError));
  }

  protected update(route: string, obj: any) {
    return this.httpClient.put<T>(route, obj).pipe(catchError(this.handleError));
  }

  protected delete(route: string) {
    return this.httpClient.delete(route).pipe(catchError(this.handleError));
  }

  protected handleError(error: any) {
    const errStatus: string = error.status;
    const errMsg: string = error._body
      ? error._body
      : error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    const err: any = {
      message: errMsg,
      status: errStatus,
      content: error.error
    };

    return throwError(err);
  }

  private GetCacheHeaders(maxAge: number, expires: Date) {
    // 1 week: 604800
    return new HttpHeaders({
      'Cache-Control': `max-age=${maxAge}`,
      Expires: expires.toUTCString(),
    });
  }
}
