import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { BaseService } from 'src/app/shared/service/base-service';
import { IRegistration } from '../model';

@Injectable({
  providedIn: 'root'
})

export class RegistrationService extends BaseService<IRegistration> {
  protected apiUrl: string;

  constructor(protected override readonly httpClient: HttpClient, private readonly config: CoreConfiguration) {
    super(httpClient);

    this.apiUrl = config.environment.api + config.apiRoutes.registration;
  }

  getRegistrationInfo(model: IRegistration): Observable<IRegistration> {
    return this.post(`${this.apiUrl}/GetRegistrationInfo`, model);
  }

  save(model: IRegistration): Observable<IRegistration> {
    return this.post(`${this.apiUrl}/Save`, model);
  }

  verifyEmail(model: IRegistration): Observable<IRegistration> {
    return this.post(`${this.apiUrl}/VerifyEmail`, model);
  }

  validateAddress(model: IRegistration): Observable<IRegistration> {
    return this.post(`${this.apiUrl}/ValidateAddress`, model);
  }

}
